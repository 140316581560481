import React, { memo } from 'react';
import { Stack } from '@mui/material';

import useTrends from 'library/common/hook/useTrends';
import { ICategory } from 'library/common//types/cards/cards.d';
import { UUID } from 'library/common/types/app.d';

import Trend from './children/Trend/Trend';

interface IProps {
	category: ICategory;
	currentCardId?: UUID;
}

const Trends = ({ category, currentCardId }: IProps) => {
	const { trends } = useTrends({ category, currentCardId });

	return (
		<Stack direction="column" spacing={1} sx={{ width: '180px', px: 4 }}>
			{trends
				.map((trend) => (
					<Trend key={trend.id} {...trend} />
				))}
		</Stack>
	);
};

export default memo(Trends, () => false);
