import { keyBy } from 'lodash';
import {
	useQuery,
	QueryFunctionContext,
	UseQueryResult,
	UseQueryOptions,
} from '@tanstack/react-query';

import { UUID } from 'library/common/types/app.d';
import cardsService from 'library/common/services/cards.service';
import { CardApiData } from 'library/common/types/cards/request.d';
import { CommentResponse, ICommentEntity } from 'library/common/types/cards/comments.d';

import commentsQueryKeys from './queryKeys';

const childCommentsQueryFunction = async ({ queryKey }: QueryFunctionContext<ReturnType<typeof commentsQueryKeys.byCommentId>>): Promise<CommentResponse> => {
	const [, , commentId] = queryKey;

	const res = await cardsService
		.getChildComments<CardApiData<ICommentEntity[]>>(commentId as string);
	return keyBy(res.data.payload, 'comment.id');
};

const useChildComments = (commentId: UUID, options?: UseQueryOptions<CommentResponse>): UseQueryResult<CommentResponse> => useQuery(
	commentsQueryKeys.byCommentId(commentId), childCommentsQueryFunction, { ...options },
);

export default useChildComments;
