import {
	useCallback, useEffect, useMemo, useState,
} from 'react';
import { useUpdateEffect } from 'react-use';

import cardsService from 'library/common/services/cards.service';
import {showSnackbarMessage} from 'library/utils/showSnackbarMessage';
import { IResult } from 'library/common/types/cards/cards.d';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { UUID } from 'library/common/types/app.d';

interface IRes {
    results: IResult[],
	resultsIsPending: boolean,
	setSearchPhrase(value: string): void;
    addNewResult(newOption: IResult): void;
}

const useResult = (categoryId?: UUID): IRes => {
	const {t} = useTranslation();

	const [results, setResults] = useState<IResult[]>([]);
	const [resultsIsPending, setResultsIsPending] = useState<boolean>(true);
	const [searchPhrase, setSearchPhrase] = useState<string>('');

	const addNewResult = (newOption: IResult) => setResults((pevState) => [newOption, ...pevState]);

	const getResult = useCallback(
		(id: UUID, data?: string) => {
			setResultsIsPending(true);

			cardsService
				.getResults({category_id: id, title: data})
				.then((result) => setResults(result.data.payload))
				.catch(() => showSnackbarMessage(t('error_loading_results'), 'error'))
				.finally(() => (setResultsIsPending(false)));
		}, [t],
	);

	const onSearchResultDebounced = useMemo(
		() => debounce((value: string) => {
			if (categoryId === undefined) return;

			getResult(categoryId, value);
		}, 1000),
		[getResult, categoryId],
	);

	useEffect(
		() => {
			if (categoryId) getResult(categoryId);
		},
		[categoryId, getResult],
	);

	useUpdateEffect(
		() => onSearchResultDebounced(searchPhrase),
		[searchPhrase],
	);

	return {
		results,
		resultsIsPending,
		setSearchPhrase,
		addNewResult,
	};
};

export default useResult;
