import React from 'react';
import { styled, Tab, TabProps } from '@mui/material';

const StyledTab = styled((props: TabProps) => (
	<Tab disableRipple {...props} />
))(({ theme }) => ({
	textTransform: 'none',
	fontWeight: theme.typography.fontWeightRegular,
	fontSize: theme.typography.pxToRem(15),
	marginRight: theme.spacing(1),
	color: 'rgba(255, 255, 255, 0.8)',
	[theme.breakpoints.down('md')]: {
		color: theme.palette.text.secondary,
	},
	'&.Mui-selected': {
		fontWeight: 600,
		color: theme.palette.common.white,

		[theme.breakpoints.down('md')]: {
			color: theme.palette.text.primary,
		},
	},
	'&.Mui-focusVisible': {
		backgroundColor: 'rgba(100, 95, 228, 0.32)',

		[theme.breakpoints.down('md')]: {
			color: theme.palette.text.primary,
		},
	},
}));

export default StyledTab;
