import { call, put, takeLatest } from 'redux-saga/effects';
import {AxiosResponse} from 'axios';
import {push} from 'redux-first-history';
import {
	SelfServiceLoginFlow,
	SelfServiceLogoutUrl,
	SelfServiceRegistrationFlow,
} from '@ory/kratos-client/api';

import * as userActions from 'library/common/actions/user.action';
import * as appActions from 'library/common/actions/app.action';
import kratosUtils from 'library/utils/kratos';
import {kratosErrorHandler} from 'library/utils/errorsHandler';
import {showSnackbarMessage} from 'library/utils/showSnackbarMessage';
import { IFullStatistics, IUserData } from 'library/common/types/user.d';
import userActionTypes from 'library/common/constants/user.constants';
import userServices from 'library/common/services/user.service';

function* authorizationSaga() {
	try {
		const profileData: IUserData = yield call(userServices.getProfile);
		const fullStatisticsResult: IFullStatistics = yield call(userServices.getFullStatistics, profileData.id);

		try {
			yield put(userActions.authorizationAsync.success({...profileData, ...fullStatisticsResult}));
		} catch (partialError) {
			// if backend not allow
			try {
				const {data: {logout_token}}: AxiosResponse<SelfServiceLogoutUrl> = yield call(
					userServices.logoutInit,
				);
				yield call(userServices.logout, logout_token);
				yield put(userActions.authorizationAsync.failure());
			} catch (logoutError) {
				// if all services are death
				yield put(appActions.setError(true));
				yield put(push('/500'));
				yield put(userActions.authorizationAsync.failure());
			}
		}
	} catch (kratosReqError) {
		yield put(userActions.authorizationAsync.failure());
	}
}

function* createUserSaga(
	{payload}: ReturnType<typeof userActions.createUserAsync.request>,
) {
	try {
		const {data: registrationRes}: AxiosResponse<SelfServiceRegistrationFlow> = yield call(
			userServices.registrationInit,
		);
		const flowId = registrationRes.id;
		const csrfToken = kratosUtils.extractCSRFToken(registrationRes.ui.nodes);

		yield call(userServices.registration, {flowId, csrfToken, ...payload});
		yield put(userActions.createUserAsync.success());
		yield put(userActions.authorizationAsync.request());
	} catch (error: any) {
		if (error?.response?.status === 422 && error?.response?.data?.redirect_browser_to.length > 0) {
			window.location.replace(error.response.data.redirect_browser_to);
		} else {
			yield put(userActions.createUserAsync.failure());
			showSnackbarMessage(
				`${kratosErrorHandler(error, 'Registration Error')}`,
				'error',
				5000,
			);
		}
	}
}

function* loginSaga(
	{payload: {email, password}}: ReturnType<typeof userActions.loginAsync.request>,
) {
	try {
		const {data: loginRes}: AxiosResponse<SelfServiceLoginFlow> = yield call(userServices.loginInit);
		const flowId = loginRes.id;
		const csrfToken = kratosUtils.extractCSRFToken(loginRes.ui.nodes);

		yield call(userServices.login, flowId, csrfToken, email, password);
		yield put(userActions.loginAsync.success());
		yield put(userActions.authorizationAsync.request());
	} catch (error: any) {
		showSnackbarMessage(
			`${kratosErrorHandler(error, 'Authorisation Error')}`,
			'error',
			5000,
		);
		yield put(userActions.loginAsync.failure());
	}
}

function* logout() {
	try {
		const {data: {logout_token}}: AxiosResponse<SelfServiceLogoutUrl> = yield call(userServices.logoutInit);
		yield call(userServices.logout, logout_token);

		yield put(userActions.logoutAsync.success());
		yield put(appActions.dropNotificationsList());
	} catch (error) {
		yield put(userActions.logoutAsync.failure());
	}
}

export default function* watchCommonLayout() {
	yield takeLatest(userActionTypes.CREATE_USER_REQUEST, createUserSaga);
	yield takeLatest(userActionTypes.AUTHORIZATION_REQUEST, authorizationSaga);
	yield takeLatest(userActionTypes.LOGIN_REQUEST, loginSaga);
	yield takeLatest(userActionTypes.LOGOUT_REQUEST, logout);
}
