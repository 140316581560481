import React from 'react';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { Box, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { UUID } from 'library/common/types/app.d';
import {getDateString} from 'library/utils/textGenerators';
import { declOfNum, getDuration } from 'library/utils/workWithData';

import { ICardActionListItem } from 'library/common/types/cards/cards.d';
import VerifiedIcon from 'resources/icons/VerifiedIcon';
import AvatarButton from '../../AvatarButton';
import Label from '../../Label/Label';
import Menu from './Menu';

interface IWrapper {
	id: UUID;
    title: string;
    stageCount: number;
    duration: number;
    date: Date;
    result?: string;
    author?: string;
    authorID?: string | null;
    userPhoto?: string | null;
	isVisible: boolean;
	isVerified?: boolean;
	disabled?: boolean;
	onMenuCloseHandlee?: (x: number, y: number) => void;
	cardButtonActionsList?: ICardActionListItem[];
}

const Wrapper = ({
	id,
	title,
	stageCount,
	duration,
	date,
	result,
	author,
	authorID,
	userPhoto,
	isVisible,
	disabled,
	onMenuCloseHandlee,
	cardButtonActionsList,
	isVerified,
}: IWrapper) => (
	<Stack
		direction="column"
		sx={{
			position: 'absolute',
			p: 2,
			pb: '38px',
			top: 0,
			right: 0,
			width: '100%',
			height: '100%',
			background: (theme) => (theme.palette as any).gradients.secondary,
			boxShadow: '2px 4px 4px rgba(66, 94, 248, 0.25)',
			backdropFilter: 'blur(20px)',
			borderRadius: '12px',
			opacity: isVisible ? 1 : 0,
			transition: 'all .5s ease-in-out',

			'&:hover': {
				opacity: 1,
			},

		}}
	>
		{!disabled && (
			<RouterLink
				to={`/app/card/${id}`}
				style={{
					top: 0,
					left: 0,
					zIndex: 1,
					height: '100%',
					width: '100%',
					position: 'absolute',
				}}
			/>
		)}
		<Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} sx={{ userSelect: 'none'}}>
			<Stack direction="row" alignItems="center" sx={{ overflow: 'hidden' }}>
				<AvatarButton
					sx={{ zIndex: 10 }}
					size={44}
					author={author}
					authorId={authorID}
					userPhoto={userPhoto}
				/>
				<Stack
					direction="row"
					spacing={1}
					sx={{
						ml: 1,
						minWidth: 0,
						pr: 1,
					}}
				>
					<Label
						title={`${author}`}
						variant="body1"
						sx={{
							WebkitLineClamp: 1,
							color: (theme) => theme.palette.common.white,
							fontWeight: 500,
							display: 'block',
						}}
					/>
					{isVerified && <VerifiedIcon sx={{ width: '16px', height: '16px' }} />}
				</Stack>
			</Stack>

			<Box>
				<Menu
					id={id}
					disabled={disabled}
					items={cardButtonActionsList}
					onClose={onMenuCloseHandlee}
				/>
			</Box>
		</Stack>

		<Stack direction="column" spacing={1} mt={10}>
			<Typography variant="caption" sx={{color: (theme) => theme.palette.common.white}}>
				{getDateString(date)}
			</Typography>

			<Label
				variant="h5"
				component="h5"
				title={title}
				sx={{
					WebkitLineClamp: 3,
					color: (theme) => theme.palette.common.white,
					fontWeight: 900,
				}}
			/>
		</Stack>

		<Stack direction="column" spacing={0.5} mt={2}>
			<Typography variant="body1" sx={{color: (theme) => theme.palette.common.white}}>
				{`you need ${getDuration(duration)}`}
			</Typography>
			<Typography variant="body1" sx={{color: (theme) => theme.palette.common.white}}>
				{`for ${stageCount} ${declOfNum(stageCount, ['step', 'steps', 'steps'])}`}
			</Typography>
		</Stack>

		<Stack direction="column" mt="auto" spacing={0.5}>
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				<Typography variant="body1" sx={{color: (theme) => theme.palette.common.white}}>
					{`#${result}`}
				</Typography>

				<EmojiEventsIcon
					sx={{
						color: (theme) => (theme.palette as any).gradients.primary,
						opacity: 0.8,
						width: 30,
						height: 32,
					}}
				/>
			</Stack>

			<Box
				sx={{
					background: (theme) => (theme.palette as any).gradients.info,
					height: '2px',
					borderRadius: '2px',
				}}
			/>
		</Stack>

	</Stack>
);

export default Wrapper;
