import React, { useCallback } from 'react';
import {Box, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TFunction } from 'react-i18next';

import { setNotificationViewed } from 'library/common/actions/app.action';
import { INotification } from 'library/common/types/notifications/notifications.d';
import NotificationType from 'library/common/types/notifications/notificationTypes';
import NotificationItem from './NotificationItem';

interface IProps {
	notifications: INotification[];
	t: TFunction<'translation'>;
	handleCloseActionsListAnchor(): void;
}

const NotificationsList: React.FunctionComponent<IProps> = ({notifications, t, handleCloseActionsListAnchor}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const onNotificationHandler = useCallback(
		(notification: INotification) => {
			dispatch(setNotificationViewed(notification.id));

			switch (notification.type) {
			case NotificationType.COMMENT: {
				navigate(`/app/card/${notification.cardId}`);
				handleCloseActionsListAnchor();
				break;
			}
			case NotificationType.ACTIVATION: {
				navigate(`/app/card/${notification.cardId}`);
				handleCloseActionsListAnchor();
				break;
			}
			case NotificationType.CHILD_COMMENT: {
				if (notification.cardId) {
					navigate(`/app/card/${notification.cardId}`);
				}
				handleCloseActionsListAnchor();
				break;
			}
			case NotificationType.LIKE: {
				if (notification.parentCardId) {
					navigate(`/app/card/${notification.parentCardId}`);
				}
				handleCloseActionsListAnchor();
				break;
			}
			case NotificationType.SUBSCRIPTION:
			default:
				handleCloseActionsListAnchor();
				break;
			}
		},
		[dispatch, handleCloseActionsListAnchor, navigate],
	);

	if (notifications.length === 0) {
		return (
			<Box
				key="empty_notifications_list"
				sx={{
					height: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					width: '400px',
				}}
			>
				<Typography variant="h3" sx={{ opacity: 0.3 }}>
					{t('empty_list')}
				</Typography>
			</Box>
		);
	}

	return (
		<>
			{notifications.map((notification) => (
				<NotificationItem
					t={t}
					key={notification.id}
					notification={notification}
					onNotificationHandler={onNotificationHandler}
				/>
			))}
		</>
	);
};

export default NotificationsList;
