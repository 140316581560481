import React, { Dispatch, SetStateAction } from 'react';
import {
	Box,
	Divider,
	List,
	Typography,
	CircularProgress,
} from '@mui/material';
import { useSelector } from 'react-redux';
import {useTranslation} from 'react-i18next';

import {declOfNum} from 'library/utils/workWithData';
import MenuPopover from 'library/common/components/Popovers/MenuPopover/MenuPopover';
import { selectNotifications } from 'library/common/selectors/app.selector';

import NotificationsList from './NotificationsList';

interface IActionsWindow {
	actionsListAnchor: null | HTMLElement;
	setActionsListAnchor: Dispatch<SetStateAction<HTMLElement | null>>;
	notificationCount: number;
}

const Notifications = ({actionsListAnchor, setActionsListAnchor, notificationCount}: IActionsWindow) => {
	const {t} = useTranslation();
	const open = Boolean(actionsListAnchor);
	const id = open ? 'notifications-popover' : undefined;

	const notificationsList = useSelector(selectNotifications);

	const isLoading = false;

	const handleCloseActionsListAnchor = () => setActionsListAnchor(null);

	return (
		<MenuPopover
			id={id}
			open={open}
			anchorEl={actionsListAnchor}
			onClose={handleCloseActionsListAnchor}
			sx={{
				width: '400px',
			}}
		>
			<Box sx={{
				display: 'flex', alignItems: 'center', py: 2, px: 2.5,
			}}
			>
				<Box sx={{ flexGrow: 1 }}>
					<Typography variant="subtitle1">Notifications</Typography>
					{notificationCount > 0 && (
						<Typography variant="body2" sx={{color: 'text.secondary'}}>
							{`${t('you_have_NUMBER_unread_messages')
								.replace('{totalUnRead}', notificationCount.toString())}`}
							&nbsp;
							{`${declOfNum(notificationCount, JSON.parse(t('message')))}`}
						</Typography>
					)}
				</Box>
			</Box>

			<Divider />

			<List
				aria-label="notifications list"
				sx={{width: '400px'}}
			>
				<Box sx={{
					height: {
						xs: 240, md: 340, lg: 440, xl: 500,
					},
					overflowX: 'auto',
				}}
				>
					{
						isLoading ? (
							<Box
								sx={{
									height: '100%',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<CircularProgress color="primary" />
							</Box>
						) : (
							<NotificationsList
								t={t}
								handleCloseActionsListAnchor={handleCloseActionsListAnchor}
								notifications={notificationsList}
							/>
						)
					}
				</Box>
			</List>
		</MenuPopover>
	);
};

export default Notifications;
