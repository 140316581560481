import React from 'react';
import {
	Box, Button, Stack, Theme, Typography,
} from '@mui/material';

import SocialLinks from 'library/common/components/SocialLinks';
import Title from 'library/common/components/Title/Title';
import {Link as RouterLink} from 'react-router-dom';

interface IProps {
	isAuth?: boolean;
	onJoinThePlatform: () => void;
}

const Footer: React.FunctionComponent<IProps> = ({ isAuth, onJoinThePlatform }) => (
	<Stack
		sx={{
			px: { xs: 2, sm: 12 },
			position: 'absolute',
			height: '166px',
			left: 0,
			right: 0,
			background: '#151133',
		}}
	>
		<Stack
			sx={{ mt: 4, width: '100%', justifyContent: 'space-between' }}
			direction="row"
		>
			<Title
				type="light"
				logoSizePx={44}
				style={{
					color: (theme) => theme.palette.common.white,
					fontSize: '24px',
					lineHeight: '25px',
				}}
			/>

			<Button
				sx={{
					height: {sx: '24px', sm: '48px' },
					fontSize: {sx: '8px', sm: '16px' },
					fontWeight: 400,
				}}
				variant="contained"
				onClick={onJoinThePlatform}
			>
				{`${isAuth ? 'Back to' : 'Join'} the platform`}
			</Button>
		</Stack>

		<Box sx={{ display: 'flex', justifyContent: 'flex-end'}}>
			<SocialLinks
				containerProps={{ mt: 2 }}
				linkSx={{ color: (theme: Theme) => theme.palette.common.white }}
			/>
		</Box>

		<Stack direction="row" spacing={5} mb={2}>
			<Typography variant="caption" sx={{ color: (theme) => theme.palette.secondary.light }}>
				© WodaQota. All rights reserved
			</Typography>

			<Typography
				component={RouterLink}
				variant="caption"
				sx={{ color: (theme) => theme.palette.secondary.light }}
				to="/privacy-notice"
			>
				Privacy policy
			</Typography>
		</Stack>

	</Stack>
);

export default Footer;
