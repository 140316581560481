import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Box,
	Collapse,
	Stack,
	SxProps,
	Typography,
	Theme,
	IconButton,
} from '@mui/material';

import FilledArrowIcon from 'resources/icons/FilledArrowIcon';
import { CountedResource, IStep } from 'library/common/types/cards/cards.d';
import ResourcesList from 'library/common/components/ResourcesList';
import countDuplicateResources from 'library/utils/countDuplicateResources';

interface IProps {
	steps: IStep[];
	sx?: SxProps<Theme>;
}

const AllResourcesList: React.FunctionComponent<IProps> = ({ steps, sx }) => {
	const {t} = useTranslation();

	const [isAllResourcesVisible, setIsAllResourcesVisible] = useState<boolean>(false);

	const countedResources: CountedResource[] = useMemo(() => countDuplicateResources(steps), [steps]);

	return (
		<Stack sx={sx}>
			<Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
				<Typography sx={{ color: ({palette}) => palette.text.primary, fontWeight: 600 }} variant="h6">
					{t('resources')}
				</Typography>
				<IconButton onClick={() => setIsAllResourcesVisible((prevState) => !prevState)}>
					<FilledArrowIcon sx={{
						color: ({palette}) => palette.text.secondary,
						fontSize: '16px',
						...(!isAllResourcesVisible && { transform: 'rotate(-180deg)' }),
					}}
					/>
				</IconButton>
			</Stack>

			<Box
				sx={{
					height: '2px',
					width: '100%',
					background: ({palette}) => palette.grey.A400,
					borderRadius: '1px',
					visibility: 'visible',
					transition: 'opacity 0.3s, visibility 0.3s',
					...(isAllResourcesVisible && { opacity: 0, visibility: 'hidden' }),
				}}
			/>

			<Collapse orientation="vertical" in={isAllResourcesVisible} mountOnEnter unmountOnExit>
				<ResourcesList stepResources={countedResources} />
			</Collapse>
		</Stack>
	);
};

export default AllResourcesList;
