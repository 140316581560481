import React from 'react';
import { styled, Tabs, TabsProps } from '@mui/material';

const StyledTabs = styled((props: TabsProps) => (
	<Tabs
		{...props}
		TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
	/>
))(({ theme }) => ({
	'& .MuiTabs-indicator': {
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: 'transparent',
	},
	'& .MuiTabs-indicatorSpan': {
		width: '100%',
		background: (theme.palette as any).gradients.primary,
	},
}));

export default StyledTabs;
