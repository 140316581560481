import { IStep } from 'library/common/types/cards/cards.d';

export default (steps: IStep[], step1: IStep, step2: IStep) => steps
	.map((step) => {
		if (step1.id === step.id) {
			return { ...step, weight: step2.weight };
		}

		if (step2.id === step.id) {
			return { ...step, weight: step1.weight };
		}

		return { ...step, weight: step.weight };
	});
