import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Grid, Typography} from '@mui/material';

import { ICardFormData } from 'library/common/types/cards/cardsCreation.d';
import timeConverter from 'library/utils/converters/time.converter';
import Card from 'library/common/components/Card/Card';
import Image from 'library/common/components/inputs/Image';
import { selectUserData } from 'library/common/selectors/user.selector';

interface ICoverButton {
	setCover: (cover: string | null) => void;
	error: boolean;
	disabled: boolean
	helperText?: string;
	formState: ICardFormData;
}

const CoverButton = ({
	setCover, error, disabled, helperText, formState,
}: ICoverButton) => {
	const {t} = useTranslation();

	const [imgURL, setImgURL] = useState<string>('');

	const {
		id: authorID,
		username: authorName,
		logoURL: userPhoto,
	} = useSelector(selectUserData);

	return (
		<Grid container sx={{ width: '100%' }} spacing={{ sx: 0, md: 2 }}>
			<Grid
				item
				xs={12}
				md={6}
				sx={{ display: 'flex', flexDirection: 'column' }}
			>
				<Typography
					sx={{
						fontWeight: 600,
						color: (theme) => theme.palette.text.secondary,
						mb: 3,
					}}
					variant="h5"
				>
					{t('cover')}
				</Typography>

				<Typography
					sx={{
						fontWeight: 500,
						color: (theme) => theme.palette.text.secondary,
					}}
					variant="caption"
				>
					{t('download_a_wodaqard_cover')}
				</Typography>

				{error && helperText && (
					<Typography
						variant="caption"
						color="error"
					>
						{helperText}
					</Typography>
				)}

				<Image
					sx={{ mt: 'auto', mb: 1 }}
					disabled={disabled}
					setData={setCover}
					setFileUrl={setImgURL}
					allowDropImageButton
				/>
			</Grid>
			<Grid
				item
				xs={12}
				md={6}
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Card
					id="1"
					title={formState.title}
					category={formState.category?.title || ''}
					poster={imgURL}
					duration={timeConverter(formState.days, formState.hours, formState.minutes, formState.seconds)}
					result={formState?.result?.title || ''}
					author={authorName}
					date={new Date()}
					comments={0}
					activations={0}
					authorID={authorID}
					userPhoto={userPhoto}
					stageCount={Object.keys(formState.stages).length}
					withBorder
					disabled
				/>
			</Grid>
		</Grid>
	);
};

export default CoverButton;
