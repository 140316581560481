import React from 'react';
import { useTranslation } from 'react-i18next';
import {Box, Container, Stack} from '@mui/material';

import { ModeType } from 'library/common/hook/useCard';
import TabPanel from 'library/common/components/Tabs/TabPanel';
import Profile from 'pages/Profile/desktop/components/Profile';
import StyledTab from 'pages/Profile/components/Styled/StyledTab';
import StyledTabs from 'pages/Profile/components/Styled/StyledTabs';
import ListWrapper from 'pages/Profile/components/List/ListWrapper';

import PublishedList from './components/PublishedList';
// import ActivatedList from './components/ActivatedList';
import { ISelfProfileProps } from '../types.d';
import SearchInput from '../components/SearchInput';

// TODO сделать хук, который будет следить за страницами и выдавать в дашборде нужные стили на нужной странице

const DesktopSelfProfile: React.FunctionComponent<ISelfProfileProps> = ({
	ListMode,
	// /activatedCards,
	favoriteCards,
	isCardsPending,
	isPending,
	isWodaBalanceLoading,
	profileData,
	progress,
	publishedCards,
	// setActivatedModeHandler,
	setAvatarRequest,
	setPublishedModeHandler,
	setFavoriteModeHandler,
	wodaBalance,
	getPublishedCards,
}) => {
	const { t } = useTranslation();

	return (
		<Container
			disableGutters
			maxWidth={false}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				backgroundColor: (theme) => theme.palette.secondary.main,
			}}
		>
			<Profile
				selfPage
				isPending={isPending}
				isWodaBalanceLoading={isWodaBalanceLoading}
				progress={progress}
				setAvatarRequest={setAvatarRequest}
				publishedCardsCount={publishedCards.length}
				wodaBalance={wodaBalance}
				{...profileData}
			/>

			<Box sx={{
				mx: 4,
				mt: 3,
				mb: 2,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'flex-end',
			}}
			>
				<Box sx={{ flexGrow: 1 }}>
					{profileData.id && ListMode === ModeType.PUBLISHED && (
						<SearchInput
							userId={profileData.id}
							getPublishedCards={getPublishedCards}
							sx={{ justifyContent: 'flex-start', alignItems: 'center' }}
						/>
					)}
				</Box>
				<StyledTabs value={ListMode} aria-label="basic tabs example">
					<StyledTab label={t(ModeType.PUBLISHED)} value={ModeType.PUBLISHED} onClick={setPublishedModeHandler} />
					<StyledTab label={t(ModeType.FAVORITE)} value={ModeType.FAVORITE} onClick={setFavoriteModeHandler} />
					{/* <StyledTab label={t(ModeType.ACTIVATED)} value={ModeType.ACTIVATED} onClick={setFavoriteModeHandler} /> */}
				</StyledTabs>
			</Box>

			<Container
				maxWidth="xl"
				disableGutters
			>
				<Stack
					direction="column"
					sx={{ height: '100%' }}
				>
					<TabPanel<ModeType> value={ListMode} index={ModeType.PUBLISHED}>
						<ListWrapper
							label={t('no_wodaqards')}
							isCardsPending={isCardsPending}
							cards={publishedCards}
							List={PublishedList}
						/>
					</TabPanel>
					<TabPanel<ModeType> value={ListMode} index={ModeType.FAVORITE}>
						<ListWrapper
							label={t('no_wodaqards')}
							isCardsPending={isCardsPending}
							cards={favoriteCards}
							List={PublishedList}
						/>
					</TabPanel>
					{/* <TabPanel<ModeType> value={ListMode} index={ModeType.ACTIVATED}>
						<ListWrapper
							label={t('no_wodaqards')}
							isCardsPending={isCardsPending}
							cards={activatedCards}
							List={ActivatedList}
						/>
					</TabPanel> */}
				</Stack>
			</Container>
		</Container>
	);
};

export default DesktopSelfProfile;
