import React, { useState } from 'react';
import {TFunction} from 'react-i18next';
import {
	Box, LinearProgress, TextField, Typography,
} from '@mui/material';
import {SxProps} from '@mui/system';
import {Theme} from '@mui/material/styles';

const MAX_LENGTH = 150;

interface IText {
	t: TFunction<'translation'>;
	value?: string;
	haveError: boolean;
	disabled: boolean;
	setData: (data: string) => void;
	sx?: SxProps<Theme>;
}

const Text = ({
	value = '', disabled, setData, t, haveError, sx,
}: IText) => {
	const [text, setText] = useState<string>(value);
	return (
		<Box sx={sx}>
			<Box sx={{position: 'relative'}}>
				<TextField
					id="outlined-multiline-card-text"
					disabled={disabled}
					label={t('description')}
					inputProps={{ maxLength: MAX_LENGTH }}
					multiline
					rows={6}
					variant="outlined"
					fullWidth
					margin="normal"
					value={text}
					onChange={(e) => setText(e.target.value)}
					onBlur={(e) => setData(e.target.value)}
					error={haveError}
				/>
				<Typography variant="body1">{`${text.length} / ${MAX_LENGTH}`}</Typography>
			</Box>
			<LinearProgress
				variant="determinate"
				color="primary"
				value={Math.round((text.length * 100) / MAX_LENGTH)}
			/>
		</Box>
	);
};

export default Text;
