import React, {useState} from 'react';
import AuthorizationLayout from './components/AuthorizationLayout';
import SignInForm from './SignIn/SignInForm';
import EmailForm from './SignIn/EmailForm';

const SingIn = () => {
	const [mode, setMode] = useState<'log_in' | 'reset_password'>('log_in');

	return (
		<AuthorizationLayout>
			{
				mode === 'log_in' ? (
					<SignInForm resetPasswordMode={() => setMode('reset_password')} />
				) : (
					<EmailForm logInMode={() => setMode('log_in')} />
				)
			}
		</AuthorizationLayout>
	);
};

export default SingIn;
