import React from 'react';

import {
	Box,
	Stack, SxProps, Theme,
} from '@mui/material';

import CardInformation from 'pages/Card/components/CardInformation';
import { IDetailedCard } from 'library/common/types/cards/cards.d';
import { COVER_SIZE, SX_COVER_SIZE } from 'pages/Card/environments';
import { getPoster } from 'library/utils/getters/getPicture';

interface IProps {
	sx: SxProps<Theme>;
    authorMode: boolean;
    isAuthorized: boolean;
    // isActionPending: boolean;
    detailedCard: IDetailedCard;
    totalResources: number;
    // toggleCardActivation(): void;
    toggleCardLike(): void;
    toggleEditMode(): void;
    deleteCardHandler(): Promise<void>;
	handleShareCard(): void;
}

const CardHeader: React.FunctionComponent<IProps> = ({
	sx,
	authorMode,
	isAuthorized,
	// isActionPending,
	detailedCard,
	totalResources,
	deleteCardHandler,
	toggleEditMode,
	// toggleCardActivation,
	toggleCardLike,
	handleShareCard,
}) => (
	<Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 3, md: 8 }} sx={{ ...sx, width: '100%', mt: 2 }}>
		<Box
			sx={{
				width: { xs: SX_COVER_SIZE.width, md: COVER_SIZE.width },
				height: { xs: SX_COVER_SIZE.width, md: COVER_SIZE.width },
			}}
		>
			<Box
				component="img"
				sx={{
					width: { xs: SX_COVER_SIZE.width, md: COVER_SIZE.width },
					height: { xs: SX_COVER_SIZE.width, md: COVER_SIZE.width },
				}}
				src={getPoster(detailedCard, 'BIG')}
			/>
		</Box>
		<CardInformation
			isAuth={isAuthorized}
			authorMode={authorMode}
			detailedCard={detailedCard}
			totalResources={totalResources}
			// isActionPending={isActionPending}
			// toggleCardActivation={toggleCardActivation}
			toggleCardLike={toggleCardLike}
			deleteCardHandler={deleteCardHandler}
			toggleEditMode={toggleEditMode}
			handleShareCard={handleShareCard}
		/>
	</Stack>
);

export default CardHeader;
