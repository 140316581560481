import React from 'react';
import { useLocation } from 'react-router-dom';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, useMediaQuery } from '@mui/material';

import { APPBAR_DESKTOP, APPBAR_MOBILE, DRAWER_WIDTH } from 'resources/styles/constants';
import useMainPageStyles from 'library/common/hook/useMainPageStyles';

import Mobile from './Mobile';
import Desktop from './Desktop';

const RootStyle = styled(AppBar)(({ theme }) => ({
	boxShadow: 'none',
	backdropFilter: 'blur(6px)',
	WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
	backgroundColor: alpha(theme.palette.background.default, 0.72),
	[theme.breakpoints.up('lg')]: {
		width: `calc(100% - ${DRAWER_WIDTH}px)`,
	},
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
	height: '80px',
	boxSizing: 'content-box',
	alignItems: 'baseline',
	[theme.breakpoints.down('md')]: {
		padding: 0,
		height: '50px',
	},
	[theme.breakpoints.up('lg')]: {
		padding: theme.spacing(0, 5, 0, 5),
	},
}));

interface IDashboardNavbar {
	onOpenSidebar: () => void;
}

const DashboardNavbar = ({onOpenSidebar}: IDashboardNavbar) => {
	const {pathname} = useLocation();
	const theme = useTheme();

	const isDesktop = useMediaQuery((theme.breakpoints.up('md')));

	const isMainPageStyles = useMainPageStyles();

	const isProfilePage = pathname.includes('/app/profile');

	return (
		<RootStyle
			sx={{
				...(isProfilePage && {
					backgroundColor: 'rgba(255, 255, 255, 0)',
				}),
			}}
		>
			<ToolbarStyle sx={{
				...(isMainPageStyles && {
					height: { xs: APPBAR_MOBILE, md: APPBAR_DESKTOP},
				}),
			}}
			>
				{
					isDesktop ? (
						<Desktop
							isMainPage={isMainPageStyles}
							onOpenSidebar={onOpenSidebar}
						/>
					) : (
						<Mobile isMainPage={isMainPageStyles} />
					)
				}
			</ToolbarStyle>
		</RootStyle>
	);
};

export default DashboardNavbar;
