import React from 'react';
import {compose} from 'redux';
import {Navigate, useRoutes} from 'react-router-dom';

import ProtectedRoute from 'library/common/components/withProtectedRoute/withProtectedRoute';
import withInitialization from 'library/common/components/withInitialization/withInitialization';
import Dashboard from 'library/common/components/Dashboard/Dashboard';
import Page500 from 'pages/Service/500';
import Page404 from 'pages/Service/404';
import Main from 'pages/Main';
import Card from 'pages/Card';
import Terms from 'pages/Docs/TermsOfUse';
import SingIn from 'pages/Authorization/SingIn';
import SingUp from 'pages/Authorization/SingUp';
import NoCard from 'pages/Card/NoCard';
import Consulting from 'pages/Consulting/Consulting';
import PrivacyNotice from 'pages/Docs/PrivacyNotice';
import CardCreation from 'pages/CardCreation';
import PasswordReset from 'pages/PasswordReset/PasswordReset';
import NeedHelp from 'pages/Docs/NeedHelp';
import Settings from 'pages/Settings/Settings';
import CookiesPolicy from 'pages/Docs/CookiesPolicy';
import Profile from 'pages/Profile';
import CareerOptions from 'pages/Docs/CareerOptions';
import Landing from 'pages/Landing';
import Liked from 'pages/Liked';
import Global from 'pages/Wodabonus/Global';
import Management from 'pages/Wodabonus/Management';
import Programs from 'pages/Wodabonus/Programs';

const Routes = () => useRoutes([
	{
		path: '/app',
		element: <Dashboard />,
		children: [
			{ element: <Navigate to="/app/main" replace /> },
			{ path: 'main/*', element: <Main /> },
			{
				path: 'card',
				children:
						[
							{ path: '', element: <NoCard /> },
							{ path: ':id', element: <Card /> },
						],
			},
			{
				path: 'card-create',
				children: [
					{ path: '', element: <ProtectedRoute Page={CardCreation} /> },
					{ path: 'flow', element: <ProtectedRoute Page={CardCreation} /> },
				],
			},
			{ path: 'settings', element: <ProtectedRoute Page={Settings} /> },
			{
				path: 'profile',
				children:
						[
							{ path: '', element: <ProtectedRoute Page={Profile} /> },
							{ path: ':id', element: <Profile /> },
						],
			},
			{ path: 'liked', element: <ProtectedRoute Page={Liked} /> },
			{
				path: 'bonuses',
				children: [
					{ path: '', element: <Navigate to="/app/bonuses/global" /> },
					{ path: 'global', element: <Global /> },
					{ path: 'programs', element: <Programs /> },
					{ path: 'management', element: <ProtectedRoute Page={Management} /> },
				],
			},
		],
	},
	{
		path: '/',
		children: [
			{ path: 'login', element: <SingIn /> },
			{ path: 'registration', element: <SingUp /> },
			{ path: 'password-reset', element: <PasswordReset /> },
			{ path: 'consulting', element: <Consulting /> },
			{ path: 'privacy-notice', element: <PrivacyNotice /> },
			{ path: 'cookies-policy', element: <CookiesPolicy /> },
			{ path: 'career-options', element: <CareerOptions /> },
			{ path: 'help', element: <NeedHelp /> },
			{ path: 'landing', element: <Landing /> },
			{ path: 'terms', element: <Terms /> },
			{ path: '500', element: <Page500 /> },
			{ path: '/', element: <Navigate to="/app/main" replace /> },
		],
	},
	{ path: '', element: <Page404 /> },
	{ path: '*', element: <Page404 /> },
]);

export default compose(withInitialization)(Routes);
