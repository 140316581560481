import React from 'react';
import {Box, CircularProgress, Typography} from '@mui/material';

import { IPoorCard } from 'library/common/types/cards/cards.d';

interface IProps {
    cards: IPoorCard[];
	isCardsPending: boolean;
    label: string;
    List: any;
}

const ListWrapper: React.FunctionComponent<IProps> = ({
	cards, isCardsPending, label, List,
}) => {
	if (isCardsPending) {
		return (
			<Box sx={{
				width: '100%', height: '100%', display: 'flex',
			}}
			>
				<CircularProgress sx={{ margin: 'auto', color: (theme) => theme.palette.common.white }} />
			</Box>
		);
	}

	if (cards.length === 0) {
		return (
			<Box sx={{
				width: '100%', height: '100%', display: 'flex',
			}}
			>
				<Typography
					variant="h4"
					sx={{
						margin: 'auto',
						opacity: 0.4,
						color: (theme) => theme.palette.common.white,
					}}
				>
					{label}
				</Typography>
			</Box>
		);
	}

	return <List cards={cards} />;
};

export default ListWrapper;
