import React from 'react';
import ReactDOM from 'react-dom';
// убрать
import {Provider} from 'react-redux';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import ReactGA from 'react-ga4';
import {I18nextProvider} from 'react-i18next';
import { QueryClientProvider } from '@tanstack/react-query';

import i18n from 'library/utils/language/conf';

import App from './main/App';
import rootSaga from './main/rootSaga';
import reportWebVitals from './reportWebVitals';
import {colorLogger} from './library/utils/lintersSugar';
import {
	history, queryClient, sagaMiddleware, store,
} from './main/rootReducer';

import 'tui-image-editor/dist/tui-image-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';

import './resources/styles/index.css';

import packageData from '../package.json';

colorLogger(`version ${packageData.version}`);

sagaMiddleware.run(rootSaga);

const isAnalyticsAvailable = document.getElementById('is-analytics-available')?.dataset?.analytics;

// todo вынести в хелпер
if (isAnalyticsAvailable === 'true') {
	ReactGA.initialize('G-8H8F14GTTM');

	history.listen((window) => {
		ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
	});
} else {
	console.log('🧿 no analytics mode🧿');
}

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<Router history={history}>
				<I18nextProvider i18n={i18n}>
					<QueryClientProvider client={queryClient}>
						<App />
					</QueryClientProvider>
				</I18nextProvider>
			</Router>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root'),
);

reportWebVitals();
