import { noop } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactQueryDevtoolsPanel } from '@tanstack/react-query-devtools';

import { selectIsReactQueryDevtoolsOpen } from 'library/common/selectors/app.selector';
import { setReactQueryDevtoolsOpen } from 'library/common/actions/app.action';

const ReactQueryDevtools: React.FunctionComponent = () => {
	const isReactQueryDevtoolsOpen = useSelector(selectIsReactQueryDevtoolsOpen);

	const dispatch = useDispatch();

	useEffect(() => {
		const openQueryDevtools = (ev: KeyboardEvent) => {
			if (ev.code === 'KeyD' && ev.ctrlKey) {
				dispatch(setReactQueryDevtoolsOpen());
			}
		};

		window.addEventListener('keyup', openQueryDevtools, false);

		return () => {
			window.addEventListener('keyup', openQueryDevtools, false);
		};
	}, []);

	if (!isReactQueryDevtoolsOpen) {
		return null;
	}

	return (
		<ReactQueryDevtoolsPanel
			isOpen
			setIsOpen={noop}
			onDragStart={noop}
			showCloseButton
			closeButtonProps={{
				onClick: () => dispatch(setReactQueryDevtoolsOpen()),
			}}
			style={{
				zIndex: 100000,
				position: 'fixed',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				height: 'auto',
			}}
		/>
	);
};

export default ReactQueryDevtools;
