import React, { useState, MouseEvent } from 'react';
import {
	MenuItem,
	IconButton,
	ListItemIcon,
	Menu as MenuWindow,
	Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { isEmpty } from 'lodash';

import { ICardActionListItem } from 'library/common/types/cards/cards.d';
import { UUID } from 'library/common/types/app.d';

interface IMenu {
	id: UUID;
	disabled?: boolean;
    items?: ICardActionListItem[];
	onClose?: (x: number, y: number) => void;
}

const Menu = ({
	id, disabled, items = [], onClose,
}: IMenu) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const open = Boolean(anchorEl);

	const handleClick = (event: MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (event: MouseEvent) => {
		event.stopPropagation();
		if (onClose) onClose(event.clientX, event.clientY);
		setAnchorEl(null);
	};

	const isElementAvailable = isEmpty(items);

	if (isElementAvailable) {
		return null;
	}

	return (
		<>
			<IconButton
				onClick={handleClick}
				aria-controls={open ? 'account-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				sx={{
					position: 'relative',
					zIndex: 10,
					width: '40px',
					height: '40px',
					background: (theme) => theme.palette.common.white,
					opacity: open ? 0.5 : 0.25,
					'&:hover': {
						background: (theme) => theme.palette.common.white,
						opacity: 0.5,
					},
				}}
				size="large"
			>
				<MoreVertIcon fontSize="inherit" />
			</IconButton>

			<MenuWindow
				anchorEl={anchorEl}
				id="account-menu"
				open={open}
				onClose={handleClose}
				// onClick={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						background: 'rgba(253, 252, 251, 0.7)',
						backdropFilter: 'blur(20px)',
						borderRadius: 1.5,
						'& .MuiListItemIcon-root': {
							color: (theme) => theme.palette.text.secondary,
						},
					},
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				{
					items.map((item) => (
						<MenuItem
							disabled={disabled}
							onClick={(e) => {
								item.callback?.(id);
								handleClose(e);
							}}
							key={item.title}
							sx={{
								height: '48px',
								'&:hover': {
									backgroundColor: 'rgba(145, 158, 171, 0.3)',
								},
							}}
						>
							<ListItemIcon>
								<item.icon />
							</ListItemIcon>
							<Typography variant="caption" sx={{color: (theme) => theme.palette.text.secondary}}>
								{item.title}
							</Typography>
						</MenuItem>
					))
				}
			</MenuWindow>
		</>
	);
};

export default Menu;
