import { v1 as uuidv1 } from 'uuid';

import { ICommentLikeMessagePayload } from 'library/common/types/cards/comments.d';
import { IBaseNotificationMessage, INotificationLike } from 'library/common/types/notifications/notifications.d';
import { mapAuthorData } from 'library/utils/mappers/user.mapper';
import NotificationType from 'library/common/types/notifications/notificationTypes';

import { MessageEventCode } from '../eventCode';

export type ILikeCommentMessage = IBaseNotificationMessage<
    MessageEventCode.LIKE_COMMENT,
    ICommentLikeMessagePayload>;

export const mapLikeCommentMessage = (data: ICommentLikeMessagePayload): INotificationLike => ({
	...mapAuthorData(data.author),
	// TODO подстановку id удалить после добаления хранилица у бекенда (должны и id добавить)
	id: uuidv1(),
	type: NotificationType.LIKE,
	parentCardId: data.data.cardId,
	parentCommentId: data.data.parentComment?.id,
	commentId: data.data.id,
	commentText: data.data.text,
	createdAt: new Date().getTime() as unknown as Date,
	viewed: false,
});
