import React from 'react';
import {
	Stack, Chip, Theme, Typography,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { UUID } from 'library/common/types/app.d';
import { declOfNum } from 'library/utils/workWithData';
import { IReelsStep } from 'library/common/types/cards/reels.d';
import UnionIcon from 'resources/icons/UnionIcon';

import ResourcesBlock from './ResourcesBlock';

interface IProps {
	cardId: UUID;
	duration: string;
	title: string;
	result: string;
    steps: IReelsStep[];
	isResourcesVisible: boolean;
}

const chipStyles = {
	px: 1,
	borderRadius: '12px',
	backgroundColor: (theme: Theme) => theme.palette.grey[200],
	height: '40px',
};

const Info: React.FunctionComponent<IProps> = ({
	title, cardId, steps, duration, result, isResourcesVisible,
}) => (
	<Stack
		direction="row"
		justifyContent="space-between"
		alignItems="center"
	>
		{isResourcesVisible ? <ResourcesBlock cardId={cardId} steps={steps} /> : (
			<Typography
				ml={0.5}
				fontWeight={600}
				variant="h6"
			>
				{title}
			</Typography>
		)}
		<Stack direction="row" spacing={1}>
			<Chip
				sx={chipStyles}
				label={duration}
				icon={<AccessTimeIcon sx={{ fill: (theme) => theme.palette.secondary.light }} />}
			/>
			<Chip
				sx={chipStyles}
				label={`for ${steps.length} ${declOfNum(steps.length, ['step', 'steps', 'steps'])}`}
				icon={<ArrowForwardIcon sx={{ fill: (theme) => theme.palette.secondary.light }} />}
			/>
			<Chip
				sx={chipStyles}
				label={`to get ${result?.[0] === '#' ? '' : '#'}${result}`}
				icon={<UnionIcon sx={{ fill: (theme) => theme.palette.secondary.light }} />}
			/>
		</Stack>

	</Stack>
);

export default Info;
