import _ from 'lodash';

import isNonNullable from 'library/utils/isNonNullable';
import { IStepData } from 'library/common/types/cards/cardsCreation.d';
import { UUID } from 'library/common/types/app.d';

/**
 * @param  {IStepData[]} value
 *
 *	description
 *	We allow next combinations:
 *	photo
 *	drawing
 *	video
 *	resources + description
 *	resources + photo
 *	resources + video
 *	resources + drawing
 *	description + photo
 *	description + video
 *	description + drawing
 *	description + photo + resources
 *	description + video + resources
 *	description + drawing + resources
 *
 */

export default (value: IStepData[]) => {
	if (isNonNullable(value)) {
		if (value.length > 0) {
			const errorsArr: {[key: UUID]: string} = value.reduce((accumulator, currentValue) => {
				const {
					video, text, draw, image,
				} = currentValue;

				return !(video || draw || image || text)
					? {
						...accumulator,
						[currentValue.id]: 'Please add more information to the step',
					}
					: accumulator;
			}, {});

			return _.isEmpty(errorsArr) || JSON.stringify(errorsArr);
		}
		return 'You need to add at least one step';
	}
	return 'error';
};
