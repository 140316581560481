import React, { useMemo } from 'react';
import {
	ListItemAvatar,
	ListItemText,
	Avatar,
	Typography,
	Stack,
	Box,
	ListItemButton,
} from '@mui/material';
import { TFunction } from 'react-i18next';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import * as colors from '@mui/material/colors';

import { takeColorByFirstLetter } from 'library/utils/myColors';
import { takeNameToAvatar, timeSince } from 'library/utils/textGenerators';
import { INotification } from 'library/common/types/notifications/notifications.d';
import { getToolbarAvatar } from 'library/utils/getters/getPicture';
import NotificationPrimeText from './NotificationPrimeText';

interface IProps {
    notification: INotification;
    onNotificationHandler(notification: INotification): void;
    t: TFunction<'translation'>,
}

const NotificationItem: React.FunctionComponent<IProps> = ({ notification, onNotificationHandler, t }) => {
	const { author, avatar } = notification;

	const bgColor = useMemo(() => (avatar ? 'transparent' : takeColorByFirstLetter(colors, author || 'A')), [author, avatar]);
	const avatarFirstLetter = useMemo(() => takeNameToAvatar(author || 'A'), [author]);

	return (
		<ListItemButton
			onClick={() => onNotificationHandler(notification)}
			sx={{
				py: 1.5,
				px: 2.5,
				mt: '1px',
				...(!notification.viewed && {
					bgcolor: 'action.selected',
				}),
			}}
		>
			<ListItemAvatar>
				<Avatar
					variant="circular"
					src={getToolbarAvatar(notification.avatar)}
					alt={author}
					sx={{ bgcolor: bgColor }}
				>
					{avatarFirstLetter}
				</Avatar>
			</ListItemAvatar>
			<ListItemText
				disableTypography
				primary={(
					<Box sx={{ minHeight: '50px' }}>
						<NotificationPrimeText notification={notification} t={t} />
					</Box>
				)}
				secondary={(
					<Stack direction="row" spacing={1} justifyContent="space-between">
						<Typography
							component="span"
							variant="body2"
							color="textPrimary"
							style={{marginRight: '4px'}}
						>
							{'cardTitle' in notification ? notification.cardTitle : ''}
						</Typography>

						<Typography
							variant="caption"
							sx={{
								mt: 0.5,
								display: 'flex',
								alignItems: 'center',
								color: 'text.disabled',
							}}
						>
							<Box component={AccessTimeFilledIcon} sx={{ mr: 0.5, width: 16, height: 16 }} />
							{notification.createdAt && timeSince(notification.createdAt)}
						</Typography>
					</Stack>
				)}
			/>
		</ListItemButton>
	);
};

export default NotificationItem;
