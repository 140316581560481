import { QueryFunctionContext, useInfiniteQuery, UseInfiniteQueryOptions } from '@tanstack/react-query';

import cardsService from 'library/common/services/cards.service';
import { showSnackbarMessage } from 'library/utils/showSnackbarMessage';
import { IPoorCard } from 'library/common/types/cards/cards.d';

import cardQueryKeys from './queryKeys';

// TODO дописать мутации likes

type QueryFunctionParams = {
	page: number;
	size: number;
}

export type FavoriteCardsResponse = {
	cards: IPoorCard[];
	pageNumber?: number;
}

const defaultQueryParams = {
	page: 0,
	size: 20,
};

const favoriteCardsQueryFunction = async (params: QueryFunctionContext<ReturnType<typeof cardQueryKeys.favorites>, QueryFunctionParams>): Promise<FavoriteCardsResponse> => {
	const response = await cardsService.getFavoriteList(params.pageParam ?? defaultQueryParams);

	return { cards: response.payload, pageNumber: response.page_number + 1 < response.total_pages ? response.page_number + 1 : undefined };
};

const useFavoritesCards = (options?: UseInfiniteQueryOptions<FavoriteCardsResponse>) => useInfiniteQuery(
	cardQueryKeys.favorites(),
	favoriteCardsQueryFunction,
	{
		getNextPageParam: (lastPage) => (lastPage.pageNumber ? ({ page: lastPage.pageNumber, size: defaultQueryParams.size }) : undefined),
		cacheTime: 0,
		staleTime: 0,
		onError: () => showSnackbarMessage('Ops, There was a problem loading cards, we are working on a fix', 'error'),
		...options,
	},
);

export default useFavoritesCards;
