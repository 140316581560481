import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Controller, useForm} from 'react-hook-form';
import {Box, Button, Typography} from '@mui/material';

import kratosUtils from 'library/utils/kratos';
import userServices from 'library/common/services/user.service';
import {kratosErrorHandler} from 'library/utils/errorsHandler';
import {showSnackbarMessage} from 'library/utils/showSnackbarMessage';
import TextFieldWithCount from 'library/common/components/TextFieldWithCount';

import { MAX_MAIL_LENGTH } from '../values';

import styles from '../authorization.module.css';

interface IEmailForm {
  logInMode: () => void;
}

type EmailData = {
	email: string;
};

const EmailForm = ({logInMode}: IEmailForm) => {
	const {t} = useTranslation();

	const [loading, setLoading] = useState(false);

	const {control, handleSubmit} = useForm<EmailData>({defaultValues: {email: ''}});

	const onSubmit = handleSubmit(async (data) => {
		setLoading(true);

		userServices.initRecovery()
			.then((initRes) => {
				const flowId = initRes.data.id;
				const csrfToken = kratosUtils.extractCSRFToken(initRes.data.ui.nodes);

				if (flowId && csrfToken !== 'Not found!') {
					userServices.recovery(csrfToken, flowId, data.email)
						.then((res) => {
							if (res.data.ui) {
								showSnackbarMessage(
									kratosUtils.extractMessage(res.data.ui, 'Done'),
									'success',
									5000,
								);
							}
							logInMode();
						})
						.catch((error) => {
							showSnackbarMessage(`${kratosErrorHandler(error, 'Recovery Error')}`,
								'error',
								5000);
						});
				}
			})
			.catch((error) => {
				showSnackbarMessage(`${kratosErrorHandler(error, 'Recovery Error')}`,
					'error',
					5000);
			})
			.finally(() => setLoading(false));
	});

	return (
		<Box>
			<Typography component="h5" sx={(theme) => ({ color: theme.palette.grey['600'], fontWeight: 600})}>
				{t('enter_email_provided_during_registration')}
			</Typography>
			<Box mt={6}>
				<form onSubmit={onSubmit}>
					<Controller
						name="email"
						control={control}
						rules={{
							required: {
								value: true,
								message: t('the_field_cannot_be_empty'),
							},
							pattern: {
								value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
								message: t('invalid_email_format'),
							},
						}}
						render={({ field, fieldState: {error} }) => (
							<TextFieldWithCount
								{...field}
								maxLength={MAX_MAIL_LENGTH}
								label="Email"
								sx={{height: '77px'}}
								fullWidth
								autoComplete="off"
								disabled={loading}
								error={!!(error && error.type)}
								helperText={error?.message}
							/>
						)}
					/>
					<Button
						className={styles.button}
						type="submit"
						variant="contained"
						color="primary"
						style={{marginTop: '40px'}}
						fullWidth
						disabled={loading}
					>
						{t('send')}
					</Button>
				</form>
			</Box>
			<Button
				className={styles.button}
				variant="outlined"
				color="primary"
				disabled={loading}
				onClick={logInMode}
				fullWidth
				sx={{mt: 2}}
			>
				{t('cancel')}
			</Button>
		</Box>
	);
};

export default EmailForm;
