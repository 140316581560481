import {ActionType, createReducer} from 'typesafe-actions';
import * as colors from '@mui/material/colors';
import { xor } from 'lodash';
import {IUserData} from '../types/user.d';
import * as actions from '../actions/user.action';
import {takeColorByFirstLetter} from '../../utils/myColors';

export type UserActions = ActionType<typeof actions>;

export type UserState = Readonly<{
	isAuth: boolean;
	isPending: boolean;
	isGlobalPending: boolean;
	userData: IUserData;
	isSocketAlive: boolean;
}>

const initialState: UserState = {
	isAuth: false,
	isPending: false,
	isGlobalPending: true,
	userData: {
		id: '',
		email: '',
		username: '',
		logoURL: '',
		backgroundColor: takeColorByFirstLetter(colors, 'A'),
		// на кого подписан я
		followings: [],
		// кто на меня подписан
		followers: [],
	},
	isSocketAlive: false,
};

export default createReducer<UserState, UserActions>(
	initialState,
)
	// Регистрация нового пользователя
	.handleAction(actions.createUserAsync.request, ((state) => ({
		...state,
		isPending: true,
	})))
	.handleAction(actions.createUserAsync.success, ((state) => ({
		...state,
		isPending: false,
	})))
	.handleAction(actions.createUserAsync.failure, ((state) => ({
		...state,
		isPending: false,
	})))

	// авторизация пользователя
	.handleAction(actions.loginAsync.request, (state) => ({
		...state,
		isPending: true,
	}))
	.handleAction(actions.loginAsync.success, (state) => ({
		...state,
		isPending: false,
	}))
	.handleAction(actions.loginAsync.failure, (state) => ({
		...state,
		isPending: false,
	}))

	// Инициализация приложения
	.handleAction(actions.authorizationAsync.request, ((state) => ({
		...state,
		isGlobalPending: true,
	})))
	.handleAction(actions.authorizationAsync.success, ((state, { payload }) => ({
		...state,
		isGlobalPending: false,
		isAuth: true,
		userData: {
			...state.userData,
			backgroundColor: takeColorByFirstLetter(colors, payload.username || 'A'),
			...payload,
		},
	})))
	.handleAction(actions.authorizationAsync.failure, ((state) => ({
		...state,
		isGlobalPending: false,
	})))

	// Выход из учетной записи
	.handleAction(actions.logoutAsync.request, ((state) => ({
		...state,
		isGlobalPending: true,
	})))
	.handleAction([actions.logoutAsync.success, actions.logoutAsync.failure], ((state) => ({
		...state,
		isGlobalPending: false,
		isAuth: false,
		// notificationCount: 0,
		userData: {
			...initialState.userData,
		},
	})))

	.handleAction(actions.setUserAvatar, ((state, action) => ({
		...state,
		userData: {
			...state.userData,
			logoURL: action.url,
		},
	})))

	.handleAction(actions.setSocketAliveStatus, (state, action) => ({
		...state,
		isSocketAlive: action.status,
	}))

	.handleAction(actions.changeUserData, (state, action) => ({
		...state,
		userData: {
			...state.userData,
			...action.data,
		},
	}))
	.handleAction(actions.changeFollowersList, (state, action) => ({
		...state,
		userData: {
			...state.userData,
			followers: xor(state.userData.followers, [action.payload]),
		},
	}))
	.handleAction(actions.changeFollowingsList, (state, action) => ({
		...state,
		userData: {
			...state.userData,
			followings: xor(state.userData.followings, [action.payload]),
		},
	}));
