import React, {useState} from 'react';
import _ from 'lodash';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Controller, useForm} from 'react-hook-form';
import {
	Box,
	Card,
	Stack,
	Button,
	Container,
	Typography,
	CardActions,
	TextField,
} from '@mui/material';

import kratosUtils from 'library/utils/kratos';
import {kratosErrorHandler} from 'library/utils/errorsHandler';
import settingService from 'library/common/services/settings.service';
import passwordValidator from 'library/utils/passwordValidator';
import {showSnackbarMessage} from 'library/utils/showSnackbarMessage';
import PasswordErrors from 'library/common/components/PasswordErrors/PasswordErrors';

type PasswordResetData = {
	password: string;
	repeatPassword: string;
};

const PasswordReset = () => {
	const {t} = useTranslation();
	const [loading, setLoading] = useState(false);

	const {
		control,
		handleSubmit,
		watch,
		formState: {
			errors,
		},
	} = useForm<PasswordResetData>();
	const watchPassword = watch('password');

	const navigate = useNavigate();

	const onSubmit = handleSubmit(async (data) => {
		setLoading(true);

		settingService.settingsInit()
			.then((initRes) => {
				const flowId = initRes.data.id;
				const csrfToken = kratosUtils.extractCSRFToken(initRes.data.ui.nodes);

				if (flowId && csrfToken !== 'Not found!') {
					settingService.settingsSetPassword(csrfToken, flowId, data.password)
						.then((res) => {
							if (res.data.ui) {
								showSnackbarMessage(
									kratosUtils.extractMessage(res.data.ui, 'Done'),
									'success',
									5000,
								);
							}
						})
						.catch((error) => {
							showSnackbarMessage(`${kratosErrorHandler(error, 'Recovery Error')}`,
								'error',
								5000);
						});
				}
			})
			.catch((error) => {
				showSnackbarMessage(`${kratosErrorHandler(error, 'Recovery Error')}`,
					'error',
					5000);
			})
			.finally(() => {
				setLoading(false);
				navigate('/app/main');
			});
	});

	return (
		<Box sx={{display: 'flex', minHeight: '100%', alignItems: 'center'}}>
			<Container maxWidth="md">
				<Box sx={{margin: 'auto', textAlign: 'center'}}>
					<Card sx={{padding: 3}}>
						<form onSubmit={onSubmit} autoComplete="off">
							<Stack direction="column" spacing={1}>
								<Typography variant="h4" gutterBottom>
									{t('password_reset')}
								</Typography>

								<Controller
									name="password"
									control={control}
									defaultValue=""
									rules={{
										validate: (value) => {
											const {haveError, errorText} = passwordValidator(value);

											return !haveError || errorText;
										},
									}}
									render={({field}) => (
										<TextField
											{...field}
											disabled={loading}
											type="password"
											label="New password"
											autoComplete="off"
											size="small"
											error={!!_.get(errors, 'password.message')}
										/>
									)}
								/>
								{/* <PasswordErrors errors={errors} path="password.message" /> */}

								<Controller
									name="repeatPassword"
									control={control}
									defaultValue=""
									rules={{
										required: {
											value: true,
											message: t('the_field_cannot_be_empty'),
										},
										validate: (value) => (
											value === watchPassword || t('password_mismatch') as string
										),
									}}
									render={({field}) => (
										<TextField
											{...field}
											disabled={loading}
											type="password"
											label="Repeat password"
											autoComplete="off"
											size="small"
											error={!!_.get(errors, 'repeatPassword.message')}
										/>
									)}
								/>
								<PasswordErrors errors={errors?.repeatPassword?.message} />
							</Stack>
							<CardActions sx={{ justifyContent: 'end' }}>
								<Button
									variant="contained"
									color="primary"
									type="submit"
									disabled={loading}
									sx={{width: '200px', mt: 2}}
								>
									{t('send')}
								</Button>
							</CardActions>
						</form>
					</Card>
				</Box>
			</Container>
		</Box>
	);
};

export default PasswordReset;
