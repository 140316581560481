import React from 'react';
import {
	Box, LinearProgress, Stack, Typography,
} from '@mui/material';
import Base from './Base';

interface IProps {
	amount: number;
}

const steps = {
	label: 'Steps',
	levels: ['0', '1', '2', '3'],
};

const media = {
	label: 'Media',
	levels: ['0', '75%'],
};

const comments = {
	label: 'Comments',
	levels: ['0', '1', '2', '3'],
};

const CardCreation: React.FunctionComponent<IProps> = ({ amount }) => (
	<Base
		title="Wodacard creation bonus"
		amount={amount}
		buttonLabel="Create a card"
		disabledButton
		isBlur
		isComingSoon
		description={(
			<>
				<Typography
					variant="caption"
					sx={{ color: ({ palette }) => palette.text.primary }}
				>
					Card contains three or more steps

				</Typography>

				<Typography
					variant="caption"
					sx={{ color: ({ palette }) => palette.text.primary }}
				>
					Content of media files is more than 75%
				</Typography>

				<Typography
					variant="caption"
					sx={{ color: ({ palette }) => palette.text.primary }}
				>
					Card received 3 or more comments from another person
				</Typography>
			</>
		)}
	>
		<Stack spacing={5}>
			<Box
				sx={{
					px: 4,
					py: 1.5,
					borderRadius: 1,
					background: '#FDE9EFB3',
					position: 'relative',

					'&:after': {
						content: "''",
						position: 'absolute',
						bottom: '-14px',
						left: '10%',
						borderStyle: 'solid',
						borderWidth: '15px 10px 0',
						borderColor: '#FDE9EFB3 transparent',
						display: 'block',
						width: 0,
						zIndex: 1,
					},
				}}
			>
				<Typography variant="body1" sx={{ color: ({ palette }) => palette.text.primary }}>
					Follow three conditions to get bonusesd
				</Typography>
			</Box>

			<Stack spacing={3}>
				{[steps, media, comments].map((action) => (
					<Stack key={action.label} direction="row" spacing={3}>
						<Stack spacing={0.3} sx={{ flex: 1 }}>
							<Typography variant="caption" sx={{ color: ({ palette }) => palette.text.primary, fontWeight: 500 }}>
								{action.label}
							</Typography>
							<LinearProgress variant="determinate" value={0} />
							<Stack direction="row" justifyContent="space-between">
								{
									action.levels.map((level) => (
										<Typography key={level} variant="caption" sx={{ color: ({ palette }) => palette.text.secondary }}>
											{level}
										</Typography>
									))
								}
							</Stack>
						</Stack>

						<Box
							sx={{
								transform: 'translateY(-28px)',
								px: 4,
								py: 1.5,
								borderRadius: 1,
								background: '#FDE9EFB3',
								position: 'relative',

								'&:after': {
									content: "''",
									position: 'absolute',
									bottom: '-14px',
									left: '10%',
									borderStyle: 'solid',
									borderWidth: '15px 10px 0',
									borderColor: '#FDE9EFB3 transparent',
									display: 'block',
									width: 0,
									zIndex: 1,
								},
							}}
						>
							<Typography variant="body1" sx={{ color: ({ palette }) => palette.text.primary }}>
								{`Add ${action.levels.length + 1}`}
							</Typography>
						</Box>
					</Stack>
				))}
			</Stack>
		</Stack>
	</Base>
);

export default CardCreation;
