import React from 'react';

import { Box, Typography } from '@mui/material';

interface IProps {
    text: string;
    background: string;
}

const DialogCloud: React.FunctionComponent<IProps> = ({ text, background }) => (
	<Box
		sx={{
			px: 4,
			py: 1.5,
			borderRadius: 1,
			background,
			position: 'relative',
			width: 'fit-content',

			'&:after': {
				content: "''",
				position: 'absolute',
				bottom: '-14px',
				left: '10%',
				borderStyle: 'solid',
				borderWidth: '15px 10px 0',
				borderColor: `${background} transparent`,
				display: 'block',
				width: 0,
				zIndex: 1,
			},
		}}
	>
		<Typography variant="body1" sx={{ color: ({ palette }) => palette.text.primary }}>
			{text}
		</Typography>
	</Box>
);

export default DialogCloud;
