import {applyMiddleware, combineReducers, createStore} from 'redux';
import {createBrowserHistory} from 'history';
import userReducer from 'library/common/reducers/user.reducer';
import cardsReducer from 'library/common/reducers/cards.reducer';
import appReducer from 'library/common/reducers/app.reducer';
import { createReduxHistoryContext } from 'redux-first-history';
import {batchDispatchMiddleware, enableBatching} from 'redux-batched-actions';
import {composeWithDevTools} from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import {StateType} from 'typesafe-actions';
import { QueryClient } from '@tanstack/react-query';

import logger from 'library/common/middlewares/logger';
import notificationsMiddleware from 'library/common/middlewares/notifications';
import { MINUTE_IN_MS } from 'library/utils/converters/time.converter';
import soundsMiddleware from 'library/common/middlewares/sounds';

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnReconnect: false,
			refetchOnWindowFocus: false,
			cacheTime: 3 * MINUTE_IN_MS,
			staleTime: 3 * MINUTE_IN_MS,
		},
	},
});

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
	history: createBrowserHistory(),
});

const createRootReducer = combineReducers({
	app: appReducer,
	user: userReducer,
	cards: cardsReducer,
	router: routerReducer,
});

const rootReducer = createRootReducer;
export const sagaMiddleware = createSagaMiddleware();

export type RootState = StateType<typeof rootReducer>;

export const store = createStore(
	enableBatching(rootReducer),
	composeWithDevTools(
		applyMiddleware(
			...(process.env.REACT_APP_ENABLE_REDUX_ACTIONS_LOGS ? [logger] : []),
			routerMiddleware,
			sagaMiddleware,
			batchDispatchMiddleware,
			notificationsMiddleware(queryClient),
			soundsMiddleware,
		),
	),
);

export const history = createReduxHistory(store);

export default store;
