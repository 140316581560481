import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const BasketIcon = (props: SvgIconProps) => (
	<SvgIcon viewBox="0 0 20 19" {...props}>
		<path
			d="M10 0a6 6 0 0 1 6 6v1h4v2h-1.167l-.757 9.083a1 1 0 0 1-.996.917H2.92a1 1 0 0 1-.996-.917L1.166 9H0V7h4V6a6 6 0 0
			1 6-6Zm6.826 9H3.173l.667 8h12.319l.667-8ZM11 11v4H9v-4h2Zm-4 0v4H5v-4h2Zm8 0v4h-2v-4h2Zm-5-9a4 4 0 0 0-3.995 3.8L6 6v1h8V6a4 4 0 0 0-3.8-3.995L10 2Z"
		/>
	</SvgIcon>
);

export default BasketIcon;
