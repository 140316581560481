import { Breakpoints } from '@mui/material';
import { MediaContent } from 'library/common/types/app.d';
import {
	Card, CountedPoorResource, CountedResource, PoorResource, Resource,
} from 'library/common/types/cards/cards.d';

// eslint-disable-next-line no-shadow
enum Sizes {
    BIG = '392x392',
    MIDDLE = '290x340',
    SMALL = '290x340',
	LIST = 'list',
}

type OptionUrl = string | undefined;

type PosterUrl = string | undefined;

export const getPoster = <T extends Card>(card: T, size: 'BIG' | 'MIDDLE' | 'SMALL' | 'LIST'): PosterUrl => card.cover[Sizes[size]];

export const getResourcePicture = (resource: Resource): OptionUrl => resource.images['512x512'];

export const getResourcePictureFrom = (
	resource: PoorResource | Resource | CountedPoorResource | CountedResource,
): OptionUrl => {
	if (resource.images === undefined) {
		return resource.images;
	}

	return (typeof resource.images === 'string' ? resource.images : resource.images['512x512']);
};

export const getReducedStepPicture = (image: MediaContent): OptionUrl => image.reduced_step_image;
export const getStepPicture = (image: MediaContent): OptionUrl => image.step_image;
export const getStepOverlayPicture = (image: MediaContent, breakpoints: Breakpoints): OptionUrl => {
	if (window.innerWidth >= breakpoints.values.xl) {
		return image.step_image_xl;
	}

	if (window.innerWidth >= breakpoints.values.lg) {
		return image.step_image_large;
	}

	return image.step_image;
};

export const getProfileAvatar = (avatarImages?: MediaContent): OptionUrl | undefined => avatarImages?.profile_avatar;

export const getToolbarAvatar = (avatarImages?: MediaContent): OptionUrl | undefined => avatarImages?.toolbar_avatar;
