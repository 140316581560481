import { AxiosResponse } from 'axios';

import { IRule, ITransition, IVault } from 'library/common/types/wodaprograms.d';
import { sortBy } from 'lodash';

export const transactionsListMapper = (response: AxiosResponse<ITransition[]>) => response.data;

export const vaultLastMapper = (response: AxiosResponse<IVault>) => response.data;

export const vaultMapper = (response: AxiosResponse<number>) => response.data;

export const selfVaultMapper = (response: AxiosResponse<number>) => response.data;

export const rulesMapper = (response: AxiosResponse<IRule[]>) => sortBy(response.data, (o) => o.amount);
