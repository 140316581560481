import React, { PropsWithChildren } from 'react';
import {
	Avatar, Box, IconButton, Link, Paper, Stack, Typography,
} from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import { URL } from 'library/common/types/app.d';

interface IBiographyCard {
	avatarUrl?: URL;
	jobTitle: string;
	name: string;
	postUrl?: URL;
	linkedInUrl?: URL;
	instagramUrl?: URL;
}

const BiographyCard: React.FunctionComponent<PropsWithChildren<IBiographyCard>> = ({
	avatarUrl, jobTitle, name, instagramUrl, linkedInUrl, postUrl, children,
}) => (
	<Paper sx={{ flex: 0.5, p: 3 }} elevation={3}>
		<Stack>
			<Stack direction={{ xs: 'column', sm: 'row' }} sx={{ position: 'relative' }}>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						borderRadius: '50%',
						width: '104px',
						height: '104px',
						background: (theme) => (theme.palette as any).gradients.info,
					}}
				>
					<Avatar
						src={avatarUrl}
						sx={{
							width: '100px',
							height: '100px',
						}}
					/>
				</Box>

				<Stack justifyContent="end" ml={{ xs: 0, sm: 3}} mt={{ xs: 2, sm: 0}}>
					<Box sx={{ display: 'flex' }}>
						<Typography
							variant="h6"
							sx={{
								fontWeight: 600,
								background: (theme) => (theme.palette as any).gradients.info,
								WebkitBackgroundClip: 'text',
								WebkitTextFillColor: 'transparent',
							}}
						>
							{jobTitle}
						</Typography>
					</Box>
					<Typography
						variant="h4"
						sx={{
							fontWeight: 600,
							fontSize: { xs: '32px', md: '24px', lg: '32px' },
							lineHeight: '130%',
							color: '#151133',
						}}
					>
						{name}
					</Typography>
				</Stack>

				<Stack
					direction="row"
					spacing={1}
					sx={{
						ml: 'auto', alignItems: 'flex-start', position: { xs: 'absolute', md: 'inherit' }, right: 0,
					}}
				>
					{
						postUrl && (
							<Link
								component={IconButton}
								href={`mailto:${postUrl}`}
								target="_blank"
							>
								<MailOutlineIcon />
							</Link>
						)
					}
					{
						instagramUrl && (
							<Link
								component={IconButton}
								sx={{ color: '#D62976' }}
								href={instagramUrl}
								target="_blank"
							>
								<InstagramIcon />
							</Link>
						)
					}
					{
						linkedInUrl && (
							<Link
								component={IconButton}
								href={linkedInUrl}
								target="_blank"
							>
								<LinkedInIcon />
							</Link>
						)
					}
				</Stack>
			</Stack>

			<Box sx={{
				my: 4,
				background: '#151133',
				borderRadius: '10px',
				width: '216px',
				height: '1px',
			}}
			/>
			{children}
		</Stack>
	</Paper>
);

export default BiographyCard;
