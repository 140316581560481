import React, {ReactNode} from 'react';
import {Box, Container, Divider} from '@mui/material';

import Title from 'library/common/components/Title/Title';

interface ITemplate {
	children: ReactNode | undefined;
}

const Template = ({children}: ITemplate) => (
	<div>
		<Container sx={{display: 'flex', justifyContent: 'end'}}>
			<Box sx={{pt: 1, pb: 1, display: 'inline-block'}}>
				<Title />
			</Box>
		</Container>
		<Divider />
		{children}
	</div>
);

export default Template;
