const OutlinedInput = () => ({
	MuiOutlinedInput: {
		styleOverrides: {
			root: {
				borderRadius: '4px',
			},
		},
	},
});

export default OutlinedInput;
