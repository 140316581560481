import { clone } from 'lodash';

function swapArrayItems<T>(array: T[], pos1: number, pos2: number) {
	const arrayCopy = clone(array);

	const temp = arrayCopy[pos1];
	arrayCopy[pos1] = arrayCopy[pos2];
	arrayCopy[pos2] = temp;

	return arrayCopy;
}

export default swapArrayItems;
