import React from 'react';
import { Box, Typography } from '@mui/material';

const ErrorComponent = () => (
	<Box
		sx={{
			display: 'flex',
			mt: 6,
			height: 336,
			borderRadius: '4px',
			borderStyle: 'solid',
			borderWidth: '1px',
			borderColor: (theme) => theme.palette.error.light,
			color: (theme) => theme.palette.error.light,
			background: (theme) => (theme.palette.error as any).lighter,
		}}
	>
		<Typography sx={{ margin: 'auto' }} component="h6" variant="h6">Something goes wrong 🤯🤧🤕 </Typography>
	</Box>
);

export default ErrorComponent;
