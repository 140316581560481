import React from 'react';
import {
	Chip, Stack, SvgIcon,
} from '@mui/material';
import BrushIcon from '@mui/icons-material/Brush';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import ExtensionIcon from '@mui/icons-material/Extension';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import TextFieldsIcon from '@mui/icons-material/TextFields';

import { StageType } from 'library/common/types/cards/cards.d';

interface IButtons {
    selectedTypes: {[key in StageType]: boolean},
    changeSelectedType: (type: StageType) => void,
}

const buttonsArray: Array<{title: string, type: StageType, icon: typeof SvgIcon}> = [
	{
		title: 'components',
		type: 'resources',
		icon: ExtensionIcon,
	},
	{
		title: 'description',
		type: 'text',
		icon: TextFieldsIcon,
	},
	{
		title: 'image',
		type: 'image',
		icon: AddAPhotoIcon,
	},
	{
		title: 'video',
		type: 'video',
		icon: VideoCallIcon,
	},
	{
		title: 'draw',
		type: 'draw',
		icon: BrushIcon,
	},
];

const Buttons = ({selectedTypes, changeSelectedType}: IButtons) => (
	<Stack
		direction="row"
		flexWrap="wrap"
		mt={3}
		mb={4}
	>
		{
			buttonsArray.map((button) => (
				<Chip
					key={button.title}
					label={button.title}
					icon={<button.icon fontSize="small" />}
					onClick={() => changeSelectedType(button.type)}
					sx={{
						m: 1,
						pl: 1,
						color: (theme) => theme.palette.secondary.light,
						'& .MuiChip-icon': {
							color: (theme) => theme.palette.secondary.light,
						},
						...(selectedTypes[button.type] && {
							color: (theme) => theme.palette.common.white,
							background: (theme) => (theme.palette as any).gradients.primary,
							'& .MuiChip-icon': {
								color: (theme) => theme.palette.common.white,
							},
						}),
					}}
				/>
			))
		}
	</Stack>
);

export default Buttons;
