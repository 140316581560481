import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const DeleteIcon = (props: SvgIconProps) => (
	<SvgIcon viewBox="0 0 18 21" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.3 0.913043C6.3 0.408782 6.70293 0 7.2 0H10.8C11.2971 0 11.7 0.408782 11.7 0.913043H17.1C17.5971 0.913043
				18 1.32183 18 1.82609C18 2.33035 17.5971 2.73913 17.1 2.73913H0.9C0.402942 2.73913 0 2.33035 0 1.82609C0 1.32183
				0.402942 0.913043 0.9 0.913043H6.3ZM17.1 4.56522H0.9V19.1739C0.9 20.1824 1.70588 21 2.7 21H15.3C16.2941 21 17.1
				20.1824 17.1 19.1739V4.56522Z"
		/>
	</SvgIcon>

);

export default DeleteIcon;
