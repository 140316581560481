import React, { useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Tooltip,
	useMediaQuery,
} from '@mui/material';

import { Theme } from '@mui/system';
import CrossIcon from 'resources/icons/CrossIcon';

interface IProps {
	title: string;
	deleteStepHandler(): Promise<void>;
}

const DeleteStepButton: React.FunctionComponent<IProps> = ({ title, deleteStepHandler }) => {
	const [open, setOpen] = React.useState(false);
	const [isDeletingInProgress, setDeletingInProgress] = useState(false);

	const fullScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const deleteCard = () => {
		setDeletingInProgress(true);
		deleteStepHandler()
			.then(() => {
				setDeletingInProgress(false);
				setOpen(false);
			});
	};

	return (
		<>
			<Tooltip title="Delete step">
				<IconButton
					size="small"
					aria-label="to_favorite"
					sx={{
						color: ({ palette }) => palette.text.secondary,
					}}
					onClick={handleClickOpen}
				>
					<CrossIcon fontSize="inherit" />
				</IconButton>
			</Tooltip>
			<Dialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-delete-step-title"
			>
				<DialogTitle id="responsive-delete-step-title">
					Step deleting dialog
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{`Are you sure you want to delete ${title}`}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button disabled={isDeletingInProgress} autoFocus onClick={handleClose} color="inherit">
						No
					</Button>
					<Button disabled={isDeletingInProgress} onClick={deleteCard} color="error" autoFocus>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default DeleteStepButton;
