import React from 'react';
import {Box, Stack, Typography} from '@mui/material';

import AvatarButton from 'library/common/components/AvatarButton';
import StatisticCounters from '../../StatisticCounters';

interface IMedia {
	category: string;
	poster?: string;
	author?: string | null;
	authorID?: string | null;
	comments?: number;
	activations?: number;
	favorites?: number;
	userPhoto?: string | null;
	isVerified?: boolean;
}

const Media = ({
	category,
	poster,
	author,
	authorID,
	activations,
	favorites,
	comments,
	userPhoto,
	isVerified,
}: IMedia) => (
	<Box
		sx={{
			display: 'block',
			height: '340px',
			position: 'relative',
			backgroundImage: `url("${poster}")`,
			backgroundColor: (theme) => theme.palette.primary.dark,
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center center',
			borderBottomLeftRadius: '4px',
			borderBottomRightRadius: '4px',
		}}
	>
		<Box
			sx={{
				position: 'absolute',
				left: 0,
				top: 0,
				background: 'rgba(255, 255, 255, 0.7)',
				backdropFilter: 'blur(20px)',
				padding: '8px 16px',
				borderBottomRightRadius: '4px',
			}}
		>
			<Typography
				variant="body1"
				sx={{fontWeight: 700}}
			>
				{category}
			</Typography>
		</Box>
		<Stack
			direction="column"
			alignItems="center"
			sx={{
				p: 2,
				width: 96,
				height: '100%',
				position: 'absolute',
				right: 0,
				top: 0,
				background: 'rgba(255, 255, 255, 0.7)',
				backdropFilter: 'blur(20px)',
				borderBottomRightRadius: '4px',
			}}
		>
			<AvatarButton
				sx={{ zIndex: 10 }}
				size={44}
				author={author || undefined}
				authorId={authorID}
				userPhoto={userPhoto}
				isVerified={isVerified}
			/>

			<StatisticCounters
				direction="column"
				mt={13}
				spacing={1}
				number_of_activation={activations}
				number_of_comments={comments}
				number_of_favorites={favorites}
			/>
		</Stack>
	</Box>
);

export default Media;
