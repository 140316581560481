import React from 'react';
import {
	Box, Container, Stack, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import WQReals from 'pages/Main/desktop/components/WQReels';
import WQLoader from 'library/common/components/WQLoader/WQLoader';
import useWQReels from 'library/common/hook/useWQReels';

interface IProps {}

const Reels: React.FunctionComponent<IProps> = () => {
	const {t} = useTranslation();

	const {
		cardsState, isPending, onLikeHandle, onSubscribeHandle,
	} = useWQReels();

	if (isPending) {
		return (
			<Box
				sx={{
					width: '100%',
					height: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					background: (theme) => (theme.palette.background as any).main,
				}}
			>
				<WQLoader />
			</Box>
		);
	}

	return (
		<Box
			pt={2}
			pb={4}
			sx={{
				width: '100%',
				background: (theme) => (theme.palette.background as any).main,
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			{/* <MainPageBanner /> */}

			{cardsState.length === 0 ? (
				<Box
					sx={{
						height: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Typography
						variant="h3"
						sx={{ opacity: 0.4 }}
					>
						{t('no_results_found_search_for_other_keywords')}
					</Typography>
				</Box>
			) : (
				<Container sx={{ mb: 8 }}>
					<Stack spacing={5} direction="column">
						<WQReals
							cardsState={cardsState}
							onLikeHandle={onLikeHandle}
							onSubscribeHandle={onSubscribeHandle}
						/>
					</Stack>
				</Container>
			)}
		</Box>
	);
};

export default Reels;
