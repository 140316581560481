const src = 'https://wodaqota.com/nginxproxy/landing/';

export default {
	// icons
	knowledgeMiningIcon: `${src}icons/knowledge_mining.svg`,
	resultOrientedPlatformIcon: `${src}icons/result_oriented_platform.svg`,
	eCommerceListIcon: `${src}icons/e_commerce_list_icon.svg`,
	videoInteractionIcon: `${src}icons/video_interaction_icon.svg`,
	googleSearchEnhancementIcon: `${src}icons/google_search_enhancement_icon.svg`,
	theMatrixTechIcon: `${src}icons/the_matrix_tech_icon.svg`,

	// images
	resultOrientedPlatformImage: `${src}images/result_oriented_platform.png`,
	eCommerceListImage: `${src}images/e_commerce_list.png`,
	knowledgeMiningImage: `${src}images/knowledge_mining.png`,
	videoInteractionImage: `${src}images/video_interaction.png`,
	googleSearchEnhancementImage: `${src}images/google_search_enhancement.png`,
	theMatrixTechImage: `${src}images/the_matrix_tech.png`,
	maskGroupImage: `${src}images/Mask_group.svg`,
	macBookImage: `${src}images/macbook_pro_mockup_front.png`,
	sergePhoto: `${src}images/serge.png`,
	mikhailPhoto: `${src}images/mikhail.png`,

	polinomImage: `${src}images/Polinom.svg`,
	polinomImage2: `${src}images/Polinom2.svg`,
	polinomImage3: `${src}images/Polinom3.svg`,
	polinomImage4: `${src}images/Polinom4.svg`,
};
