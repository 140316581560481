import { showSnackbarMessage } from 'library/utils/showSnackbarMessage';
import { AxiosError } from 'axios';

import { logger } from './lintersSugar';
import toLoginPage from './routes/toLoginPage';

interface IOptionsProps {
	snackbarMessage: string;
	alwaysShowSnackbarMessage: boolean;
}

export default (error: AxiosError, options?: Partial<IOptionsProps>) => {
	logger(`${error}`, 'error');

	if (options?.alwaysShowSnackbarMessage && options?.snackbarMessage) {
		showSnackbarMessage(options.snackbarMessage, 'error');
	}

	if (error?.response?.status === 401) {
		toLoginPage();
	} else if (!options?.alwaysShowSnackbarMessage && options?.snackbarMessage) {
		showSnackbarMessage(options.snackbarMessage, 'error');
	}
};
