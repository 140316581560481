import i18n from 'library/utils/language/conf';

export default [
	{
		name: i18n.t('career_options'),
		value: '/career-options',
	},
	{
		name: i18n.t('terms_of_use'),
		value: '/terms',
	},
	{
		name: i18n.t('privacy_notice'),
		value: '/privacy-notice',
	},
	{
		name: i18n.t('need_help'),
		value: '/help',
	},
];
