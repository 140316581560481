import React from 'react';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { SvgIconProps } from '@mui/material';

const LikeIcon: React.FunctionComponent<SvgIconProps> = ({sx, ...props}) => (
	<ThumbUpIcon
		{...props}
		sx={{ ...sx, fontSize: '20px' }}
	/>
);

export default LikeIcon;
