import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const WodaBonusIcon = (props: SvgIconProps) => (
	<SvgIcon viewBox="0 0 32 32" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M24.8318 18.2634C24.7018 18.4774 24.3846 18.3658 24.3846 18.1154C24.3846 12.3164 19.6836
            7.61538 13.8846 7.61538C13.6342 7.61538 13.5226 7.29826 13.7367 7.16828C14.9575 6.4269 16.3904
             6 17.9231 6C22.3839 6 26 9.61616 26 14.0769C26 15.6096 25.5731 17.0426 24.8318 18.2634ZM22.7692
             18.1154C22.7692 23.0222 18.7915 27 13.8846 27C8.97778 27 5 23.0222 5 18.1154C5 13.2085 8.97778
             9.23077 13.8846 9.23077C18.7915 9.23077 22.7692 13.2085 22.7692 18.1154ZM11.9656 22.1538C11.5348
             22.1538 11.1561 21.8683 11.0376 21.4542L9.50156 16.0859C9.36092 15.5944 9.72997 15.1049 10.2413
             15.1049C10.5975 15.1049 10.9071 15.3494 10.9897 15.696L12.0088 19.9739C12.0128 19.9908 12.0279
             20.0027 12.0453 20.0027C12.0623 20.0027 12.0772 19.9912 12.0815 19.9747L13.1844 15.7786C13.2887
             15.3816 13.6475 15.1049 14.058 15.1049C14.4688 15.1049 14.8279 15.3821 14.9319 15.7796L16.0315
             19.9834C16.036 20.0008 16.0518 20.013 16.0698 20.013C16.0882 20.013 16.1042 20.0004 16.1084
             19.9825L17.1274 15.6962C17.2098 15.3496 17.5195 15.1049 17.8758 15.1049C18.3869 15.1049 18.7559
             15.5943 18.6153 16.0857L17.0793 21.4539C16.9607 21.8682 16.582 22.1538 16.151 22.1538C15.7217
             22.1538 15.344 21.8704 15.224 21.4583L14.0919 17.5716C14.0874 17.5559 14.073 17.5452 14.0567
             17.5452C14.0404 17.5452 14.026 17.556 14.0215 17.5716L12.8924 21.4579C12.7727 21.8702 12.3949
             22.1538 11.9656 22.1538Z"
		/>
	</SvgIcon>

);

export default WodaBonusIcon;
