import React from 'react';
import {
	Box, Grid, Typography,
} from '@mui/material';
import {useSelector} from 'react-redux';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import LabelWithIcon from 'library/common/components/LabelWIthIcon';
import { getDuration } from 'library/utils/workWithData';
import UnionIcon from 'resources/icons/UnionIcon';
import { IReelsStep } from 'library/common/types/cards/reels.d';
import { selectMainPageCategoriesVisibility } from 'library/common/selectors/app.selector';

interface IProps {
    category: string;
    duration: number;
    steps: IReelsStep[];
    result: string;
}

const Info: React.FunctionComponent<IProps> = ({
	category, duration, steps, result,
}) => {
	const isCategoryVisible = useSelector(selectMainPageCategoriesVisibility);

	return (
		<Grid
			container
			direction="row"
			justifyContent="center"
			alignItems="center"
			sx={{
				height: '1.45rem',
			}}
		>
			{isCategoryVisible && (
				<Grid
					item
					xs={3}
					display="flex"
					justifyContent="start"
					alignItems="center"
				>

					<Box
						sx={{
							px: 1,
							py: 0.3,
							borderWidth: '1px',
							borderStyle: 'solid',
							borderColor: '#DADADA',
							borderRadius: '15px',
						}}
					>
						<Typography
							sx={{
								fontWeight: 600,
								color: ({ palette }) => palette.text.secondary,
							}}
						>
							{category}
						</Typography>

					</Box>
				</Grid>
			)}
			<Grid item xs={isCategoryVisible ? 2 : 3} pl={isCategoryVisible ? 0.5 : 0}>
				<LabelWithIcon
					tooltip="duration"
					variant="caption"
					icon={
						<AccessTimeIcon fontSize="small" sx={{ fill: (theme) => theme.palette.secondary.light }} />
					}
					title={getDuration(duration, true)}
				/>
			</Grid>
			<Grid item xs={isCategoryVisible ? 2 : 3} pl={0.5}>
				<LabelWithIcon
					tooltip="duration"
					variant="body1"
					icon={
						<ArrowForwardIcon fontSize="small" sx={{ fill: (theme) => theme.palette.secondary.light }} />
					}
					title={`${steps.length}st`}
				/>
			</Grid>
			<Grid item xs={isCategoryVisible ? 5 : 6} pl={1}>
				<LabelWithIcon
					tooltip="duration"
					variant="caption"
					withHidden
					icon={
						<UnionIcon fontSize="small" sx={{ fill: (theme) => theme.palette.secondary.light }} />
					}
					title={`${result?.[0] === '#' ? '' : '#'}${result}`}
				/>
			</Grid>
		</Grid>
	);
};

export default Info;
