import React from 'react';
import { Stack, Box } from '@mui/material';
import { useSelector } from 'react-redux';

import { getFullDuration } from 'library/utils/workWithData';
import { IReelsCard } from 'library/common/types/cards/reels.d';
import { UUID } from 'library/common/types/app.d';

import { selectMainPageResourcesVisibility } from 'library/common/selectors/app.selector';
import Buttons from './Buttons';
import Footer from './Footer';
import Header from './Header';
import Media from './Media';
import Info from './Info';

interface IProps {
    card: IReelsCard;
    shareCardHandle(): void;
    toCardPageHandle(): void;
    onLikeHandle(cardId: UUID, status?: boolean): void;
    onSubscribeHandle(authorId: UUID, isSubscribed: boolean): void;
}

const Reel: React.FunctionComponent<IProps> = ({
	card, shareCardHandle, toCardPageHandle, onLikeHandle, onSubscribeHandle,
}) => {
	const isResourcesVisible = useSelector(selectMainPageResourcesVisibility);

	return (
		<Stack
			sx={{ width: '100%' }}
			spacing={2}
		>
			{isResourcesVisible && <Header title={card.title} category={card.category} />}
			<Info
				isResourcesVisible={isResourcesVisible}
				title={card.title}
				cardId={card.id}
				steps={card.steps}
				duration={`you need ${getFullDuration(card.duration)}`}
				result={card.result.title}
			/>
			<Box sx={{ position: 'relative' }}>
				<Media steps={card.steps} />
				<Box
					sx={{
						height: '100%',
						position: 'absolute',
						top: 0,
						right: 0,
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<Buttons
						commentsCount={card.statistic.comments}
						likesCount={card.statistic.favorites}
						isLiked={card.client_info?.is_favorite}
						shareCard={shareCardHandle}
						goToCard={toCardPageHandle}
						likeCardHandler={() => onLikeHandle(card.id, card.client_info?.is_favorite)}
					/>
				</Box>
			</Box>
			<Footer
				author={card.author}
				onSubscribeHandle={onSubscribeHandle}
				goToCard={toCardPageHandle}
			/>
		</Stack>
	);
};

export default Reel;
