import React from 'react';
import {
	Box, Grid, Stack, Typography,
} from '@mui/material';

import {
	CountedPoorResource, CountedResource, PoorResource, Resource,
} from 'library/common/types/cards/cards.d';
import { UUID } from 'library/common/types/app.d';
import ResourceItem from './ResourceItem';

interface IProps {
    stepResources: (PoorResource | Resource | CountedPoorResource | CountedResource)[];
	onResourceRemoveHandler?: (id: UUID) => void;
	withBorder?: boolean;
}

const ResourcesList: React.FunctionComponent<IProps> = ({ stepResources, onResourceRemoveHandler, withBorder }) => (
	<Box
		sx={{
			padding: '1px',
			borderRadius: '4px',
			backgroundOrigin: 'border-box',
			backgroundClip: 'content-box, border-box',
			overflow: 'hidden',
			...(withBorder && {
				backgroundImage: (theme) => (
					`linear-gradient(white, white), ${(theme.palette as any).gradients.info}`
				),
			}),
		}}
	>
		<Grid
			container
			spacing={2}
			p="16px 8px"
			sx={{
				maxHeight: '312px',
				overflow: 'auto',
				'&::-webkit-scrollbar': {
					width: '0.4em',
				},
				'&::-webkit-scrollbar-thumb': {
					backgroundColor: 'rgba(0,0,0,.1)',
				},
			}}
		>

			{
				stepResources.map((resource) => (
					resource
						? (
							<Grid
								key={`${resource.title}`}
								item
								xs={6}
								sm={4}
							>
								<Stack
									direction="row"
									justifyContent="flex-start"
									alignItems="center"
									spacing={2}
								>
									<ResourceItem resource={resource} onResourceRemoveHandler={onResourceRemoveHandler} />
									<Typography
										gutterBottom
										variant="h6"
										component="h6"
										sx={{
											color: (theme) => theme.palette.secondary.main,
											fontWeight: 600,
											overflow: 'hidden',
											display: '-webkit-box',
											textOverflow: 'ellipsis',
											whiteSpace: 'normal',
											WebkitLineClamp: '3',
											WebkitBoxOrient: 'vertical',
										}}
									>
										{`${resource.title}`}
									</Typography>
								</Stack>
							</Grid>
						)
						: null))
			}
		</Grid>
	</Box>
);

export default ResourcesList;
