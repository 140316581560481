import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {showSnackbarMessage} from 'library/utils/showSnackbarMessage';
import cardsService from 'library/common/services/cards.service';
import { PoorResource, Resource } from 'library/common/types/cards/cards.d';
import { AxiosCardApiPaginationResponse, CardApiData } from 'library/common/types/cards/request.d';
import { getResourcePicture } from 'library/utils/getters/getPicture';
import { MediaUUID } from '../types/app.d';

interface IProps {
	addToSelectedHandler?: (resource: PoorResource) => void;
}

interface IResult {
	resources: PoorResource[];
	resourcesIsPending: boolean;
	isTouched: boolean;
	onSearchResource(value: string): void;
	addResource(data: {title: string, image?: MediaUUID}, onSuccessCallback?: () => void): void;
}

const useResources = ({ addToSelectedHandler }: IProps): IResult => {
	const {t} = useTranslation();

	const [resources, setResources] = useState<PoorResource[]>([]);
	const [resourcesIsPending, setResourcesIsPending] = useState<boolean>(false);
	const [isTouched, setTouched] = useState(false);

	const getResource = useCallback(
		(value?: string) => {
			if (value && value?.length > 0) {
				setResourcesIsPending(true);

				cardsService
					.getResources<AxiosCardApiPaginationResponse>({page: 0, size: 20, title: value})
					.then((result) => {
						setResources(
							result
								.data
								.payload
								.map((resource: Resource) => ({
									...resource,
									images: getResourcePicture(resource),
								})),
						);
						setTouched(true);
					})
					.catch(() => showSnackbarMessage(t('error_loading_resources'), 'error'))
					.finally(() => (setResourcesIsPending(false)));
			} else {
				setTouched(false);
				setResources([]);
			}
		},
		[t],
	);

	const addResource = useCallback(
		(data: {title: string, image?: MediaUUID}, onSuccessCallback?: () => void) => {
			cardsService
				.addResources<CardApiData<Resource>>(data)
				.then(({ data: { payload } }) => {
					const newResource = {
						id: payload.id,
						title: payload.title,
						images: getResourcePicture(payload),

					};

					setResources((prev) => [newResource, ...prev]);
					if (addToSelectedHandler) addToSelectedHandler(newResource);
					if (onSuccessCallback) onSuccessCallback();
				})
				.catch(() => showSnackbarMessage(t('error_loading_resources'), 'error'))
				.finally(() => (setResourcesIsPending(false)));
		},
		[t, addToSelectedHandler],
	);

	return {
		resources,
		resourcesIsPending,
		onSearchResource: getResource,
		addResource,
		isTouched,
	};
};

export default useResources;
