import React from 'react';
import {Container, Typography} from '@mui/material';
import Link from '@mui/material/Link';
import Template from './Template';

const NeedHelp = () => (
	<Template>
		<Container sx={{pt: 2, pb: 2}}>
			<Typography variant="h3" sx={{fontWeight: 700}}>
				Contact Us
			</Typography>

			If you need help, please contact us at this email:
			&nbsp;
			<Link href="mailto:support@wodaqota.com">support@wodaqota.com</Link>

			<Typography variant="h5" sx={{m: '0.8rem 0', fontWeight: 500}}>
				Copyright Issues
			</Typography>
			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				If you are the copyright owner of a video, picture and feel it has been
				uploaded without your permission, please contact us
			</Typography>

			<Typography variant="h5" sx={{m: '0.8rem 0', fontWeight: 500}}>
				Inappropriate Content
			</Typography>
			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				To report an inappropriate video or picture on WodaQota, please copy t
				he URL address and sent it to us by email
			</Typography>

			<Typography variant="h5" sx={{m: '0.8rem 0', fontWeight: 500}}>
				Additional Contact Information
			</Typography>
			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				You can contact WodaQota at the address below.
			</Typography>

			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				<br />
				WodaQota, Inc.
				<br />
				919 North Market Street
				<br />
				Suite 950 in the City of Wilmington
				<br />
				County of New Castle, DE 19801
				<br />
				Date of Revision: October 20, 2021
			</Typography>
		</Container>
	</Template>
);

export default NeedHelp;
