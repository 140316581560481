import React, { useState, createContext } from 'react';

// eslint-disable-next-line no-shadow
export enum Sections {
	NONE = 'NONE',
	ABOUT_US = 'ABOUT_US',
	ADVANTAGES = 'Advantages',
	OUR_TEAM = 'OUR_TEAM',
	CORE_VALUES = 'CORE_VALUES',
	FAQ = 'FAQ',
}

export const NavContext = createContext<Partial<{
    activeNavLinkId: Sections;
    setActiveNavLinkId: React.Dispatch<React.SetStateAction<Sections>>;
}>>({});

const NavProvider = ({ children }: React.PropsWithChildren<any>) => {
	const [activeNavLinkId, setActiveNavLinkId] = useState<Sections>(Sections.NONE);

	const providerValue = {
		activeNavLinkId,
		setActiveNavLinkId,
	};

	return (
		<NavContext.Provider value={providerValue}>{children}</NavContext.Provider>
	);
};

export default NavProvider;
