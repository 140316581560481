import React, { useCallback, useRef, useState } from 'react';
import { Box } from '@mui/material';
import ReactPlayer from 'react-player';
import { OnProgressProps } from 'react-player/base';
import { useDispatch, useSelector } from 'react-redux';
import { useIntersection } from 'react-use';

import { APPBAR_MOBILE } from 'resources/styles/constants';
import { IReelsCard } from 'library/common/types/cards/reels.d';
import { selectSetWodaStoryMuteState } from 'library/common/selectors/app.selector';
import { setWodaStoryMuteState } from 'library/common/actions/app.action';
import { UUID } from 'library/common/types/app.d';

import Interface from './Interface';

interface IProps {
	cardsState: IReelsCard;
	onLikeHandle(cardId: UUID, status?: boolean): void;
    shareCardHandle(): void;
}

const Media: React.FunctionComponent<IProps> = ({
	cardsState: {
		id, author, steps, client_info,
	},
	onLikeHandle,
	shareCardHandle,
}) => {
	const dispatch = useDispatch();

	const mediaBoxRef = useRef<HTMLDivElement>(null);
	const timelineRef = useRef<HTMLDivElement>(null);

	const muted = useSelector(selectSetWodaStoryMuteState);

	const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
	const [isReady, setIsReady] = useState(false);

	const inViewport = useIntersection(mediaBoxRef, {
		root: null,
		rootMargin: '0px',
		threshold: 1,
	});

	const toggleMuted = () => dispatch(setWodaStoryMuteState());

	const setProgressHandler = useCallback((progress: OnProgressProps) => {
		if (timelineRef.current?.style) {
			timelineRef.current.style.width = `${Math.round(progress.played * 100)}%`;
		}
	}, []);

	const goToNextSlideHandler = useCallback(() => {
		if (timelineRef.current?.style) {
			timelineRef.current.style.width = '0';
		}
		setActiveStepIndex((prev) => (prev + 1) % steps.length);
	}, [steps.length]);

	return (
		<Box
			ref={mediaBoxRef}
			sx={{
				position: 'relative',
				height: `calc(100vh - ${APPBAR_MOBILE}px - 56px - 3.05rem - 25vh)`,
				backgroundColor: 'rgb(94, 81, 151)',
				borderRadius: '10px',
			}}
		>
			<ReactPlayer
				url={`${steps[activeStepIndex].video.source}#t=0.05`}
				width="100%"
				height="100%"
				playsinline
				controls={false}
				muted={muted}
				playing={inViewport?.isIntersecting && isReady}
				onEnded={() => {
					setIsReady(false);
					goToNextSlideHandler();
				}}
				onProgress={setProgressHandler}
				onReady={() => setIsReady(true)}
			/>
			<Interface
				cardId={id}
				author={author}
				muted={muted}
				toggleMuted={toggleMuted}
				steps={steps}
				activeStepIndex={activeStepIndex}
				timelineRef={timelineRef}
				isLiked={client_info?.is_favorite}
				shareCard={shareCardHandle}
				likeCardHandler={() => onLikeHandle(id, client_info?.is_favorite)}
			/>
		</Box>
	);
};

export default Media;
