import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Container } from '@mui/material';

import { selectIsAuthorized } from 'library/common/selectors/user.selector';

import AboutUs from './components/AboutUs';
import Advantages from './components/Advantages';
import OurTeam from './components/OurTeam';
import CoreValues from './components/CoreValues';
import Header from './components/Header';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import NavContext, { Sections } from './components/NavContext';
import resources from './resources';

interface IProps {}

const Landing: React.FunctionComponent<IProps> = () => {
	const navigate = useNavigate();
	const isAuth = useSelector(selectIsAuthorized);

	const signInHandler = () => navigate('/login');
	const toPlatformHandler = () => navigate(isAuth ? '/app/main' : '/registration');

	return (
		<NavContext>
			<Container
				maxWidth={false}
				disableGutters
				sx={{
					px: {
						xs: 2, md: 3, lg: 12,
					},
					overflow: 'hidden',
					background: (theme) => (theme.palette.background as any).main,
				}}
			>
				<Header isAuth={isAuth} onSignIn={signInHandler} />
				<Box
					id={Sections.ABOUT_US}
					component="section"
					sx={{
						pt: { xs: 15, sm: 20 },
						width: '100%',
						position: 'relative',

						'&:before': {
							content: "''",
							background: `url(${resources.polinomImage})`,
							position: 'absolute',
							display: 'block',
							zIndex: 0,
							width: '35%',
							height: '35%',
							backgroundSize: '100% 100%',
							right: -96,
							top: 0,
						},
					}}
				>
					<AboutUs
						isAuth={isAuth}
						threshold={0.2}
						onJoinThePlatform={toPlatformHandler}
					/>
				</Box>
				<Box
					id={Sections.ADVANTAGES}
					component="section"
					sx={{
						pt: { xs: 10, sm: 20 },
						width: '100%',
						position: 'relative',
						display: 'block',

						'&:before': {
							content: "''",
							background: `url(${resources.polinomImage2})`,
							position: 'absolute',
							display: 'block',
							zIndex: 0,
							width: '70%',
							height: '70%',
							backgroundSize: '100% 100%',
							left: -100,
							top: -500,
						},

						'&:after': {
							content: "''",
							background: `url(${resources.polinomImage3})`,
							position: 'absolute',
							display: 'block',
							zIndex: 0,
							width: '70%',
							height: '40%',
							backgroundSize: '100% 100%',
							right: -100,
							bottom: 0,
						},
					}}
				>
					<Advantages
						isAuth={isAuth}
						threshold={0.05}
						onJoinThePlatform={toPlatformHandler}
					/>
				</Box>
				<Box id={Sections.OUR_TEAM} component="section" sx={{ pt: { xs: 10, sm: 20 }, width: '100%' }}>
					<OurTeam threshold={0.5} />
				</Box>
				<Box
					id={Sections.CORE_VALUES}
					component="section"
					sx={{
						pt: { xs: 10, sm: 20 },
						width: '100%',
						position: 'relative',

						'&:before': {
							content: "''",
							background: `url(${resources.polinomImage4})`,
							position: 'absolute',
							display: 'block',
							zIndex: 0,
							width: '100%',
							height: '150%',
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'contain',
							backgroundPositionX: 'right',
							right: -97,
							top: -100,
						},
					}}
				>
					<CoreValues threshold={0.5} />
				</Box>
				<Box id={Sections.FAQ} component="section" sx={{ pt: { xs: 10, sm: 20 }, width: '100%' }}>
					<FAQ threshold={0.5} />
				</Box>
				<Box sx={{ pt: { xs: 10, sm: 20 }, width: '100%' }}>
					<Footer isAuth={isAuth} onJoinThePlatform={toPlatformHandler} />
				</Box>
			</Container>
		</NavContext>
	);
};

export default Landing;
