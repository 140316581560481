import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

import resources from '../resources';
import useNav from '../useNav';
import { Sections } from './NavContext';
import BiographyCard from './BiographyCard';

interface IProps {
	threshold?: number | number[]
}

const OurTeam: React.FunctionComponent<IProps> = ({ threshold }) => {
	const ref = useNav(Sections.OUR_TEAM, threshold);

	return (
		<Stack ref={ref} spacing={2}>
			<Box sx={{ display: 'flex' }}>
				<Typography
					variant="h6"
					sx={{
						fontWeight: 600,
						background: (theme) => (theme.palette as any).gradients.info,
						WebkitBackgroundClip: 'text',
						WebkitTextFillColor: 'transparent',
					}}
				>
					Together, we are all WodaQota – but where did our concept begin?
				</Typography>
			</Box>

			<Typography
				variant="h6"
				sx={{
					fontWeight: 800,
					color: '#151133',
					fontSize: '44px',
					lineHeight: '130%',
				}}
			>
				Our team
			</Typography>

			<Stack direction={{ xs: 'column', md: 'row' }} spacing={4}>
				<BiographyCard
					name="Serge Tishchenko"
					jobTitle="President"
					avatarUrl={resources.sergePhoto}
					postUrl="stishchenko@wodaqota.com"
				>
					<Typography
						variant="body1"
						sx={{
							fontWeight: '400',
							fontSize: '20px',
							lineHeight: '130%',
							color: '#151133',
						}}
					>
						A life-long lover of learning, Serge possesses incredible experience in the world of academia.
						He has been a Researcher at the Paris Center Institute, as well as Professor at universities
						across France, Japan, China
						<br />
						<br />
						He has conducted significant work in the algorithmic and network theory fields of mathematics
						and received multiple awards for his research, such as the American Mathematical Society&#8217;s
						Karl Menger Award and Intel Corp&#8217;s Outstanding Achievement Award
						<br />
						<br />
						Now, as President of WodaQota Inc., Serge channels his mathematical genius, expertise
						in algorithmic development and passion for knowledge into the WodaQota platform in
						his quest to promote knowledge-sharing across the Indian continent
					</Typography>
				</BiographyCard>

				<BiographyCard
					name="Mikhail Shakh"
					jobTitle="CEO"
					avatarUrl={resources.mikhailPhoto}
					postUrl="mshakh@wodaqota.com"
					linkedInUrl="https://www.linkedin.com/in/mikhail-shakh-74a345178/"
				>
					<Typography
						variant="body1"
						sx={{
							fontWeight: '400',
							fontSize: '20px',
							lineHeight: '130%',
							color: '#151133',
						}}
					>
						An enthusiastic researcher in the field of economics, Mikhail has channeled that same, diligent approach into his work as Director of B2B Video Platform Projects for European’s largest digital services provider
						<br />
						<br />
						Mikhail possesses outstanding organizational skills, having managed vast international IT- teams and owned two IT companies – and has contributed to the success of AI projects across Japan, China, Austria and the USA
						<br />
						<br />
						As WodaQota Inc.’s CEO, Mikhail brings his stellar organizational skills and deep AI technology experience to creating a new standard of knowledge-sharing ecosystem for the people of India
					</Typography>
				</BiographyCard>
			</Stack>
		</Stack>
	);
};

export default OurTeam;
