import React, { SyntheticEvent } from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import TouchRipple, {TouchRippleActions} from '@mui/material/ButtonBase/TouchRipple';
import DotsLoader from 'library/common/components/DotsLoader/DotsLoader';
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

interface IInput {
    sx?: SxProps<Theme>;
	isDragActive: boolean;
	progress: null | number;
	errors: string;
	openVideoInputRef?: React.MutableRefObject<(() => void) | undefined>;
	getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
	getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
}

const onHoverStyle = {
	borderColor: '#4198EF',
	background: (theme: Theme) => (theme.palette.secondary as any).lighter,
	color: (theme: Theme) => theme.palette.grey[600],
};

const Input = ({
	sx, getInputProps, getRootProps, isDragActive, progress, errors,
}: IInput) => {
	const {t} = useTranslation();

	const rippleRef = React.useRef<TouchRippleActions>(null);

	const onRippleStart = (e: SyntheticEvent) => {
		rippleRef.current?.start(e);
	};

	const onRippleStop = (e: SyntheticEvent) => {
		rippleRef.current?.stop(e);
	};

	return (
		<>
			<Stack
				flexDirection="column"
				sx={{
					cursor: 'pointer',
					position: 'relative',
					minHeight: 336,
					padding: 3,
					borderRadius: '4px',
					borderWidth: '1px',
					borderStyle: 'dashed',
					borderColor: (theme) => `${theme.palette.secondary.dark}4D`,
					userSelect: 'none',
					color: (theme) => theme.palette.grey[400],
					...sx,
					...((isDragActive || progress) && onHoverStyle),
					'&:hover': {
						...onHoverStyle,
					},
				}}
				onMouseDown={onRippleStart}
				onMouseUp={onRippleStop}
				{...getRootProps()}
			>
				<input
					type="file"
					autoComplete="off"
					style={{
						display: 'none',
						width: '100%',
						height: '100%',
					}}
					{...getInputProps()}
				/>
				<Box>
					<VideoCallIcon fontSize="large" />
				</Box>

				<Stack mt="auto" spacing={1}>
					<CloudUploadOutlinedIcon fontSize="large" />

					{
						progress ? (
							<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={4}>
								<Typography component="h5" variant="h5">{t('video_upload')}</Typography>
								<DotsLoader />
							</Stack>
						) : (
							<Typography component="h5" variant="h5">{t('add_video')}</Typography>
						)
					}

					<Typography variant="subtitle1">Drag or select video file to upload</Typography>
					<Typography variant="subtitle1">
						MP4 WebM GIF Resolution 1280x720 Up to 25s Less than 12 MB
					</Typography>
					<Typography component="h5" variant="h5" sx={{color: (theme) => theme.palette.error.main}}>
						{errors}
					</Typography>

				</Stack>

				{!progress && <TouchRipple ref={rippleRef} center={false} />}
			</Stack>
		</>
	);
};

export default Input;
