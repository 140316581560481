import React, {
	forwardRef, useEffect, useRef, useState,
} from 'react';
import { updateRef } from 'library/utils/ref';
import { useMountedState } from 'react-use';
import { Box, CircularProgress, Paper } from '@mui/material';

import { URL } from 'library/common/types/app.d';
import preloadImage from 'library/utils/preloadImage';

interface IProps {
  src?: URL;
  onError?(error: HTMLImageElement): void;
  alt?: string;
}

export default forwardRef<HTMLImageElement, IProps>((props, ref) => {
	const { src, onError, alt } = props;
	const [imageLoaded, setImageLoaded] = useState(false);
	const isMounted = useMountedState();

	const onErrorRef = useRef<IProps['onError']>();

	useEffect(() => {
		onErrorRef.current = onError;
	}, [onError]);

	useEffect(() => {
		if (!src) {
			return;
		}

		const preloader = preloadImage(src);
		if (preloader instanceof HTMLImageElement) {
			updateRef(ref, preloader);
			setImageLoaded(true);
			return;
		}

		preloader.then(
			(image) => {
				if (isMounted()) {
					updateRef(ref, image);
					setImageLoaded(true);
				}
			},
			(image) => {
				if (isMounted()) {
					if (onErrorRef.current) {
						onErrorRef.current(image);
					}
					setImageLoaded(false);
				}
			},
		);
	}, [isMounted, ref, src]);

	return (
		<>
			{imageLoaded
				? (
					<Paper sx={{
						width: {
							xs: '100%',
							md: 'auto',
						},
						px: {
							xs: 2,
							md: 0,
						},
						background: 'inherit',
						boxShadow: 24,
						overflow: 'auto',
					}}
					>
						<Box sx={{ display: 'block', width: '100%' }} component="img" src={src} alt={alt} />
					</Paper>
				) : (
					<CircularProgress color="success" />
				)}
		</>
	);
});
