import React, {memo} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import CardHeader from '@mui/material/CardHeader';
import {
	Avatar, Box, Card, CardActionArea, Typography,
} from '@mui/material';
import CardMedia from '@mui/material/CardMedia';

import {takeNameToAvatar, getDateString} from 'library/utils/textGenerators';
import { ITrend } from 'library/common/types/cards/trends.d';
import Label from 'library/common/components/Label/Label';
import { getToolbarAvatar } from 'library/utils/getters/getPicture';

interface IProps extends ITrend {}

const Trend = ({
	id,
	title,
	author,
	cover,
	created,
}: IProps) => (
	<Card elevation={0} sx={{ width: 120, borderRadius: 1 }}>
		<CardActionArea component={RouterLink} to={`/app/card/${id}`}>
			<Box sx={{
				position: 'relative',
				borderRadius: '8px',
				overflow: 'hidden',
			}}
			>
				<CardMedia
					sx={{
						height: '120px',
						width: '120px',
					}}
					component="img"
					image={cover.trend}
					alt={title}
				/>
				<Box
					sx={{
						top: 0,
						width: '100%',
						height: '100%',
						position: 'absolute',
						clipPath: 'polygon(0 76%, 100% 55%, 100% 100%, 0% 100%)',
						background: 'rgba(253, 252, 251, 0.9)',
					}}
				/>
				<Avatar
					sx={{
						bgcolor: author.bgColor,
						position: 'absolute',
						bottom: '8px',
						right: '8px',
						width: '32px',
						height: '32px',
					}}
					aria-label={author.login}
					src={getToolbarAvatar(author.avatar)}
				>
					{takeNameToAvatar(author.login)}
				</Avatar>
			</Box>
			<CardHeader
				sx={{ px: 0.5, py: 1 }}
				title={(
					<Label
						variant="subtitle1"
						component="h6"
						title={title}
						sx={{
							WebkitLineClamp: 1, fontSize: '16px', lineHeight: '18px', fontWeight: 500, margin: 0,
						}}
					/>
				)}
				subheader={(
					<Typography
						variant="subtitle2"
						sx={{
							fontSize: '12px', lineHeight: '16px', fontWeight: 400,
						}}
					>
						{getDateString(created)}
					</Typography>
				)}
			/>
		</CardActionArea>
	</Card>
);
export default memo(Trend, () => true);
