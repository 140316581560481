export const COVER_SIZE = {
	width: 392,
	height: 392,
};

export const SX_COVER_SIZE = {
	width: '100%',
	height: 392,
};

// eslint-disable-next-line no-shadow
export enum EditMode {
    NONE = 'none',
    DESCRIPTION = 'description',
    EDIT_STEP = 'edit_step',
    CREATE_STEP = 'create_step',
}
