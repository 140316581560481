import React, { ReactElement, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactPlayer from 'react-player';
import { OnProgressProps, SourceProps } from 'react-player/base';
import { Box } from '@mui/material';

import { setWodaStoryMuteState, setWodaStoryPlayingState } from 'library/common/actions/app.action';
import { selectSetWodaStoryMuteState, selectWodaStoryPlayingState } from 'library/common/selectors/app.selector';

import ControlPanel from './ControlPanel';

import styles from './styles.module.css';

interface IProps {
    url: string | string[] | SourceProps[] | MediaStream;
    isActive?: boolean;
	isIntersecting?: boolean;
    light?: boolean | string | ReactElement;
    onReady?: (player: ReactPlayer) => void;
    onStart?: () => void;
    onPlay?: () => void;
    onPause?: () => void;
    onBuffer?: () => void;
    onBufferEnd?: () => void;
    onEnded?: () => void;
    onClickPreview?: (event: any) => void;
    onEnablePIP?: () => void;
    onDisablePIP?: () => void;
    onError?: (error: any, data?: any, hlsInstance?: any, hlsGlobal?: any) => void;
    onDuration?: (duration: number) => void;
    onSeek?: (seconds: number) => void;
    onProgress?: (state: OnProgressProps) => void;
}

const WodaStoryPlayer: React.FunctionComponent<IProps> = ({
	isActive,
	isIntersecting,
	...props
}) => {
	const dispatch = useDispatch();

	const videoRef = useRef<ReactPlayer>(null);
	const controlsRef = useRef<HTMLDivElement>(null);

	const playing = useSelector(selectWodaStoryPlayingState);
	const muted = useSelector(selectSetWodaStoryMuteState);

	const togglePlay = () => dispatch(setWodaStoryPlayingState());

	const toggleMuted = () => dispatch(setWodaStoryMuteState());

	const handleMouseMove = () => {
		if (controlsRef.current?.style) {
			controlsRef.current.style.opacity = '1';
		}
	};

	const handleMouseLeave = () => {
		if (controlsRef.current?.style) {
			controlsRef.current.style.opacity = '0';
		}
	};

	return (
		<Box
			sx={{
				width: '100%',
				height: '100%',
				cursor: 'pointer',
			}}
			onMouseMove={handleMouseMove}
			onMouseLeave={handleMouseLeave}
		>
			<ReactPlayer
				{...props}
				width="100%"
				height="100%"
				ref={videoRef}
				controls={false}
				muted={!muted}
				playing={playing && isIntersecting && isActive}
				className={styles.player}
				// onReady={() => console.log(index, isActive, 'I am ready', props.url)}
			/>
			<ControlPanel
				isActive={isActive}
				ref={controlsRef}
				muted={!muted}
				playing={playing}
				toggleMuted={toggleMuted}
				togglePlay={togglePlay}
			/>
		</Box>
	);
};

export default WodaStoryPlayer;
