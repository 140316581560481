import wodabonusesApi from 'library/common/services/wodabonuses.service';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { IRule } from 'library/common/types/wodaprograms.d';
import { showSnackbarMessage } from 'library/utils/showSnackbarMessage';
import { MINUTE_IN_MS } from 'library/utils/converters/time.converter';

import wodabonusesQueryKeys from './queryKeys';
import { rulesMapper } from './mappers';

const rulesQueryFunction = async (): Promise<IRule[]> => {
	const result = await wodabonusesApi.getRules();

	return rulesMapper(result);
};

const useRules = (options?: UseQueryOptions<IRule[]>) => useQuery(
	wodabonusesQueryKeys.rules(),
	rulesQueryFunction,
	{
		cacheTime: MINUTE_IN_MS,
		staleTime: MINUTE_IN_MS,
		onError: () => showSnackbarMessage('Ops, There was a problem loading bonus rules, we are working on a fix', 'error'),
		retry: 2,
		retryDelay: 1000,
		...options,
	},
);

export default useRules;
