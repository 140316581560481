export const SECOND_IN_MS = 1000;
export const MINUTE_IN_MS = 60 * SECOND_IN_MS;
export const HOUR_IN_MS = 60 * MINUTE_IN_MS;
export const DAY_IN_MS = 24 * HOUR_IN_MS;

export default (
	days: number,
	hours: number,
	min: number,
	sec: number,
): number => (days * 86400 || 0) + (hours * 3600 || 0) + (min * 60 || 0) + (sec || 0);
