import React from 'react';
import {
	Box, Divider, Grid, Typography,
} from '@mui/material';

import { getPoster } from 'library/utils/getters/getPicture';
import { IPoorCard } from 'library/common/types/cards/cards.d';
import { declOfNum, getFullDuration } from 'library/utils/workWithData';
import UnionIcon from 'resources/icons/UnionIcon';
import BorderLine from 'pages/Profile/components/List/BorderLine';

import ListItem from './ListItem';

interface IProps {
    cards: IPoorCard[];
}

const SimpleList: React.FunctionComponent<IProps> = ({ cards }) => (
	<>
		{
			cards.map((card) => (
				<ListItem key={card.id} id={card.id}>
					<>
						<Grid item sm={12} lg={1}>
							<Box
								component="img"
								sx={{
									width: '64px',
									height: '64px',
								}}
								src={getPoster(card, 'LIST')}
							/>
						</Grid>

						<Grid container item sm={12} lg={10} position="relative">
							<Grid item xs={12} lg={6} display="flex" alignItems="center">
								<Typography
									variant="h6"
									sx={{
										fontWeight: 600,
										color: (theme) => theme.palette.common.white,
									}}
								>
									{card.title}
								</Typography>
							</Grid>
							<Grid item xs={12} lg={5} display="flex" alignItems="center">
								<Typography
									variant="h6"
									className="info"
									sx={{ color: (theme) => theme.palette.secondary.light }}
								>
									{card.duration && getFullDuration(card.duration)}
								</Typography>
								<Divider
									orientation="vertical"
									flexItem
									className="info"
									sx={{
										mx: 1,
										borderColor: (theme) => theme.palette.secondary.light,
										height: '16px',
										alignSelf: 'auto',
									}}
								/>
								<Typography
									variant="h6"
									className="info"
									sx={{ color: (theme) => theme.palette.secondary.light }}
								>
									{`${card.number_of_steps} ${declOfNum(card.number_of_steps, ['step', 'steps', 'steps'])} out of ${card.number_of_steps}`}
								</Typography>
							</Grid>
							<Grid item xs={12} lg={1} display="flex" alignItems="center" justifyContent="flex-end">
								<Typography
									variant="h6"
									className="info"
									sx={{ color: (theme) => theme.palette.secondary.light }}
								>
									{card.category.title}
								</Typography>
							</Grid>

							<BorderLine />
						</Grid>

						<Grid item sm={12} lg={1} sx={{display: 'flex'}}>
							<UnionIcon
								sx={{
									margin: 'auto',
									width: '28px',
									height: '30px',
									fill: (theme) => theme.palette.secondary.light,
								}}
							/>
						</Grid>
					</>
				</ListItem>
			))
		}
	</>
);

export default SimpleList;
