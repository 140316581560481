import React, {SyntheticEvent, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {
	Avatar, Badge, IconButton, SxProps, Theme,
} from '@mui/material';
import * as colors from '@mui/material/colors';
import VerifiedIcon from 'resources/icons/VerifiedIcon';
import {takeColorByFirstLetter} from 'library/utils/myColors';
import {takeNameToAvatar} from 'library/utils/textGenerators';

interface IAvatarButton {
	author?: string;
	authorId?: string | null;
	userPhoto?: string | null;
	isVerified?: boolean;
	size: number;
	sx?: SxProps<Theme>
}

const AvatarButton = ({
	author, authorId, userPhoto, size, sx, isVerified,
}: IAvatarButton) => {
	const [bgColor] = useState(userPhoto ? 'transparent' : takeColorByFirstLetter(colors, author || 'A'));
	const [avatarFirstLetter] = useState(takeNameToAvatar(author || 'A'));

	return (
		<IconButton
			sx={{
				p: 0,
				...sx,
			}}
			component={RouterLink}
			to={`/app/profile/${authorId}`}
			onClick={(event: SyntheticEvent) => event.stopPropagation()}
		>
			{isVerified ? (
				<Badge
					overlap="circular"
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					badgeContent={
						<VerifiedIcon sx={{ width: '16px', height: '16px' }} />
					}
				>
					<Avatar
						variant="circular"
						src={userPhoto || undefined}
						sx={{
							width: `${size}px`,
							height: `${size}px`,
							bgcolor: bgColor,
						}}
					>
						{avatarFirstLetter}
					</Avatar>
				</Badge>

			) : (
				<Avatar
					variant="circular"
					src={userPhoto || undefined}
					sx={{
						width: `${size}px`,
						height: `${size}px`,
						bgcolor: bgColor,
					}}
				>
					{avatarFirstLetter}
				</Avatar>
			)}
		</IconButton>
	);
};

export default AvatarButton;
