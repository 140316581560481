import React, { ForwardedRef, SyntheticEvent, forwardRef } from 'react';
import { animated, useSpring } from '@react-spring/web';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import {
	Box, IconButton, Stack, Theme,
} from '@mui/material';
import TouchRipple, { TouchRippleActions } from '@mui/material/ButtonBase/TouchRipple';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

import styles from './styles.module.css';

interface IProps {
	isActive?: boolean;
	innerRef?: ForwardedRef<HTMLDivElement>;
	playing: boolean;
	muted: boolean;
	togglePlay(): void;
	toggleMuted(): void;
}

const buttonStyle = {
	fill: (theme: Theme) => theme.palette.common.white,
};

const ControlPanel: React.FunctionComponent<IProps> = ({
	innerRef, isActive, muted, playing, toggleMuted, togglePlay,
}) => {
	const rippleRef = React.useRef<TouchRippleActions>(null);

	const playerStateIndicatorStyles = useSpring({
		from: { opacity: 0 },
		to: [
			{ opacity: 1 },
			{ opacity: 0},
		],
		config: {
			duration: 300,
		},
	});

	const onRippleStart = (e: SyntheticEvent) => {
		rippleRef.current?.start(e);
		togglePlay();
	};

	const onRippleStop = (e: SyntheticEvent) => {
		rippleRef.current?.stop(e);
	};

	if (!isActive) {
		return null;
	}

	return (
		<Box
			ref={innerRef}
			onMouseDown={onRippleStart}
			onMouseUp={onRippleStop}
			sx={{
				opacity: 0,
				transition: 'opacity 0.1s cubic-bezier(0.4,0,1,1)',
				position: 'absolute',
				top: 0,
				left: 0,
				zIndex: 1,
				width: '100%',
				height: '100%',
			}}
		>
			<Stack sx={{
				height: '88px',
				borderTopLeftRadius: '16px',
				borderTopRightRadius: '16px',
				flexDirection: 'row',
				justifyContent: 'space-between',
				px: 2,
				pt: 2,
				pb: 4,
				backgroundImage: 'linear-gradient(180deg,rgba(0,0,0,0.4),transparent)',
			}}
			>
				<IconButton onClick={togglePlay}>
					{
						playing ? <PauseCircleIcon sx={buttonStyle} /> : <PlayCircleIcon sx={buttonStyle} />
					}
				</IconButton>

				<IconButton
					sx={{
						position: 'relative',
						zIndex: 1,
					}}
					onMouseDown={(e) => {
						e.preventDefault();
						e.stopPropagation();
						toggleMuted();
					}}
				>
					{
						muted ? <VolumeUpIcon sx={buttonStyle} /> : <VolumeOffIcon sx={buttonStyle} />
					}
				</IconButton>
			</Stack>
			<animated.div className={styles.indicatorPanel} style={playerStateIndicatorStyles}>
				{
					!playing ? (
						<PauseIcon sx={{
							fontSize: '5rem',
							fill: (theme: Theme) => theme.palette.common.white,
						}}
						/>
					) : (
						<PlayArrowIcon sx={{
							fontSize: '5rem',
							fill: (theme: Theme) => theme.palette.common.white,
						}}
						/>
					)
				}
			</animated.div>
			<TouchRipple ref={rippleRef} center={false} />
		</Box>
	);
};

export default forwardRef((props: IProps, ref: ForwardedRef<HTMLDivElement>) => (
	<ControlPanel innerRef={ref} {...props} />
));
