import React from 'react';
import { Box, CircularProgress } from '@mui/material';

interface IProps {}

const FullScreenLoader: React.FunctionComponent<IProps> = () => (
	<Box
		sx={{
			width: '100%',
			height: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		}}
	>
		<CircularProgress />
	</Box>
);

export default FullScreenLoader;
