import React from 'react';
import { useMount } from 'react-use';
import cn from 'classnames';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {RootState} from 'main/rootReducer';

import styles from './styles.module.css';

const Page500 = () => {
	const haveError = useSelector((state: RootState) => state.app.error);
	const navigate = useNavigate();

	useMount(() => {
		if (!haveError) navigate('/app/main');
	});

	return (
		<div className={styles.container}>
			<button type="button" className={styles.error_code}>
				<div className={cn(styles.number, styles.five)}>
					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>
					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>
					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>
					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>

					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>
					<div className={cn(styles.cell, styles.empty)} />
					<div className={cn(styles.cell, styles.empty)} />
					<div className={cn(styles.cell, styles.empty)} />

					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>
					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>
					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>
					<div className={cn(styles.cell, styles.empty)} />

					<div className={cn(styles.cell, styles.empty)} />
					<div className={cn(styles.cell, styles.empty)} />
					<div className={cn(styles.cell, styles.empty)} />
					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>

					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>
					<div className={cn(styles.cell, styles.empty)} />
					<div className={cn(styles.cell, styles.empty)} />
					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>

					<div className={cn(styles.cell, styles.empty)} />
					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>
					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>
					<div className={cn(styles.cell, styles.empty)} />
				</div>

				<div className={cn(styles.number, styles.zero)}>
					<div className={cn(styles.cell, styles.empty)} />
					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>
					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>
					<div className={cn(styles.cell, styles.empty)} />

					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>
					<div className={cn(styles.cell, styles.empty)} />
					<div className={cn(styles.cell, styles.empty)} />
					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>

					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>
					<div className={cn(styles.cell, styles.empty)} />
					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>
					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>

					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>
					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>
					<div className={cn(styles.cell, styles.empty)} />
					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>

					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>
					<div className={cn(styles.cell, styles.empty)} />
					<div className={cn(styles.cell, styles.empty)} />
					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>

					<div className={cn(styles.cell, styles.empty)} />
					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>
					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>
					<div className={cn(styles.cell, styles.empty)} />
				</div>

				<div className={cn(styles.number, styles.zero)}>
					<div className={cn(styles.cell, styles.empty)} />
					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>
					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>
					<div className={cn(styles.cell, styles.empty)} />

					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>
					<div className={cn(styles.cell, styles.empty)} />
					<div className={cn(styles.cell, styles.empty)} />
					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>

					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>
					<div className={cn(styles.cell, styles.empty)} />
					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>
					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>

					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>
					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>
					<div className={cn(styles.cell, styles.empty)} />
					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>

					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>
					<div className={cn(styles.cell, styles.empty)} />
					<div className={cn(styles.cell, styles.empty)} />
					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>

					<div className={cn(styles.cell, styles.empty)} />
					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>
					<div className={cn(styles.cell, styles.filled)}>
						<div className={styles.bug} />
					</div>
					<div className={cn(styles.cell, styles.empty)} />
				</div>
			</button>
		</div>
	);
};

export default Page500;
