import React from 'react';
import {
	Box, IconButton, SxProps, Theme,
} from '@mui/material';
import CrossIcon from 'resources/icons/CrossIcon';

interface IView {
    src: string,
    alt: string,
    sx?: SxProps<Theme>;
	dropImage?: () => void;
}

const View = ({
	src, alt, sx, dropImage,
}: IView) => (
	<Box
		sx={{
			position: 'relative',
			maxWidth: '100%',
			borderRadius: '4px',
		}}
	>
		<Box
			component="img"
			src={src}
			alt={alt}
			sx={{
				maxWidth: '100%',
				...sx,
			}}
		/>
		{dropImage &&	(
			<IconButton
				aria-label="drop image"
				size="small"
				sx={{
					position: 'absolute',
					top: '16px',
					right: '16px',
					background: (theme) => `${theme.palette.secondary.light}33`,
					'&:hover': {
						background: (theme) => `${theme.palette.secondary.light}66`,
					},
				}}
				onClick={dropImage}
			>
				<CrossIcon fontSize="inherit" />
			</IconButton>
		)}
	</Box>
);

export default View;
