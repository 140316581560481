import { IRule, ITransition, IVault } from '../types/wodaprograms.d';
import { wodabonusApi } from './urls';

const getTransactionsList = () => wodabonusApi
	.get<ITransition[]>('transactions')
	.then((result) => result);

const getLastTransactionsList = () => wodabonusApi
	.get<ITransition[]>('transactions/last')
	.then((result) => result);

const getVaultInfo = () => wodabonusApi
	.get<IVault>('vault/last')
	.then((result) => result);

const getVault = () => wodabonusApi
	.get<number>('vault')
	.then((result) => result);

const getSelfVault = () => wodabonusApi
	.get<number>('vault/self')
	.then((result) => result);

const getRules = () => wodabonusApi
	.get<IRule[]>('rules')
	.then((result) => result);

export default {
	getTransactionsList,
	getLastTransactionsList,
	getVaultInfo,
	getVault,
	getSelfVault,
	getRules,
};
