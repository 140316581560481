export default {
	1000: {
		eng: 'Normal Closure',
		rus: 'Нормальное закрытие',
	},
	1001: {
		eng: 'Going Away',
		rus: 'Ошибка на сервере',
	},
	1002: {
		eng: 'Protocol Error',
		rus: 'Ошибка протокола',
	},
	1003: {
		eng: 'Unsupported Data',
		rus: 'Неподдерживаемые данные',
	},
	1004: {
		eng: '(For future)',
		rus: 'Неизвестная ошибка',
	},
	1005: {
		eng: 'No Status Received',
		rus: 'Статус не получен',
	},
	1006: {
		eng: 'Abnormal Closure',
		rus: 'Аномальное закрытие',
	},
	1007: {
		eng: 'Invalid frame payload data',
		rus: 'Неверные данные',
	},
	1008: {
		eng: 'Policy Violation',
		rus: 'Нарушение Политики',
	},
	1009: {
		eng: 'Message too big',
		rus: 'Сообщение слишком большое',
	},
	1010: {
		eng: 'Missing Extension',
		rus: 'Отсутствует расширение',
	},
	1011: {
		eng: 'Internal Error',
		rus: 'Внутренняя ошибка',
	},
	1012: {
		eng: 'Service Restart',
		rus: 'Перезапуск службы',
	},
	1013: {
		eng: 'Try Again Later',
		rus: 'Попробуйте позже',
	},
	1014: {
		eng: 'Bad Gateway',
		rus: 'Плохой шлюз',
	},
	1015: {
		eng: 'TLS Handshake',
		rus: 'Ошибка при рукопожатии',
	},
	3001: {
		eng: 'Closed from client side',
		rus: 'Закрыто со стороны клиента',
	},
};
