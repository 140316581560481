import React, {useCallback, useMemo, useState} from 'react';
import {
	Collapse,
	CircularProgress,
	Button,
	IconButton,
	Typography,
	Stack,
	Box,
} from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

import { UUID } from 'library/common/types/app.d';
import { ICommentEntity } from 'library/common/types/cards/comments.d';

import useChildComments from 'library/common/data/comments/useChildComments';
import { getCommentsMapper } from 'library/common/data/comments/mappers';
import useAddChildCommentMutation from 'library/common/data/comments/useAddChildCommentMutation';
import useToggleLikeMutation from 'library/common/data/comments/useToggleLikeMutation';
import useRemoveCommentMutation from 'library/common/data/comments/useRemoveCommentMutation';
import CommentHeader from './CommentHeader';
import ShowMoreButton from './ShowMoreButton';

import NewCommentComponent from './NewCommentComponent';

interface IProps {
	root?: boolean;

	/**
	 * If root - cardId, if child - parent comment id
	 */
	parentId: UUID;

	userId: string | null;
	comment: ICommentEntity;
}

const Comment = ({
	root,
	parentId,
	comment,
	userId,
}: IProps) => {
	const {
		comment: {
			id, text, likes, updated, childrenCount,
		},
		commentUserInfo: {
			author,
		},
		liked,
	} = comment;

	const isMyComment = author.id === userId;

	const [answerMode, setAnswerMode] = useState<boolean>(false);
	const [showMore, setShowMore] = useState(false);

	const {
		data, refetch: getChildCommentsFn, isFetching, isSuccess, isStale,
	} = useChildComments(id, { enabled: showMore });

	const { mutateAsync: removeCommentFn } = useRemoveCommentMutation();
	const { mutateAsync: addChildCommentFn, isLoading: addingCommentInProgress } = useAddChildCommentMutation({
		onSuccessAction() {
			setShowMore(true);
		},
	});
	const { mutateAsync: toggleLikeFn, isLoading: likeInProgress } = useToggleLikeMutation();

	const isChildCommentsPending = isFetching && !isSuccess;

	const childComments = useMemo(() => getCommentsMapper(data), [data]);

	const getChildCommentsHandler = () => {
		if (!showMore) {
			setShowMore(true);
			if (isStale) {
				getChildCommentsFn();
			}
		} else {
			setShowMore(false);
		}
	};

	const removeCommentHandler = useCallback(
		(commentId: UUID) => removeCommentFn({ commentId, root, commentParentId: parentId }),
		[parentId, removeCommentFn, root],
	);

	const toggleLikeHandler = useCallback(() => {
		toggleLikeFn({
			root,
			commentId: id,
			commentParentId: parentId,
		});
	}, [id, parentId, root, toggleLikeFn]);

	return (
		<>
			<Stack direction="column">
				<CommentHeader
					commentId={id}
					author={author}
					createdDate={updated}
					isAuthor={isMyComment}
					removeCommentFn={removeCommentHandler}
				/>

				<Box sx={{ my: 1, ml: 9 }}>
					<Typography variant="body1">{text}</Typography>
				</Box>

				{root && (
					<Collapse orientation="vertical" unmountOnExit mountOnEnter in={answerMode}>
						<NewCommentComponent
							sx={{ ml: 7 }}
							addCommentHandler={(commentText: string) => addChildCommentFn({ commentId: id, commentMessage: commentText})}
							defaultCancelAction={() => setAnswerMode(false)}
							disabled={addingCommentInProgress}
							autoFocus
						/>
					</Collapse>
				)}

				<Stack direction="row" alignItems="center" sx={{ ml: 9, mt: 2 }}>
					{
						root && (
							<ShowMoreButton
								showMore={showMore}
								childrenCount={childrenCount}
								onClickHandler={getChildCommentsHandler}
							/>
						)
					}
					<Stack direction="row" alignItems="center" spacing={2} ml="auto">
						<Stack direction="row" alignItems="center" spacing={0.5}>
							<IconButton
								size="small"
								onClick={toggleLikeHandler}
								disabled={likeInProgress}
								sx={{ color: ({ palette }) => palette.text.secondary }}
							>
								<ThumbUpIcon
									style={{fontSize: 16}}
									color={liked ? 'primary' : 'inherit'}
								/>
							</IconButton>
							<Typography variant="subtitle1" sx={{ color: ({ palette }) => palette.text.secondary }}>
								{likes}
							</Typography>
						</Stack>
						{
							!answerMode && root && (
								<Button size="small" variant="outlined" color="secondary" onClick={() => setAnswerMode(true)}>Reply</Button>
							)
						}
					</Stack>
				</Stack>
			</Stack>
			<Box style={{marginLeft: root ? '44px' : undefined}}>
				{isChildCommentsPending && (
					<Box
						sx={{
							width: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							padding: '12px',
						}}
					>
						<CircularProgress color="primary" size={24} />
					</Box>

				)}
				{
					showMore && !isChildCommentsPending && childComments && (
						childComments
							.map((childComment) => (
								<Comment
									key={childComment.comment.id}
									parentId={id}
									userId={userId}
									comment={childComment}
								/>
							))
					)
				}
			</Box>
		</>
	);
};

export default Comment;
