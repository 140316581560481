import React from 'react';
import {Box} from '@mui/material';

interface ITabPanel<T> {
	children?: React.ReactNode;
	index: T;
	value: T;
}

function TabPanel<T>({
	children, value, index, ...other
}: ITabPanel<T>) {
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			style={{height: '100%'}}
			{...other}
		>
			{value === index && (
				<Box sx={{p: { xs: '32px 0px', md: '32px 24px'}, height: '100%' }}>
					{children}
				</Box>
			)}
		</div>
	);
}

export default TabPanel;
