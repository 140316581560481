import { TextField, TextFieldProps, Typography } from '@mui/material';
import React, { forwardRef } from 'react';
import { RefCallBack } from 'react-hook-form';

type IProps = TextFieldProps & {
    innerRef: RefCallBack | null,
    maxLength: number,
}

const TextFieldWithCount = ({
	value, error, innerRef, maxLength, ...props
}: IProps) => (
	<TextField
		{...props}
		ref={innerRef}
		value={value}
		error={error}
		inputProps={{
			maxLength,
		}}
		// eslint-disable-next-line react/jsx-no-duplicate-props
		InputProps={{
			sx: {
				pr: 0,
				fontSize: '16px',
			},
			endAdornment: (
				<Typography
					sx={{
						color: (theme) => (error
							? theme.palette.error.main
							: theme.palette.text.secondary),
						position: 'absolute',
						right: '4px',
						top: '-20px',
						...props.sx,
					}}
					variant="caption"
				>
					{`${(value as string | undefined)?.length}/${maxLength}`}
				</Typography>
			),
		}}
	/>
);

export default forwardRef((props: Omit<IProps, 'innerRef'>, ref: any) => (
	<TextFieldWithCount innerRef={ref} {...props} />
));
