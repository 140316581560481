import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Container, IconButton, Stack, Typography,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import ProgramsBanner from 'resources/image/Banner_for_programs.svg';
import useRules from 'library/common/data/wodabonuses/useRules';
import FullScreenLoader from 'library/common/components/FullScreenLoader';
import getBonusGlobalUrl from 'library/utils/routes/getBonusGlobalUrl';

import dictionary from '../dictionary';
import ProgramCard from './ProgramCard';

interface IProps {}

const Programs: React.FunctionComponent<IProps> = () => {
	const navigate = useNavigate();

	const { data, isLoading } = useRules();

	if (isLoading) {
		return (
			<Container disableGutters maxWidth={false}>
				<FullScreenLoader />
			</Container>
		);
	}

	return (
		<Container
			disableGutters
			maxWidth={false}
			sx={{
				position: 'relative',
				pb: { xs: 10, md: 5 },

				'&:before': {
					content: "''",
					position: 'absolute',
					zIndex: 1,
					top: 0,
					right: 0,
					backgroundImage: `url("${ProgramsBanner}")`,
					width: '100%',
					height: '344px',
					backgroundPositionX: 'right',
					backgroundPositionY: 'top',
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'contain',
				},
			}}
		>

			<Container
				maxWidth="xl"
				sx={{
					position: 'relative',
					zIndex: 2,
				}}
			>
				<Stack direction="row" alignItems="center" spacing={1}>
					<IconButton size="small" onClick={() => navigate(getBonusGlobalUrl())}>
						<ArrowBackIosIcon fontSize="small" />
					</IconButton>
					<Typography variant="caption" sx={{ color: ({ palette}) => palette.text.secondary }}>
						go to global bonuses
					</Typography>
				</Stack>
				<Typography
					variant="h3"
					sx={{ mt: 3, color: '#272342' }}
				>
					{dictionary.wodabonusPrograms}
				</Typography>
				<Typography
					variant="h6"
					sx={{ mt: 1.5, color: ({ palette }) => palette.text.primary }}
				>
					Participate in programs and get bonuses
				</Typography>

				<Typography
					variant="h6"
					sx={{ mt: 9, fontSize: '20px', color: ({ palette }) => palette.text.primary }}
				>
					Advanced programs
				</Typography>

				<Stack mt={5} spacing={5}>
					{data?.map((datum) => <ProgramCard key={datum.type} type={datum.type} amount={datum.amount} />)}
				</Stack>

				<Typography
					variant="h6"
					sx={{ mt: 7, color: ({ palette }) => palette.text.primary }}
				>
					Important notices
				</Typography>
				<Typography
					variant="body1"
					sx={{ mt: 1, color: ({ palette }) => palette.text.primary }}
				>
					Bonus programs are available only for registered users
					<br />
					We reserve the right to disqualify and punish any dishonest and cheating behavior
					<br />
					We reserve the right to adjust tasks and rewards at any time according to market conditions
					<br />
				</Typography>
			</Container>
		</Container>
	);
};

export default Programs;
