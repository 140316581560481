import { mapExternalNotificationsToken } from 'library/utils/mappers/notifications.mapper';

import { notificationsApi } from './urls';

const getToken = () => notificationsApi
	.get('/token')
	.then(mapExternalNotificationsToken);

export default {
	getToken,
};
