import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import {
	Box, Button, Typography, Container, Stack,
} from '@mui/material';

import DashboardIcon from 'resources/icons/DashboardIcon';

const RootStyle = styled(Box)(({ theme }) => ({
	display: 'flex',
	minHeight: '100%',
	alignItems: 'center',
	paddingTop: theme.spacing(15),
	paddingBottom: theme.spacing(10),
	background: (theme.palette as any).gradients.info,
}));

const transformArray = [0, -1, 0, 1, 0, -1, 0];

const animationStyles1 = (index: number) => ({
	[`@keyframes error-code-anim-${index}`]: [...Array(5)].reduce((accumulator, __, i) => ({
		...accumulator,
		[`${i * 25}%`]: {
			transform: `translateY(${transformArray[i + index] || 0}vmin)`,
		},
	}), {}),
});

const Page404 = () => (
	<RootStyle title="404 Page Not Found">
		<Container>
			<Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
				<Stack
					direction="row"
					textAlign="center"
					sx={{
						color: (theme) => theme.palette.common.white,
						textShadow: '1.6vmin 1.6vmin rgb(48 48 48 / 50%)',
						userSelect: 'none',
						padding: '1.5vmin 5vmin',
					}}
				>
					{
						[4, 0, 4].map((value, index) => (
							<Typography
								// eslint-disable-next-line react/no-array-index-key
								key={`${index}_${value}`}
								variant="h1"
								component="span"
								sx={{
									fontSize: '10rem',
									fontWeight: 900,
									animation: `error-code-anim-${index} 600ms linear infinite`,

									...animationStyles1(index),
								}}
							>
								{value}
							</Typography>
						))
					}

				</Stack>

				<Typography
					variant="h3"
					paragraph
					sx={{
						mt: 3,
						color: (theme) => theme.palette.common.white,
						fontWeight: 700,
						textShadow: '0.1866666667rem 0.1866666667rem rgb(48 48 48 / 50%)',
						userSelect: 'none',
					}}
				>
					Sorry, page not found!
				</Typography>
				<Typography
					variant="body1"
					sx={{
						color: (theme) => theme.palette.common.white,
						fontWeight: 700,
					}}
				>
					Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL?
					Be sure to check your spelling.
				</Typography>

				<Button
					to="/"
					size="large"
					variant="contained"
					component={RouterLink}
					sx={{ mt: 4, minWidth: '50%', background: (theme) => theme.palette.secondary.main }}
					startIcon={<DashboardIcon />}
				>
					Go to Home
				</Button>
			</Box>
		</Container>
	</RootStyle>
);

export default Page404;
