import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { logger } from 'library/utils/lintersSugar';
import getLoginPageUrl from 'library/utils/routes/getLoginPageUrl';
import { showSnackbarMessage } from 'library/utils/showSnackbarMessage';
import userServices from 'library/common/services/user.service';
import cardsService from 'library/common/services/cards.service';

import { UUID } from '../types/app.d';
import { IReelsCard } from '../types/cards/reels.d';
import { selectIsAuthorized } from '../selectors/user.selector';
import { changeFollowingsList } from '../actions/user.action';

interface IResult {
    cardsState: IReelsCard[];
	isSubscribing: boolean;
	isPending: boolean;
    onLikeHandle(cardId: UUID, status?: boolean): void;
    onSubscribeHandle(authorId: UUID, isSubscribed: boolean): void;
}

// TODO обмазать проверками
// TODO в идеале - сделать в useQuery

const useWQReels = (): IResult => {
	const {t} = useTranslation();

	const dispatch = useDispatch();

	const [isSubscribing, setIsSubscribing] = useState<boolean>(false);
	const [isPending, setIsPending] = useState<boolean>(true);

	const isAuth = useSelector(selectIsAuthorized);

	const navigate = useNavigate();

	const [cardsState, setCardsState] = useState<IReelsCard[]>([]);

	useEffect(() => {
		cardsService.getReelsCardData()
			.then(setCardsState)
			.catch((e) => logger(e, 'error'))
			.finally(() => setIsPending(false));
	}, []);

	const onSubscribeHandle = async (authorId: UUID, isSubscribed: boolean) => {
		if (!isAuth) {
			navigate(getLoginPageUrl());
			return;
		}

		try {
			setIsSubscribing(true);

			await userServices[isSubscribed ? 'unfollowUser' : 'followUser'](authorId);

			dispatch(changeFollowingsList(authorId));
		} catch (e) {
			showSnackbarMessage('Something goes bad', 'error', 3000);
		} finally {
			setIsSubscribing(false);
		}
	};

	/**
	 * Like === Favorite
	 */
	const onLikeHandle = useCallback(
		async (cardId: UUID, status: boolean = false) => {
			if (!isAuth) {
				navigate(getLoginPageUrl());
				return;
			}

			try {
				await cardsService[status ? 'removeFromFavoriteCard' : 'addToFavoriteCard'](cardId);

				setCardsState((prev) => prev.map((card) => {
					if (card.id !== cardId) {
						return card;
					}

					return {
						...card,
						client_info: {
							...card?.client_info,
							is_favorite: !status,
						},
						statistic: {
							...card.statistic,
							favorites: status
								? card.statistic.favorites - 1
								: card.statistic.favorites + 1,
						},
					};
				}));
			} catch (error) {
				showSnackbarMessage(t('card_add_to_favorites_error'), 'error');
				logger('activate/deactivate falls', 'error');
			}
		},
		[isAuth, navigate, t],
	);

	return {
		cardsState,
		onLikeHandle,
		onSubscribeHandle,
		isSubscribing,
		isPending,
	};
};

export default useWQReels;
