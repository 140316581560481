import React from 'react';
import { animated, useSpring } from '@react-spring/web';

import { ISlideProps } from './types.d';
import { getDefaultTranslateX } from './utils';

import styles from './styles.module.css';

const Slide: React.FunctionComponent<ISlideProps> = ({
	content,
	offsetRadius,
	index,
	animationConfig,
	onClick,
}) => {
	const [props] = useSpring(() => {
		const offsetFromCenter = index - offsetRadius;
		const distanceFactor = 1 - Math.abs(offsetFromCenter / (offsetRadius + 1));

		const translateX = getDefaultTranslateX(
			offsetFromCenter,
			offsetRadius,
			index,
		);

		return {
			to: {
				transform: `translateY(-50%) translateX(${translateX}%) scale(${distanceFactor})`,
				left: `${offsetRadius === 0 ? 50 : 50 + (offsetFromCenter * 50) / offsetRadius}%`,
				// opacity: distanceFactor * distanceFactor,
			},

			config: animationConfig,
		};
	}, [index]);

	return (
		<animated.div
			onClick={onClick}
			className={styles.sliderContainer}
			style={{
				...props,
				zIndex: Math.abs(Math.abs(index - offsetRadius) - offsetRadius),
			}}
		>
			{content}
		</animated.div>
	);
};

export default Slide;
