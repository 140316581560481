import React, { useCallback, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
	Box, Button, CircularProgress, Stack, styled, Typography,
} from '@mui/material';

import Image from 'library/common/components/inputs/Image';
import { IDetailedCard } from 'library/common/types/cards/cards.d';
import EditIcon from 'resources/icons/EditIcon';
import { ICardDetailedFormData } from 'library/common/types/cards/cardsCreation.d';
import { getPoster } from 'library/utils/getters/getPicture';
import { getFullTimeObj } from 'library/utils/workWithData';
import TextFieldWithCount from 'library/common/components/TextFieldWithCount';
import CategoriesSelector from 'pages/CardCreation/components/CategoriesSelector';
import ResultsSelector from 'library/common/components/ResultsSelector/ResultsSelector';
import TimeInput from 'pages/CardCreation/components/TimeInput';

interface IProps {
	detailedCard: IDetailedCard;
	coverSize: {
		width: number;
		height: number;
	}
    handleClose(): void;

	changeDescriptionHandler(newCardState: Partial<ICardDetailedFormData>): Promise<boolean>;
}

const StyledTextField = styled(TextFieldWithCount)(({ theme }) => ({
	fieldset: {
		borderColor: `${theme.palette.secondary.dark}4D`,
	},
}));

const DescriptionEdit: React.FunctionComponent<IProps> = ({
	detailedCard, coverSize, handleClose, changeDescriptionHandler,
}) => {
	const {t} = useTranslation();

	const [isPending, setPending] = useState(false);

	const {
		control, handleSubmit, watch, formState: {errors},
	} = useForm<ICardDetailedFormData>({
		defaultValues: {
			title: detailedCard.title,
			category: detailedCard.category,
			result: detailedCard.result,
			cover: detailedCard.cover,
			...getFullTimeObj(detailedCard.duration || 0),
		},
	});

	const formState = watch();
	const timeWatcher = watch(['days', 'hours', 'minutes', 'seconds']);

	const openImageInputRef = useRef<(() => void) | undefined>(undefined);

	const timeValidation = useCallback((): boolean => !!(
		timeWatcher[0] || timeWatcher[1] || timeWatcher[2] || timeWatcher[3]
	),
	[timeWatcher]);

	const onSubmit = handleSubmit(async (data) => {
		setPending(true);
		changeDescriptionHandler(data).then((result) => {
			setPending(false);

			if (result) handleClose();
		});
	});

	return (
		(
			<Stack direction="column" sx={{ width: '100%' }} component="form" onSubmit={onSubmit}>
				<Typography variant="h5" sx={{ color: ({ palette }) => palette.text.primary }}>
					{t('edit_the_cards_main_information')}
				</Typography>
				<Stack direction={{ xs: 'column', md: 'row' }} mt={8}>
					<Stack direction="column">
						<Controller
							control={control}
							name="cover"
							rules={{
								required: {
									value: true,
									message: t('add_a_card_cover'),
								},
							}}
							render={({ field: { onChange } }) => (
								<Image
									sx={{
										width: { xs: '100%', md: coverSize.width },
										height: { xs: 'auto', md: coverSize.height },
									}}
									disabled={isPending}
									value={getPoster(detailedCard, 'BIG')}
									setData={onChange}
									openImageInputRef={openImageInputRef}
								/>
							)}
						/>

						<Box sx={{ ml: 'auto', mt: 3 }}>
							{errors && errors.cover && (
								<Typography
									variant="caption"
									color="error"
								>
									{errors.cover.message as string}
								</Typography>
							)}
							<Button
								sx={{
									color: (theme) => theme.palette.text.secondary,
									textTransform: 'none',
								}}
								startIcon={<EditIcon />}
								onClick={() => {
									if (openImageInputRef.current) {
										openImageInputRef.current();
									}
								}}
							>
								Change image
							</Button>
						</Box>
					</Stack>

					<Stack direction="column" spacing={2} pl={{ xs: 0, md: 5 }} sx={{ width: '100%' }}>

						<Controller
							control={control}
							name="category"
							rules={{
								required: {
									value: true,
									message: t('please_select_at_least_one_category'),
								},
							}}
							render={({ field: { value, onChange }, fieldState: {error} }) => (
								<CategoriesSelector
									selectionColor="primary"
									onChange={onChange}
									value={value}
									error={error?.message}
								/>
							)}
						/>

						<Controller
							control={control}
							name="title"
							rules={{
								minLength: {
									value: 3,
									message: t('minimum_3_characters'),
								},
								maxLength: {
									value: 100,
									message: t('maximum_100_characters'),
								},
								required: {
									value: true,
									message: t('please_enter_a_name'),
								},
							}}
							render={({ field, fieldState: {error} }) => (
								<StyledTextField
									{...field}
									style={{ marginTop: '36px' }}
									maxLength={120}
									label={`${t('card_name')}`}
									variant="outlined"
									size="small"
									disabled={isPending}
									fullWidth
									error={Boolean(error)}
									helperText={error?.message}
								/>
							)}
						/>

						<TimeInput
							timeMetrics={['days', 'hours', 'minutes', 'seconds']}
							timeValidation={timeValidation}
							control={control}
							daysError={errors.days}
							hoursError={errors.hours}
							minError={errors.minutes}
							secError={errors.seconds}
							errorMessage={t('set_the_time')}
						/>

						<Controller
							control={control}
							name="result"
							rules={{
								required: {
									value: true,
									message: t('please_select_the_result'),
								},
							}}
							render={({ field: { onChange, value }, fieldState: {error} }) => (
								<ResultsSelector
									value={value}
									setValue={onChange}
									categoryId={formState?.category?.id}
									error={error?.message}
								/>
							)}
						/>

					</Stack>

				</Stack>
				<Stack direction="row" spacing={1} sx={{ ml: 'auto', mt: 3 }}>
					<Button disabled={isPending} onClick={handleClose}>Cancel</Button>
					<Button
						sx={{ width: 100 }}
						disabled={isPending}
						endIcon={isPending && <CircularProgress size={16} color="inherit" />}
						type="submit"
					>
						Save
					</Button>
				</Stack>
			</Stack>
		)
	);
};

export default DescriptionEdit;
