import React, { SyntheticEvent } from 'react';
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import {
	Box, Stack, Typography,
} from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import {SxProps} from '@mui/system';
import {Theme} from '@mui/material/styles';
import TouchRipple, {TouchRippleActions} from '@mui/material/ButtonBase/TouchRipple';
import { useTranslation } from 'react-i18next';

interface IInput {
	getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
	getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
	isDragActive: boolean;
	errors: string;
    sx?: SxProps<Theme>;
}

const onHoverStyle = {
	borderColor: '#4198EF',
	background: (theme: Theme) => (theme.palette.secondary as any).lighter,
	color: (theme: Theme) => theme.palette.grey[600],
};

const Input = ({
	getRootProps, getInputProps, isDragActive, errors, sx,
}: IInput) => {
	const {t} = useTranslation();

	const rippleRef = React.useRef<TouchRippleActions>(null);

	const onRippleStart = (e: SyntheticEvent) => {
		rippleRef.current?.start(e);
	};

	const onRippleStop = (e: SyntheticEvent) => {
		rippleRef.current?.stop(e);
	};

	return (
		<>
			<Stack
				flexDirection="column"
				sx={{
					cursor: 'pointer',
					position: 'relative',
					minHeight: 336,
					height: '100%',
					padding: 3,
					borderRadius: '4px',
					borderWidth: '1px',
					borderStyle: 'dashed',
					borderColor: (theme) => `${theme.palette.secondary.dark}4D`,
					userSelect: 'none',
					color: (theme) => theme.palette.grey[400],
					...sx,
					...(isDragActive && onHoverStyle),
					'&:hover': {
						...onHoverStyle,
					},
				}}
				onMouseDown={onRippleStart}
				onMouseUp={onRippleStop}
				{...getRootProps()}
			>
				<input
					type="file"
					autoComplete="off"
					style={{
						display: 'none',
						width: '100%',
						height: '100%',
					}}
					{...getInputProps()}
				/>
				<Box>
					<AddAPhotoIcon fontSize="large" />
				</Box>

				<Stack mt="auto" spacing={1}>
					<CloudUploadOutlinedIcon fontSize="large" />
					<Typography component="h5" variant="h5">{t('add_image')}</Typography>
					<Typography variant="subtitle1">Drag or select image to upload</Typography>
					<Typography variant="subtitle1">JPG PNG BMP Resolution 1280x720 Less than 3 MB</Typography>
					<Typography component="h5" variant="h5" sx={{color: (theme) => theme.palette.error.main}}>
						{errors}
					</Typography>
				</Stack>
				<TouchRipple ref={rippleRef} center={false} />
			</Stack>
		</>
	);
};

export default Input;
