import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse, Stack } from '@mui/material';

import { PoorResource, StageType } from 'library/common/types/cards/cards.d';
import Image from 'library/common/components/inputs/Image';
import Video from 'library/common/components/inputs/Video';
import Draw from 'library/common/components/inputs/Draw';
import { IStepData } from 'library/common/types/cards/cardsCreation.d';

import Text from './components/Text';
import Header from './components/Header';
import Footer from './components/Footer';
import Buttons from './components/Buttons';
import Resources from './components/Resources';

interface IStage {
	stageId: string;
    stepIndex: number;
    disabled: boolean;
    stage: IStepData,
	length: number,
	error: string | undefined,
    setStepResources: (resources: PoorResource[], isTouched?: boolean) => void,
	setStepData: (
		value: string | undefined,
		dataType: 'text' | 'image' | 'video' | 'draw',
		isTouched?: boolean,
		) => void;
	dropMedia: () => void;
	addStep: () => void;
	dropStep: () => void;
	moveUpStage: () => void;
	moveDownStage: () => void;
}

const Stage = ({
	stageId,
	stepIndex,
	disabled,
	stage,
	length,
	setStepResources,
	setStepData,
	dropMedia,
	dropStep,
	addStep,
	moveUpStage,
	moveDownStage,
	error,
}: IStage) => {
	const {t} = useTranslation();

	const [isTouched, setTouched] = useState(false);
	const [selectedTypes, setSelectedTypes] = useState<{[key in StageType]: boolean}>({
		resources: false,
		text: false,
		image: false,
		video: false,
		draw: false,
	});

	const changeSelectedType = (type: StageType) => {
		setSelectedTypes((prev) => {
			if (type === 'draw' || type === 'image' || type === 'video') {
				const newState = {
					...prev,
					draw: false,
					image: false,
					video: false,
				};

				dropMedia();

				return {
					...newState,
					[type]: !prev[type],
				};
			}

			if (type === 'resources' && prev.resources) {
				setStepResources([], true);
			}

			if (type === 'text' && prev.text) {
				setStepData('', 'text', true);
			}

			return { ...prev, [type]: !prev[type] };
		});
	};

	const setData = (data: string, source: 'image' | 'text' | 'draw' | 'video') => {
		setStepData(data, source, isTouched);
		if (!isTouched) setTouched(true);
	};

	const setResources = (data: PoorResource[]) => {
		setStepResources(data, isTouched);
		if (!isTouched) setTouched(true);
	};

	return (
		<Stack direction="column" mt={6} mb={6}>
			<Header
				moveCardDownAvailable={length > 1 && stepIndex + 1 !== length}
				moveCardUpAvailable={length > 1 && stepIndex !== 0}
				dropStepAvailable={length > 1}
				title={`${t('step')} ${stepIndex + 1}`}
				dropStep={dropStep}
				moveDownStage={moveDownStage}
				moveUpStage={moveUpStage}
				error={error}
			/>

			<Buttons selectedTypes={selectedTypes} changeSelectedType={changeSelectedType} />

			{
				selectedTypes.resources && (
					<Resources
						disabled={disabled}
						stepResources={stage.resources}
						setFormResources={setResources}
					/>
				)
			}

			<Collapse in={selectedTypes.text} unmountOnExit mountOnEnter>
				<Text
					t={t}
					sx={{ mt: 6 }}
					disabled={disabled}
					setData={(data: string) => setData(data, 'text')}
					haveError={false}
				/>
			</Collapse>

			<Collapse in={selectedTypes.image} unmountOnExit mountOnEnter>
				<Image
					sx={{ mt: 6, minHeight: 336}}
					setData={(data: string) => setData(data, 'image')}
					disabled={disabled}
				/>
			</Collapse>

			<Collapse in={selectedTypes.video} unmountOnExit mountOnEnter>
				<Video
					sx={{ mt: 6 }}
					setData={(data: string) => setData(data, 'video')}
					disabled={disabled}
				/>
			</Collapse>

			<Collapse in={selectedTypes.draw} unmountOnExit mountOnEnter>
				<Draw
					sx={{ mt: 6 }}
					id={stageId}
					setData={(data: string) => setData(data, 'draw')}
					disabled={disabled}
				/>
			</Collapse>
			{
				length === stepIndex + 1 && (
					<Footer addStep={addStep} sx={{mt: 2}} />
				)
			}
		</Stack>
	);
};

export default Stage;
