import { useRef, useContext, useEffect } from 'react';
import useIsVisible from 'library/common/hook/useIsVisible';
import { Sections, NavContext } from './components/NavContext';

const useNav = (navLinkId: Sections, threshold?: number | number[]) => {
	const ref = useRef<HTMLDivElement>(null);

	const { setActiveNavLinkId } = useContext(NavContext);

	const isOnScreen = useIsVisible(ref, threshold);

	useEffect(() => {
		if (isOnScreen) {
			setActiveNavLinkId?.(navLinkId);
		}
	}, [isOnScreen, navLinkId, setActiveNavLinkId]);

	return ref;
};

export default useNav;
