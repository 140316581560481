import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import getSelfProfileUrl from 'library/utils/routes/getSelfProfileUrl';
import Base from './Base';
import CountNumber from './CountNumber';
import DialogCloud from './DialogCloud';

interface IProps {
    amount: number;
}

const Visit: React.FunctionComponent<IProps> = ({ amount }) => {
	const navigate = useNavigate();

	return (
		<Base
			title="Visit"
			description={(
				<>
					<Typography
						variant="body1"
						sx={{ color: ({ palette }) => palette.text.primary }}
					>
						{`Visit WodaQota at least once within 24 hours and get ${amount} bonuses (WB)`}
					</Typography>

					<Typography
						variant="caption"
						sx={{ color: ({ palette }) => palette.text.primary }}
					>
						Only for registered users
					</Typography>
				</>
			)}
			buttonLabel="View bonuses"
			amount={amount}
			onButtonClick={() => navigate(getSelfProfileUrl())}
		>
			<Stack spacing={3}>
				<DialogCloud
					text="What needs to be done in order for the bonuses to be credited"
					background="rgba(255, 243, 224, 0.3)"
				/>

				<Typography
					variant="caption"
					sx={{ pl: 0.5, color: ({ palette }) => palette.common.black, fontWeight: 500 }}
				>
					You were on the platform
				</Typography>

				<Stack direction="row" spacing={1}>
					<CountNumber count="01" />

					<Typography
						variant="body1"
						sx={{ pl: 1, pt: 2, color: ({ palette }) => palette.common.black }}
					>
						Day
					</Typography>
				</Stack>
			</Stack>
		</Base>
	);
};

export default Visit;
