import { UUID } from 'library/common/types/app.d';
import {createSelector} from 'reselect';
import {RootState} from 'main/rootReducer';

export const isAuthorMode: (state: RootState, cardAuthorId?: UUID) => boolean = createSelector(
	[
		(state: RootState) => state.user.userData.id,
		(state: RootState, cardAuthorId?: UUID) => cardAuthorId,
	],
	(id, cardAuthorId) => {
		if (id === undefined || id === null || cardAuthorId === undefined) {
			return false;
		}

		return id === cardAuthorId;
	},
);

export const selectCards = (state: RootState) => state.cards.cards;

export const selectCardsPending = (state: RootState) => state.cards.isPending;

export const selectPageNumber = (state: RootState) => state.cards.pageNumber;

export const selectTotalPages = (state: RootState) => state.cards.totalPages;

export const selectHasNextPage: (state: RootState) => boolean = createSelector(
	[
		selectPageNumber,
		selectTotalPages,
	],
	(pageNumber: number | undefined, totalPages: number | undefined) => pageNumber !== undefined && totalPages !== undefined && (pageNumber + 1) <= totalPages,
);

export const selectSelectedCategory = (state: RootState) => state.cards.selectedCategory;
