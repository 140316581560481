import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const UnionIcon = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path
			d="M9.99991 0H4.3902V4.69591C4.3902 7.20468 5.61369 9.36751 7.19506 11V11.1354C4.52052 10.197
            2.47684 7.9668 1.58535 5.1462V3.02339H3.1707V2.18713C3.1707 1.72528 2.81581 1.35088 2.37803
            1.35088H0V5.53216L2.37803 10.3246L7.24778 12.7605C7.38483 13.1953 7.77351 13.5088 8.23163
            13.5088H8.78041V15.6316H9.99991V0Z"
		/>
		<path
			d="M9.02431 16.9181H9.99991V22H4.99995V20.7778H5.24385C5.64019 20.7783 6.10894 20.5848 6.10894
             20.1001C6.10894 18.655 7.56091 16.9181 9.02431 16.9181Z"
		/>
		<path
			d="M9.99991 0H15.6098V4.69591C15.6098 7.20468 14.3863 9.36751 12.8049 11V11.1354C15.4795 10.197
            17.5232 7.9668 18.4146 5.1462V3.02339H16.8293V2.18713C16.8293 1.72528 17.1842 1.35088 17.622
            1.35088H20V5.53216L17.622 10.3246L12.7522 12.7605C12.6152 13.1953 12.2265 13.5088 11.7684
            13.5088H11.2196V15.6316H9.99991V0Z"
		/>
		<path
			d="M10.9757 16.9181H9.99991V22H15V20.7778H14.7561C14.3598 20.7783 13.8911 20.5848 13.8911 20.1001C13.8911
            18.655 12.4391 16.9181 10.9757 16.9181Z"
		/>
	</SvgIcon>
);

export default UnionIcon;
