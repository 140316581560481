import _ from 'lodash';
import {logger} from 'library/utils/lintersSugar';
import { SECOND_IN_MS } from 'library/utils/converters/time.converter';
import statuses from './statuses';
import {socketUrl} from '../services/urls';
import notificationsService from '../services/notifications.service';

const RETRY_CONNECT_DELAY = 30 * SECOND_IN_MS;

const hasJsonStructure = (str: string | object) => {
	if (typeof str !== 'string') return false;
	try {
		const result = JSON.parse(str);

		return Object.prototype.toString.call(result) === '[object Object]' || Array.isArray(result);
	} catch (err) {
		return false;
	}
};

export default class WebSockets {
	private ws?: WebSocket;

	// private timer?: NodeJS.Timeout;
	private heartbeatTimer?: any;

	private retryTimer?: any;

	private missingAnswers: number = 0;

	private retryCount: number = 0;

	private haveFirstMessage: boolean = false;

	private onMessageCallback?: <T extends any>(socketData: T) => void;

	private setSocketStatus?: (status: boolean) => void;

	private isAlive() {
		if (this.ws) {
			return this.ws.OPEN === this.ws.readyState;
		}
		throw new Error('Websocket not initialized');
	}

	public sendMessage(data: string) {
		if (this.ws) {
			return this.ws.send(data);
		}
		throw new Error('Websocket not initialized');
	}

	private heartbeat(): void {
		if (!this.ws || this.ws.readyState !== this.ws.OPEN) return;
		if (this.missingAnswers > 2) {
			this.ws.close();
		} else {
			this.sendMessage('ping');
			this.missingAnswers += 1;
		}
	}

	private sendDeliveredMessage(): void {
		setTimeout(() => this.sendMessage('delivered'), 1500);
	}

	public async initializeWebSockets(
		setSocketStatus: (status: boolean) => void,
		onMessageCallback: <T extends any>(socketData: T) => void,
	) {
		logger('|----> Initialize web socket <----|');
		if (this.ws && this.isAlive()) return;

		if (!this.onMessageCallback && !this.setSocketStatus) {
			this.setSocketStatus = setSocketStatus;
			this.onMessageCallback = onMessageCallback;
		}

		// TODO обработать случай ошибки

		try {
			const token = await notificationsService.getToken();

			if (token === undefined) {
				throw Error('Failed to get token');
			}

			this.ws = new WebSocket(`${socketUrl}ws?token=${token}`);

			this.ws.onopen = () => {
				setSocketStatus(this.isAlive());
				this.heartbeatTimer = global.setInterval(() => this.heartbeat(), 45000);
			};

			this.ws.onmessage = (event) => {
				try {
					if (hasJsonStructure(event.data)) {
						const data = JSON.parse(event.data);

						onMessageCallback(data);

						if (this.haveFirstMessage === false) {
							this.haveFirstMessage = true;
							this.sendDeliveredMessage();
						}
					} else if (event.data === 'pong') {
						this.missingAnswers -= 1;
					} else {
						logger(`message from server: ${event.data}`);
					}
				} catch (error) {
					logger(`${error}`, 'error');
				}
			};
			this.ws.onclose = (e) => {
				const string = e.wasClean ? 'Connection closed cleanly' : 'Connection lost';
				const reason = _.get(statuses, e.code)
					? _.get(statuses, e.code)
					: {eng: 'The reason for the closure is not known'};

				logger(`${string}. ${reason.eng}`, 'error');

				this.retryConnect();
			};
		} catch (error) {
			logger(error, 'error');
			this.retryConnect();
		}
	}

	private retryConnect() {
		this.destroy();

		if (this.onMessageCallback && this.setSocketStatus && this.retryCount < 1) {
			this.retryCount += 1;
			logger(`Retry to socket connect number ${this.retryCount} after ${RETRY_CONNECT_DELAY / 1000} seconds`);

			this.retryTimer = setTimeout(
				() => this.initializeWebSockets(
				this.setSocketStatus as (status: boolean) => void,
				this.onMessageCallback as <T extends any>(socketData: T) => void,
				),
				RETRY_CONNECT_DELAY,
			);
		}
	}

	public destroy() {
		if (this.ws) this.ws.close();
		if (this.heartbeatTimer) clearInterval(this.heartbeatTimer);
		if (this.retryTimer) clearInterval(this.retryTimer);
		if (this.setSocketStatus) this.setSocketStatus(false);
		this.missingAnswers = 0;
		this.heartbeatTimer = undefined;
		this.retryTimer = undefined;
	}
}
