import React from 'react';
import {
	Box, Skeleton, Stack, Typography,
} from '@mui/material';

import { ICategory } from 'library/common/types/cards/cards.d';
import useCategories from 'library/common/data/categories/useCategories';

interface IProps {
    onChange: (...event: any[]) => void;
	selectionColor?: 'primary' | 'info' | 'success';
    value?: ICategory;
	error?: string;
}

const CategoriesSelector: React.FunctionComponent<IProps> = ({
	value, onChange, error, selectionColor = 'info',
}) => {
	const {data: availableCategories, isLoading: isCategoriesLoading } = useCategories();

	return (
		<Stack
			direction="column"
		>
			<Typography
				sx={{
					fontWeight: 500,
					color: (theme) => theme.palette.text.secondary,
					mb: 4,
				}}
				variant="h6"
			>
				Category

			</Typography>

			<Stack
				direction="row"
				flexWrap="wrap"
				sx={{
					zIndex: 1,
					position: 'relative',
				}}
			>
				{
					isCategoriesLoading ? (
						[51, 78, 110, 66]
							.map((size) => (
								<Skeleton key={size} animation="wave" height={32} width={size} />
							))
					) : (
						availableCategories
							?.filter((category) => !category.private)
							.map((category) => {
								const isSelected = value !== undefined && value.id === category.id;

								return (
									<Box
										role="button"
										key={category.id}
										onClick={() => onChange(category)}
										sx={{
											mr: 1,
											mb: 1,
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											cursor: 'pointer',
											height: '32px',
											userSelect: 'none',
											px: 1,
											position: 'relative',
											backgroundColor: isSelected ? 'white' : 'inherit',
											backgroundClip: 'padding-box',
											borderRadius: '16px',
											textTransform: 'inherit',
											color: (theme) => (isSelected
												? theme.palette.common.white
												: theme.palette.text.secondary),
											background: (theme) => (isSelected
												? (theme.palette as any).gradients[selectionColor]
												: theme.palette.common.white),
											border: `1px solid ${isSelected ? 'transparent' : '#DADADA'}`,

											':hover': {
												border: '1px solid transparent',

												'&:after': {
													opacity: 0.2,
													position: 'absolute',
													top: -2,
													left: -2,
													right: -2,
													bottom: -2,
													content: '""',
													zIndex: -1,
													borderRadius: '16px',
													background: (theme) => (theme.palette as any).gradients.info,
												},
											},
										}}
									>

										<Typography variant="body2">{`${category.title}`}</Typography>
									</Box>
								);
							})
					)
				}
			</Stack>

			{
				error && (
					<Typography
						variant="caption"
						sx={{
							color: (theme) => theme.palette.error.main,
							mt: '4px',
							ml: '14px',
						}}
					>
						{error}
					</Typography>
				)
			}
		</Stack>
	);
};

export default CategoriesSelector;
