import React, {useState} from 'react';
import { useLocation } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {IconButton} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';

import {RootState} from 'main/rootReducer';
import { setNotificationCount } from 'library/common/actions/app.action';
import Notifications from './Notifications/Notifications';
import CustomBadge from '../../overrides/CustomBadge';

const NotificationsPopover = () => {
	const dispatch = useDispatch();

	const notificationCount = useSelector((state: RootState) => state.app.notificationCount);

	const [actionsListAnchor, setActionsListAnchor] = useState<null | HTMLElement>(null);

	const {pathname} = useLocation();

	const handleClickActionsListAnchor = (event: React.MouseEvent<HTMLElement>) => {
		setActionsListAnchor(event.currentTarget);
		dispatch(setNotificationCount(0));
	};

	return (
		<>
			<IconButton
				color={pathname === '/app/profile' ? 'secondary' : 'default'}
				aria-label="notifications"
				onClick={handleClickActionsListAnchor}
			>
				<CustomBadge badgeContent={notificationCount} color="primary">
					<NotificationsIcon fontSize="inherit" />
				</CustomBadge>
			</IconButton>
			<Notifications
				actionsListAnchor={actionsListAnchor}
				setActionsListAnchor={setActionsListAnchor}
				notificationCount={notificationCount}
			/>
		</>
	);
};

export default NotificationsPopover;
