export default (
	url: string,
	timeout?: number,
): HTMLImageElement | Promise<HTMLImageElement> => {
	const image = new Image();
	image.src = url;

	if (image.complete) {
		return image;
	}

	return new Promise((resolve, reject) => {
		if (image.complete) {
			resolve(image);
		} else {
			const onLoad = resolve.bind(null, image);
			const onError = reject.bind(null, image);

			image.addEventListener('load', onLoad);
			image.addEventListener('error', onError);

			if (typeof timeout === 'number') {
				setTimeout(() => {
					if (!image.complete) {
						image.removeEventListener('load', onLoad);
						image.removeEventListener('error', onError);

						onError();
					}
				}, Math.max(0, timeout));
			}
		}
	});
};
