import React, {useCallback, useState} from 'react';
import { useSelector } from 'react-redux';
import {useTranslation} from 'react-i18next';
import {
	Avatar,
	Button,
	Stack,
	SxProps,
	TextField,
	Theme,
} from '@mui/material';

import { takeNameToAvatar } from 'library/utils/textGenerators';
import { ICommentEntity } from 'library/common/types/cards/comments.d';
import { selectUserData } from 'library/common/selectors/user.selector';

interface IProps {
	sx?: SxProps<Theme>;
	addCommentHandler: (text: string) => Promise<ICommentEntity>;
	defaultCancelAction?: () => void;
	autoFocus?: boolean;
	disabled?: boolean;
}

const NewCommentComponent = ({
	sx,
	addCommentHandler,
	defaultCancelAction,
	autoFocus,
	disabled,
}: IProps) => {
	const {t} = useTranslation();

	const [text, setText] = useState<string>('');

	const { username, logoURL, backgroundColor } = useSelector(selectUserData);

	const onCancel = useCallback(() => {
		setText('');
		if (defaultCancelAction)defaultCancelAction();
	}, [defaultCancelAction]);

	const onSubmit = useCallback(() => {
		addCommentHandler(text).then(onCancel);
	}, [addCommentHandler, onCancel, text]);

	return (
		<Stack sx={sx} direction="row" spacing={2} alignItems="center">
			<Avatar
				variant="circular"
				src={logoURL}
				sx={{
					width: '56px',
					height: '56px',
					bgcolor: backgroundColor,
				}}
			>
				{takeNameToAvatar(username)}
			</Avatar>
			<Stack direction="column" flex={1} spacing={1}>
				<TextField
					id="standard-basic"
					variant="standard"
					disabled={disabled}
					size="small"
					label={t('add_a_comment')}
					value={text}
					onChange={(e) => setText(e.target.value)}
					fullWidth
					autoComplete="off"
					autoFocus={autoFocus}
					onKeyUp={(e) => {
						if (e.code === 'Enter' && text.length > 0) {
							onSubmit();
						}
					}}
				/>
				<Stack direction="row" justifyContent="flex-end" spacing={1}>
					{
						(text?.length > 0 || defaultCancelAction) && (
							<Button size="small" color="secondary" onClick={onCancel}>{t('cancel')}</Button>
						)
					}

					<Button
						variant="outlined"
						color="primary"
						onClick={onSubmit}
						size="small"
						disabled={text.length === 0 || disabled}
					>
						{t('add_a_comment')}
					</Button>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default NewCommentComponent;
