/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
	Box, Container, Link, Typography,
} from '@mui/material';
import Template from './Template';

const CareerOptions: React.FunctionComponent = () => (
	<Template>
		<Container sx={{pt: 2, pb: 2}}>
			<Typography variant="h3" sx={{fontWeight: 700}}>
				Career Options
			</Typography>

			<Typography variant="h5" sx={{m: '0.8rem 0', fontWeight: 500}}>
				Сompany culture, values and mission
			</Typography>

			{[
				'At WodaQota, we are dedicated to a unique platform for sharing How To instructions, guides, and manuals at different topics (education, recipes, technology, fitness etc.)',
				'Our culture is characterized by the foundation of openness, transparency, collaboration and a strong commitment to fill the world with knowledge (exactly knowledge, not '
				+ 'just an information). The main difference between knowledge and information is that knowledge is based on personal experience.',
				'We believe in the core values of WodaQota which include the belief in the power of knowledge to change the world, a respect for the principles of free speech, free access to information and work together as a team to deliver and give access of clear knowledge to every individual. ',
				'WodaQota mission is to provide platform where anyone can get desired result based on other people knowledge in the shortest time. ',
				'Our company seeks to empower people by giving them access to the sum of all human practical and spiritual knowledge and by encouraging them to contribute their own knowledge and expertise to the community.',
				'We believe that the modern time-consuming information in the world will only grow. And in this complex information world with limited time, the need for clear,'
				+ ' understandable knowledge to achieve desired result will only increase. The boring exploitation manuals, unverified instructions, receipts, help center databases should go. ',
				'The search of the future is a search in live knowledge base. That\'s why the world needs WodaQota.',
				'We are proud to offer a dynamic and inclusive workplace where employees are encouraged to grow, collaborate and make a real difference.',

			].map((paragraph, index) => (
				<Typography key={`mission${index}`} variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
					{paragraph}
				</Typography>
			))}

			<Typography variant="h5" sx={{m: '0.8rem 0', fontWeight: 500}}>
				Available positions
			</Typography>
			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				Further you can find the list of current job openings, a brief description of each role and its
				responsibilities.
			</Typography>

			<Typography variant="h5" sx={{m: '0.8rem 0 0 2rem', fontWeight: 500}}>
				1. Digital Marketing Manager (Intern)
			</Typography>

			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				Who are we looking for?
			</Typography>

			<ul style={{margin: 0}}>
				{[
					'Someone who is ready to work on attracting hundreds of thousands users',
					'Someone who understands marketing within the 4Ps',
					'Someone who have experience as marketing manager in the India',
					'Someone who understands what \'sales\' is and knows how to influence at them',
					'A person who is ready to perform tasks with his own hands',
					'A person who wants to constantly look for something new',
					'A person who does his job better every day',
				].map((paragraph, index) => (
					<li key={`Whoarewelookingfor_${index}`}>
						<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
							{paragraph}
						</Typography>
					</li>
				))}
			</ul>

			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				Responsibilities:
			</Typography>

			<ul style={{margin: 0}}>
				{[
					'Development of the marketing strategy for platform promotion at Indian market',
					'Formation and implementation of a plan and budget for marketing strategy',
					'Interaction with influencers in India: search, communication, coordination and control of work',
					'Preparation and launch of a lead generation advertising campaign at Indian market',
					'Organize and launch email marketing campaigns at Indian market',
					'Preparation of press releases and information materials for the media at Indian market',
					'Analysis of the marketing strategy effectiveness',
				].map((paragraph, index) => (
					<li key={`Responsibilities1_${index}`}>
						<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
							{paragraph}
						</Typography>
					</li>
				))}
			</ul>

			<Typography variant="h5" sx={{m: '0.8rem 0 0 2rem', fontWeight: 500}}>
				2. Social Media Manager (Intern)
			</Typography>

			{[
				'Who are we looking for?',
				'We are looking for a qualified social media manager to join our team. If you have experience in social media, PR and promotional events to make connection and management with target audience/community of IT product then we would like to meet you.',
				'Our ideal candidate has exceptional oral and written communication skills and is able to develop engaging content. You should be a \'people person\' with great customer service skills and the ability to moderate online and offline conversations with our new community.',
				'A person should be able to act as the face and voice of our company and manage all community communications.',
			].map((paragraph, index) => (
				<Typography key={`SocialMediaManager${index}`} variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
					{paragraph}
				</Typography>
			))}

			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				Responsibilities:
			</Typography>

			<ul style={{margin: 0}}>
				{[
					'Build a brand-new online community (global level)',
					'Lead social media and communication campaigns',
					'Provide content and challenges for the community',
					'Respond to comments of community members',
					'Stay up to date with new social digital trends',
				].map((paragraph, index) => (
					<li key={`Responsibilities2_${index}`}>
						<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
							{paragraph}
						</Typography>
					</li>
				))}
			</ul>

			<Typography variant="h5" sx={{m: '0.8rem 0 0 2rem', fontWeight: 500}}>
				3. Content Creator (Intern)
			</Typography>

			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				Who are we looking for?
			</Typography>

			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				A person who will be responsible for creating and producing various types of high quality written and visual
				content. This will include short videos, graphics, animation design and more.
			</Typography>

			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				Responsibilities:
			</Typography>
			<ul style={{margin: 0}}>
				{[
					'Research and generation of ideas for new content',
					'Making visual content by Adobe, Canva tools',
					'Design development of new UI/UI elements',
					'Monitoring analytics of produced content',
					'Stay up to date with new social industry trends',
				].map((paragraph, index) => (
					<li key={`Responsibilities3_${index}`}>
						<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
							{paragraph}
						</Typography>

					</li>
				))}
			</ul>

			<Typography variant="h5" sx={{m: '0.8rem 0', fontWeight: 500}}>
				Internship benefits
			</Typography>

			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				If you want to apply for intern position, please read the text below.
				You can expect a benefits package that provides work-life balance and opportunities for personal and
				professional growth. Some common benefits of working with us:
			</Typography>

			<Box component="ol" sx={{ color: (theme) => theme.palette.grey['700'], m: 0, fontSize: '0.9rem' }}>
				{[
					'Professional development: lectures, workshops and test tasks from experts',
					'Participation in WodaBonus Program: you can gain our own limited digital coins',
					'Work experience in international company: our team work in USA, India, France, Armenia',
					'Personalized Letter of Recommendation from WodaQota, Inc.',
					'Recommendation in LinkedIn from WodaQota, Inc.',
					'Career Advancement Opportunities: opportunities for growth and advancement within the company, including promotions and leadership positions',
					'Remote Work Options: flexibility to work from home or a remote location',

				].map((paragraph, index) => (
					<li key={`benefits${index}`}>
						<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
							{paragraph}
						</Typography>

					</li>
				))}
			</Box>

			<Typography variant="h5" sx={{m: '0.8rem 0', fontWeight: 500}}>
				Career development and work life balance
			</Typography>

			{[
				'Technology and Product Development: WodaQota is at the forefront of the technology industry and employees can work on cutting-edge projects and products that are shaping the future of online communication.',
				'Collaboration with Top Talent: WodaQota attracts top talent from around the world, offering employees the chance to work alongside and learn from experienced professionals in their field.',
				'Focus on Learning and Development: WodaQota places a strong emphasis on employee learning and development, offering opportunities for training, mentorship, and professional development programs.',
				'Entrepreneurial Spirit: As a Startup we often like employees who have an entrepreneurial spirit and are driven by the passion for their work. This can help us to lead to a high level of '
				+ 'engagement and a strong sense of purpose, making work feel less like work and more like a calling.',
				'Fast-Paced Environment: We operate in a fast-paced and dynamic environment, which can be challenging for some employees but can also provide opportunities for personal and professional growth.',
			].map((paragraph, index) => (
				<Typography key={`lifebalance${index}`} variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
					{paragraph}
				</Typography>
			))}

			<Typography variant="h5" sx={{m: '0.8rem 0', fontWeight: 500}}>
				Application process
			</Typography>

			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				To apply for a job or internship, please follow the actions below:
			</Typography>

			<Box component="ol" sx={{ color: (theme) => theme.palette.grey['700'], m: 0, fontSize: '0.9rem' }}>
				<li>
					<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
						Send you CV to the email:
						&nbsp;
						<Link href="mailto:ab_milli@wodaqota.com">ab_milli@wodaqota.com</Link>
						&nbsp;
					</Typography>
				</li>
				<li>
					<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
						Mention the position you are interested in the Subject line
					</Typography>
				</li>

				<li>
					<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
						Resumes without a cover letter will not be considered
					</Typography>
				</li>

				<li>
					<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
						Our HR team will contact you for further details
					</Typography>
				</li>
			</Box>

			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				For any question about career opportunities, text to the email: &nbsp;
				<Link href="mailto:ab_milli@wodaqota.com">ab_milli@wodaqota.com</Link>
			&nbsp;
			</Typography>
		</Container>
	</Template>
);

export default CareerOptions;
