import {SelfServiceSettingsFlow} from '@ory/kratos-client/api';
import {AxiosRequestConfig} from 'axios';

import { identityApi } from './urls';

const settingsInit = () => identityApi
	.get<SelfServiceSettingsFlow>('self-service/settings/browser')
	.then((res) => res);

const settingsSetPassword = (csrfToken: string, flowId: string, password: string) => identityApi
	.post(
		'self-service/settings',
		{
			csrf_token: csrfToken,
			method: 'password',
			password,
		},
		{
			params: {
				flow: flowId,
			},
		},
	).then((res) => res);

const templateSettingsRequest = (config: AxiosRequestConfig) => identityApi(config)
	.then((res) => res);

export default {
	settingsInit,
	settingsSetPassword,
	templateSettingsRequest,
};
