// eslint-disable-next-line no-shadow
enum appActionTypes {
  SET_ERROR = '@@APP/SET_ERROR',
  INIT_SETTINGS_REQUEST = '@@APP/INIT_SETTINGS_REQUEST',
  INIT_SETTINGS_SUCCESS = '@@APP/INIT_SETTINGS_SUCCESS',
  INIT_SETTINGS_FAILURE = '@@APP/INIT_SETTINGS_FAILURE',

  APPLY_SETTINGS_REQUEST = '@@APP/APPLY_SETTINGS_REQUEST',
  APPLY_SETTINGS_SUCCESS = '@@APP/APPLY_SETTINGS_SUCCESS',
  APPLY_SETTINGS_FAILURE = '@@APP/APPLY_SETTINGS_FAILURE',

  NOTIFICATION_EVENT = '@@APP/NOTIFICATION_EVENT',
  ADD_NOTIFICATION = '@@APP/ADD_NOTIFICATION',
  DROP_NOTIFICATIONS_LIST = '@@APP/DROP_NOTIFICATIONS_LIST',
  SET_NOTIFICATION_COUNT = '@@APP/SET_NOTIFICATION_COUNT',
	INCREASE_NOTIFICATION_COUNT = '@@APP/INCREASE_NOTIFICATION_COUNT',
  SET_NOTIFICATION_VIEWED = '@@APP/SET_NOTIFICATION_VIEWED',

  SET_REACT_QUERY_DEVTOOLS_OPEN = '@@APP/SET_REACT_QUERY_DEVTOOLS_OPEN',

  SET_WODA_STORY_PLAYING_STATE = '@@APP/SET_WODA_STORY_PLAYING_STATE',
  SET_WODA_STORY_MUTE_STATE = '@@APP/SET_WODA_STORY_MUTE_STATE',
}

export default appActionTypes;
