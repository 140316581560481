// TODO расскомментировать когда будет api
// eslint-disable-next-line @typescript-eslint/no-unused-vars, import/prefer-default-export
export const getUserDataSchema = (isSelfPage?: boolean) => {
	const common = [
		{ id: 'cardsCount', title: 'Cards' },
		{ id: 'followings', title: 'Followings' },
		{ id: 'followers', title: 'Followers' },
	];

	// if (isSelfPage) {
	// 	return [
	// 		...common,
	// 		{ id: 'comments', title: 'Comments' },
	// 	];
	// }

	return common;
};
