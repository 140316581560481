import wodabonusesApi from 'library/common/services/wodabonuses.service';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { showSnackbarMessage } from 'library/utils/showSnackbarMessage';
import { MINUTE_IN_MS } from 'library/utils/converters/time.converter';

import wodabonusesQueryKeys from './queryKeys';
import { selfVaultMapper } from './mappers';

const selfVaultQueryFunction = async (): Promise<number> => {
	const result = await wodabonusesApi.getSelfVault();

	return selfVaultMapper(result);
};

const useSelfVault = (options?: UseQueryOptions<number>) => useQuery(
	wodabonusesQueryKeys.vaultSelf(),
	selfVaultQueryFunction,
	{
		cacheTime: MINUTE_IN_MS,
		staleTime: MINUTE_IN_MS,
		onError: () => showSnackbarMessage('Ops, There was a problem loading bonuses balance, we are working on a fix', 'error'),
		retry: 2,
		retryDelay: 1000,
		...options,
	},
);

export default useSelfVault;
