/* eslint-disable no-console */
export const getKey = (value: number, place: string): string => `_${place}_${value}_`;

export const logger = (message: any, type: 'log' | 'error' = 'log'): void => {
	if (process.env.REACT_APP_ENABLE_CONSOLE_LOGS) {
		console[type](message);
	}
};

export const colorLogger = (message: string): void => console.log(`%c ${message}`, 'background: #222; color: #bada55');
