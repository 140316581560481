import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {
	Avatar,
	Box,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';

import {IUserData} from 'library/common/types/user.d';
import CustomBadge from 'library/common/components/overrides/CustomBadge';

interface IMobileActions {
	isAuth: boolean;
	userData: IUserData;
	notificationCount: number;
}

const MobileActions = ({
	isAuth, userData, notificationCount,
}: IMobileActions) => {
	const navigate = useNavigate();
	const {t} = useTranslation();

	const { username, logoURL, backgroundColor } = userData;

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Box>
			<IconButton size="small" aria-label="header-menu" onClick={handleClick}>
				<MoreVertIcon />
			</IconButton>
			{isAuth ? (
				<Menu
					id="simple-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					<MenuItem sx={{ pl: 1 }} component={RouterLink} to="/app/profile">
						<ListItemIcon>
							<Avatar
								variant="circular"
								src={logoURL}
								sx={{
									bgcolor: logoURL ? 'transparent' : backgroundColor,
								}}
							>
								{username[0] ? username[0].toUpperCase() : ''}
							</Avatar>

						</ListItemIcon>
						<ListItemText sx={{ ml: 1}} primary={username} />
					</MenuItem>

					<MenuItem>
						<ListItemIcon>
							<CustomBadge badgeContent={notificationCount} color="primary">
								<NotificationsIcon fontSize="inherit" />
							</CustomBadge>
						</ListItemIcon>
						<ListItemText primary="Notifications" />
					</MenuItem>

					<MenuItem component={RouterLink} to="/app/settings">
						<ListItemIcon>
							<SettingsIcon fontSize="inherit" />
						</ListItemIcon>
						<ListItemText primary="Settings" />
					</MenuItem>

					{/* <MenuItem disabled>
						<ListItemIcon>
							<AccountBalanceWalletIcon fontSize="inherit" />
						</ListItemIcon>
						<ListItemText primary="Wallet" />
					</MenuItem> */}
				</Menu>
			) : (
				<Menu
					id="simple-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					<MenuItem
						color="primary"
						onClick={() => navigate('/login')}
					>
						{t('sign_in')}
					</MenuItem>
				</Menu>
			)}
		</Box>
	);
};

export default MobileActions;
