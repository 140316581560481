import React from 'react';
import { Box } from '@mui/material';

interface IProps {}

const BorderLine: React.FunctionComponent<IProps> = () => (
	<Box
		className="borderLine"
		sx={{
			margin: 0,
			position: 'absolute',
			bottom: '6px',
			height: '2px',
			width: '100%',
			display: 'flex',
			background: (theme) => theme.palette.secondary.light,
		}}
	/>
);

export default BorderLine;
