import React, { CSSProperties } from 'react';
import {ReactComponent as WQDarkLogoSVG} from 'resources/image/wq_logo.svg';
import {ReactComponent as WQLightLogoSVG} from 'resources/image/white_wq_logo.svg';

interface IProps {
    type?: 'dark' | 'light';
    style?: CSSProperties;
}

const WQLogo: React.FunctionComponent<IProps> = ({ type = 'dark', style }) => (
	type === 'dark' ? <WQDarkLogoSVG style={style} /> : <WQLightLogoSVG />
);

export default WQLogo;
