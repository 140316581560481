import React from 'react';
import { Box, Container } from '@mui/material';

import Profile from 'pages/Profile/desktop/components/Profile';
import ListWrapper from 'pages/Profile/components/List/ListWrapper';
import { IUserProfileProps } from 'pages/Profile/types.d';

import SimpleList from './components/SimpleList';
import SearchInput from '../components/SearchInput';

// TODO добавить отображение социальных сетей

const DesktopUserProfile: React.FunctionComponent<IUserProfileProps> = ({
	isCardsPending,
	isPending,
	isSubscribing,
	isSubscribed,
	canISubscribe,
	profileData,
	publishedCards,
	subscribeToUser,
	getPublishedCards,
}) => (
	<Container
		disableGutters
		maxWidth={false}
		sx={{
			display: 'flex',
			flexDirection: 'column',
			backgroundColor: (theme) => theme.palette.secondary.main,
		}}
	>
		<Profile
			isPending={isPending}
			isSubscribeButtonAvailable={canISubscribe}
			isSubscribed={isSubscribed}
			onSubscribe={subscribeToUser}
			isSubscribing={isSubscribing}
			publishedCardsCount={publishedCards.length}
			{...profileData}
		/>

		<Box sx={{
			mt: 5,
			'&::-webkit-scrollbar': {
				width: '0.4em',
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: 'rgba(0,0,0,.5)',
			},
		}}
		>
			<Container
				maxWidth="xl"
				disableGutters
				sx={{
					height: '100%',
					pb: 5,
				}}
			>
				{profileData.id && <SearchInput userId={profileData.id} getPublishedCards={getPublishedCards} />}
				<ListWrapper
					label="No cards"
					cards={publishedCards}
					isCardsPending={isCardsPending}
					List={SimpleList}
				/>
			</Container>
		</Box>

	</Container>
);

export default DesktopUserProfile;
