import { get } from 'lodash';
import {
	useMutation,
	useQueryClient,
	UseMutationOptions,
	QueryClient,
} from '@tanstack/react-query';

import cardsService from 'library/common/services/cards.service';
import { UUID } from 'library/common/types/app.d';
import { mapAxiosCardApiResponse } from 'library/utils/mappers/api.mapper';
import { showSnackbarMessage } from 'library/utils/showSnackbarMessage';
import { CardApiData } from 'library/common/types/cards/request.d';
import incrementOrDecrement from 'library/utils/incrementOrDecrement';
import { CommentResponse } from 'library/common/types/cards/comments.d';
import axiosErrorHandler from 'library/utils/axiosErrorHandler';

import commentsQueryKeys from './queryKeys';

type MutationParams = {
    root?: boolean;
    commentId: UUID;
    commentParentId: UUID;
 }

const getToggleLikeMutation = (queryClient: QueryClient): UseMutationOptions<
    number,
    any,
    MutationParams,
    { key: ReturnType<typeof commentsQueryKeys.byCardId | typeof commentsQueryKeys.byCommentId>; oldValue?: CommentResponse; commentId: UUID }
> => ({
	mutationFn: ({ commentId }: MutationParams) => cardsService
		.toggleCommentLike<CardApiData<number>>(commentId)
		.then(mapAxiosCardApiResponse),
	onMutate: ({
		root, commentId, commentParentId,
	}) => {
		const key = commentsQueryKeys[root ? 'byCardId' : 'byCommentId'](commentParentId);
		const oldValue = queryClient.getQueryData<CommentResponse>(key);
		const currentComment = get(oldValue, commentId);

		if (currentComment) {
			queryClient.setQueryData(key, {
				...oldValue,
				[commentId]: {
					...currentComment,
					comment: {
						...currentComment?.comment,
						likes: incrementOrDecrement(currentComment.comment.likes, !currentComment.liked),
					},
					liked: !currentComment.liked,
				},
			});
		}

		return { key, oldValue, commentId };
	},
	onSuccess: () => undefined,
	onError: (error, _vars, context) => {
		if (context?.oldValue) {
			showSnackbarMessage('Error when setting like', 'error');

			queryClient.setQueryData(context.key, context.oldValue);
		}

		axiosErrorHandler(error);
	},
});

const useToggleLikeMutation = () => {
	const queryClient = useQueryClient();

	return useMutation(getToggleLikeMutation(queryClient));
};

export default useToggleLikeMutation;
