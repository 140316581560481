import React from 'react';
import { IconButton, Stack, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowIcon from 'resources/icons/ArrowIcon';

interface ITitle {
	dropStepAvailable?: boolean;
	moveCardUpAvailable?: boolean;
	moveCardDownAvailable?: boolean;
    title: string;
	error: string | undefined,
	dropStep?: () => void;
	moveUpStage?: () => void;
	moveDownStage?: () => void;
}

const Header = ({
	moveCardDownAvailable = false,
	moveCardUpAvailable = false,
	dropStepAvailable = false,
	title,
	error,
	dropStep,
	moveDownStage,
	moveUpStage,
}: ITitle) => (
	<Stack
		direction="row"
		spacing={1}
		alignItems="center"
		justifyContent="space-between"
		sx={{
			...(error && {
				borderWidth: '1px',
				borderStyle: 'solid',
				borderRadius: '4px',
				borderColor: (theme) => theme.palette.error.light,
				padding: 1,
				background: (theme) => (theme.palette.error as any).lighter,
			}),
		}}
	>
		<Typography
			sx={{
				color: ({ palette }) => palette.text.secondary,
				fontWeight: 800,
				fontSize: '24px',
				lineHeight: '24px',
			}}
			component="h6"
			variant="subtitle1"
		>
			{title}
		</Typography>

		{error && (
			<Typography
				sx={{
					color: (theme) => theme.palette.error.main,
					fontSize: '24px',
					lineHeight: '24px',
				}}
				component="h6"
				variant="subtitle1"
			>
				{error}
			</Typography>
		)}
		<Stack direction="row" spacing={0.5}>
			{
				moveCardUpAvailable && (
					<IconButton
						onClick={moveUpStage}
						aria-label="delete"
						size="small"
						sx={{
							color: (theme) => theme.palette.grey[400],
							'&:hover': {
								color: (theme) => theme.palette.grey[600],
							},
						}}
					>
						<ArrowIcon sx={{
							p: 0.5,
							rotate: '90deg',
						}}
						/>
					</IconButton>
				)
			}

			{
				moveCardDownAvailable && (
					<IconButton
						onClick={moveDownStage}
						aria-label="delete"
						size="small"
						sx={{
							color: (theme) => theme.palette.grey[400],
							'&:hover': {
								color: (theme) => theme.palette.grey[600],
							},
						}}
					>
						<ArrowIcon sx={{
							p: 0.5,
							rotate: '270deg',
						}}
						/>
					</IconButton>
				)
			}

			{
				dropStepAvailable && (
					<IconButton
						onClick={dropStep}
						aria-label="delete"
						size="small"
						sx={{
							color: (theme) => theme.palette.grey[400],
							'&:hover': {
								color: (theme) => theme.palette.grey[600],
							},
						}}
					>
						<CancelIcon />
					</IconButton>
				)
			}
		</Stack>
	</Stack>
);

export default Header;
