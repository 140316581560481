import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import SkeletonGrid from './SkeletonGrid';

import styles from './styles.module.css';

interface IWQGridWithScroll {
	children: React.ReactElement[];
	getData: () => void;
	dataLength?: number;
	hasNextPage: boolean;
}

const WQGridWithScroll = ({
	children,
	getData,
	dataLength = 0,
	hasNextPage,
}: IWQGridWithScroll) => (
	<InfiniteScroll
		next={getData}
		dataLength={dataLength}
		hasMore={hasNextPage}
		loader={<SkeletonGrid />}
	>
		<div className={styles.container}>
			<div className={styles.list}>
				{children}
			</div>
		</div>
	</InfiniteScroll>
);

export default WQGridWithScroll;
