import { createAction, createAsyncAction, createCustomAction } from 'typesafe-actions';
import {SelfServiceSettingsFlow} from '@ory/kratos-client/api';

import appActionTypes from 'library/common/constants/app.constant';
import { INotification } from 'library/common/types/notifications/notifications.d';
import { UUID } from 'library/common/types/app.d';

export const setWodaStoryPlayingState = createCustomAction(appActionTypes.SET_WODA_STORY_PLAYING_STATE, (state?: boolean) => ({state}));

export const setWodaStoryMuteState = createCustomAction(appActionTypes.SET_WODA_STORY_MUTE_STATE, (state?: boolean) => ({state}));

export const setError = createCustomAction(appActionTypes.SET_ERROR, (haveError: boolean) => ({haveError}));

export const initSettingsPage = createAsyncAction(
	appActionTypes.INIT_SETTINGS_REQUEST,
	appActionTypes.INIT_SETTINGS_SUCCESS,
	appActionTypes.INIT_SETTINGS_FAILURE,
)<undefined, SelfServiceSettingsFlow, undefined>();

export const applySettings = createAsyncAction(
	appActionTypes.APPLY_SETTINGS_REQUEST,
	appActionTypes.APPLY_SETTINGS_SUCCESS,
	appActionTypes.APPLY_SETTINGS_FAILURE,
)<{methodGroup: string, data: object, successMessage?: string}, SelfServiceSettingsFlow, undefined>();

export const notificationEvent = createAction(appActionTypes.NOTIFICATION_EVENT)<any>();

export const setReactQueryDevtoolsOpen = createCustomAction(appActionTypes.SET_REACT_QUERY_DEVTOOLS_OPEN);

export const addNotification = createAction(appActionTypes.ADD_NOTIFICATION)<INotification>();

export const dropNotificationsList = createAction(appActionTypes.DROP_NOTIFICATIONS_LIST)();

export const setNotificationCount = createCustomAction(appActionTypes.SET_NOTIFICATION_COUNT,
	(count: number) => ({count}));

export const increaseNotificationCount = createAction(appActionTypes.INCREASE_NOTIFICATION_COUNT)();

export const setNotificationViewed = createAction(appActionTypes.SET_NOTIFICATION_VIEWED)<UUID>();
