import {
	GenericError, SelfServiceLoginFlow, UiNode, UiText,
} from '@ory/kratos-client/api';
import {AxiosError} from 'axios';
import _ from 'lodash';

// eslint-disable-next-line import/prefer-default-export
export const kratosErrorHandler = (
	error: AxiosError< SelfServiceLoginFlow | GenericError>,
	defaultText: string,
): string => {
	try {
		const messages = _.get(error, 'response.data.ui.messages') || _.get(error, 'response.data.error.message');

		if (messages) {
			return _.isArray(messages)
				? messages.reduce((accumulator: string[], currentValue) => {
					if (currentValue?.type === 'error' && currentValue?.text?.length > 0) {
						return [...accumulator, currentValue.text];
					}
					return accumulator;
				}, []).join('. ').trim()
				: messages;
		}

		const alternativeErrorSource = _.get(error, 'response.data.ui.nodes');

		if (alternativeErrorSource) {
			const alternativeText = alternativeErrorSource
				.map((node: UiNode) => node.messages.reduce((accumulator: string, currentValue: UiText) => {
					if (currentValue) {
						return `${accumulator} ${currentValue.text}`;
					}

					return accumulator;
				}, ''))
				.filter((text: string) => !!text && text.length > 3);

			if (alternativeText?.length > 0) {
				return alternativeText;
			}
		}

		return defaultText;
	} catch (e) {
		return defaultText;
	}
};
