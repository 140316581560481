import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const AddPhotoIcon = ({width, height, ...props}: SvgIconProps) => (
	<SvgIcon {...props} viewBox="0 0 80 68" sx={{width: `${width}px`, height: `${height}px`}}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19.2 68C29.8038 68 38.3999 59.3015 38.3999 48.5714C38.3999 37.8413 29.8038 29.1429 19.2 29.1429C8.59612
              29.1429 0 37.8413 0 48.5714C0 59.3015 8.59612 68 19.2 68ZM16 38.883C16 37.0804 17.4327 35.619 19.2 35.619C20.9673
              35.619 22.4 37.0804 22.4 38.883V45.3332H28.8255C30.5788 45.3332 32.0001 46.7829 32.0001 48.5713C32.0001 50.3596
              30.5788 51.8094 28.8255 51.8094H22.4V58.2598C22.4 60.0624 20.9673 61.5238 19.2 61.5238C17.4327 61.5238 16 60.0624
              16 58.2598V51.8094H9.57473C7.82145 51.8094 6.40013 50.3596 6.40013 48.5713C6.40013 46.7829 7.82145 45.3332 9.57473
              45.3332H16V38.883Z"
		/>
		<path
			d="M49.6001 37.2381C54.902 37.2381 59.2 32.8888 59.2 27.5238C59.2 22.1587 54.902 17.8095 49.6001
            17.8095C44.2981 17.8095 40.0001 22.1587 40.0001 27.5238C40.0001 32.8888 44.2981 37.2381 49.6001
            37.2381Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.0001 26.1343V16.1905C16.0001 12.6138 18.8655 9.71428 22.4001 9.71428H36.8001C37.6837 9.71428 38.4001
                8.98941 38.4001 8.09523V1.61905C38.4001 0.724872 39.1164 0 40.0001 0H55.3373C55.7617 0 56.1686 0.170577
                56.4687 0.474207L61.9314 6.00198C62.2315 6.30561 62.6384 6.47618 63.0628 6.47618H73.6C77.1346 6.47618 80
                9.37567 80 12.9524V45.3333C80 48.91 77.1346 51.8095 73.6 51.8095H41.3732C41.5227 50.7519 41.6 49.6708
                41.6 48.5714C41.6 36.0529 31.5712 25.9047 19.2001 25.9047C18.1136 25.9047 17.0452 25.983 16.0001
                26.1343ZM49.6001 40.4762C56.6693 40.4762 62.4 34.6772 62.4 27.5238C62.4 20.3704 56.6693 14.5714
                49.6001 14.5714C42.5308 14.5714 36.8001 20.3704 36.8001 27.5238C36.8001 34.6772 42.5308 40.4762
                49.6001 40.4762Z"
		/>
	</SvgIcon>
);

export default AddPhotoIcon;
