import React, { useState } from 'react';
import copy from 'copy-to-clipboard';
import { Tooltip, TooltipProps } from '@mui/material';

interface ChildProps {
  copy: (content: string) => void;
}

interface IProps {
  tooltipProps?: Partial<TooltipProps>;
  children: (props: ChildProps) => React.ReactElement<any>;
}

const CopyToClipboard: React.FunctionComponent<IProps> = ({ children, tooltipProps }) => {
	const [showTooltip, setShowTooltip] = useState(false);

	const onCopy = (content: any) => {
		copy(content);
		setShowTooltip(true);
	};

	const handleOnTooltipClose = () => {
		setShowTooltip(false);
	};
	return (
		<Tooltip
			open={showTooltip}
			title="Copied to clipboard!"
			leaveDelay={1500}
			onClose={handleOnTooltipClose}
			{...tooltipProps ?? {}}
		>
			{children({ copy: onCopy }) as React.ReactElement<any>}
		</Tooltip>
	);
};

export default CopyToClipboard;
