import React from 'react';
import {
	Box, Skeleton, Stack, Typography,
} from '@mui/material';

interface IProps {
	isPending: boolean;
	data: Partial<{[key: string]: number}>;
	schema: {id: string; title: string}[];
}

const ProfileCounters: React.FunctionComponent<IProps> = ({ isPending, data, schema }) => (
	<Stack
		spacing={5}
		direction="row"
		mt={{
			xs: 3.5,
			md: 3,
		}}
		justifyContent={{
			xs: 'center',
			md: 'flex-start',
		}}
	>
		{
			schema.map(((el) => (
				<Box key={el.id}>
					<Typography
						variant="h5"
						sx={{
							color: (theme) => ({
								xs: theme.palette.text.secondary,
								md: (theme.palette.background as any).main,
							}),
							textAlign: { xs: 'center', md: 'start' },
							fontWeight: 600,
						}}
					>
						{isPending ? <Skeleton variant="text" /> : data[el.id] || 0}
					</Typography>
					<Typography
						variant="body1"
						sx={{
							mt: 1,
							color: (theme) => ({
								xs: theme.palette.text.primary,
								md: (theme.palette.background as any).main,
							}),
						}}
					>
						{el.title}
					</Typography>
				</Box>
			)))
		}
	</Stack>
);

export default ProfileCounters;
