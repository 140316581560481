import React, { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
	Button, CircularProgress, Collapse, Stack,
} from '@mui/material';

import Resources from 'pages/CardCreation/components/Stage/components/Resources';
import Buttons from 'pages/CardCreation/components/Stage/components/Buttons';
import Header from 'pages/CardCreation/components/Stage/components/Header';
import Text from 'pages/CardCreation/components/Stage/components/Text';
import { IStepData } from 'library/common/types/cards/cardsCreation.d';
import { StageType } from 'library/common/types/cards/cards.d';
import Image from 'library/common/components/inputs/Image';
import Video from 'library/common/components/inputs/Video';
import Draw from 'library/common/components/inputs/Draw';
import EditIcon from 'resources/icons/EditIcon';

interface IProps {
	sourceStepWeight: number;
	sourceStepIndex: number;
	handleClose(): void;
	createStepHandler(sourceStepWeight: number, newStepData: IStepData): Promise<boolean>;
}

const StepCreate: React.FunctionComponent<IProps> = ({
	sourceStepWeight, sourceStepIndex, createStepHandler, handleClose,
}) => {
	const {t} = useTranslation();

	const openMediaInputRef = useRef<(() => void) | undefined>(undefined);

	const [isPending, setPending] = useState(false);

	const {
		control, handleSubmit, formState: { errors, isDirty }, setValue, setError,
	} = useForm<IStepData>({
		defaultValues: {
			resources: [],
		},
	});

	const [selectedTypes, setSelectedTypes] = useState<{[key in StageType]: boolean}>({
		resources: false,
		text: false,
		image: false,
		video: false,
		draw: false,
	});

	const changeSelectedType = (type: StageType) => {
		setSelectedTypes((prev) => {
			if (type === 'draw' || type === 'image' || type === 'video') {
				const newState = {
					...prev,
					draw: false,
					image: false,
					video: false,
				};

				setValue('draw', undefined);
				setValue('image', undefined);
				setValue('video', undefined);

				return {
					...newState,
					[type]: !prev[type],
				};
			}

			if (type === 'resources' && prev.resources) {
				setValue('resources', []);
			}

			if (type === 'text' && prev.text) {
				setValue('text', '');
			}

			return { ...prev, [type]: !prev[type] };
		});
	};

	const onSubmit = handleSubmit(async (data) => {
		const {
			draw, image, text, video,
		} = data;
		if (!(video || draw || image || text)) {
			setError('text', { message: 'Please add more information to the step' });
			return;
		}

		setPending(true);
		createStepHandler(sourceStepWeight, data)
			.then((result) => {
				setPending(false);

				if (result) handleClose();
			});
	});

	return (
		<Stack direction="column" sx={{ width: '100%' }} component="form" onSubmit={onSubmit}>
			<Header
				moveCardDownAvailable={false}
				moveCardUpAvailable={false}
				dropStepAvailable={false}
				title={`${t('step')} ${sourceStepIndex + 2}`}
				error={
					errors.text?.message
					|| errors.image?.message
					|| errors.draw?.message
					|| errors.video?.message
				}
			/>

			<Buttons selectedTypes={selectedTypes} changeSelectedType={changeSelectedType} />

			{
				selectedTypes.resources && (
					<Controller
						control={control}
						name="resources"
						render={({ field }) => (
							<Resources
								disabled={isPending}
								stepResources={field.value}
								setFormResources={field.onChange}
							/>
						)}
					/>
				)
			}

			<Collapse in={selectedTypes.text} unmountOnExit mountOnEnter>
				<Controller
					control={control}
					name="text"
					render={({ field, fieldState}) => (
						<Text
							t={t}
							sx={{ mt: 6 }}
							disabled={isPending}
							value={field.value}
							setData={field.onChange}
							haveError={!!fieldState.error}
						/>
					)}
				/>
			</Collapse>

			<Collapse in={selectedTypes.image} unmountOnExit mountOnEnter>
				<Stack direction="column">
					<Controller
						control={control}
						name="image"
						render={({ field }) => (
							<Image
								sx={{ mt: 6, minHeight: 336}}
								value={field.value}
								setData={field.onChange}
								disabled={isPending}
								openImageInputRef={openMediaInputRef}
							/>
						)}
					/>
					<Button
						sx={{
							ml: 'auto',
							color: (theme) => theme.palette.text.secondary,
							textTransform: 'none',
						}}
						startIcon={<EditIcon />}
						onClick={() => {
							if (openMediaInputRef.current) {
								openMediaInputRef.current();
							}
						}}
					>
						Change image
					</Button>
				</Stack>
			</Collapse>

			<Collapse in={selectedTypes.video} unmountOnExit mountOnEnter>
				<Stack direction="column">
					<Controller
						control={control}
						name="video"
						render={({ field }) => (
							<Video
								sx={{ mt: 6 }}
								value={field.value}
								setData={field.onChange}
								disabled={isPending}
								openVideoInputRef={openMediaInputRef}
							/>
						)}
					/>
					<Button
						sx={{
							ml: 'auto',
							color: (theme) => theme.palette.text.secondary,
							textTransform: 'none',
						}}
						startIcon={<EditIcon />}
						onClick={() => {
							if (openMediaInputRef.current) {
								setValue('video', undefined);
								openMediaInputRef.current();
							}
						}}
					>
						Change video
					</Button>
				</Stack>
			</Collapse>

			<Collapse in={selectedTypes.draw} unmountOnExit mountOnEnter>
				<Stack direction="column">
					<Controller
						control={control}
						name="draw"
						render={({ field }) => (
							<Draw
								sx={{ mt: 6 }}
								id="new step creation"
								value={field.value}
								setData={field.onChange}
								disabled={isPending}
								openDrawInputRef={openMediaInputRef}
							/>
						)}
					/>
					<Button
						sx={{
							ml: 'auto',
							color: (theme) => theme.palette.text.secondary,
							textTransform: 'none',
						}}
						startIcon={<EditIcon />}
						onClick={() => {
							if (openMediaInputRef.current) {
								setValue('video', undefined);
								openMediaInputRef.current();
							}
						}}
					>
						Add new draw
					</Button>
				</Stack>
			</Collapse>

			<Stack direction="row" spacing={1} sx={{ ml: 'auto', mt: 3 }}>
				<Button disabled={isPending} onClick={handleClose}>Cancel</Button>
				<Button
					sx={{ width: 100 }}
					disabled={isPending || !isDirty}
					endIcon={isPending && <CircularProgress size={16} color="inherit" />}
					type="submit"
				>
					Save
				</Button>
			</Stack>
		</Stack>
	);
};

export default StepCreate;
