import React from 'react';
import {
	Box, Stack, SxProps, Theme, Typography,
} from '@mui/material';

import WqMobileLogo from 'resources/image/mobile_wq_logo.jpg';

import styles from './title.module.css';
import WQLogo from '../WQLogo';

interface ITitle {
	withoutText?: boolean;
	style?: SxProps<Theme>;
	mobileMode?: boolean;
	type?: 'dark' | 'light';
	logoSizePx?: number;
}

const Title = ({
	withoutText: withoutLogo, style, mobileMode, type = 'dark', logoSizePx = 32,
}: ITitle) => (
	<Stack
		direction="row"
		spacing={1}
		onClick={() => window.location.replace('/app/main')}
		className={styles.title}
		alignItems="center"
	>
		{
			mobileMode ? (
				<Box component="img" src={WqMobileLogo} sx={{ width: '24px', height: '24px' }} />
			) : (
				<WQLogo type={type} style={{ height: `${logoSizePx}px`, width: `${logoSizePx}px` }} />
			)
		}
		{
			!withoutLogo && (
				<Box>
					<Typography
						variant="body1"
						sx={{
							...(mobileMode && {
								fontSize: '10px',
								lineHeight: '11px',
							}),
							color: (theme) => theme.palette.text.primary,
							fontWeight: 800,
							...style,
						}}
					>
						Woda
					</Typography>
					<Typography
						variant="body1"
						color="secondary"
						sx={{
							...(mobileMode && {
								fontSize: '10px',
								lineHeight: '11px',
							}),
							color: (theme) => theme.palette.text.primary,
							fontWeight: 800,
							...style,
						}}
					>
						Qota
					</Typography>
				</Box>
			)
		}
	</Stack>
);

export default Title;
