import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMount } from 'react-use';
import * as colors from '@mui/material/colors';

import { ITrend } from 'library/common/types/cards/trends.d';
import cardsService from 'library/common/services/cards.service';
import { ICategory, IPoorCard } from 'library/common/types/cards/cards.d';
import { showSnackbarMessage } from 'library/utils/showSnackbarMessage';
import { AxiosCardApiPaginationResponse } from 'library/common/types/cards/request.d';
import { takeColorByFirstLetter } from 'library/utils/myColors';
import isNonNullable from 'library/utils/isNonNullable';
import { UUID } from 'library/common/types/app.d';

interface IProps {
    category: ICategory;
    currentCardId?: UUID;
}

interface IResult {
    trends: ITrend[];
    isPending: boolean;
}

const trendsSetter = (data: IPoorCard[], currentCardId?: UUID): ITrend[] => {
	const trends = data.map(({
		id, author, title, created, cover,
	}) => ({
		id,
		author: {
			...author,
			bgColor: takeColorByFirstLetter(colors, author.login),
		},
		title,
		created,
		cover,
	}));

	if (isNonNullable(currentCardId)) {
		return trends.filter((trend) => trend.id !== currentCardId);
	}

	return trends;
};

const useTrends = ({ category, currentCardId }: IProps): IResult => {
	const {t} = useTranslation();

	const [trends, setTrends] = useState<ITrend[]>([]);
	const [isPending, setIsPending] = useState(false);

	const getTrends = useCallback(
		() => {
			setIsPending(true);
			cardsService
				.getCardsList<AxiosCardApiPaginationResponse<IPoorCard[]>>({category_id: category.id})
				.then(({ data: { payload }}) => setTrends(trendsSetter(payload, currentCardId)))
				.catch(() => showSnackbarMessage(t('error_loading_data'), 'error', 3000))
				.finally(() => setIsPending(false));
		},
		[category.id, currentCardId, t],
	);

	useMount(getTrends);

	return {
		trends,
		isPending,
	};
};

export default useTrends;
