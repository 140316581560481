import React from 'react';
import { Box, Container, Stack } from '@mui/material';

import useVault from 'library/common/data/wodabonuses/useVault';
import useLastTransactions from 'library/common/data/wodabonuses/useLastTransactions';
import useLastVault from 'library/common/data/wodabonuses/useLastVault';

import FullScreenLoader from 'library/common/components/FullScreenLoader';
import Description from './components/Description';
import Diagram from './components/Diagram';
import Frame from './components/Frame';
import Transactions from './components/Transactions';

interface IProps {}

const Global: React.FunctionComponent<IProps> = () => {
	const { data: emissionVaultData, isLoading: isEmissionVaultDataLoading } = useLastVault();
	const { data: lastTransactionsData, isLoading: isLastTransactionsDataLoading } = useLastTransactions();
	const { data: vaultData, isLoading: isVaultDataLoading} = useVault();

	const isLoading = isEmissionVaultDataLoading && isLastTransactionsDataLoading && isVaultDataLoading;

	if (isLoading) {
		return (
			<Container disableGutters maxWidth={false}>
				<FullScreenLoader />
			</Container>
		);
	}

	return (
		<Container disableGutters maxWidth={false}>
			<Container maxWidth="xl">
				<Stack
					direction={{ xs: 'column', md: 'row' }}
					spacing={{ xs: 4, md: 20 }}
				>
					<Description
						sx={{
							flex: {
								xs: 1, md: 0.3, lg: 0.4, xl: 0.5,
							},
						}}
					/>
					<Diagram
						sx={{
							flex: {
								xs: 1, md: 0.7, lg: 0.6, xl: 0.5,
							},
						}}
						vaultData={vaultData}
						lastTransactionsData={lastTransactionsData}
						emissionVaultData={emissionVaultData}
					/>
				</Stack>

				<Box mt={7}>
					<Frame />
				</Box>
			</Container>

			<Box
				sx={{
					mt: 8,
					pt: 3,
					pb: { xs: 10, md: 5 },
					background: 'linear-gradient(0deg, rgba(65, 154, 242, 0.02), rgba(65, 154, 242, 0.02)), #FFFFFF',
				}}
			>
				<Container maxWidth="xl">
					<Transactions />
				</Container>
			</Box>
		</Container>
	);
};

export default Global;
