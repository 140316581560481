import React, { ButtonHTMLAttributes, SyntheticEvent } from 'react';
import {
	Box,
	Tab,
	Tabs,
	IconButton,
} from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { styled } from '@mui/system';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { UUID } from 'library/common/types/app.d';
import useCategories from 'library/common/data/categories/useCategories';
import MainPageQueryParams from 'library/utils/routes/mainPageQueryParams';
import { getMainPageUrl } from 'library/utils/routes/mainPageUrls';
import { selectSelectedCategory } from 'library/common/selectors/card.selector';

interface IScrollButton extends ButtonHTMLAttributes<HTMLButtonElement> {
	orientation?: 'horizontal' | 'vertical';
	direction: 'left' | 'right';
}

const ScrollButton = ({direction, ...props}: IScrollButton) => (
	<Box
		sx={{
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			ml: direction === 'right' ? 1 : undefined,
			mr: direction === 'left' ? 1 : undefined,
		}}
	>
		<IconButton onClick={props.onClick} disabled={props.disabled} size="small">
			{direction === 'left' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
		</IconButton>
	</Box>
);

const WQTab = styled(Tab)(({ theme }) => (
	`
	min-height: 32px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	font-size: 0.8rem;
	padding: 0 12px;
	margin: 0 6px;
	font-weight: bold;
	text-transform: none;
	border-radius: 16px;
	color: ${theme.palette.text.secondary};
	background: ${theme.palette.common.white};
	border: 1px solid ${theme.palette.grey[300]};
  
	&:hover {
	  background-color: ${theme.palette.grey[300]};
	}
  
	&.${tabUnstyledClasses.selected} {
	  background-color: ${theme.palette.text.secondary};
	  color: ${theme.palette.primary.contrastText};
	}
  
	&.${buttonUnstyledClasses.disabled} {
	  opacity: 0.5;
	  cursor: not-allowed;
	}
  `
));

const PrivateWQTab = styled(Tab)(({ theme }) => (
	`
	min-height: 32px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	font-size: 0.8rem;
	padding: 0 12px;
	margin: 0 6px;
	font-weight: bold;
	text-transform: none;
	border-radius: 16px;
	color: ${theme.palette.common.white};
	background: ${theme.palette.gradients.primary};
  
	&:hover {
	  	background: ${theme.palette.gradients.info};
		opacity: 0.7;
	}
  
	&.${tabUnstyledClasses.selected} {
		opacity: 1;
		color: ${theme.palette.common.white};
	  	background: ${theme.palette.gradients.info};
	}
  
	&.${buttonUnstyledClasses.disabled} {
	  opacity: 0.5;
	  cursor: not-allowed;
	}
  `
));

export interface IProps {}

const CategoryTabs: React.FunctionComponent<IProps> = () => {
	const { data } = useCategories();

	const navigate = useNavigate();

	const [searchQuery, setSearchParams] = useSearchParams({});
	const location = useLocation();

	const selectedCategory: UUID | undefined = useSelector(selectSelectedCategory);

	const availableCategories = data ? [{id: 'all', title: 'All'}, ...data] : [];

	const isMainPage = location.pathname === getMainPageUrl();

	const handleChange = (_: SyntheticEvent, newValue: UUID | 'all') => {
		if (newValue === 'all') {
			searchQuery.delete(MainPageQueryParams.CATEGORY);
			setSearchParams(searchQuery);
			navigate(getMainPageUrl());
		} else if (!isMainPage) {
			navigate(`${getMainPageUrl()}?${MainPageQueryParams.CATEGORY}=${newValue}`);
		} else {
			searchQuery.set(MainPageQueryParams.CATEGORY, newValue);
			setSearchParams(searchQuery);
		}
	};

	const value = isMainPage ? selectedCategory || 'all' : false;

	return (
		<Box sx={{ width: '100%', mt: 1 }}>
			<Tabs
				value={value}
				onChange={handleChange}
				variant="scrollable"
				scrollButtons="auto"
				aria-label="categories selector"
				sx={{minHeight: '32px'}}
				ScrollButtonComponent={ScrollButton}
				TabIndicatorProps={{
					style: {
						display: 'none',
					},
				}}
			>
				{
					availableCategories.map((category) => (
						category.private
							? (
								<PrivateWQTab
									value={category.id}
									key={category.id}
									label={category.title}
								/>
							) : (
								<WQTab
									value={category.id}
									key={category.id}
									label={category.title}
								/>
							)
					))
				}
			</Tabs>
		</Box>
	);
};

export default CategoryTabs;
