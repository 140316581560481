import React, { useState } from 'react';
import { animated, useSpring } from 'react-spring';
import {
	AppBar, Backdrop, Box, Button, Divider, Stack, Typography,
} from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import Title from 'library/common/components/Title/Title';
import { IUserData } from 'library/common/types/user.d';
import AvatarButton from 'library/common/components/AvatarButton';

import BurgerButton from './BurgerButton';
import styles from './styles.module.css';
import NavbarLink from '../../NavbarLink';
import sections from '../sections';

interface IProps {
	isAuth?: boolean;
    userData: IUserData;
	onSignIn: () => void;
}

const MobileHeader: React.FunctionComponent<IProps> = ({ isAuth, userData, onSignIn }) => {
	const [isOpen, setOpen] = useState(false);

	const { top } = useSpring({
		from: { top: '-100%' },
		top: isOpen ? '0' : '-100%',
	});

	const handleClick = () => {
		setOpen((prev) => !prev);
	};

	return (
		<>
			<AppBar sx={{
				px: {
					xs: 2, md: 3, lg: 12,
				},
				height: '88px',
				background: (theme) => `${theme.palette.common.white}E0`,
				color: (theme) => theme.palette.secondary.light,
				display: 'flex',
				justifyContent: 'center',
			}}
			>
				<Stack
					direction="row"
					justifyContent="space-between"
					sx={{
						width: '100%',
						position: 'relative',
						zIndex: '999',
					}}
				>
					<Title />

					<BurgerButton isOpen={isOpen} onClick={handleClick} />
				</Stack>
			</AppBar>
			<animated.div style={{ top }} className={styles.sidebar}>
				<Box sx={{
					pt: '112px',
					borderRadius: '0px 0px 16px 16px',
					width: '100vw',
					height: '402px',
					background: (theme) => theme.palette.common.white,
				}}
				>
					<Stack px={2} spacing={0.5}>
						{
							sections.map((section) => (
								<NavbarLink key={section.id} name={section.name} id={section.id} underlineWidth="50px" />
							))
						}
					</Stack>

					<Divider sx={{
						borderColor: 'rgba(114, 110, 131, 0.15)',
						backdropFilter: 'blur(5px)',
					}}
					/>
					<Box sx={{
						px: 2,
						display: 'flex',
						alignItems: 'center',
						height: '92px',
					}}
					>
						{
							isAuth ? (
								<Stack direction="row" alignItems="center" spacing={2}>
									<AvatarButton
										size={44}
										author={userData.username}
										authorId={userData.id}
										userPhoto={userData.logoURL}
										isVerified={userData.isVerified}
									/>
									<Typography
										variant="h6"
										sx={{ color: (theme) => theme.palette.grey[600] }}
									>
										{userData.username}
									</Typography>
								</Stack>
							) : (
								<Button
									variant="outlined"
									onClick={onSignIn}
									endIcon={<ExitToAppIcon />}
								>
									Sign In
								</Button>
							)
						}
					</Box>
				</Box>
			</animated.div>
			<Backdrop open={isOpen} sx={{ zIndex: 2 }} />
		</>
	);
};

export default MobileHeader;
