import React from 'react';
import { styled } from '@mui/material/styles';
import {Drawer} from '@mui/material';

import MHidden from 'library/common/components/MHidden/MHidden';
import { DRAWER_WIDTH } from 'resources/styles/constants';
import Sidebar from './components/Sidebar';

const RootStyle = styled('div')(({ theme }) => ({
	[theme.breakpoints.up('lg')]: {
		flexShrink: 0,
		width: DRAWER_WIDTH,
	},
}));

interface IDashboardSidebar {
	isOpenSidebar: boolean;
	onCloseSidebar: () => void;
}

const DesktopSidebar = ({isOpenSidebar, onCloseSidebar}: IDashboardSidebar) => (
	<RootStyle>
		<MHidden breakpoint="lg" direction="Up">
			<Drawer
				open={isOpenSidebar}
				onClose={onCloseSidebar}
				PaperProps={{
					sx: {
						width: DRAWER_WIDTH,
					},
				}}
			>
				<Sidebar />
			</Drawer>
		</MHidden>

		<MHidden breakpoint="lg" direction="Down">
			<Drawer
				open
				variant="persistent"
				PaperProps={{
					sx: {
						width: DRAWER_WIDTH,
						border: 'none',
					},
				}}
			>
				<Sidebar />
			</Drawer>
		</MHidden>
	</RootStyle>
);

export default DesktopSidebar;
