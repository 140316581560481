import React from 'react';
import { Button } from '@mui/material';

interface IGradientButton {
    borderRadius?: number;
	hoveredBorderRadius?: number;
    ariaLabel: string;
    label: string;
    disabled?: boolean | undefined;
	fullWidth?: boolean;
	fullGradient?: boolean;
    icon?: ({width, height}: {width: number, height: number}) => React.ReactNode;
	mt?: number,
    onClick: () => void;
}

const GradientButton = ({
	mt,
	icon,
	label,
	onClick,
	disabled,
	ariaLabel,
	fullWidth,
	borderRadius = 0,
	fullGradient,
	hoveredBorderRadius,
}: IGradientButton) => (
	<Button
		sx={{
			mt,
			padding: '6px 28px 6px 8px',
			position: 'relative',
			border: '1px solid transparent',
			backgroundColor: 'white',
			color: (theme) => theme.palette.text.secondary,
			backgroundClip: 'padding-box',
			borderRadius: `${borderRadius}px`,
			textTransform: 'inherit',

			'&:hover': {
				borderRadius: hoveredBorderRadius ? `${hoveredBorderRadius}px` : undefined,
			},

			'&:after': {
				position: 'absolute',
				top: fullGradient ? 0 : -1,
				left: fullGradient ? 0 : -1,
				right: fullGradient ? 0 : -1,
				bottom: -1,
				background: (theme) => (theme.palette as any).gradients.info,
				content: '""',
				zIndex: -1,
				borderRadius: `${borderRadius}px`,
				opacity: 0.2,
			},
		}}
		fullWidth={fullWidth}
		aria-label={ariaLabel}
		startIcon={icon ? icon({height: 32, width: 32}) : undefined}
		onClick={onClick}
		disabled={disabled}
	>
		{label}
	</Button>
);

export default GradientButton;
