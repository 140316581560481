import React from 'react';
import {
	Box, Divider, Grid, Stack, Typography,
} from '@mui/material';

import { getPoster } from 'library/utils/getters/getPicture';
import { IPoorCard } from 'library/common/types/cards/cards.d';
import Label from 'library/common/components/Label/Label';
import { declOfNum, getFullDuration } from 'library/utils/workWithData';

import MobileListItem from './ListItem';

interface IProps {
    cards: IPoorCard[];
}

const MobileSimpleList: React.FunctionComponent<IProps> = ({ cards }) => (
	<>
		{
			cards.map((card) => (
				<MobileListItem key={card.id} id={card.id}>
					<>
						<Grid
							item
							xs={2}
							display="flex"
							alignItems="center"
							justifyContent="center"
						>
							<Box
								component="img"
								sx={{
									width: '48px',
									height: '48px',
								}}
								src={getPoster(card, 'LIST')}
							/>
						</Grid>

						<Grid item xs={10}>
							<Stack
								direction="column"
								sx={{ height: '100%', justifyContent: 'center' }}
							>
								<Label
									component="h6"
									title={card.title}
									sx={{
										fontWeight: 600,
										WebkitLineClamp: 1,
										color: (theme) => (theme.palette as any).gradients.textDark,
									}}
								/>

								<Stack direction="row" mt={0.5}>
									<Typography
										variant="h6"
										sx={{
											whiteSpace: 'nowrap',
											color: (theme) => theme.palette.secondary.light,
										}}
									>
										{card.duration && getFullDuration(card.duration)}
									</Typography>

									<Divider
										orientation="vertical"
										flexItem
										sx={{
											mx: 1,
											height: '16px',
											alignSelf: 'auto',
										}}
									/>

									<Box>
										<Typography
											variant="h6"
											sx={{
												whiteSpace: 'nowrap',
												color: (theme) => theme.palette.secondary.light,
											}}
										>
											{`${card.number_of_steps || 0} ${declOfNum(card.number_of_steps || 0, ['step', 'steps', 'steps'])}`}
										</Typography>
									</Box>

									<Divider
										orientation="vertical"
										flexItem
										sx={{
											mx: 1,
											height: '16px',
											alignSelf: 'auto',
										}}
									/>

									<Box>
										<Label
											component="h6"
											title={`#${card.result.title}`}
											sx={{
												WebkitLineClamp: 1,
												color: (theme) => theme.palette.secondary.light,
											}}
										/>
									</Box>
								</Stack>
							</Stack>
						</Grid>

					</>
				</MobileListItem>
			))
		}
	</>
);

export default MobileSimpleList;
