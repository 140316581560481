import { v1 as uuidv1 } from 'uuid';

import { ICommentEntity } from 'library/common/types/cards/comments.d';
import { IBaseNotificationMessage, INotificationComment } from 'library/common/types/notifications/notifications.d';
import { mapAuthorData } from 'library/utils/mappers/user.mapper';
import NotificationType from 'library/common/types/notifications/notificationTypes';
import { MessageEventCode } from '../eventCode';

export type ICommentMessage = IBaseNotificationMessage<
    MessageEventCode.COMMENT,
    ICommentEntity>;

export const mapCommentAddMessage = (data: ICommentEntity): INotificationComment => ({
	...mapAuthorData(data.commentUserInfo.author),
	// TODO подстановку id удалить после добаления хранилица у бекенда (должны и id добавить)
	id: uuidv1(),
	type: NotificationType.COMMENT,
	commentText: data.comment.text,
	createdAt: data.comment.created,
	cardId: data.comment.cardId,
	viewed: false,
	// TODO добавить на беке название карточки
});
