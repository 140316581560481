export const getShortestDirection = (from: number, to: number, slidesLength: number): -1 | 0 | 1 => {
	if (from > to) {
		if (from - to > slidesLength - 1 - from + to) {
			return 1;
		} return -1;
	} if (to > from) {
		if (to - from > from + slidesLength - 1 - to) {
			return -1;
		} return 1;
	}
	return 0;
};

export const clampOffsetRadius = (offsetRadius: number, slidesLength: number): number => {
	const upperBound = Math.floor((slidesLength - 1) / 2);

	if (offsetRadius < 0) {
		return 0;
	}
	if (offsetRadius > upperBound) {
		return upperBound;
	}

	return offsetRadius;
};

export const mod = (a: number, b: number): number => ((a % b) + b) % b;

export const modBySlidesLength = (index: number, slidesLength: number): number => mod(index, slidesLength);

export const getDefaultTranslateX = (
	offsetFromCenter: number,
	offsetRadius: number,
	index: number,
) => {
	const totalPresentable = 2 * offsetRadius + 1;
	const translateXoffset = 50 * (Math.abs(offsetFromCenter) / (offsetRadius + 1));
	let translateX = -50;

	if (offsetRadius !== 0) {
		if (index === 0) {
			translateX = 0;
		} else if (index === totalPresentable - 1) {
			translateX = -100;
		}
	}

	if (offsetFromCenter > 0) {
		translateX += translateXoffset;
	} else if (offsetFromCenter < 0) {
		translateX -= translateXoffset;
	}
	return translateX;
};
