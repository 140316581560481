import React, {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from '@mui/material';

import {IResult} from 'library/common/types/cards/cards.d';
import cardsService from 'library/common/services/cards.service';
import {showSnackbarMessage} from 'library/utils/showSnackbarMessage';
import { AxiosCardApiResponse } from 'library/common/types/cards/request.d';
import { UUID } from 'library/common/types/app.d';

interface INewResultDialog {
	isOpen: boolean;
	category: UUID;
	onCloseHandle: () => void;
	addToOptions: (clickedValue: IResult) => void;
}

const defaultValues: {title: string} = {
	title: '',
};

const NewResultDialog = ({
	isOpen, onCloseHandle, category, addToOptions,
}: INewResultDialog) => {
	const {
		control, handleSubmit, formState: {errors}, setValue, reset,
	} = useForm<{title: string}>({defaultValues});

	const [isLoading, setIsLoading] = useState(false);

	const onClose = () => {
		setValue('title', '');
		reset();
		onCloseHandle();
	};

	const onSubmitHandler = handleSubmit(async (data) => {
		setIsLoading(true);
		cardsService.addResult({title: data.title, category_id: category})
			.then((res: AxiosCardApiResponse) => {
				addToOptions(res.data.payload);
				onClose();
			}).catch(() => {
				showSnackbarMessage('Failed to add result', 'error');
			}).finally(() => setIsLoading(false));
	});

	return (
		<Dialog open={isOpen} onClose={onClose}>
			<Box sx={{width: '400px'}}>
				<DialogTitle>Custom result creation</DialogTitle>
				<Box sx={{paddingTop: 1}}>
					<DialogContent>
						<Controller
							control={control}
							name="title"
							defaultValue={defaultValues.title}
							rules={{
								maxLength: { value: 50, message: 'Maximum 50 symbols' },
								minLength: { value: 3, message: 'Minimum 3 symbols' },
								required: {value: true, message: 'Please enter result'},
							}}
							render={({field}) => (
								<TextField
									{...field}
									autoFocus
									disabled={isLoading}
									label="New result"
									fullWidth
									variant="outlined"
									size="small"
									type="text"
									error={!!errors.title}
									helperText={errors.title?.message}
									InputProps={{
										endAdornment: (
											<>
												{isLoading && <CircularProgress color="primary" size={20} />}
											</>
										),
									}}
								/>
							)}
						/>
					</DialogContent>
				</Box>
				<DialogActions>
					<Button onClick={onClose}>
						Cancel
					</Button>
					<Button
						onClick={onSubmitHandler}
						disabled={isLoading}
					>
						Save
					</Button>
				</DialogActions>
			</Box>
		</Dialog>
	);
};

export default NewResultDialog;
