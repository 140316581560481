import React from 'react';
import { Stack, Typography } from '@mui/material';
import Label from 'library/common/components/Label/Label';
import { IDetailedCard } from 'library/common/types/cards/cards.d';
import AvatarButton from 'library/common/components/AvatarButton';
import { getDateString } from 'library/utils/textGenerators';

import CardMenu from 'pages/Card/components/CardMenu/CardMenu';
import { getToolbarAvatar } from 'library/utils/getters/getPicture';
import StatisticCounters from 'library/common/components/StatisticCounters';
import VerifiedIcon from 'resources/icons/VerifiedIcon';
import ResultWithLabel from './children/ResultWithLabel';
import AmountData from './children/AmountData';

interface IProps {
	isAuth: boolean;
	authorMode: boolean;
	// isActionPending: boolean;
    detailedCard: IDetailedCard;
	totalResources: number;
	// toggleCardActivation(): void;
	toggleCardLike(): void;
	deleteCardHandler(): Promise<void>;
	toggleEditMode(): void;
	handleShareCard(): void;
}

const CardInformation: React.FunctionComponent<IProps> = ({
	isAuth,
	authorMode,
	detailedCard: {
		// id,
		title,
		author,
		category,
		created,
		steps,
		duration,
		statistics: {
			number_of_favorites,
			number_of_comments,
		},
		result,
		client_info,
	},
	totalResources,
	deleteCardHandler,
	toggleEditMode,
	handleShareCard,
	toggleCardLike,
}) => (
	<Stack direction="column" sx={{ width: '100%'}}>
		<Stack direction="row">
			<Typography
				sx={({palette}) => ({
					fontSize: '16px',
					fontWeight: 600,
					color: palette.primary.main,
				})}
			>
				{category.title}
			</Typography>

			<CardMenu
				title={title}
				isLiked={client_info?.is_favorite}
				isAuth={isAuth}
				authorMode={authorMode}
				deleteCardHandler={deleteCardHandler}
				toggleEditMode={toggleEditMode}
				handleShareCard={handleShareCard}
				handleLikeCard={toggleCardLike}
			/>
		</Stack>

		<Typography
			sx={{
				color: ({palette}) => palette.text.primary,
				fontSize: '12px',
				fontWeight: 400,
				mt: 4,
			}}
		>
			{getDateString(created)}

		</Typography>
		<Typography
			variant="h4"
			sx={{ color: ({palette}) => palette.text.primary }}
		>
			{title}
		</Typography>

		<Stack direction="row" alignItems="center" mt={4}>
			<AvatarButton
				size={44}
				author={author?.login}
				authorId={author?.id}
				userPhoto={getToolbarAvatar(author.avatar)}
			/>
			<Stack direction="row" spacing={1}>
				<Label
					title={`${author?.login}`}
					variant="body1"
					sx={{
						ml: 2,
						color: ({palette}) => palette.text.primary,
						fontWeight: 600,
					}}
				/>
				{author.is_verified && <VerifiedIcon sx={{ width: '16px', height: '16px' }} />}
			</Stack>
		</Stack>

		<AmountData duration={duration} totalResources={totalResources} totalSteps={steps.length} />

		<Stack direction={{ xs: 'row', md: 'column' }} mt={3} flex={1}>
			<Stack direction={{ xs: 'column', md: 'row' }} sx={{ height: { md: '80px' } }}>
				<ResultWithLabel result={result} />
				{/* {!authorMode && client_info?.is_activated && (
					<ActivationIndicator
						sx={{
							ml: { xs: 0, md: 'auto' },
							mt: { xs: 3, md: 0 },
						}}
					/>
				)} */}
			</Stack>

			<StatisticCounters
				id="card_parameters"
				direction={{ xs: 'column', md: 'row' }}
				spacing={2}
				mt={{ xs: 0, md: 3 }}
				ml={{ xs: 'auto', md: 0 }}
				number_of_comments={number_of_comments}
				number_of_favorites={number_of_favorites}
			/>
		</Stack>

		{/* {!authorMode && (
			<ActivateButton
				sx={{ mt: 4 }}
				isActivated={client_info?.is_activated}
				isActionPending={isActionPending}
				toggleCardActivation={toggleCardActivation}
			/>
		)} */}

	</Stack>
);

export default CardInformation;
