export default (field?: string) => {
	switch (field) {
	case 'title':
		return 'title';

	case 'category':
		return 'category';

	case 'days':
	case 'hours':
	case 'minutes':
	case 'seconds':
		return 'time';

	case 'stages':
		return 'stages';

	case 'result':
		return 'result';

	case 'cover':
		return 'cover';

	default:
		return undefined;
	}
};
