import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { selectUserId } from 'library/common/selectors/user.selector';
import SelfProfile from './SelfProfile';
import UserProfile from './UserProfile';

const Profile: React.FunctionComponent = () => {
	const { id } = useParams<{id?: string}>();
	const userId = useSelector(selectUserId);

	// TODO переделать типизацию в SelfProfile и UserProfile под union types

	if (id === userId || id === undefined) {
		return (
			<SelfProfile />
		);
	}

	return (
		<UserProfile id={id} />
	);
};

export default Profile;
