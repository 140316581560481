import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from '@mui/material';
import { MediaUUID } from 'library/common/types/app.d';

interface INewResultDialog {
    isLoading: boolean;
	isOpen: boolean;
	onCloseHandle: () => void;
    addResourceHandler(data: {title: string, image?: MediaUUID}, onSuccessCallback?: () => void): void;
}

const defaultValues: {title: string} = {
	title: '',
};

const NewResourceDialog = ({
	isLoading,
	isOpen,
	onCloseHandle,
	addResourceHandler,
}: INewResultDialog) => {
	const {
		control, handleSubmit, formState: {errors}, setValue, reset,
	} = useForm<{title: string}>({defaultValues});

	const onClose = () => {
		setValue('title', '');
		reset();
		onCloseHandle();
	};

	const onSubmitHandler = handleSubmit(async (data) => {
		addResourceHandler(data, onClose);
	});

	return (
		<Dialog open={isOpen} onClose={onClose}>
			<Box sx={{width: '400px'}}>
				<DialogTitle>Custom resource creation</DialogTitle>
				<Box sx={{paddingTop: 1}}>
					<DialogContent>
						<Controller
							control={control}
							name="title"
							defaultValue={defaultValues.title}
							rules={{
								maxLength: { value: 50, message: 'Maximum 50 symbols' },
								minLength: { value: 3, message: 'Minimum 3 symbols' },
								required: {value: true, message: 'Please enter resource'},
							}}
							render={({field}) => (
								<TextField
									{...field}
									autoFocus
									disabled={isLoading}
									label="New resource"
									fullWidth
									variant="outlined"
									size="small"
									type="text"
									error={!!errors.title}
									helperText={errors.title?.message}
									InputProps={{
										endAdornment: (
											<>
												{isLoading && <CircularProgress color="primary" size={20} />}
											</>
										),
									}}
								/>
							)}
						/>
					</DialogContent>
				</Box>
				<DialogActions>
					<Button onClick={onClose}>
						Cancel
					</Button>
					<Button
						onClick={onSubmitHandler}
						disabled={isLoading}
					>
						Save
					</Button>
				</DialogActions>
			</Box>
		</Dialog>
	);
};

export default NewResourceDialog;
