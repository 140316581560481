import React, {
	useCallback, useRef, useState,
} from 'react';
import { Card as MaterialCard } from '@mui/material';

import { MediaURL, UUID } from 'library/common/types/app.d';
import { ICardActionListItem } from 'library/common/types/cards/cards.d';
import Media from './children/Media';
import Wrapper from './children/Wrapper';
import Content from './children/Content';

interface ICard {
	id: UUID;
	// disabled: boolean;
	title: string;
	category: string;
	poster?: MediaURL;
	duration: number;
	date: Date;
	comments?: number;
	activations?: number;
	favorites?: number;
	// userID?: string | null;
	authorID?: string | null;
	// userActivated: boolean;
	// statistic: Statistic;
	// isAuth: boolean;
	result?: string;
	author?: string;
	// activeMode?: boolean;
	userPhoto?: MediaURL;

	stageCount: number;
	disabled?: boolean;
	withBorder?: boolean;
	// on card click action
	clickble?: boolean;
	cardButtonActionsList?: ICardActionListItem[];
	isVerified?: boolean;
}

const Card = ({
	id,
	title,
	category,
	poster,
	duration,
	result,
	author,
	date,
	comments,
	favorites,
	activations,
	authorID,
	userPhoto,
	stageCount,
	disabled,
	withBorder,
	clickble,
	cardButtonActionsList,
	isVerified,
}: ICard) => {
	const [isWrapperVisible, setIsWrapperVisible] = useState(false);
	const ref = useRef<HTMLDivElement | null>(null);

	const visibilityOn = useCallback(() => setIsWrapperVisible(true), []);

	const visibilityOff = useCallback(() => setIsWrapperVisible(false), []);

	const onMenuCloseHandler = useCallback(
		(x: number, y: number) => {
			if (ref.current) {
				const coordinates = ref.current.getBoundingClientRect();
				const coordinateRangeX = {
					start: coordinates.left,
					end: coordinates.left + coordinates.width,
				};
				const coordinateRangeY = {
					start: coordinates.top,
					end: coordinates.top + coordinates.height,
				};

				const inX = x >= coordinateRangeX.start && x <= coordinateRangeX.end;
				const inY = y >= coordinateRangeY.start && y <= coordinateRangeY.end;

				if (!(inX && inY)) visibilityOff();
			}
		},
		[visibilityOff],
	);

	return (
		<MaterialCard
			ref={ref}
			onMouseEnter={visibilityOn}
			onMouseLeave={visibilityOff}
			sx={{
				height: '480px',
				width: '290px',
				position: 'relative',
				cursor: 'pointer',
				...(withBorder && {
					borderWidth: '1px',
					borderStyle: 'solid',
					borderColor: (theme) => `${theme.palette.text.secondary}12`,
				}),
			}}
		>
			<Media
				category={category}
				poster={poster}
				comments={comments}
				favorites={favorites}
				activations={activations}
				userPhoto={userPhoto}
				author={author}
				authorID={authorID}
				isVerified={isVerified}
			/>

			<Content
				title={title}
				duration={duration}
				stageCount={stageCount}
				result={result}
			/>

			<Wrapper
				id={id}
				title={title}
				author={author}
				authorID={authorID}
				duration={duration}
				date={date}
				result={result}
				userPhoto={userPhoto}
				stageCount={stageCount}
				isVisible={isWrapperVisible}
				onMenuCloseHandlee={onMenuCloseHandler}
				disabled={disabled || clickble}
				cardButtonActionsList={cardButtonActionsList}
				isVerified={isVerified}
			/>

		</MaterialCard>
	);
};

export default Card;
