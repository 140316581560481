import React from 'react';
import {useSelector} from 'react-redux';
import {Navigate} from 'react-router-dom';

import {RootState} from 'main/rootReducer';

interface IProtectedRoute {
	Page: React.FunctionComponent;
}

const ProtectedRoute = ({Page}: IProtectedRoute) => {
	const isAuth = useSelector((state: RootState) => state.user.isAuth);

	if (!isAuth) {
		return (<Navigate to="/login" replace />);
	}
	return <Page />;
};

export default ProtectedRoute;
