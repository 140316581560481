import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const AddIcon = (props: SvgIconProps) => (
	<SvgIcon viewBox="0 0 32 32" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16 27C22.0751 27 27 22.0751 27 16C27 9.92487 22.0751 5 16 5C9.92487 5 5 9.92487 5 16C5
			22.0751 9.92487 27 16 27ZM15 10C15 9.44772 15.4477 9 16 9C16.5523 9 17 9.44772 17 10V15H22C22.5523
			15 23 15.4477 23 16C23 16.5523 22.5523 17 22 17H17V22C17 22.5523 16.5523 23 16 23C15.4477 23 15 22.5523
			15 22V17H10C9.44772 17 9 16.5523 9 16C9 15.4477 9.44772 15 10 15H15V10Z"
		/>
	</SvgIcon>
);

export default AddIcon;
