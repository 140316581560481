import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import Link from '@mui/material/Link';
import {Stack, Typography} from '@mui/material';
import serviceLinks from 'library/utils/serviceLinks';

const Footer = () => (
	<Stack mt="auto" p={2} direction="column" alignItems="center" justifyContent="center" spacing={1}>
		<Stack direction="row" spacing={2}>
			{serviceLinks.map((link) => (
				<Link
					component={RouterLink}
					key={link.value}
					to={link.value}
					underline="none"
					sx={{
						color: (theme) => theme.palette.text.primary,
					}}
				>
					{link.name}

				</Link>
			))}
		</Stack>
		<Typography
			variant="caption"
			sx={{
				color: (theme) => theme.palette.text.secondary,
			}}
		>
			{`© ${new Date().getFullYear()}, WodaQota, Inc.`}

		</Typography>
	</Stack>

);

export default Footer;
