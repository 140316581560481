import React from 'react';
import { Stack, StackProps } from '@mui/material';
import CommentIcon from '@mui/icons-material/Comment';
// import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';

import LabelWithIcon from 'library/common/components/LabelWIthIcon';

interface IProps extends StackProps {
    number_of_comments?: number;
    number_of_activation?: number;
    number_of_favorites?: number;
}

const StatisticCounters: React.FunctionComponent<IProps> = ({
	number_of_comments, number_of_favorites, ...props
}) => (
	<Stack {...props}>
		<LabelWithIcon
			sx={{ color: (theme) => theme.palette.text.secondary }}
			tooltip="Likes"
			title={`${number_of_favorites ?? 0}`}
			icon={<ThumbUpIcon sx={{ color: (theme) => theme.palette.text.secondary }} />}
		/>

		<LabelWithIcon
			sx={{ color: (theme) => theme.palette.text.secondary }}
			tooltip="Number of comments"
			title={`${number_of_comments ?? 0}`}
			icon={<CommentIcon sx={{ color: (theme) => theme.palette.text.secondary }} />}
		/>

		{/* <LabelWithIcon
			sx={{ color: (theme) => theme.palette.text.secondary }}
			tooltip="Number of activations"
			title={`${number_of_activation ?? 0}`}
			icon={(
				<CheckCircleRoundedIcon sx={{
					color: (theme) => theme.palette.text.secondary,
				}}
				/>
			)}
		/> */}
	</Stack>
);

export default StatisticCounters;
