import { RootState } from 'main/rootReducer';
import { INotification } from 'library/common/types/notifications/notifications.d';

export const selectIsReactQueryDevtoolsOpen = (state: RootState): boolean => !!state.app.isReactQueryDevtoolsOpen;

export const selectNotifications = (state: RootState): INotification[] => state.app.notificationsList ?? [];

export const selectWodaStoryPlayingState = (state: RootState): boolean => state.app.isWodaStoryPlaying;

export const selectSetWodaStoryMuteState = (state: RootState): boolean => state.app.isWodaStoryMuted;

export const selectMainPageResourcesVisibility = (state: RootState): boolean => state.app.mainPageParams.isResourcesVisible;

export const selectMainPageCategoriesVisibility = (state: RootState): boolean => state.app.mainPageParams.isCategoryVisible;
