import React from 'react';
import moment from 'moment';
import {
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow, tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import getBonusProgramName from 'library/utils/getters/getBonusProgramName';
import useTransactions from 'library/common/data/wodabonuses/useTransactions';
import { slice } from 'lodash';

const StyledTableCell = styled(TableCell)(() => ({
	borderBottom: 'none',

	[`&.${tableCellClasses.head}`]: {
		fontWeight: 600,
		fontSize: 12,
		lineHeight: '16px',
		color: '#0C1A6780',
	},
	[`&.${tableCellClasses.body}`]: {
		color: '#0C1A67',
		fontSize: 14,
		background: 'linear-gradient(0deg, rgba(65, 154, 242, 0.2), rgba(65, 154, 242, 0.2)), #FFFFFF',
	},
}));

const StyledTableRow = styled(TableRow)(() => ({
	height: '64px',
}));

const Transactions: React.FunctionComponent = () => {
	const { data: transactions } = useTransactions();

	return (
		<TableContainer>
			<Table
				sx={{
					borderCollapse: 'separate',
					borderSpacing: '0px 16px',
					border: 'none',
					minWidth: 650,
				}}
				aria-label="transactions table"
			>
				<TableHead>
					<TableRow>
						<StyledTableCell sx={{ borderBottom: 'none' }} align="center">Time</StyledTableCell>
						<StyledTableCell align="center">Program</StyledTableCell>
						<StyledTableCell align="center">WodaBonus</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{transactions
						? slice(transactions, 0, 20).map((row) => (
							<StyledTableRow key={row.id}>
								<StyledTableCell align="center">
									{moment(row.time).format('HH:mm:ss')}
								</StyledTableCell>
								<StyledTableCell align="center">{getBonusProgramName(row.action)}</StyledTableCell>
								<StyledTableCell sx={{ fontWeight: 600 }} align="center">{row.amount}</StyledTableCell>
							</StyledTableRow>
						))
						: (
							<StyledTableRow>
								<TableCell colSpan={6} align="center">No records found</TableCell>
							</StyledTableRow>
						)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default Transactions;
