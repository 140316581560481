export const takeHead = (str: string): string => {
	if (str.length > 0) {
		if (str.length <= 20) {
			return str.slice(0, str.length);
		}

		return `${str.slice(0, 20)}...`;
	}
	return '';
};

export const plusMinusToArrows = (resValue: string) => `${resValue[0] === '+' ? '↑' : '↓'}${resValue.slice(1)}`;

export const takeNameToAvatar = (name?: string) => {
	if (name && name.length > 0) {
		return name[0].toUpperCase();
	}
	return 'A';
};

export const getDateString = (date: Date): string => {
	try {
		const dstr = date.toString();
		const dmy = dstr.substring(0, dstr.indexOf(' '));

		if (dmy) {
			const d = new Date(dmy);

			const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
			const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
			const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

			return `${mo} ${da}, ${ye}`;
		}

		return '';
	} catch (e) {
		return '';
	}
};

export const timeSince = (date: Date) => {
	const seconds = Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000);

	let interval = seconds / 31536000;

	if (interval > 1) {
		return `${Math.floor(interval)} years ago`;
	}
	interval = seconds / 2592000;
	if (interval > 1) {
		return `${Math.floor(interval)} months ago`;
	}
	interval = seconds / 86400;
	if (interval > 1) {
		return `${Math.floor(interval)} days ago`;
	}
	interval = seconds / 3600;
	if (interval > 1) {
		return `${Math.floor(interval)} hours ago`;
	}
	interval = seconds / 60;
	if (interval > 1) {
		return `${Math.floor(interval)} minutes ago`;
	}
	return `${Math.floor(seconds > 1 ? seconds : 1)} seconds ago`;
};
