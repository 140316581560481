import React, { useContext } from 'react';
import {
	Box, Button, Stack, Theme,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import { useNavigate } from 'react-router-dom';

import { IReelsCard } from 'library/common/types/cards/reels.d';
import Label from 'library/common/components/Label/Label';
import toCardPage from 'library/utils/routes/getCardPageUrl';
import { UUID } from 'library/common/types/app.d';

import ShareContext from 'library/common/context/ShareContext';
import Info from './Info';
import Media from './Media';

interface IProps {
    cardsState: IReelsCard;
	onLikeHandle(cardId: UUID, status?: boolean): void;
}

const WQMobileReel: React.FunctionComponent<IProps> = ({ cardsState, onLikeHandle }) => {
	const navigate = useNavigate();

	const shareContext = useContext(ShareContext);

	const shareCardHandle = (id: UUID) => shareContext?.openShareDialog({ url: id, target: 'card' });

	return (
		<Stack direction="column" sx={{ flexGrow: 1 }} spacing={1}>
			<Stack direction="row" sx={{ height: '1.6rem' }}>
				<Label title={cardsState.title} variant="h6" sx={{ fontWeight: 600, WebkitLineClamp: 1 }} />
			</Stack>

			<Info category={cardsState.category.title} result={cardsState.result.title} duration={cardsState.duration} steps={cardsState.steps} />
			<Media cardsState={cardsState} onLikeHandle={onLikeHandle} shareCardHandle={() => shareCardHandle(cardsState.id)} />

			<Box>
				<Stack direction="row" spacing={1} alignItems="center" sx={{ mt: '1vh' }}>
					<Button
					// eslint-disable-next-line no-alert
						onClick={() => alert('Coming soon')}
						variant="outlined"
						startIcon={<VideoCallIcon />}
						sx={{
							flexGrow: 1,
							textTransform: 'none',
							boxShadow: 'none',
							border: '1px solid #AB47BC4D',
							color: (theme) => theme.palette.secondary.main,

							'&:hover': {
								borderColor: '#AB47BC96',
							},
						}}
					>
						1:1 Consultation
					</Button>

					<Button
						variant="contained"
						startIcon={<InfoOutlinedIcon />}
						sx={(theme: Theme) => ({
							flexGrow: 1,
							textTransform: 'none',
							boxShadow: 'none',
							background: '#D9EBFC',
							color: theme.palette.secondary.main,

							'&:hover': {
								background: theme.palette.primary.light,
								color: theme.palette.common.white,
							},
						})}
						onClick={() => navigate(toCardPage(cardsState.id))}
					>
						Learn more
					</Button>
				</Stack>
			</Box>
		</Stack>
	);
};

export default WQMobileReel;
