import React from 'react';
import { isEmpty } from 'lodash';
import { Stack } from '@mui/material';

import ResourcesList from 'library/common/components/ResourcesList';
import isNonNullable from 'library/utils/isNonNullable';
import { IStep } from 'library/common/types/cards/cards.d';

import TextContent from './components/TextContent';
import ImageContent from './components/ImageContent';
import VideoContent from './components/VideoContent';
import StepHeader from './components/StepHeader';

interface IProps extends Omit<IStep, 'id' | 'client_info'> {
	title: string;
	cardTitle: string;
	isLast: boolean;
	isFirst: boolean;
	// isCardActivate: boolean;
	// isStepCompleted?: boolean;
	authorMode: boolean;
    isAuthorized: boolean;
	// toggleActivation(): void;
	deleteStepHandler(): Promise<void>;
	toggleEditMode(): void;
	toggleCreateMode(): void;
	moveStepUpHandler(): Promise<void>;
	moveStepDownHandler(): Promise<void>;
}

const Step: React.FunctionComponent<IProps> = ({
	title,
	cardTitle,
	isLast,
	isFirst,
	weight,
	text,
	draw,
	image,
	resources,
	video,
	// isCardActivate,
	// isStepCompleted,
	authorMode,
	isAuthorized,

	// toggleActivation,
	deleteStepHandler,
	toggleEditMode,
	toggleCreateMode,
	moveStepUpHandler,
	moveStepDownHandler,
}) => (
	<Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 3, md: 0 }}>
		<Stack spacing={2} sx={{ flex: 1 }}>

			<StepHeader
				title={title}
				isLast={isLast}
				isFirst={isFirst}
				authorMode={authorMode}
				isAuthorized={isAuthorized}
				deleteStepHandler={deleteStepHandler}
				toggleEditMode={toggleEditMode}
				toggleCreateMode={toggleCreateMode}
				moveStepUpHandler={moveStepUpHandler}
				moveStepDownHandler={moveStepDownHandler}
			/>

			{ isNonNullable(text) && (
				<TextContent data={text} />
			)}

			{ !isEmpty(resources) && (
				<ResourcesList stepResources={resources} />
			)}

			{ !isEmpty(draw) && (
				<ImageContent data={draw} alt={`${cardTitle} draw. Step ${weight + 1}`} sx={{ mt: 7 }} />
			)}

			{ !isEmpty(image) && (
				<ImageContent data={image} alt={`${cardTitle} image. Step ${weight + 1}`} sx={{ mt: 7 }} />
			)}

			{
				// пока так, после того как придумаем обработку для видео - переделать
				isNonNullable(video) && (
					<VideoContent data={video} />
				)
			}
		</Stack>
		{/* {
				isCardActivate && (
					<Box sx={{ ml: 1, mt: 'auto', width: { xs: '100%', md: '100px' } }}>
						<Button
							color="primary"
							sx={{ width: '100%' }}
							variant={isStepCompleted ? 'outlined' : 'contained'}
							onClick={toggleActivation}
						>
							{t(isStepCompleted ? 'cancel' : 'done')}
						</Button>
					</Box>
				)
			} */}
	</Stack>
);

export default Step;
