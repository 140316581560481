import React from 'react';
import {
	Box, SxProps, Theme, Typography,
} from '@mui/material';

import dictionary from 'pages/Wodabonus/dictionary';

interface IProps {
  sx?: SxProps<Theme>
}

const Description: React.FunctionComponent<IProps> = ({ sx }) => (
	<Box sx={sx}>
		<Typography
			variant="h5"
			sx={{ color: '#272342', pb: 2 }}
		>
			{dictionary.wodabonuses}
		</Typography>
		<Typography variant="body1" sx={{ color: ({ palette }) => palette.secondary.main, pb: 1.5 }}>
			{dictionary.description1}
		</Typography>
		<Typography variant="body1" sx={{ color: ({ palette }) => palette.secondary.main }}>
			{dictionary.description2}
		</Typography>
	</Box>
);

export default Description;
