import React from 'react';
import {IconButton} from '@mui/material';
import { withErrorBoundary } from 'react-error-boundary';
import {SnackbarKey, SnackbarProvider} from 'notistack';
import CloseIcon from '@mui/icons-material/Close';

import ThemeConfig from 'library/common/components/ThemeConfig/ThemeConfig';
import {SnackbarUtilsConfigurator} from 'library/utils/showSnackbarMessage';
import useShare from 'library/common/hook/useShare';
import ShareContext from 'library/common/context/ShareContext';
import CookieBanner from 'library/common/components/CookieBanner';

import useWebsocket from 'library/common/hook/useWebsocket';
import ReactQueryDevtools from 'library/common/components/ReactQueryDevtools';
import AppError from 'library/common/components/AppError';
import Routes from './Routes';

import styles from '../resources/styles/app.module.css';

function App() {
	const notistackRef:any = React.createRef();
	const onClickDismiss = (key: SnackbarKey) => () => {
		notistackRef.current.closeSnackbar(key);
	};

	const { ShareComponent, closeShareDialog, openShareDialog } = useShare();

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { socket } = useWebsocket();

	return (
		<ThemeConfig>
			<SnackbarProvider
				maxSnack={3}
				anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
				style={{marginBottom: 24}}
				ref={notistackRef}
				action={(key) => (
					<IconButton
						size="small"
						onClick={onClickDismiss(key)}
					>
						<CloseIcon />
					</IconButton>
				)}
			>
				<SnackbarUtilsConfigurator />
				<div className={styles.app}>
					<ShareContext.Provider value={{ closeShareDialog, openShareDialog }}>
						<Routes />
					</ShareContext.Provider>
					<CookieBanner />
					<ShareComponent />
				</div>
				{ process.env.REACT_APP_ENABLE_QUERY_DEVTOOLS && <ReactQueryDevtools /> }
			</SnackbarProvider>
		</ThemeConfig>
	);
}

export default withErrorBoundary(App, {
	FallbackComponent: AppError,
}) as () => React.ReactElement;
