import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as colors from '@mui/material/colors';
import {Avatar, Box, Skeleton} from '@mui/material';

import { takeColorByFirstLetter } from 'library/utils/myColors';
import { takeNameToAvatar } from 'library/utils/textGenerators';
import { selectIsAuthorized } from 'library/common/selectors/user.selector';
import AvatarInput from 'library/common/components/WQAvatarInput/Input';
import CircularProgressWithLabel from 'library/common/components/CircularProgressWithLabel';
import { avatarRadius } from 'library/common/components/WQAvatarInput/constants';

interface IProps {
    username?: string;
    logoURL?: string;
	selfPage?: boolean;
    isPending: boolean;
	progress?: number | null;
	setAvatarRequest?: (newAvatar: File[] | undefined) => void;
}

const ProfileAvatar: React.FunctionComponent<IProps> = ({
	isPending, username, logoURL, selfPage, setAvatarRequest, progress,
}) => {
	const avatarColor = useMemo(() => takeColorByFirstLetter(colors, username), [username]);
	const avatarTitle = useMemo(() => takeNameToAvatar(username), [username]);

	const isAuthorized = useSelector(selectIsAuthorized);

	if (isPending) {
		return (
			<Skeleton
				variant="circular"
				sx={{ ...avatarRadius() }}
			/>
		);
	}

	if (isAuthorized && selfPage && setAvatarRequest) {
		if (progress) {
			return (
				<Box sx={{
					...avatarRadius(),
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
				>
					<CircularProgressWithLabel
						sx={{
							color: (theme) => theme.palette.common.white,
						}}
						variant={progress === 100 ? 'indeterminate' : 'determinate'}
						value={progress}
					/>
				</Box>
			);
		}

		return (
			<AvatarInput image={logoURL} onChange={setAvatarRequest} />
		);
	}

	return (
		<Avatar
			variant="circular"
			src={logoURL}
			sx={{
				...avatarRadius(),
				fontSize: { xs: '4rem', md: '8rem'},
				bgcolor: logoURL ? 'transparent' : avatarColor,
			}}
		>
			{avatarTitle}
		</Avatar>
	);
};

export default ProfileAvatar;
