import React, { useState } from 'react';
import {
	IconButton, Stack, Tooltip, Typography,
} from '@mui/material';

import EditIcon from 'resources/icons/EditIcon';
import AddCardIcon from 'resources/icons/AddCardIcon';
import ArrowIcon from 'resources/icons/ArrowIcon';
import DeleteStepButton from './DeleteStepButton';

interface IProps {
    title: string;
    isLast: boolean;
	isFirst: boolean;
    authorMode: boolean;
    isAuthorized: boolean;
    deleteStepHandler(): Promise<void>;
	toggleEditMode(): void;
	toggleCreateMode(): void;
	moveStepUpHandler(): Promise<void>;
	moveStepDownHandler(): Promise<void>;
}

const StepHeader: React.FunctionComponent<IProps> = ({
	title,
	isFirst,
	isLast,
	authorMode,
	isAuthorized,
	deleteStepHandler,
	moveStepDownHandler,
	moveStepUpHandler,
	toggleCreateMode,
	toggleEditMode,
}) => {
	const [isDisabled, setDisabled] = useState(false);

	const moveStepDown = () => {
		setDisabled(true);
		moveStepDownHandler().then(() => setDisabled(false));
	};

	const moveStepUp = () => {
		setDisabled(true);
		moveStepUpHandler().then(() => setDisabled(false));
	};

	return (
		<Stack direction="row" justifyContent="space-between">
			<Typography variant="h5" sx={{ fontWeight: 800 }}>
				{title}
			</Typography>
			{

				authorMode && isAuthorized && (
					<Stack direction="row" spacing={1}>

						{!isFirst && (
							<Tooltip title="Take step up">
								<IconButton
									disabled={isDisabled}
									onClick={moveStepUp}
									aria-label="delete"
									size="small"
									sx={{
										color: (theme) => theme.palette.grey[400],
										'&:hover': {
											color: (theme) => theme.palette.grey[600],
										},
									}}
								>
									<ArrowIcon sx={{
										p: 0.5,
										rotate: '90deg',
									}}
									/>
								</IconButton>
							</Tooltip>
						)}

						{
							!isLast && (
								<Tooltip title="Take step down">
									<IconButton
										disabled={isDisabled}
										onClick={moveStepDown}
										aria-label="delete"
										size="small"
										sx={{
											color: (theme) => theme.palette.grey[400],
											'&:hover': {
												color: (theme) => theme.palette.grey[600],
											},
										}}
									>
										<ArrowIcon sx={{
											p: 0.5,
											rotate: '270deg',
										}}
										/>
									</IconButton>
								</Tooltip>
							)
						}

						<Tooltip title="Edit step">
							<IconButton
								disabled={isDisabled}
								size="small"
								aria-label="edit step"
								sx={{
									color: ({ palette }) => palette.text.secondary,
								}}
								onClick={toggleEditMode}
							>
								<EditIcon fontSize="inherit" />
							</IconButton>
						</Tooltip>

						<Tooltip title="Add next step">
							<IconButton
								disabled={isDisabled}
								size="small"
								aria-label="add next step"
								sx={{
									color: ({ palette }) => palette.text.secondary,
								}}
								onClick={toggleCreateMode}
							>
								<AddCardIcon color="inherit" />
							</IconButton>
						</Tooltip>

						<DeleteStepButton title={title} deleteStepHandler={deleteStepHandler} />
					</Stack>
				)
			}
		</Stack>
	);
};

export default StepHeader;
