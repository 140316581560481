import i18n from 'library/utils/language/conf';

export const declOfNum = (number: number, titles: string[]) => {
	const cases = [2, 0, 1, 1, 1, 2];
	return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
};

export const getDuration = (dur: number, shortLabel?: boolean) => {
	const getDurationLabel = (duration: number, metrics: string) => {
		const truncateDuration = Math.trunc(duration);
		const metric = declOfNum(truncateDuration, JSON.parse(i18n.t(metrics)));

		let str = `${truncateDuration}${duration % 1 === 0 ? '' : '+'}`;

		if (shortLabel) {
			str += metric[0];
		} else {
			str += ` ${metric}`;
		}

		return str;
	};

	const days = dur / 86400;
	if (days >= 1) {
		return getDurationLabel(days, 'daysForm');
	}

	const hour = (dur % 86400) / 3600;
	if (hour >= 1) return getDurationLabel(hour, 'hourForm');

	const min = Math.trunc(dur / 60);
	if (min >= 1) return getDurationLabel(min, 'minForm');

	return `${dur} ${declOfNum(dur, JSON.parse(i18n.t('secForm')))}`;
};

export const getFullDuration = (dur: number) => {
	const days = Math.trunc(dur / 86400);
	const hour = Math.trunc((dur % 86400) / 3600);
	const min = Math.trunc((dur % 3600) / 60);
	const sec = dur % 60;

	const duration = [];
	if (days > 0 && duration.length < 2) duration.push(`${days} ${declOfNum(days, JSON.parse(i18n.t('daysForm')))}`);
	if (hour > 0 && duration.length < 2) duration.push(` ${hour} ${declOfNum(hour, JSON.parse(i18n.t('hourForm')))}`);
	if (min > 0 && duration.length < 2) duration.push(`${min} ${declOfNum(min, JSON.parse(i18n.t('minForm')))}`);
	if ((sec > 0 && duration.length < 2) || ((sec === 0) && duration.length === 0)) duration.push(` ${sec} ${declOfNum(sec, JSON.parse(i18n.t('secForm')))}`);

	return duration?.join(' ')?.trim();
};

export const getFullTimeObj = (dur: number): {days: number, hours: number, minutes: number, seconds: number} => {
	if (dur === 0) {
		return {
			days: 0,
			hours: 0,
			minutes: 0,
			seconds: 0,
		};
	}
	const days = Math.trunc(dur / 86400);
	const hours = Math.trunc((dur % 86400) / 3600);
	const minutes = Math.trunc((dur % 3600) / 60);
	const seconds = dur % 60;

	return {
		days, hours, minutes, seconds,
	};
};
