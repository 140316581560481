import {
	Ref, RefCallback, RefObject, ForwardedRef, MutableRefObject,
} from 'react';

const isRefCallback = <T>(ref: Ref<T>): ref is RefCallback<T> => typeof ref === 'function';

export const isRefObject = <T>(ref: Ref<T>): ref is RefObject<T> => Boolean(ref && typeof ref === 'object');

export const updateRef = <T>(
	ref: ForwardedRef<T> | RefCallback<T> | MutableRefObject<T>,
	value: T,
): void => {
	if (!ref) {
		return;
	}

	if (isRefCallback(ref)) {
		ref(value);
	} else {
		// eslint-disable-next-line no-param-reassign
		ref.current = value;
	}
};
