import {
	useQuery,
	QueryFunctionContext,
	UseQueryResult,
	UseQueryOptions,
} from '@tanstack/react-query';

import { UUID } from 'library/common/types/app.d';
import cardsService from 'library/common/services/cards.service';
import { CardApiData } from 'library/common/types/cards/request.d';
import { CommentResponse, ICommentEntity } from 'library/common/types/cards/comments.d';

import { keyBy } from 'lodash';
import commentsQueryKeys from './queryKeys';

const commentsQueryFunction = async ({ queryKey }: QueryFunctionContext<ReturnType<typeof commentsQueryKeys.byCardId>>): Promise<CommentResponse> => {
	const [, , cardId] = queryKey;

	const res = await cardsService
		.getComments<CardApiData<ICommentEntity[]>>(cardId as string);
	return keyBy(res.data.payload, 'comment.id');
};

const useComments = (cardId: UUID, options?: UseQueryOptions<CommentResponse>): UseQueryResult<CommentResponse> => useQuery(
	commentsQueryKeys.byCardId(cardId), commentsQueryFunction, { ...options },
);

export default useComments;
