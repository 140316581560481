import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
	Box,
	Card,
	Stack,
	Divider,
} from '@mui/material';

import {RootState} from 'main/rootReducer';
import KratosForm from 'library/common/components/KratosForm';

import WQAvatarInput from 'library/common/components/WQAvatarInput';
import {applySettings} from 'library/common/actions/app.action';
import CircularProgressWithLabel from 'library/common/components/CircularProgressWithLabel';
import useProfile from 'library/common/hook/useProfile';

const General = () => {
	const dispatch = useDispatch();

	const profileFields = useSelector((state: RootState) => state.app.settings?.profileSettings);
	const identity = useSelector((state: RootState) => state.app.settings?.identity);

	const {
		isPending,
		profileData: {
			logoURL,
		},
		progress,
		setAvatarRequest,
	} = useProfile();

	// TODO занести в хук
	const submitForm = (data: object) => {
		dispatch(applySettings.request({methodGroup: 'profile', data: {traits: {...data}}}));
	};

	return (
		<Stack
			direction={{ xs: 'column', md: 'row' }}
			divider={<Divider orientation="vertical" flexItem />}
			spacing={{ xs: 2, md: 4 }}
			sx={{height: '100%'}}
		>
			<Card sx={{p: '80px 24px'}}>
				{
					progress ? (
						<Box sx={{
							width: '266px',
							height: '380px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
						>
							<CircularProgressWithLabel
								color="primary"
								variant={progress === 100 ? 'indeterminate' : 'determinate'}
								value={progress}
							/>
						</Box>
					) : (
						<WQAvatarInput image={logoURL} setImage={setAvatarRequest} isPending={isPending} withInfoLabel />
					)
				}
			</Card>
			<Card sx={{p: 3, flexGrow: 1}}>
				<KratosForm fields={profileFields} identity={identity} submitForm={submitForm} />
			</Card>
		</Stack>
	);
};

export default General;
