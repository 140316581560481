import React from 'react';
import { AppBar, Button, Stack } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import Title from 'library/common/components/Title/Title';
import AccountPopover from 'library/common/components/Popovers/AccountPopover/AccountPopover';
import { IUserData } from 'library/common/types/user.d';

import NavbarLink from '../../NavbarLink';
import sections from '../sections';

interface IProps {
    isAuth?: boolean;
    userData: IUserData;
	onSignIn: () => void;
}

const DesktopHeader: React.FunctionComponent<IProps> = ({
	isAuth, userData, onSignIn,
}) => (
	<AppBar sx={{
		px: {
			xs: 2, md: 3, lg: 12,
		},
		height: '88px',
		background: (theme) => `${theme.palette.common.white}E0`,
		color: (theme) => theme.palette.secondary.light,
		display: 'flex',
		justifyContent: 'center',
	}}
	>
		<Stack direction="row" justifyContent="space-between">
			<Title />

			<Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
				{
					sections.map((section) => (
						<NavbarLink key={section.id} name={section.name} id={section.id} />
					))
				}
			</Stack>
			{
				isAuth ? (
					<AccountPopover
						email={userData.email}
						username={userData.username}
						backgroundColor={userData.backgroundColor}
						logoURL={userData.logoURL}
					/>
				) : (
					<Button
						variant="outlined"
						onClick={onSignIn}
						endIcon={<ExitToAppIcon />}
					>
						Sign In
					</Button>
				)
			}
		</Stack>
	</AppBar>
);

export default DesktopHeader;
