import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Stack} from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import PublicIcon from '@mui/icons-material/Public';

import {RootState} from 'main/rootReducer';
import {UiNode} from '@ory/kratos-client/api';

import settingsService from 'library/common/services/settings.service';
import {initSettingsPage} from 'library/common/actions/app.action';
import {kratosErrorHandler} from 'library/utils/errorsHandler';
import {showSnackbarMessage} from 'library/utils/showSnackbarMessage';

const getIcon = (value: string) => {
	switch (value) {
	case 'facebook': return <FacebookIcon />;

	case 'google': return <GoogleIcon />;

	default: return <PublicIcon />;
	}
};

const SocialLinks = () => {
	const dispatch = useDispatch();
	const links = useSelector((state: RootState) => state.app.settings?.linksSettings);
	const url = useSelector((state: RootState) => state.app?.settings?.actionUrl);
	const csrfToken: string | undefined = useSelector((state: RootState) => state.app.settings?.csrfToken);

	if (links === undefined) {
		return (
			<div />
		);
	}

	return (
		<Stack spacing={2} width={200}>
			{
				links
					.filter((link: UiNode) => 'value' in link.attributes && 'name' in link.attributes)
					.map((link: UiNode) => (
						<Button
							key={link.meta.label?.text}
							variant="contained"
							color="primary"
							disabled={'disabled' in link.attributes ? link.attributes.disabled : false}
							startIcon={'value' in link.attributes ? getIcon(link.attributes.value) : undefined}
							onClick={() => {
								const {name, value} = link.attributes as any;

								settingsService.templateSettingsRequest(
									{
										method: 'POST',
										url,
										data: {
											csrf_token: csrfToken,
											[name]: value,
										},
									},
								).then((res) => {
									if (res && res.data) {
										dispatch(initSettingsPage.request());
									}
								})
									.catch((error) => {
										if (
											error?.response?.status === 422
										&& error?.response?.data?.redirect_browser_to.length > 0) {
											window.location.replace(error.response.data.redirect_browser_to);
										} else {
											showSnackbarMessage(
												`${kratosErrorHandler(error, 'Error')}`,
												'error',
												5000,
											);
										}
									});
							}}
						>
							{link.meta.label?.text}
						</Button>
					))
			}
		</Stack>
	);
};

export default SocialLinks;
