import { QueryKey } from '@tanstack/react-query';

import { UUID } from 'library/common/types/app.d';

const commentsQueryKeys = {
	base: () => ['comments'] as QueryKey,
	byCardId: (id: UUID) => [...commentsQueryKeys.base(), 'byCardId', id] as QueryKey,
	byCommentId: (id: UUID) => [...commentsQueryKeys.base(), 'byCommentId', id] as QueryKey,
};

export default commentsQueryKeys;
