import React from 'react';
import {getCountOfSkeletons} from 'library/utils/itemsCountCalc';

import Skeleton from '../Card/Skeleton';

import styles from './styles.module.css';

const SkeletonGrid = () => (
	<div className={styles.container}>
		<div className={styles.list}>
			{
				Array.from({ length: getCountOfSkeletons()}, (_, i) => i).map((value) => (
					<Skeleton key={value} />
				))
			}
		</div>
	</div>
);

export default SkeletonGrid;
