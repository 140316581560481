import React from 'react';
import { Chip, Stack, Typography } from '@mui/material';
import { ICategory } from 'library/common/types/cards/cards.d';
import { useSelector } from 'react-redux';
import { selectMainPageCategoriesVisibility } from 'library/common/selectors/app.selector';

interface IProps {
    title: string;
	category: ICategory;
}

const Header: React.FunctionComponent<IProps> = ({ title, category }) => {
	const isCategoryVisible = useSelector(selectMainPageCategoriesVisibility);

	return (
		<Stack
			justifyContent="space-between"
			alignItems="center"
			direction="row"
		>
			<Typography
				ml={0.5}
				fontWeight={600}
				variant="h6"
			>
				{title}
			</Typography>

			{isCategoryVisible && <Chip sx={{ backgroundColor: (theme) => theme.palette.grey[200] }} label={category.title} />}

			{/* <Box sx={{ position: 'relative', zIndex: 0 }}>
			<Button
				sx={{
					px: 6,
					border: '1px solid transparent',
					backgroundColor: 'white',
					position: 'relative',
					color: (theme) => theme.palette.text.secondary,
					fontWeight: 700,
					backgroundClip: 'padding-box',
					textTransform: 'inherit',

					'&:hover': {
						color: (theme) => theme.palette.common.white,
					},

					'&:after': {
						position: 'absolute',
						top: -1,
						left: -1,
						right: -1,
						bottom: -1,
						background: (theme) => (theme.palette as any).gradients.info,
						content: '""',
						zIndex: -1,
						borderRadius: '4px',
					},
				}}
			>
				ACTIVATE
			</Button>
		</Box> */}
		</Stack>
	);
};

export default Header;
