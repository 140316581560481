import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Container, Typography} from '@mui/material';
import Link from '@mui/material/Link';

import Template from './Template';

const PrivacyNotice = () => (
	<Template>
		<Container sx={{pt: 2, pb: 2}}>
			<Typography variant="h3" sx={{fontWeight: 700}}>
				Data Policy
			</Typography>

			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				This policy describes the information we process to support WodaQota Service.
			</Typography>

			<Typography variant="h5" sx={{m: '0.8rem 0', fontWeight: 500}}>
				I. What kinds of information do we collect?
			</Typography>

			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				To provide the Service, we must process information about you.
				The types of information we collect depend on how you use our Service.
				You can learn how to access and delete information we collect by visiting the WodaQota Settings.
			</Typography>

			<Typography variant="h6" sx={{mt: '0.8rem 0'}}>
				Things you and others do and provide.
			</Typography>

			<ul style={{margin: 0}}>
				<li>
					<Typography component="p" sx={{mb: '0.8rem'}}>
						<Typography component="span" sx={{fontWeight: 500}}>
							Information and content you provide.
						</Typography>
						&nbsp;
						<Typography component="span" sx={{color: (theme) => theme.palette.grey['700']}}>
							We collect the content, communications and other information you provide when
							you use our Service,
							including when you sign up for an account, create or share content,
							and communicate with others.
							This can include information in or about the content you provide (like metadata),
							such as the location of a photo or the date a file was created.
							It can also include what you see through features we provide, such as our camera.
						</Typography>
					</Typography>
				</li>
				<li>
					<Typography component="p" sx={{m: '0.8rem 0'}}>
						<Typography component="span" sx={{fontWeight: 500}}>
							Networks and connections.
						</Typography>
						&nbsp;
						<Typography component="span" sx={{color: (theme) => theme.palette.grey['700']}}>
							We collect information about the people, Pages, accounts you are connected to and how
							you interact with them across our Service. We also collect contact information if you
							choose to upload, sync or import it from a device (such as an address book or call log
							or SMS log history), which we use for things like helping you and others find people
							you may know and for the other purposes listed below.
						</Typography>
					</Typography>
				</li>
				<li>
					<Typography component="p" sx={{m: '0.8rem 0'}}>
						<Typography component="span" sx={{fontWeight: 500}}>
							Your usage.
						</Typography>
						&nbsp;
						<Typography component="span" sx={{color: (theme) => theme.palette.grey['700']}}>
							We collect information about how you use our Service, such as the types of content you view
							or engage with; the features you use; the actions you take; the people or accounts you
							interact with; and the time, frequency and duration of your activities.
						</Typography>
					</Typography>
				</li>
				<li>
					<Typography component="p" sx={{m: '0.8rem 0'}}>
						<Typography component="span" sx={{fontWeight: 500}}>
							Things others do and information they provide about you.
						</Typography>
						&nbsp;
						<Typography component="span" sx={{color: (theme) => theme.palette.grey['700']}}>
							We also receive and analyze content, communications and information that other people
							provide when they use our Service. This can include information about you, such as
							when others share or comment on a photo of you, send a message to you, or upload,
							sync or import your contact information.
						</Typography>
					</Typography>
				</li>
			</ul>

			<Typography variant="h6" sx={{mt: '0.8rem 0'}}>
				Device Information
			</Typography>
			<Typography variant="body1" sx={{color: (theme) => theme.palette.grey['700']}}>
				As described below, we collect information from and about the computers, phones, connected TVs and
				other web-connected devices you use that integrate with our Service, and we combine this information
				across different devices you use. For example, we use information collected about your use of our
				Service on your phone to better personalize the content (including ads) or features you see when you
				use our Products on another device, such as your laptop or tablet, or to measure whether you took an
				action in response to an ad we showed you on your phone on a different device. Information we obtain
				from these devices includes:
			</Typography>

			<ul style={{margin: 0}}>
				<li>
					<Typography component="p" sx={{mb: '0.8rem'}}>
						<Typography component="span" sx={{fontWeight: 500}}>
							Device attributes:
						</Typography>
						&nbsp;
						<Typography component="span" sx={{color: (theme) => theme.palette.grey['700']}}>
							information such as the operating system, hardware and software versions, battery level,
							signal strength, available storage space, browser type, app and file names and types,
							and plugins.
						</Typography>
					</Typography>
				</li>
				<li>
					<Typography component="p" sx={{m: '0.8rem'}}>
						<Typography component="span" sx={{fontWeight: 500}}>
							Device operations:
						</Typography>
						&nbsp;
						<Typography component="span" sx={{color: (theme) => theme.palette.grey['700']}}>
							information about operations and behaviors performed on the device, such as whether a
							window is foregrounded or backgrounded, or mouse movements (which can help distinguish
							humans from bots).
						</Typography>
					</Typography>
				</li>
				<li>
					<Typography component="p" sx={{m: '0.8rem'}}>
						<Typography component="span" sx={{fontWeight: 500}}>
							Identifiers:
						</Typography>
						&nbsp;
						<Typography component="span" sx={{color: (theme) => theme.palette.grey['700']}}>
							unique identifiers, device IDs, and other identifiers, such as from games, apps or
							accounts you use, and Family Device IDs.
						</Typography>
					</Typography>
				</li>
				<li>
					<Typography component="p" sx={{m: '0.8rem'}}>
						<Typography component="span" sx={{fontWeight: 500}}>
							Device signals:
						</Typography>
						&nbsp;
						<Typography component="span" sx={{color: (theme) => theme.palette.grey['700']}}>
							Bluetooth signals, and information about nearby Wi-Fi access points, beacons,
							and cell towers.
						</Typography>
					</Typography>
				</li>
				<li>
					<Typography component="p" sx={{m: '0.8rem'}}>
						<Typography component="span" sx={{fontWeight: 500}}>
							Data from device settings:
						</Typography>
						&nbsp;
						<Typography component="span" sx={{color: (theme) => theme.palette.grey['700']}}>
							information you allow us to receive through device settings you turn on, such
							as access to your GPS location, camera or photos.
						</Typography>
					</Typography>
				</li>
				<li>
					<Typography component="p" sx={{m: '0.8rem'}}>
						<Typography component="span" sx={{fontWeight: 500}}>
							Network and connections:
						</Typography>
						&nbsp;
						<Typography component="span" sx={{color: (theme) => theme.palette.grey['700']}}>
							information such as the name of your mobile operator or ISP, language, time zone,
							mobile phone number, IP address, connection speed and, in some cases, information
							about other devices that are nearby or on your network, so we can do things like
							help you stream a video from your phone to your TV.
						</Typography>
					</Typography>
				</li>
				<li>
					<Typography component="p" sx={{m: '0.8rem'}}>
						<Typography component="span" sx={{fontWeight: 500}}>
							Cookie data:
						</Typography>
						&nbsp;
						<Typography component="span" sx={{color: (theme) => theme.palette.grey['700']}}>
							data from cookies stored on your device, including cookie IDs and settings.
						</Typography>
					</Typography>
				</li>
			</ul>

			<Typography variant="h5" sx={{m: '0.8rem 0', fontWeight: 500}}>
				II. How do we use this information?
			</Typography>
			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				We use the information we have (subject to choices you make) as described below and to provide
				and support the Service described in the&nbsp;
				<Link component={RouterLink} to="/terms">WodaQota Terms</Link>
				. Here&apos;s how:
			</Typography>

			<Typography variant="h6" sx={{mt: '0.8rem 0'}}>
				Provide, personalize and improve our Service.
			</Typography>
			<Typography variant="body1" sx={{mb: '0.8rem', color: (theme) => theme.palette.grey['700']}}>
				We use the information we have to deliver our Service, including to personalize features and
				content (including your News Feed and ads) on and off. To create personalized Service that are
				unique and relevant to you, we use your connections, preferences, interests and activities
				based on the data we collect and learn from you and others (including any data with special
				protections you choose to provide); how you use and interact with our Service;&nbsp;and the people,
				places, or things you&apos;re connected to and interested in on and off our Service; you can also
				learn more about how we choose the ads that you see.
			</Typography>
			<ul style={{margin: 0}}>
				<li>
					<Typography component="p" sx={{mb: '0.8rem'}}>
						<Typography component="span" sx={{fontWeight: 500}}>
							Location-related information:
						</Typography>
						&nbsp;
						<Typography component="span" sx={{color: (theme) => theme.palette.grey['700']}}>
							We use location-related information-such as your current location, where you live,
							the places you like to go, and the businesses and people you&apos;re near-to provide,
							personalize and improve our Service, including ads, for you and others. Location-related
							information can be based on things like precise device location (if you&apos;ve allowed
							us to collect it), IP addresses, and information from your and others&apos; use of
							Service (such as check-ins or events you attend).
						</Typography>
					</Typography>
				</li>
				<li>
					<Typography component="p" sx={{m: '0.8rem'}}>
						<Typography component="span" sx={{fontWeight: 500}}>
							Product research and development:
						</Typography>
						&nbsp;
						<Typography component="span" sx={{color: (theme) => theme.palette.grey['700']}}>
							We use the information we have to develop, test and improve our Service, including
							by conducting surveys and research, and testing and troubleshooting new products
							and features.
						</Typography>
					</Typography>
				</li>
				<li>
					<Typography component="p" sx={{m: '0.8rem'}}>
						<Typography component="span" sx={{fontWeight: 500}}>
							Ads and other sponsored content:
						</Typography>
						&nbsp;
						<Typography component="span" sx={{color: (theme) => theme.palette.grey['700']}}>
							We use the information we have about you-including information about your interests,
							actions and connections-to select and personalize ads, offers and other sponsored content
							that we show you. Learn more about how we select and personalize ads, and your choices over
							the data we use to select ads and other sponsored content for you in the WodaQota Settings.
						</Typography>
					</Typography>
				</li>
			</ul>

			<Typography variant="h6" sx={{mt: '0.8rem'}}>
				Provide measurement, analytics, and other business services.
			</Typography>
			<Typography variant="body1" sx={{mb: '0.8rem', color: (theme) => theme.palette.grey['700']}}>
				We use the information we have (including your activity off our Service, such as the websites
				you visit and ads you see) to help advertisers and other partners measure the effectiveness and
				distribution of their ads and services, and understand the types of people who use their services
				and how people interact with their websites, apps, and services. Learn how we share information
				with these partners.
			</Typography>

			<Typography variant="h6" sx={{mt: '0.8rem'}}>
				Promote safety, integrity and security.
			</Typography>
			<Typography variant="body1" sx={{mb: '0.8rem', color: (theme) => theme.palette.grey['700']}}>
				We use the information we have to verify accounts and activity, combat harmful conduct, detect
				and prevent spam and other bad experiences, maintain the integrity of our Service, and promote
				safety and security on and off of Service.
			</Typography>

			<Typography variant="h6" sx={{mt: '0.8rem'}}>
				Communicate with you.
			</Typography>
			<Typography variant="body1" sx={{mb: '0.8rem', color: (theme) => theme.palette.grey['700']}}>
				We use the information we have to send you marketing communications, communicate with you about
				our Service, and let you know about our policies and terms. We also use your information to respond
				to you when you contact us.
			</Typography>

			<Typography variant="h6" sx={{mt: '0.8rem'}}>
				Research and innovate for social good.
			</Typography>
			<Typography variant="body1" sx={{mb: '0.8rem', color: (theme) => theme.palette.grey['700']}}>
				We use the information we have (including from research partners we collaborate with) to
				conduct and support research and innovation on topics of general social welfare, technological
				advancement, public interest, health and well-being. For example,we analyze information we have
				about migration patterns during crises to aid relief efforts.
			</Typography>

			<Typography variant="h5" sx={{m: '0.8rem 0', fontWeight: 500}}>
				III. How is this information shared?
			</Typography>
			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				Your information is shared with others in the following ways:
			</Typography>
			<Typography variant="h6" sx={{m: '0.8rem 0'}}>
				Sharing on WodaQota Service
			</Typography>

			<Typography variant="body1" sx={{mt: '0.8rem', fontWeight: 500}}>
				People and accounts you share and communicate with
			</Typography>
			<Typography variant="body1" sx={{color: (theme) => theme.palette.grey['700']}}>
				When you share and communicate using our Service, you choose the audience for what you share.
				For example, when you post card on WodaQota, you select the audience for the post, such as a group,
				all of your friends, the public, or a customized list of people. Your network can also see actions
				you have taken on our Service, including engagement with ads and sponsored content.
			</Typography>
			<Typography variant="body1" sx={{mb: '0.8rem', color: (theme) => theme.palette.grey['700']}}>
				Public information can be seen by anyone, on or off our Service, including if they don&apos;t have
				an account. This includes your WodaQota username; any information you share with a public audience;
				information in your public profile on WodaQota; and content you share on a WodaQota Page. You,
				other people using our Service, and we can provide access to or send public information to anyone
				on or off our Service, in search results, or through tools and APIs. Public information can also be
				seen, accessed, reshared or downloaded through third-party services such as search engines, APIs,
				and offline media such as TV, and by apps, websites and other services that integrate with our Service.
			</Typography>

			<Typography variant="body1" sx={{mt: '0.8rem', fontWeight: 500}}>
				Content others share or reshare about you
			</Typography>
			<Typography variant="body1" sx={{color: (theme) => theme.palette.grey['700']}}>
				You should consider who you choose to share with, because people who can see your
				activity on our Service can choose to share it with others on and off our Service,
				including people and businesses outside the audience you shared with. For example,
				when you share a post or send a message to specific friends or accounts, they can download,
				screenshot, or reshare that content to others across or off our Service, in person or in virtual
				reality experiences. Also, when you comment on someone else&apos;s card or react to their content,
				your comment or reaction is visible to anyone who can see the other person&apos;s content,
				and that person can change the audience later.People can also use our Service to create and
				share content about you with the audience they choose. For example, people can share a photo
				of you in a Story, mention or tag you at a location in a post, or share information about you
				in their posts or messages. If you are uncomfortable with what others have shared about you
				on our Service.
			</Typography>

			<Typography variant="body1" sx={{mt: '0.8rem', fontWeight: 500}}>
				Information about your active status or presence on our Service.
			</Typography>
			<Typography variant="body1" sx={{color: (theme) => theme.palette.grey['700']}}>
				People in your networks can see signals telling them whether you are active on our Service,
				including whether you are currently active on WodaQota, or when you last used our Service.
			</Typography>

			<Typography variant="body1" sx={{mt: '0.8rem', fontWeight: 500}}>
				New owner.
			</Typography>
			<Typography variant="body1" sx={{mb: '0.8rem', color: (theme) => theme.palette.grey['700']}}>
				If the ownership or control of all or part of our Service or their assets changes,
				we may transfer your information to the new owner.
			</Typography>

			<Typography variant="h5" sx={{m: '0.8rem 0', fontWeight: 500}}>
				IV. How can I manage or delete information about me?
			</Typography>
			<Typography variant="body1" sx={{mt: '0.8rem', color: (theme) => theme.palette.grey['700']}}>
				We provide you with the ability to access, rectify, port and erase your data. We store data until it is
				no longer necessary to provide our Service, or until your account is deleted - whichever comes first.
				This is a case-by-case determination that depends on things like the nature of the data,
				why it is collected and processed, and relevant legal or operational retention needs.
			</Typography>
			<Typography variant="body1" sx={{mb: '0.8rem', color: (theme) => theme.palette.grey['700']}}>
				When you send request to delete your account, we delete things you have posted, such as your
				photos and status updates, and you won&apos;t be able to recover that information later. Information
				that others have shared about you isn&apos;t part of your account and won&apos;t be deleted. To delete
				your account at any time, please send an email with the subject of the letter «account deletion»
				at
				&nbsp;
				<Link href="mailto:support@wodaqota.com">support@wodaqota.com</Link>
				&nbsp;
				Point out in the email your login and email,
				using during Service usage. During three working days – your account will be deleted.
			</Typography>

			<Typography variant="h5" sx={{m: '0.8rem 0', fontWeight: 500}}>
				V. How do we respond to legal requests or prevent harm?
			</Typography>
			<Typography variant="body1" sx={{mt: '0.8rem', color: (theme) => theme.palette.grey['700']}}>
				We access, preserve and share your information with regulators, law enforcement or others:
			</Typography>
			<ul>
				<li>
					<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
						In response to a legal request (like a search warrant, court order or subpoena) if we have
						a good faith belief that the law requires us to do so. This may include responding to legal
						requests from jurisdictions outside of the United States when we have a good-faith belief that
						the response is required by law in that jurisdiction, affects users in that jurisdiction, and
						is consistent with internationally recognized standards.
					</Typography>
				</li>
				<li>
					<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
						When we have a good-faith belief it is necessary to: detect, prevent and address fraud,
						unauthorized use of the Service, violations of our terms or policies, or other harmful or
						illegal activity;&apos;to protect ourselves (including our rights, property or Services),
						you or others, including as part of investigations or regulatory inquiries;&apos;or
						to prevent death or imminent bodily harm. For example, if relevant, we provide information
						to and receive information from third-party partners about the reliability of your account
						to prevent fraud, abuse and other harmful activity on and off our Service.
					</Typography>
				</li>
			</ul>
			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				Information we receive about you can be accessed and preserved for an extended period when it
				is the subject of a legal request or obligation, governmental investigation, or investigations
				of possible violations of our terms or policies, or otherwise to prevent harm. We also retain
				information from accounts disabled for terms violations for at least a year to prevent repeat
				abuse or other term violations.
			</Typography>

			<Typography variant="h5" sx={{m: '0.8rem 0', fontWeight: 500}}>
				VI. How do we operate and transfer data as part of our service?
			</Typography>
			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				We share information globally, both internally within the WodaQota, and externally with our
				partners and with those you connect and share with around the world in accordance with this
				policy. Your information may, for example, be transferred or transmitted to, or stored and
				processed in the United States or other countries outside of where you live for the purposes
				as described in this policy. These data transfers are necessary to provide the services set forth
				in the&nbsp;
				<Link component={RouterLink} to="/terms">WodaQota Terms</Link>
				&nbsp;and to globally operate and provide our Service to you. We utilize standard
				contract clauses, rely on the European Commission&apos;s adequacy decisions about certain countries, as
				applicable, and obtain your consent for these data transfers to the United States and other countries.
			</Typography>

			<Typography variant="h5" sx={{m: '0.8rem 0', fontWeight: 500}}>
				VII. How will we notify you of changes to this policy?
			</Typography>
			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				We&apos;ll notify you before we make changes to this policy and give you the opportunity to
				review the revised policy before you choose to continue using our Service.
			</Typography>

			<Typography variant="h5" sx={{m: '0.8rem 0', fontWeight: 500}}>
				VIII. How to contact WodaQota with questions
			</Typography>
			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				If you have questions about this policy, you can contact us as described below.
				You can contact us by mail at:
			</Typography>

			<Typography variant="body1" sx={{mt: '0.8rem', color: (theme) => theme.palette.grey['700']}}>
				WodaQota, Inc.
			</Typography>
			<Typography variant="body1" sx={{color: (theme) => theme.palette.grey['700']}}>
				919 North Market Street
			</Typography>
			<Typography variant="body1" sx={{color: (theme) => theme.palette.grey['700']}}>
				Suite 950 in the City of Wilmington
			</Typography>
			<Typography variant="body1" sx={{color: (theme) => theme.palette.grey['700']}}>
				County of New Castle, DE 19801
			</Typography>
			<Typography variant="body1" sx={{color: (theme) => theme.palette.grey['700']}}>
				Date of Revision: October 20, 2021
			</Typography>
		</Container>
	</Template>
);

export default PrivacyNotice;
