import React, {ReactElement} from 'react';
import Typography, { TypographyPropsVariantOverrides } from '@mui/material/Typography';
import {Box, Tooltip} from '@mui/material';
import {SxProps} from '@mui/system';
import { OverridableStringUnion } from '@mui/types';
import {Theme} from '@mui/material/styles';
import { Variant } from '@mui/material/styles/createTypography';

interface ILabelWithIcon {
	title: string | number;
	tooltip: string;
	icon: ReactElement;
	sx?: SxProps<Theme>;
	variant?: OverridableStringUnion<'inherit' | Variant, TypographyPropsVariantOverrides>;
	withHidden?: boolean;

}

const LabelWithIcon = ({
	icon, title, tooltip, sx, variant = 'caption', withHidden,
}: ILabelWithIcon) => (
	<Tooltip title={tooltip}>
		<Box sx={{
			...(withHidden && { overflow: 'hidden' }),
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-start',
		}}
		>
			{icon}
		&nbsp;
			<Typography
				variant={variant}
				noWrap
				sx={{...sx}}
			>
				{title}
			</Typography>
		</Box>
	</Tooltip>
);

export default LabelWithIcon;
