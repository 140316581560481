import React from 'react';
import {
	Box, Stack, SxProps, Theme, Typography,
} from '@mui/material';
import { AxisOptions, Chart } from 'react-charts';

import { ITransition, IVault } from 'library/common/types/wodaprograms.d';
import EmissionTimer from './EmissionTimer';

interface IProps {
	sx?: SxProps<Theme>;
	vaultData?: number;
	lastTransactionsData?: ITransition[];
	emissionVaultData?: IVault;
}

type StatisticsType = {
	value: number,
	time: number,
}

const timeMap = [
	{
		label: '02:00',
		timeStart: 0,
		timeFinish: 4,
	},
	{
		label: '04:00',
		timeStart: 4,
		timeFinish: 6,
	},
	{
		label: '06:00',
		timeStart: 6,
		timeFinish: 8,
	},
	{
		label: '08:00',
		timeStart: 8,
		timeFinish: 10,
	},
	{
		label: '10:00',
		timeStart: 10,
		timeFinish: 12,
	},
	{
		label: '12:00',
		timeStart: 12,
		timeFinish: 14,
	},
	{
		label: '14:00',
		timeStart: 14,
		timeFinish: 16,
	},
	{
		label: '16:00',
		timeStart: 16,
		timeFinish: 18,
	},
	{
		label: '18:00',
		timeStart: 18,
		timeFinish: 20,
	},
	{
		label: '20:00',
		timeStart: 20,
		timeFinish: 22,
	},
	{
		label: '22:00',
		timeStart: 22,
		timeFinish: 24,
	},
];

const Diagram: React.FunctionComponent<IProps> = ({
	sx,
	vaultData = 0,
	emissionVaultData = { amount: 0, nextEmission: 0, time: 0 },
	lastTransactionsData = [],
}) => {
	const handedOut = emissionVaultData.amount - vaultData > 0 ? emissionVaultData.amount - vaultData : 0;

	const data = [
		{
			label: 'Issued',
			data: timeMap.reduce((accumulator, currentValue) => [
				...accumulator,
				{
					time: currentValue.label,
					value: lastTransactionsData?.reduce((summary, transaction) => {
						const transactionHour = new Date(transaction.time).getHours();

						if (transactionHour >= currentValue.timeStart && transactionHour < currentValue.timeFinish) {
							return summary + transaction.amount;
						}

						return summary;
					}, 0),
				},
			], [] as any[]),
		},
	];

	const primaryAxis = React.useMemo((): AxisOptions<StatisticsType> => ({ getValue: (datum) => datum.time, elementType: 'line' }), []);

	const secondaryAxes = React.useMemo((): AxisOptions<StatisticsType>[] => [{ getValue: (datum) => datum.value, elementType: 'line' }], []);

	return (
		<Box
			sx={{
				...sx,
				boxSizing: 'border-box',
				position: 'relative',
				padding: '2px',
				background: 'linear-gradient(90deg, #419AF2 0%, #3945F0 59.9%, #503EE6 100%)',
				borderRadius: 1,
			}}
		>
			<Box sx={{
				background: '#FFF', borderRadius: 0.8, px: 1, py: 3,
			}}
			>
				<Stack direction="row" alignItems="center" justifyContent="space-between" px={3}>
					<Typography
						variant="h6"
						sx={{
							color: (theme) => theme.palette.text.primary,
							fontWeight: 600,
						}}
					>
						Available WodaBonus
					</Typography>
					<Box sx={{ display: 'inline-flex'}}>
						<Typography
							variant="h4"
							sx={{
								color: (theme) => theme.palette.text.secondary,
								fontWeight: 600,
							}}
						>
							{handedOut}
						</Typography>
						<Typography
							variant="caption"
							sx={{
								color: (theme) => theme.palette.text.secondary,
								fontWeight: 600,
							}}
						>
							WB
						</Typography>
					</Box>
				</Stack>
				<Stack direction="row" sx={{ height: '314px' }}>
					<Box sx={{ position: 'relative', width: '100%' }}>
						{lastTransactionsData?.length > 0 && (
							<Chart
								options={{
									data,
									primaryAxis,
									secondaryAxes,
								}}
							/>
						)}
					</Box>
					<Stack py={1} pl={1} justifyContent="space-between">
						<Box>
							<Typography variant="h6" sx={{ color: '#0C1A67'}}>
								{emissionVaultData.amount}
							</Typography>
							<Typography variant="caption" sx={{ color: (theme) => theme.palette.text.secondary }}>
								can be mined
							</Typography>
						</Box>
						<EmissionTimer nextEmissionTime={emissionVaultData.nextEmission} />
					</Stack>
				</Stack>
			</Box>
		</Box>

	);
};

export default Diagram;
