import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Typography } from '@mui/material';

import MHidden from 'library/common/components/MHidden/MHidden';
import useProfile from 'library/common/hook/useProfile';
import useCard, { ModeType } from 'library/common/hook/useCard';
import { UUID } from 'library/common/types/app.d';

import DesktopUserProfile from './desktop/DesktopUserProfile';
import MobileUserProfile from './mobile/MobileUserProfile';

interface IProps {
	id: UUID;
}

const UserProfile: React.FunctionComponent<IProps> = ({ id }) => {
	const {t} = useTranslation();

	const {
		isPending,
		isSubscribing,
		noSuchUser,
		onSubscribe,
		profileData,
		canISubscribe,
		isSubscribed,
	} = useProfile(id);

	const {
		publishedCards,
		getPublishedCards,
		isPending: isCardsPending,
	} = useCard({ authorId: id, defaultMode: ModeType.PUBLISHED });

	const subscribeToUser = useCallback(
		() => {
			if (id) {
				onSubscribe(id);
			}
		},
		[id, onSubscribe],
	);

	if (noSuchUser) {
		return (
			<Container
				maxWidth="xl"
				sx={{
					height: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Typography
					variant="h4"
					sx={{
						color: 'text.disabled',
						fontWeight: 600,
					}}
				>
					{t('no_such_user')}
				</Typography>
			</Container>
		);
	}

	return (
		<>
			<MHidden breakpoint="md" direction="Down">
				<DesktopUserProfile
					profileData={profileData}
					isPending={isPending}
					isSubscribing={isSubscribing}
					publishedCards={publishedCards}
					isCardsPending={isCardsPending}
					subscribeToUser={subscribeToUser}
					canISubscribe={canISubscribe}
					isSubscribed={isSubscribed}
					getPublishedCards={getPublishedCards}
				/>
			</MHidden>
			<MHidden breakpoint="md" direction="Up">
				<MobileUserProfile
					profileData={profileData}
					isPending={isPending}
					isSubscribing={isSubscribing}
					publishedCards={publishedCards}
					isCardsPending={isCardsPending}
					subscribeToUser={subscribeToUser}
					canISubscribe={canISubscribe}
					isSubscribed={isSubscribed}
					getPublishedCards={getPublishedCards}
				/>
			</MHidden>
		</>
	);
};

export default UserProfile;
