import {CustomTheme} from './Theme.d';

const Button = (theme: CustomTheme) => ({
	MuiButton: {
		styleOverrides: {
			root: {
				borderRadius: '4px',
				'&:hover': {
					boxShadow: 'none',
				},
			},
			sizeLarge: {
				height: 48,
			},
			containedInherit: {
				color: theme.palette.grey[800],
				boxShadow: theme.customShadows.z8,
				'&:hover': {
					backgroundColor: theme.palette.grey[400],
				},
			},
			containedPrimary: {
				boxShadow: theme.customShadows.primary,
				color: '#FFFFFF',
				fontWeight: 600,
				background: (theme.palette as any).gradients.primary,
			},
			containedSecondary: {
				boxShadow: theme.customShadows.secondary,
			},
			outlinedInherit: {
				border: `1px solid ${(theme.palette.grey as any)[500_32]}`,
				'&:hover': {
					backgroundColor: theme.palette.action.hover,
				},
			},
			textInherit: {
				'&:hover': {
					backgroundColor: theme.palette.action.hover,
				},
			},
		},
	},
});

export default Button;
