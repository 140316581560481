import React, { useMemo, useState } from 'react';
import {AxiosResponse} from 'axios';
import { debounce, uniqBy } from 'lodash';
import {useTranslation} from 'react-i18next';
import {
	Autocomplete,
	Box,
	CircularProgress,
	IconButton,
	InputAdornment,
	SxProps,
	Theme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';

import { AxiosCardApiPaginationResponse } from 'library/common/types/cards/request.d';
import {IPoorCard} from 'library/common/types/cards/cards.d';
import cardServices from 'library/common/services/cards.service';
import { UUID } from 'library/common/types/app.d';
import {logger} from 'library/utils/lintersSugar';
import SearchIcon from 'resources/icons/SearchIcon';

interface IOption {
	id: UUID;
	title: string;
}

interface IProps {
    userId: UUID;
    getPublishedCards(searchValue?: string): void;
	isMobile?: boolean;
	sx?: SxProps<Theme>;
}

const SearchInput: React.FunctionComponent<IProps> = ({
	userId, getPublishedCards, isMobile, sx,
}) => {
	const {t} = useTranslation();

	const [open, setOpen] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [option, setOption] = useState<IOption[]>([]);
	const [searchInputValue, setSearchInputValue] = useState<string>('');

	const getOptions = useMemo(() => debounce((value: string) => {
		setLoading(true);
		cardServices.getUserCreatedCards(userId, value.replace(/\s+/g, ' ').trim())
			.then((res: AxiosResponse<AxiosCardApiPaginationResponse<IPoorCard[]>>) => (
				setOption(
					uniqBy(res.data.payload, 'title')
						.map((card: IPoorCard) => ({id: card.id, title: card.title})),
				)))
			.catch((error) => logger(error, 'error'))
			.finally(() => setLoading(false));
	}, 500), [userId]);

	const onSearchHandle = (search_query: string = '') => {
		setSearchInputValue(search_query);
		getPublishedCards(search_query);
	};

	return (
		<Box
			sx={{
				px: 2,
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'flex-end',
				...sx,
			}}
		>
			<Box sx={{ flex: isMobile ? 1 : 0.7}}>
				<Autocomplete
					id="cards search"
					freeSolo
					open={open}
					onOpen={() => setOpen(true)}
					onClose={() => setOpen(false)}
					options={option}
					loading={loading}
					loadingText={`${t('loading')}...`}
					getOptionLabel={(item: any) => item.title ?? ''}
					inputValue={searchInputValue}
					onChange={(e, val) => {
						if (val && typeof val === 'object' && 'title' in val) {
							onSearchHandle(val.title);
						}
					}}
					disableClearable
					renderInput={(params) => (
						<TextField
							{...params}
							size="small"
							label={t('search_text')}
							margin="none"
							variant="outlined"
							onChange={(e) => {
								setSearchInputValue(e.target.value);
								getOptions(e.target.value);
							}}
							onKeyDown={(e) => {
								if (e.key === 'Enter') onSearchHandle(searchInputValue);
							}}
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<>
										{loading ? <CircularProgress color="inherit" size={20} /> : null}
										<InputAdornment position="end">
											{searchInputValue.length > 0 && (
												<IconButton
													aria-label="delete search request text"
													size="small"
													onClick={() => onSearchHandle()}
												>
													<CloseIcon fontSize="inherit" />
												</IconButton>
											)}
											<IconButton
												aria-label={t('search')}
												size="small"
												onClick={() => onSearchHandle(searchInputValue)}
											>
												<SearchIcon fontSize="inherit" />
											</IconButton>
										</InputAdornment>
									</>
								),
							}}
							sx={({ palette: { action, common: { white, black } } }: Theme) => ({
								'& .MuiFormLabel-root': {
									color: isMobile ? black : white,
								},
								'& .MuiOutlinedInput-root': {
									color: isMobile ? black : white,

									'& > fieldset': {
										borderColor: action.active,
									},
								},
								'& .MuiOutlinedInput-root:hover': {
									'& > fieldset': {
										borderColor: isMobile ? action.active : white,
									},
								},
								'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
									borderColor: isMobile ? action.active : white,
								},
								'& .MuiInputLabel-root.Mui-focused': {
									color: isMobile ? action.active : white,
								},
							})}
						/>
					)}
				/>
			</Box>
		</Box>
	);
};

export default SearchInput;
