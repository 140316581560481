import React from 'react';

import cn from 'classnames';
import styles from './loader.module.css';

const WQLoader = () => (
	<div className={styles.Logo}>
		{
			Array.from({length: 49}, (_, i) => (
				<div key={i} className={cn(styles.LogoFlakeWrapper, styles[`LogoFlakeWrapper__${i + 1}`])}>
					<div className={styles.LogoFlake} />
				</div>
			))
		}
	</div>
);

export default WQLoader;
