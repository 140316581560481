import React, { useRef, useState } from 'react';
import ImageEditor from '@toast-ui/react-image-editor';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import {Box, Fab, Stack} from '@mui/material';

import pictureResizer from 'library/utils/pictureResizer';
import mediaDownloader from 'library/utils/mediaDownloader';
import LinearProgressWithLabel from 'library/common/components/LinearProgressWithLabel';
import { showSnackbarMessage } from 'library/utils/showSnackbarMessage';

interface IMyImageEditor {
	path: string;
	fileName: string;
    menu: ('crop' |'draw' |'shape' |'icon' |'text' | 'rotate' | 'shape' | 'filter' | 'flip')[]
    onLoad: (editedFile: File, res: number) => void;
    onCancelHandle: () => void;
	mobileMode?: boolean;
}

const MyImageEditor = ({
	menu,
	path,
	fileName,
	onLoad,
	onCancelHandle,
	mobileMode,
}: IMyImageEditor) => {
	const [progress, setProgress] = useState<number | null>(null);

	const editorRef = useRef<any>(null);

	const uploadImageHandle = async () => {
		try {
			if (editorRef?.current) {
				const {imageEditorInst} = editorRef.current;
				const toDataURL = imageEditorInst.toDataURL();

				if (toDataURL) {
					pictureResizer(
						toDataURL,
						fileName,
						(file) => mediaDownloader(
							'image',
							file,
							setProgress,
							(editedFile: File, res: number) => onLoad(editedFile, res),
						),
					);
				}
			}
		} catch (error) {
			showSnackbarMessage('Error', 'error');
		}
	};

	return (
		<Box sx={{ backgroundColor: '#151515', display: 'flex', position: 'relative'}}>
			<Stack
				direction={mobileMode ? 'row' : 'column'}
				alignItems="center"
				justifyContent="center"
				spacing={2}
				sx={{
					position: 'absolute',
					right: 12,
					top: mobileMode ? 50 : 12,
				}}
			>
				<Fab
					color="primary"
					onClick={uploadImageHandle}
					disabled={!!progress}
					size={mobileMode ? 'small' : 'large'}
					aria-label="Upload picture"
				>
					<SaveIcon />
				</Fab>

				<Fab
					color="primary"
					onClick={onCancelHandle}
					disabled={!!progress}
					size={mobileMode ? 'small' : 'medium'}
					aria-label="Cancel"
				>
					<ClearIcon />
				</Fab>
			</Stack>
			<Box sx={{margin: 'auto'}}>
				<ImageEditor
					ref={editorRef}
					includeUI={{
						loadImage: {
							path,
							name: fileName,
						},
						theme: {
							'header.display': 'none',
							'menu.backgroundColor': 'white',
							'common.backgroundColor': '#151515',
							// colorpicker style
							'colorpicker.button.border': '1px solid #1e1e1e',
							'colorpicker.title.color': '#fff',
						},
						menu,
						draw: {
							color: '#00a9ff',
							opacity: 1.0,
							range: {
								value: 8,
							},
						},
						initMenu: 'draw',
						uiSize: {
							width: mobileMode ? `${document.documentElement.clientWidth}px` : '1270px',
							height: mobileMode ? `${document.documentElement.clientHeight}px` : '870px',
						},
						menuBarPosition: 'bottom',
					}}
					cssMaxWidth={document.documentElement.clientWidth}
					cssMaxHeight={document.documentElement.clientHeight}
					selectionStyle={{
						cornerSize: 50,
						rotatingPointOffset: 100,
						...(mobileMode && {
							borderColor: 'red',
							cornerColor: 'green',
							transparentCorners: false,
						}),
					}}
					usageStatistics={false}
				/>

				<Box
					sx={{
						width: '100%',
						height: '21px',
						mb: 1,
						mt: 1,
					}}
				>
					{
						progress && (
							<LinearProgressWithLabel value={progress} />
						)
					}
				</Box>
			</Box>
		</Box>

	);
};

export default MyImageEditor;
