import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import cardsService from 'library/common/services/cards.service';
import { UUID } from 'library/common/types/app.d';
import { CommentResponse, ICommentEntity } from 'library/common/types/cards/comments.d';
import { CardApiData } from 'library/common/types/cards/request.d';
import axiosErrorHandler from 'library/utils/axiosErrorHandler';
import { mapAxiosCardApiResponse } from 'library/utils/mappers/api.mapper';

import commentsQueryKeys from './queryKeys';

interface IMutationParams {
	commentMessage: string;
	commentId: UUID;
}

const useAddChildCommentMutation = (options: { onSuccessAction?: () => void }) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: ({commentId, commentMessage}: IMutationParams) => cardsService
			.addChildComment<CardApiData<ICommentEntity>>(commentId, commentMessage)
			.then(mapAxiosCardApiResponse),
		onSuccess: (data, { commentId }) => {
			if (data.comment.parentComment) {
				const parentCardId = data.comment.parentComment.cardId;
				const parentCommentId = data.comment.parentComment.id;

				const parentKey = commentsQueryKeys.byCardId(parentCardId);
				const currentCardCommentsData = queryClient.getQueryData<CommentResponse>(parentKey);

				if (currentCardCommentsData !== undefined) {
					queryClient.setQueriesData(parentKey, {
						...currentCardCommentsData,
						[parentCommentId]: {
							...currentCardCommentsData[parentCommentId],
							comment: {
								...currentCardCommentsData[parentCommentId].comment,
								childrenCount: currentCardCommentsData[parentCommentId].comment.childrenCount + 1,
							},
						},
					});
				}
			}

			const key = commentsQueryKeys.byCommentId(commentId);
			queryClient.invalidateQueries(key);

			options?.onSuccessAction?.();
		},
		onError: (error: AxiosError) => {
			axiosErrorHandler(error);
		},
	});
};

export default useAddChildCommentMutation;
