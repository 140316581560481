import React, { memo, useState } from 'react';
import {
	Box, Modal, SxProps, Theme, useMediaQuery, useTheme,
} from '@mui/material';

import { MediaContent } from 'library/common/types/app.d';
import { getStepOverlayPicture, getStepPicture } from 'library/utils/getters/getPicture';

import Image from 'library/common/components/Image';
import style from '../stage.module.css';

interface IProps {
	data: MediaContent;
	alt?: string;
	sx?: SxProps<Theme>;
}

const ImageContent: React.FunctionComponent<IProps> = ({ data: image, alt, sx }) => {
	const [isOverlayOpen, setOverlayOpen] = useState(false);

	const { breakpoints } = useTheme();

	const isMobile = useMediaQuery(breakpoints.up('sm'));

	const overlayOpenHandle = () => setOverlayOpen(true && isMobile);

	const overlayCloseHandle = () => setOverlayOpen(false);

	return (
		<Box sx={{ ...sx }}>
			<Box
				aria-hidden="true"
				role="button"
				className={style.image}
				onClick={overlayOpenHandle}
			>
				<Box component="img" src={getStepPicture(image)} className={style.image_on_click} alt={alt} />
			</Box>
			<Modal
				open={isOverlayOpen}
				onClose={overlayCloseHandle}
			>
				<Box sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					outline: 'none',
				}}
				>
					<Image src={getStepOverlayPicture(image, breakpoints)} alt={alt} />
				</Box>
			</Modal>
		</Box>
	);
};

export default memo(ImageContent);
