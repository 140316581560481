import React from 'react';
import isNonNullable from 'library/utils/isNonNullable';
import { useSearchParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import MainPageQueryParams from 'library/utils/routes/mainPageQueryParams';

import MobileReels from './mobile/MobileReels';
import MainGrid from './MainGrid';
import Reels from './desktop/Reels';

interface IProps {}

const Main: React.FunctionComponent<IProps> = () => {
	const [searchParams] = useSearchParams({});

	const searchQuery = searchParams.get(MainPageQueryParams.SEARCH_QUERY);
	const category = searchParams.get(MainPageQueryParams.CATEGORY);

	if (isNonNullable(searchQuery) || isNonNullable(category)) {
		return (
			<MainGrid
				category={category || undefined}
				searchQuery={searchQuery || undefined}
			/>
		);
	}

	if (isMobile) {
		return (
			<MobileReels />
		);
	}

	return (
		<Reels />
	);
};

export default Main;
