import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const CrossIcon = ({...props}: SvgIconProps) => (
	<SvgIcon viewBox="0 0 12 12" {...props}>
		<path d="M1.0377 10.9372C0.744805 10.6443 0.744805 10.1694 1.0377 9.87655L9.87653 1.03772C10.1694
		0.744825 10.6443 0.744825 10.9372 1.03772C11.2301 1.33061 11.2301 1.80549 10.9372 2.09838L2.09836
		10.9372C1.80546 11.2301 1.33059 11.2301 1.0377 10.9372Z"
		/>
		<path d="M1.03766 0.977399C1.33056 0.684506 1.80543 0.684506 2.09832 0.977399L10.9372 9.81623C11.2301
		10.1091 11.2301 10.584 10.9372 10.8769C10.6443 11.1698 10.1694 11.1698 9.8765 10.8769L1.03766
		2.03806C0.74477 1.74517 0.74477 1.27029 1.03766 0.977399Z"
		/>
	</SvgIcon>
);

export default CrossIcon;
