import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const FilledArrowIcon = ({...props}: SvgIconProps) => (
	<SvgIcon viewBox="0 0 14 11" {...props}>
		<path
			d="M7.80296 9.9183C7.40314 10.4569 6.59686 10.4569 6.19705 9.9183L0.390525 2.09604C-0.0992832 1.4362 0.371709 0.5
        1.19348 0.5L12.8065 0.5C13.6283 0.5 14.0993 1.43619 13.6095 2.09604L7.80296 9.9183Z"
		/>
	</SvgIcon>
);

export default FilledArrowIcon;
