import {Container, Typography} from '@mui/material';
import React from 'react';
import Link from '@mui/material/Link';

import Template from './Template';

const CookiesPolicy = () => (
	<Template>
		<Container sx={{pt: 2, pb: 2}}>
			<Typography variant="h3" sx={{fontWeight: 700}}>
				Cookies Policy
			</Typography>

			<Typography
				variant="body1"
				sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700'], fontStyle: 'italic'}}
			>
				Last updated: February 17, 2022
			</Typography>

			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				Welcome to WodaQota (the “Platform”) and our website, services, applications, products and content
				(that include but are not limited to the Platform) (collectively, the “Services”).
				We use cookies on our website. Cookies make your interaction with our Platform and Services
				easy and helpful and allow us to store information or gain access to information stored on your device.
				Cookies are used by all website providers to make the website work and provide information to
				the owners of the Platform or provides of the Services.
			</Typography>

			<Typography variant="h5" sx={{m: '0.8rem 0', fontWeight: 500}}>
				I. What is a cookie?
			</Typography>

			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				Cookies are small text files that uniquely identify your browser or device.
				The cookie is stored on your browser by website or apps that you visit.
				When you return to that website or app (or visit websites or apps that use the same cookies)
				these Platforms recognise the cookies and your browsing device. For a more thorough explanation
				of what cookies are and how they work, please visit the
				&nbsp;
				<Link
					target="_blank"
					rel="noopener noreferrer"
					href="https://wikis.ec.europa.eu/display/WEBGUIDE/04.+Cookies"
				>
					EU Guide to Cookies
				</Link>
				&nbsp;or&nbsp;
				<Link target="_blank" rel="noopener noreferrer" href="https://www.aboutcookies.org/">
					aboutcookies.org.
				</Link>
			</Typography>

			<Typography variant="h5" sx={{m: '0.8rem 0', fontWeight: 500}}>
				II. Categories of cookies
			</Typography>

			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				Cookies can be &quot;First-party&quot; Cookies or “Third-party” Cookies.
				First-party cookies are served by us directly to your device.
				Third-party cookies are served by a third party on our behalf.
			</Typography>
			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				Cookies can remain on your computer or mobile device for different periods of time.
				Some cookies are &quot;Session cookies&quot;, meaning that they exist only while your browser
				is open and are deleted automatically once you close your browser. Other cookies are
				&quot;Persistent cookies&quot;, meaning that they survive for a period of time after your browser
				is closed. Most Persistent cookies are set so that they automatically expire after a defined duration.
			</Typography>

			<Typography variant="h5" sx={{m: '0.8rem 0', fontWeight: 500}}>
				III. What types of cookies do we use and how do we use them?
			</Typography>

			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				Cookies do a lot of different tasks to ensure you enjoy your visit to our Platform,
				for example, they are used to remember your preferences on sites you visit,
				to help you navigate between pages more efficiently and to make sure the adverts
				you see on our Platform are relevant to you and your interests. We have set out some
				further information about each category, and the purposes of the cookies we and third
				parties set in the following table.
			</Typography>

			<ul style={{margin: 0}}>
				<li>
					<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
						Strictly necessary cookies. These are cookies that are required for the operation of
						our website. They include, for example, cookies that ensure basic website functions
						of the website.
					</Typography>
				</li>
				<li>
					<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
						Analytical/performance cookies. They allow us to recognise and count the number of visitors
						and to see how visitors move around our website when they are using it.
						This helps us to improve the way our website works, for example, by ensuring that
						users are finding what they are looking for easily.
					</Typography>
				</li>
			</ul>

			<Typography variant="h6" sx={{mt: '0.8rem 0'}}>
				Google Analytics
			</Typography>
			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				Google Analytics is a service provided by Google, Inc. (&quot;Google&quot;).
				We use Google Analytics to collect anonymized statistics in order to improve the Site.
				You can learn about Google’s practices by going to&nbsp;
				<Link
					target="_blank"
					rel="noopener noreferrer"
					href="https://support.google.com/analytics/answer/6004245?hl=en-GB"
				>
					Google Analytics and privacy
				</Link>
				, and opt out of them by downloading the
				&nbsp;
				<Link
					target="_blank"
					rel="noopener noreferrer"
					href="https://tools.google.com/dlpage/gaoptout?hl=en"
				>
					Google Analytics Opt-out Browser Add-on.
				</Link>
			</Typography>

			<Typography variant="h5" sx={{m: '0.8rem 0', fontWeight: 500}}>
				IV. How to delete cookies
			</Typography>

			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				We use cookies on this site to make sure you can use the site easily and enjoy the site.
			</Typography>
			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				You can change your preferences by changing the settings in your browser.
				Most browsers will allow you to choose the level of privacy settings you want.
				This lets you control your cookie settings so that you can:
			</Typography>
			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				<ul style={{margin: 0}}>
					<li>
						<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
							See what cookies or other locally stored data you’ve got and delete them on an
							individual basis;
						</Typography>
					</li>
					<li>
						<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
							Block third party cookies or similar technology;
						</Typography>
					</li>
					<li>
						<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
							Block cookies or similar technology from particular sites;
						</Typography>
					</li>
					<li>
						<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
							Block all cookies or similar technologies from being set; or
						</Typography>
					</li>
					<li>
						<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
							Delete all cookies or similar technologies when you close your browser.
						</Typography>
					</li>

				</ul>
			</Typography>
			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				Deleting cookies means that any preference settings you have made on a Platform will be lost.
				Blocking all cookies means functionality on our Platforms will be lost.
				Please note that if you choose to refuse cookies you may not be able to use the full
				functionality of our Platform.
			</Typography>
			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				For more information on how to manage the most popular browsers, please see below:
			</Typography>

			<Typography variant="h6" sx={{mt: '0.8rem 0'}}>
				Internet Explorer
			</Typography>
			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				<Link
					target="_blank"
					rel="noopener noreferrer"
					// eslint-disable-next-line max-len
					href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
				>
					{/* eslint-disable-next-line max-len */}
					https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d
				</Link>
			</Typography>

			<Typography variant="h6" sx={{mt: '0.8rem 0'}}>
				Firefox
			</Typography>
			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				<Link
					target="_blank"
					rel="noopener noreferrer"
					// eslint-disable-next-line max-len
					href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?"
				>
					{/* eslint-disable-next-line max-len */}
					https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?
				</Link>
			</Typography>

			<Typography variant="h6" sx={{mt: '0.8rem 0'}}>
				Google Chrome
			</Typography>
			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				<Link
					target="_blank"
					rel="noopener noreferrer"
					// eslint-disable-next-line max-len
					href="https://support.google.com/chrome/answer/95647?hl=en"
				>
					{/* eslint-disable-next-line max-len */}
					https://support.google.com/chrome/answer/95647?hl=en
				</Link>
			</Typography>

			<Typography variant="h6" sx={{mt: '0.8rem 0'}}>
				Safari
			</Typography>
			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				<Link
					target="_blank"
					rel="noopener noreferrer"
					// eslint-disable-next-line max-len
					href="https://support.apple.com/ru-ru/guide/safari/sfri11471/mac"
				>
					{/* eslint-disable-next-line max-len */}
					https://support.apple.com/ru-ru/guide/safari/sfri11471/mac
				</Link>
			</Typography>

			<Typography variant="h5" sx={{m: '0.8rem 0', fontWeight: 500}}>
				V. Changes to this policy
			</Typography>

			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				We will occasionally update this policy to reflect changes in our practices,
				Platform and Services. When we post changes to this policy, we will revise the &quot;Last Updated&quot;
				date at the top of this policy. If we make any material changes in the way we collect, use,
				and/or share information held in cookies, we will notify you by prominently posting notice of
				the changes on the Platform. We recommend that you check this page from time to time to inform
				yourself of any changes in this policy or any of our other policies.
			</Typography>

			<Typography variant="h5" sx={{m: '0.8rem 0', fontWeight: 500}}>
				VI. Need more information?
			</Typography>
			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				If you would like to find out more about cookies and their use on the Internet,
				you may find the following links useful:
			</Typography>

			<ul style={{margin: 0}}>
				<li>
					<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
						<Link
							target="_blank"
							rel="noopener noreferrer"
							href="https://ec.europa.eu/ipg/basics/legal/cookies/index_en.htm"
						>
							EU Guide to Cookies
						</Link>
					</Typography>
				</li>
				<li>
					<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
						<Link
							target="_blank"
							rel="noopener noreferrer"
							href="https://www.youronlinechoices.com/uk/"
						>
							youronlinechoices.co.uk/uk
						</Link>
					</Typography>
				</li>
			</ul>

			<Typography variant="h5" sx={{m: '0.8rem 0', fontWeight: 500}}>
				VII. Contact us
			</Typography>

			<Typography variant="body1" sx={{m: '0.8rem 0', color: (theme) => theme.palette.grey['700']}}>
				If you have any questions or comments about this policy, or privacy matters generally,
				please contact us via email:&nbsp;
				<Link href="mailto:support@wodaqota.com">support@wodaqota.com</Link>
			</Typography>
		</Container>
	</Template>
);

export default CookiesPolicy;
