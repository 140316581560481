import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/material';

import { getUserDataSchema } from 'pages/Profile/data';
import TabPanel from 'library/common/components/Tabs/TabPanel';
import { ModeType } from 'library/common/hook/useCard';
import StyledTab from 'pages/Profile/components/Styled/StyledTab';
import ListWrapper from 'pages/Profile/components/List/ListWrapper';
import StyledTabs from 'pages/Profile/components/Styled/StyledTabs';
import ProfileCounters from 'pages/Profile/components/ProfileCounters';

import MobilePublishedList from './components/MobilePublishedList';
import { ISelfProfileProps } from '../types.d';
import ProfileHeader from './components/ProfileHeader';
import WodaWallet from './components/WodaWallet';
import SearchInput from '../components/SearchInput';

const MobileSelfProfile: React.FunctionComponent<ISelfProfileProps> = ({
	ListMode,
	// activatedCards,
	favoriteCards,
	isCardsPending,
	isPending,
	profileData: {
		id,
		username,
		logoURL,
		followers,
		followings,
		referredUsersCount,
		isVerified,
	},
	progress,
	publishedCards,
	// setActivatedModeHandler,
	setAvatarRequest,
	setPublishedModeHandler,
	setFavoriteModeHandler,
	wodaBalance,
	isWodaBalanceLoading,
	getPublishedCards,
}) => {
	const { t } = useTranslation();

	return (
		<Stack sx={{ mt: 7.5, pb: 7.5, width: '100%' }}>
			<ProfileHeader
				selfPage
				isPending={isPending}
				username={username}
				logoURL={logoURL}
				progress={progress}
				setAvatarRequest={setAvatarRequest}
				isVerified={isVerified}
			/>

			<WodaWallet
				id={id}
				isWodaBalanceLoading={isWodaBalanceLoading}
				isPending={isPending}
				referredUsersCount={referredUsersCount}
				wodaBalance={wodaBalance}
			/>

			<ProfileCounters
				isPending={isPending}
				data={{
					followers,
					followings,
					cardsCount: publishedCards.length ?? 0,
				}}
				schema={getUserDataSchema(true)}
			/>

			<Box sx={{
				mx: 4,
				mb: 2,
				mt: {
					xs: 4,
					md: 3,
				},
				display: 'flex',
				alignItems: 'center',
				justifyContent: {
					xs: 'center',
					md: 'flex-end',
				},
			}}
			>
				<StyledTabs value={ListMode} aria-label="Mode tabs">
					<StyledTab label={t(ModeType.PUBLISHED)} value={ModeType.PUBLISHED} onClick={setPublishedModeHandler} />
					<StyledTab label={t(ModeType.FAVORITE)} value={ModeType.FAVORITE} onClick={setFavoriteModeHandler} />
					{/* <StyledTab label={t(ModeType.ACTIVATED)} value={ModeType.ACTIVATED} onClick={setActivatedModeHandler} /> */}
				</StyledTabs>
			</Box>
			<Box sx={{ flexGrow: 1 }}>
				{id && ListMode === ModeType.PUBLISHED && (
					<SearchInput
						isMobile
						userId={id}
						getPublishedCards={getPublishedCards}
						sx={{ justifyContent: 'flex-start', alignItems: 'center' }}
					/>
				)}
			</Box>

			<Box sx={{ width: '100%', height: '100%' }}>
				<Stack direction="column" sx={{ height: '100%' }}>
					<TabPanel<ModeType> value={ListMode} index={ModeType.PUBLISHED}>
						<ListWrapper
							label={t('no_wodaqards')}
							isCardsPending={isCardsPending}
							cards={publishedCards}
							List={MobilePublishedList}
						/>
					</TabPanel>
					<TabPanel<ModeType> value={ListMode} index={ModeType.FAVORITE}>
						<ListWrapper
							label={t('no_wodaqards')}
							isCardsPending={isCardsPending}
							cards={favoriteCards}
							List={MobilePublishedList}
						/>
					</TabPanel>

					{/* <TabPanel<ModeType> value={ListMode} index={ModeType.ACTIVATED}>
						<ListWrapper
							label={t('no_wodaqards')}
							isCardsPending={isCardsPending}
							cards={activatedCards}
							List={MobileActivatedList}
						/>
					</TabPanel> */}
				</Stack>
			</Box>
		</Stack>
	);
};

export default MobileSelfProfile;
