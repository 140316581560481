import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import {BottomNavigation, BottomNavigationAction} from '@mui/material';

import { MOBILE_SIDEBAR_HEIGHT } from 'resources/styles/constants';

import menuItems from '../menuItems';

const MobileStyle = styled('div')({
	width: '100%',
	position: 'fixed',
	bottom: 0,
	zIndex: 1200,
	boxShadow: '0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%)',
});

const MobileSidebar = () => {
	const navigate = useNavigate();
	const location = useLocation();

	return (
		<MobileStyle>
			<BottomNavigation
				sx={{
					height: MOBILE_SIDEBAR_HEIGHT,
					background: (theme) => (theme.palette as any).gradients.service,
					backdropFilter: 'blur(2px)',
				}}
				value={menuItems.find((item) => location.pathname.indexOf(item.path) >= 0)?.path}
				onChange={(_, newValue) => {
					navigate(`/app/${newValue}`);
				}}
			>
				{menuItems.map((item) => (
					<BottomNavigationAction
						sx={{
							svg: {
								fontSize: item.fontSize,
							},
						}}
						key={item.path}
						value={item.path}
						icon={<item.icon />}
						disabled={item.disabled}
					/>
				))}
			</BottomNavigation>
		</MobileStyle>
	);
};

export default MobileSidebar;
