import React from 'react';
import { v1 as uuidv1 } from 'uuid';
import {Stack} from '@mui/material';

import { PoorResource } from 'library/common/types/cards/cards.d';
import { IStepData } from 'library/common/types/cards/cardsCreation.d';
import { UUID } from 'library/common/types/app.d';

import swapArrayItems from 'library/utils/swapArrayItems';
import Stage from './Stage';

interface IStages {
	data: IStepData[];
	setValue: (newData: IStepData[]) => void;
	errors?: string | undefined;
	disabled: boolean,
}

const Stages = ({
	data,
	errors,
	setValue,
	disabled,
}: IStages) => {
	const errorsObj = errors ? JSON.parse(errors) : {};

	const addStep = () => setValue([...data, { id: uuidv1(), resources: [] }]);

	const dropStep = (key: UUID) => () => setValue(data.filter((item) => item.id !== key));

	const setStepResources = (key: string) => (selectedResources: PoorResource[], isTouched?: boolean) => {
		setValue([
			...data.map((item) => {
				if (item.id === key) {
					return ({
						...item,
						resources: selectedResources,
					});
				}

				return item;
			}),
			...(!isTouched ? [{ id: uuidv1(), resources: [] }] : []),
		]);
	};

	const setStepData = (key: UUID) => (
		value: string | undefined, dataType: 'text' | 'image' | 'video' | 'draw',
		isTouched?: boolean,
	) => {
		setValue([
			...data.map((item) => {
				if (item.id === key) {
					return ({
						...item,
						[dataType]: value,
					});
				}

				return item;
			}),
			...(!isTouched ? [{ id: uuidv1(), resources: [] }] : []),
		]);
	};

	const dropStepMediaData = (key: UUID) => () => {
		setValue([
			...data.map((item) => {
				if (item.id === key) {
					return ({
						...item,
						image: undefined,
						video: undefined,
						draw: undefined,
					});
				}

				return item;
			}),
		]);
	};

	const moveDownStage = (index: number) => () => setValue(swapArrayItems(data, index, index + 1));

	const moveUpStage = (index: number) => () => setValue(swapArrayItems(data, index, index - 1));

	return (
		<Stack direction="column">
			{
				data.map((item: IStepData, index) => (
					<Stage
						key={item.id}
						stageId={item.id}
						disabled={disabled}
						stage={item}
						stepIndex={index}
						setStepResources={setStepResources(item.id)}
						setStepData={setStepData(item.id)}
						dropMedia={dropStepMediaData(item.id)}
						addStep={addStep}
						dropStep={dropStep(item.id)}
						moveDownStage={moveDownStage(index)}
						moveUpStage={moveUpStage(index)}
						length={data.length}
						error={errorsObj[item.id]}
					/>
				))
			}
		</Stack>
	);
};

export default Stages;
