import {
	Link, SxProps, Theme, Typography,
} from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface IProps {
  label: string;
  to: string;
  linkSx?: SxProps<Theme>;
  TypographySx?: SxProps<Theme>;
}

const TypographyLink: React.FunctionComponent<IProps> = ({
	label, to, TypographySx, linkSx,
}) => (
	<Link
		component={RouterLink}
		underline="none"
		to={to}
		sx={{ ...linkSx }}
	>
		<Typography
			sx={{
				color: (theme) => theme.palette.text.primary,
				fontWeight: 500,
				...TypographySx,
			}}
			variant="h6"
		>
			{label}
		</Typography>
	</Link>
);

export default TypographyLink;
