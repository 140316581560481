import {
	call, put, select, takeLatest,
} from 'redux-saga/effects';
import {AxiosResponse, Method} from 'axios';
import {SelfServiceSettingsFlow} from '@ory/kratos-client/api';

import {RootState} from 'main/rootReducer';
import {showSnackbarMessage} from 'library/utils/showSnackbarMessage';
import {kratosErrorHandler} from 'library/utils/errorsHandler';

import appActionTypes from '../constants/app.constant';
import * as appActions from '../actions/app.action';
import * as userActions from '../actions/user.action';
import settingsService from '../services/settings.service';

function* initSettingsPageSata() {
	try {
		const {data}: AxiosResponse<SelfServiceSettingsFlow> = yield call(settingsService.settingsInit);

		yield put(appActions.initSettingsPage.success(data));
	} catch (e) {
		yield put(appActions.initSettingsPage.failure());
	}
}

function* changeProfileSaga(action: ReturnType<typeof appActions.applySettings.request>) {
	try {
		// проверить свежесть flowId, если не свежий, то необходимо заменить, потом продолжить
		const sessionExpiresAt: Date | undefined = yield select((state: RootState) => state.app.settings?.expiresAt);
		let url = '';

		if (sessionExpiresAt && (sessionExpiresAt > new Date())) {
			url = yield select((state: RootState) => state.app?.settings?.actionUrl);
		} else {
			const spareSettings: AxiosResponse<SelfServiceSettingsFlow> = yield call(settingsService.settingsInit);
			url = spareSettings.data.ui.action.substring(spareSettings.data.ui.action.indexOf('.com/') + 5);
		}

		if (url !== undefined && url.length > 0) {
			const httpMethod: Method | undefined = yield select((state: RootState) => state.app.settings?.httpMethod);
			const csrfToken: string | undefined = yield select((state: RootState) => state.app.settings?.csrfToken);

			yield call(
				settingsService.templateSettingsRequest,
				{
					method: httpMethod || 'POST',
					url,
					data: {
						csrf_token: csrfToken,
						method: action.payload.methodGroup,
						...action.payload.data,
					},
				},
			);

			const {data}: AxiosResponse<SelfServiceSettingsFlow> = yield call(settingsService.settingsInit);

			yield put(appActions.applySettings.success(data));
			yield put(userActions.changeUserData({
				email: data.identity.traits.email,
				username: data.identity.traits.login,
			}));

			if (action.payload.successMessage !== undefined) {
				showSnackbarMessage(action.payload.successMessage, 'success', 4000);
			}
		} else {
			throw new Error('Bad url');
		}
	} catch (error: any) {
		showSnackbarMessage(
			`${kratosErrorHandler(error, 'Error')}`,
			'error',
			5000,
		);
		yield put(appActions.applySettings.failure());
	}
}

export default function* watchCommonLayout() {
	yield takeLatest(appActionTypes.INIT_SETTINGS_REQUEST, initSettingsPageSata);
	yield takeLatest(appActionTypes.APPLY_SETTINGS_REQUEST, changeProfileSaga);
}
