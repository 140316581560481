import React from 'react';
import { SystemProps } from '@mui/system';
import { useTranslation } from 'react-i18next';
import {
	Box, Stack, Theme, Typography,
} from '@mui/material';
import { Control, Controller, FieldError } from 'react-hook-form';

import SimpleNumberInput from 'library/common/components/SimpleNumberInput/SimpleNumberInput';

interface IProps extends SystemProps<Theme> {
	timeMetrics: ('days' | 'hours' | 'minutes' | 'seconds')[];
    errorMessage?: string;
	isPending?: boolean;
	control: Control<any, any>,
	timeValidation(): boolean;
	daysError?: FieldError;
	hoursError?: FieldError;
	minError?: FieldError;
	secError?: FieldError;
}

const TimeInput: React.FunctionComponent<IProps> = ({
	timeMetrics,
	control,
	isPending,
	errorMessage,
	timeValidation,
	daysError,
	hoursError,
	minError,
	secError,
	...props
}) => {
	const {t} = useTranslation();

	const haveError = Boolean(daysError || hoursError || minError || secError);

	return (
		<Box>
			<Typography
				sx={{
					fontWeight: 500,
					color: (theme) => theme.palette.text.secondary,
					mb: 1,
				}}
				variant="h6"
			>
				{t('time')}
			</Typography>

			<Typography
				sx={{
					color: (theme) => theme.palette.text.secondary,
					display: 'block',
					mb: 3,
				}}
				variant="caption"
			>
				Required time to achieve your result
			</Typography>

			<Stack
				direction={{ xs: 'column', sm: 'row' }}
				spacing={{ xs: 1, sm: 2 }}
				justifyContent="flex-start"
				alignItems="center"
				{...props}
			>
				{
					timeMetrics.map((metric) => (
						<Controller
							key={metric}
							control={control}
							name={metric}
							rules={{
								validate: () => timeValidation() || t('set_the_time'),
							}}
							render={({ field }) => (
								<SimpleNumberInput
									label={t(metric)}
									disabled={isPending}
									error={haveError}
									{...field}
								/>
							)}
						/>
					))
				}
			</Stack>
			{haveError && (
				<Typography
					variant="caption"
					sx={{
						color: (theme) => theme.palette.error.main,
						mt: '4px',
						ml: '14px',
					}}
				>
					{errorMessage}
				</Typography>
			)}
		</Box>
	);
};

export default TimeInput;
