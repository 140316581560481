import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import ShareContext from 'library/common/context/ShareContext';
import { UUID } from 'library/common/types/app.d';
import toCardPage from 'library/utils/routes/getCardPageUrl';
import { IReelsCard } from 'library/common/types/cards/reels.d';

import Reel from './components/Reel';

interface IProps {
	cardsState: IReelsCard[];
	onLikeHandle(cardId: UUID, status?: boolean): void;
    onSubscribeHandle(authorId: UUID, isSubscribed: boolean): void;
}

const WQReals: React.FunctionComponent<IProps> = ({ cardsState, onLikeHandle, onSubscribeHandle }) => {
	const navigate = useNavigate();

	const shareContext = useContext(ShareContext);

	const shareCardHandle = (id: UUID) => shareContext?.openShareDialog({ url: id, target: 'card' });

	return (
		<>
			{cardsState.map((card) => (
				<Reel
					key={card.id}
					card={card}
					onSubscribeHandle={onSubscribeHandle}
					onLikeHandle={onLikeHandle}
					shareCardHandle={() => shareCardHandle(card.id)}
					toCardPageHandle={() => navigate(toCardPage(card.id))}
				/>
			))}
		</>
	);
};

export default WQReals;
