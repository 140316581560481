import { MessageEventCode } from './eventCode';
import { IActivationMessage } from './messages/activation.message';
import { IChildCommentMessage } from './messages/childComment.message';
import { ICommentMessage } from './messages/comment.message';
import { ILikeCommentMessage } from './messages/likeComment.message';
import { ISubscriptionMessage } from './messages/subscription.message';

export type INotificationMessage = ICommentMessage
    | IActivationMessage
    | IChildCommentMessage
    | ILikeCommentMessage
    | ISubscriptionMessage

export const isCommentMessage = (message: INotificationMessage): message is ICommentMessage => (
	'type' in message && message.type === MessageEventCode.COMMENT
);

export const isActivationMessage = (message: INotificationMessage): message is IActivationMessage => (
	'type' in message && message.type === MessageEventCode.ACTIVATION
);

export const isChildCommentMessage = (message: INotificationMessage): message is IChildCommentMessage => (
	'type' in message && message.type === MessageEventCode.CHILD_COMMENT
);

export const isLikeCommentMessage = (message: INotificationMessage): message is ILikeCommentMessage => (
	'type' in message && message.type === MessageEventCode.LIKE_COMMENT
);

export const isSubscriptionMessage = (message: INotificationMessage): message is ISubscriptionMessage => (
	'type' in message && message.type === MessageEventCode.SUBSCRIPTION
);
