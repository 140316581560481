import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useTimer } from 'react-timer-hook';

interface IProps {
  nextEmissionTime: number
}

const timeMask = (entity: number) => (entity < 10 ? `0${entity}` : entity);

const EmissionTimer: React.FunctionComponent<IProps> = ({ nextEmissionTime }) => {
	// TODO на окончание таймера "протухать" кэш
	const { hours, minutes, seconds } = useTimer({ expiryTimestamp: new Date(nextEmissionTime) });

	return (
		<Stack>
			<Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main, fontWeight: 700 }}>
				{`${timeMask(hours)}:${timeMask(minutes)}:${timeMask(seconds)}`}
			</Typography>
			<Typography variant="caption" sx={{ color: (theme) => theme.palette.text.secondary }}>
				time left
			</Typography>
		</Stack>
	);
};

export default EmissionTimer;
