import { showSnackbarMessage } from 'library/utils/showSnackbarMessage';
import {
	AnyAction, Dispatch, MiddlewareAPI,
} from 'redux';
import { QueryClient } from '@tanstack/query-core';
import { EmptyAction, PayloadAction } from 'typesafe-actions';
import cardServices from 'library/common/services/cards.service';
import { logger } from 'library/utils/lintersSugar';
import { mapCardActivationMessage } from 'library/common/sockets/messages/activation.message';
import { mapCommentAddMessage } from 'library/common/sockets/messages/comment.message';
import { selectUserId } from 'library/common/selectors/user.selector';
import { mapChildCommentAddMessage } from 'library/common/sockets/messages/childComment.message';
import { mapLikeCommentMessage } from 'library/common/sockets/messages/likeComment.message';
import {
	isActivationMessage, isChildCommentMessage, isCommentMessage, isLikeCommentMessage, isSubscriptionMessage,
} from 'library/common/sockets/message';
import commentsQueryKeys from 'library/common/data/comments/queryKeys';
import { addNotification } from 'library/common/actions/app.action';
import appActionTypes from 'library/common/constants/app.constant';
import { mapSubscriptionMessage } from '../sockets/messages/subscription.message';

type Action = PayloadAction<any, any> | EmptyAction<string>;

// TODO добавлять уведомления в стор и сделать список уведомлений
const notificationsMiddleware = (queryClient: QueryClient) => (middlewareApi: MiddlewareAPI<Dispatch<any>>) => (next: Dispatch<AnyAction>) => (action: Action) => {
	if (action.type === appActionTypes.NOTIFICATION_EVENT) {
		const userId = selectUserId(middlewareApi.getState());
		// TODO не обрабатывать собственные действия
		// TODO Удалить проверку автора после правок на стороне бека
		if ('payload' in action && userId) {
			if (isCommentMessage(action.payload)) {
				const messageData = mapCommentAddMessage(action.payload.payload);

				// TODO Удалить проверку автора после правок на стороне бека
				if (userId !== messageData.authorId) {
					showSnackbarMessage(`${messageData.author} left a comment: ${messageData.commentText}`, 'info', 5000);

					const key = commentsQueryKeys.byCardId(messageData.cardId);
					queryClient.invalidateQueries(key);

					middlewareApi.dispatch(addNotification(messageData));
				}
			}

			if (isChildCommentMessage(action.payload)) {
				const messageData = mapChildCommentAddMessage(action.payload.payload);

				// TODO Удалить проверку автора после правок на стороне бека
				if (userId !== messageData.authorId) {
					showSnackbarMessage(`${messageData.author} replied a comment: ${messageData.parentComment}`, 'info', 5000);

					if (messageData.parentCommentId) {
						const key = commentsQueryKeys.byCommentId(messageData.parentCommentId);
						queryClient.invalidateQueries(key);

						middlewareApi.dispatch(addNotification(messageData));
					}
				}
			}

			if (isLikeCommentMessage(action.payload)) {
				const messageData = mapLikeCommentMessage(action.payload.payload);

				// TODO Удалить проверку автора после правок на стороне бека
				if (userId !== messageData.authorId) {
					showSnackbarMessage(`${messageData.author} liked a comment: ${messageData.commentText}`, 'info', 5000);

					middlewareApi.dispatch(addNotification(messageData));
				}
			}

			if (isActivationMessage(action.payload)) {
				const messageData = mapCardActivationMessage(action.payload.payload);
				// TODO Удалить проверку автора после правок на стороне бека
				if (userId !== messageData.authorId) {
				// TODO это вообще пиздец какой-то, после того как на беке смогут отправлять название карты - удалить
					cardServices.getCardById(messageData.cardId)
						.then((result) => {
							const messageDataWithCard = {
								...messageData,
								cardTitle: result.data.payload.title,
							};

							showSnackbarMessage(`${messageData.author} activated card "${messageDataWithCard.cardTitle}"`, 'info', 5000);

							middlewareApi.dispatch(addNotification(messageData));
						}).catch((error) => {
							logger(error);
						});
				}
			}

			if (isSubscriptionMessage(action.payload)) {
				const messageData = mapSubscriptionMessage(action.payload.payload);

				showSnackbarMessage(`${messageData.author} started following you`, 'info', 5000);

				middlewareApi.dispatch(addNotification(messageData));
			}
		}
	}

	next(action);
};

export default notificationsMiddleware;
