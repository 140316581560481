import { Sections } from 'pages/Landing/components/NavContext';

import { ISection } from './type';

const sections: ISection[] = [
	{
		id: Sections.ABOUT_US,
		name: 'About us',
	},
	{
		id: Sections.ADVANTAGES,
		name: 'Advantages',
	},
	{
		id: Sections.OUR_TEAM,
		name: 'Our team',
	},
	{
		id: Sections.CORE_VALUES,
		name: 'Our values',
	},
	{
		id: Sections.FAQ,
		name: 'FAQ',
	},
];

export default sections;
