import { isNil } from 'lodash';
import {createSelector} from 'reselect';

import { UUID } from 'library/common/types/app.d';
import {RootState} from 'main/rootReducer';

export const interactPermissions: (state: RootState, id: UUID) => boolean = createSelector(
	[
		(state: RootState) => state.user.isAuth,
		(state: RootState) => state.user.userData.id,
		(state: RootState, userId?: UUID) => userId,
	],
	(isAuth, myId, userId) => !!userId && myId !== userId && isAuth,
);

export const selectIsAuthorized = (state: RootState) => state.user.isAuth;

export const selectLogoUrl = (state: RootState) => state.user.userData.logoURL;

export const selectUserId = (state: RootState) => state.user.userData.id;

export const selectUserData = (state: RootState) => state.user.userData;

export const selectFollowings = (state: RootState) => state.user.userData.followings;

export const selectFollowers = (state: RootState) => state.user.userData.followers;

export const selectIsSubscribed: (state: RootState, userId?: UUID) => boolean = createSelector(
	[
		(state: RootState) => selectFollowings(state),
		(_state: RootState, userId?: UUID) => userId,
	],
	(followings, userId) => (isNil(userId) ? false : followings.some((following) => following === userId)),
);
