import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import Link from '@mui/material/Link';

interface IStyledLink {
    label: string;
    to: string;
}

const StyledLink = ({label, to}: IStyledLink) => (
	<Link
		component={RouterLink}
		underline="none"
		to={to}
		sx={{
			color: (theme) => theme.palette.text.primary,
			fontSize: '12px',
			fontWeight: 500,
		}}
	>
		{label}
	</Link>
);

export default StyledLink;
