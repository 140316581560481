import {UiContainer, UiNode} from '@ory/kratos-client/api';

const extractCSRFToken = (nodes: UiNode[]): string => {
	const csrfNode = nodes.find((node) => (
		'name' in node.attributes && node.attributes?.name === 'csrf_token'
	));

	if (csrfNode && 'value' in csrfNode.attributes && csrfNode.attributes.value) {
		return csrfNode.attributes.value;
	}

	return 'Not found!';
};

const extractMessage = (nodes: UiContainer, defaultMessage: string): string => {
	if (nodes.messages) {
		const message = nodes.messages.reduce((accumulator, currentValue) => `${currentValue.text} `, '');
		if (!!message && message.length > 0) {
			return message.trim();
		}
	}

	return defaultMessage;
};

export default {
	extractMessage,
	extractCSRFToken,
};
