// eslint-disable-next-line no-shadow
enum NotificationTypes {
    SUBSCRIPTION = 'SUBSCRIPTION',
    COMMENT = 'COMMENT',
    CHILD_COMMENT = 'CHILD_COMMENT',
    ACTIVATION = 'ACTIVATION',
    LIKE = 'LIKE',
}

export default NotificationTypes;
