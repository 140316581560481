import { v1 as uuidv1 } from 'uuid';
import { IBaseNotificationMessage, INotificationSubscription } from 'library/common/types/notifications/notifications.d';

import { ISubscriptionMessagePayload } from 'library/common/types/user.d';
import { mapAuthorData } from 'library/utils/mappers/user.mapper';
import NotificationTypes from 'library/common/types/notifications/notificationTypes';
import { MessageEventCode } from '../eventCode';

export type ISubscriptionMessage = IBaseNotificationMessage<
    MessageEventCode.SUBSCRIPTION,
    ISubscriptionMessagePayload>;

export const mapSubscriptionMessage = (data: ISubscriptionMessagePayload): INotificationSubscription => ({
	...mapAuthorData(data.author),
	// TODO подстановку id удалить после добаления хранилица у бекенда (должны и id добавить)
	type: NotificationTypes.SUBSCRIPTION,
	id: uuidv1(),
	createdAt: new Date().getTime() as unknown as Date,
});
