import React from 'react';
import {
	Box, Button, Skeleton, Stack, Typography, Theme, Link,
} from '@mui/material';
import {Link as RouterLink} from 'react-router-dom';

import CopyToClipboard from 'library/common/components/CopyToClipboard';
import SubtractIcon from 'resources/icons/Subtract';
import { getReferralLink } from 'library/common/services/urls';
import { UUID } from 'library/common/types/app.d';
import { useTranslation } from 'react-i18next';
import Bonuses from 'resources/image/Bonuses_mobile.svg';

const referredUsersCountBlockStyles = {
	px: 2.5,
	flex: 1,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	height: '40px',
	borderRadius: 1,
	backgroundColor: (theme: Theme) => `${(theme.palette.primary as any).darker}30`,
};

interface IProps {
	id?: UUID;
	isPending: boolean;
	isWodaBalanceLoading: boolean;
	referredUsersCount?: number;
	wodaBalance?: number;
}

const ReferralLink: React.FunctionComponent<IProps> = ({
	id,
	isPending,
	isWodaBalanceLoading,
	referredUsersCount,
	wodaBalance,
}) => {
	const { t } = useTranslation();

	return (
		<Box>
			<Box sx={{ position: 'relative' }}>
				<Box
					sx={{
						width: '100%',
						px: 2,
						pt: 3,
						pb: 1,
					}}
					component="img"
					src={Bonuses}
					alt="Bonuses card"
				/>
				<Typography
					variant="h4"
					sx={{
						position: 'absolute',
						top: 34,
						right: 34,
						color: ({palette}) => palette.secondary.main,
					}}
				>
					{!isWodaBalanceLoading && wodaBalance}
				</Typography>
			</Box>

			<Stack sx={{
				p: 2,
				flexDirection: 'row',
				alignItems: 'center',
			}}
			>
				{isPending ? (
					<Skeleton variant="rectangular" sx={referredUsersCountBlockStyles} />
				) : (
					<Box sx={referredUsersCountBlockStyles}>
						<Typography
							variant="body1"
							sx={{ color: (theme) => (theme.palette.primary as any).darker, fontWeight: 600 }}
						>
							{t('registered_users')}
						</Typography>
						<Typography
							variant="h4"
							sx={{ color: (theme) => (theme.palette.primary as any).darker, fontWeight: 600 }}
						>
							{referredUsersCount}
						</Typography>
					</Box>
				)}
				<CopyToClipboard>
					{({ copy }) => (
						<Button
							onClick={() => {
								if (id) copy(getReferralLink(id));
							}}
							variant="contained"
							sx={{
								ml: 6,
								height: '40px',
								width: '40px',
								minWidth: 0,
								background: (theme) => (theme.palette.primary as any).darker,
								borderRadius: 1,
							}}
						>
							<SubtractIcon sx={{ fontSize: 24 }} />
						</Button>
					)}
				</CopyToClipboard>
			</Stack>
			<Box sx={{ mx: 2, height: '1px', backgroundColor: (theme) => theme.palette.action.disabled }} />

			<Typography
				variant="caption"
				sx={{ mt: 1, px: 2 }}
				color="secondary"
			>
				<Link
					color="secondary"
					component={RouterLink}
					to={{ pathname: '/terms', hash: '#referral_program' }}
				>
					Referral program
				</Link>
				: Earn for Each Registration
			</Typography>
		</Box>

	);
};

export default ReferralLink;
