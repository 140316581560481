import React from 'react';

import { Typography } from '@mui/material';

import { TransitionActionType } from 'library/common/types/wodaprograms.d';
import wodaprogramActionTypes from 'library/common/constants/wodaprograms.constant';

import Base from './Base';

interface IProps {
    type: TransitionActionType;
	amount: number;
}

const ComingSoon: React.FunctionComponent<IProps> = ({ type, amount }) => (
	<Base
		isBlur
		title={wodaprogramActionTypes[type]}
		amount={amount}
		isComingSoon
		description={(
			<Typography
				variant="body1"
				sx={{ color: ({ palette }) => palette.text.primary }}
			>
				Coming soon
			</Typography>
		)}
	/>
);

export default ComingSoon;
