import React from 'react';
import {
	Skeleton as MaterialSkeleton, Card as MaterialCard, Stack,
} from '@mui/material';

const Skeleton = () => (
	<MaterialCard sx={{width: '290px'}}>
		<MaterialSkeleton variant="rectangular" height={340} />
		<Stack
			height={140}
			direction="column"
			sx={{
				pt: 2, pb: 2, pl: 2, pr: 2,
			}}
		>
			<MaterialSkeleton animation="wave" height={64} />

			<MaterialSkeleton animation="wave" height={32} />
		</Stack>
	</MaterialCard>
);

export default Skeleton;
