import { v1 as uuidv1 } from 'uuid';

import { ICommentEntity } from 'library/common/types/cards/comments.d';
import { IBaseNotificationMessage, INotificationChildComment } from 'library/common/types/notifications/notifications.d';
import NotificationType from 'library/common/types/notifications/notificationTypes';
import { mapAuthorData } from 'library/utils/mappers/user.mapper';

import { MessageEventCode } from '../eventCode';

export type IChildCommentMessage = IBaseNotificationMessage<
    MessageEventCode.CHILD_COMMENT,
    ICommentEntity>;

export const mapChildCommentAddMessage = (data: ICommentEntity): INotificationChildComment => ({
	...mapAuthorData(data.commentUserInfo.author),
	// TODO подстановку id удалить после добаления хранилица у бекенда (должны и id добавить)
	id: uuidv1(),
	type: NotificationType.CHILD_COMMENT,
	commentText: data.comment.text,
	createdAt: data.comment.created,
	parentComment: data.comment.parentComment?.text,
	parentCommentId: data.comment.parentComment?.id,
	cardId: data.comment.parentComment?.cardId,
	viewed: false,
});
