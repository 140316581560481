import React, { useState } from 'react';
import {
	Box, Chip, CircularProgress, Stack, TextField, Typography,
} from '@mui/material';
import { useUpdateEffect } from 'react-use';

import {IResult} from 'library/common/types/cards/cards.d';

import ExcludeIcon from 'resources/icons/ExcludeIcon';

import { UUID } from 'library/common/types/app.d';
import useResult from 'library/common/hook/useResult';
import FieldErrorText from '../FieldErrorText';
import NewResultDialog from './NewResultDialog';

interface IResultsSelector {
    categoryId?: UUID,
    value?: IResult,
    error?: string,
    setValue(id?: IResult): void,
}

const ResultsSelector = ({
	value, setValue, categoryId, error,
}: IResultsSelector) => {
	const [addCustomResultWindow, setAddCustomResultWindow] = useState(false);

	const noCategory = categoryId === undefined;

	const {
		results, resultsIsPending, setSearchPhrase, addNewResult,
	} = useResult(categoryId);

	const handleClick = (clickedValue: IResult) => {
		if (value?.id === clickedValue.id) {
			setValue(undefined);
			return;
		}

		setValue(clickedValue);
	};

	const addNewOption = (newOption: IResult) => {
		addNewResult(newOption);
		handleClick(newOption);
	};

	useUpdateEffect(
		() => setValue(undefined),
		[categoryId],
	);

	return (
		<Box>
			<Typography
				sx={{
					fontWeight: 500,
					color: (theme) => theme.palette.text.secondary,
					mb: 1,
				}}
				variant="h6"
			>
				Result
			</Typography>

			<Typography
				sx={{
					display: 'block',
					color: (theme) => theme.palette.text.secondary,
					mb: 3,
				}}
				variant="caption"
			>
				What other users will achieve with your WodaQard
			</Typography>

			<Stack direction="row" spacing={1}>
				<TextField
					id="outlined-basic"
					label="Find WodaQard result"
					variant="outlined"
					size="small"
					fullWidth
					disabled={noCategory}
					onChange={(e) => setSearchPhrase(e.target.value)}
					error={!!error}
					sx={{
						fieldset: {
							borderColor: (theme) => `${theme.palette.secondary.dark}4D`,
						},
					}}
					InputProps={{
						endAdornment: (
							<>
								{resultsIsPending && !noCategory && <CircularProgress color="primary" size={20} />}
							</>
						),
					}}
				/>
			</Stack>
			{
				!noCategory && (
					<Box
						sx={{
							mt: 2,
							display: 'flex',
							flexDirection: 'row',
							flexWrap: 'wrap',
							padding: 1,
							maxHeight: 200,
							overflowX: 'auto',
							alignItems: 'center',
							minHeight: '56px',

							borderWidth: '1px',
							borderStyle: 'solid',
							borderColor: (theme) => `${`${theme.palette.secondary.dark}1A`}`,
							borderRadius: '4px',

							'& .MuiChip-filled': {
								color: 'white',
							},
						}}
					>
						{
							((results.length > 0) ? (
								results.map((option) => (
									<Chip
										key={option.id}
										sx={{ margin: 0.5 }}
										disabled={resultsIsPending}
										color="secondary"
										variant={value?.id === option.id ? 'filled' : 'outlined'}
										label={
											<Typography variant="body2">{`${option.title}`}</Typography>
										}
										clickable
										onClick={() => handleClick(option)}
									/>
								))
							) : (
								value && (
									<Chip
										key={value.id}
										sx={{
											margin: 0.5,
										}}
										color="secondary"
										variant="filled"
										label={
											<Typography variant="body2">{`${value.title}`}</Typography>
										}
										clickable
										onClick={() => handleClick(value)}
									/>
								)
							))
						}

						{
							!resultsIsPending && (
								<Chip
									sx={{
										margin: 0.5,
										px: 1,
									}}
									icon={(
										<ExcludeIcon
											sx={{ fill: (theme) => theme.palette.secondary.light}}
											fontSize="small"
										/>
									)}
									disabled={resultsIsPending}
									color="secondary"
									variant="outlined"
									label={
										<Typography variant="body2">add new result</Typography>
									}
									clickable
									onClick={() => setAddCustomResultWindow(true)}
								/>
							)
						}

						{resultsIsPending && !noCategory && (
							<CircularProgress size={16} color="primary" sx={{margin: '0 8px'}} />
						)}
					</Box>
				)
			}
			<Box
				sx={{
					display: error ? 'flex' : 'none',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<FieldErrorText message={error} />
			</Box>

			{
				addCustomResultWindow && categoryId && (
					<NewResultDialog
						isOpen={addCustomResultWindow}
						onCloseHandle={() => setAddCustomResultWindow(false)}
						category={categoryId}
						addToOptions={addNewOption}
					/>
				)
			}
		</Box>
	);
};

export default ResultsSelector;
