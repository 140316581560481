import { showSnackbarMessage } from 'library/utils/showSnackbarMessage';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { sortBy } from 'lodash';

import cardsService from 'library/common/services/cards.service';
import { ICategory } from 'library/common/types/cards/cards.d';
import { DAY_IN_MS } from 'library/utils/converters/time.converter';

import categoriesQueryKeys from './queryKeys';

const categoriesQueryFunction = async (): Promise<ICategory[]> => {
	const result = await cardsService.getCategories();

	return sortBy(result.data.payload, (category) => category.weight);
};

const useCategories = (options?: UseQueryOptions<ICategory[]>) => useQuery(
	categoriesQueryKeys.base(),
	categoriesQueryFunction,
	{
		cacheTime: DAY_IN_MS,
		staleTime: DAY_IN_MS,
		onError: () => showSnackbarMessage('Ops, There was a problem loading categories, we are working on a fix', 'error'),
		...options,
	},
);

export default useCategories;
