import { createAction, createAsyncAction, createCustomAction } from 'typesafe-actions';

import { UUID } from '../types/app.d';
import userActionTypes from '../constants/user.constants';
import { IChangedUserData, IFullStatistics, IUserData } from '../types/user.d';

export const createUserAsync = createAsyncAction(
	userActionTypes.CREATE_USER_REQUEST,
	userActionTypes.CREATE_USER_SUCCESS,
	userActionTypes.CREATE_USER_FAILURE,
)<
	{
		method: 'password' | 'oidc',
		provider?: 'facebook' | 'google',
		email?: string,
		login?: string,
		password?: string,
		referral_id?: UUID,
	},
	undefined,
	undefined
>();

export const authorizationAsync = createAsyncAction(
	userActionTypes.AUTHORIZATION_REQUEST,
	userActionTypes.AUTHORIZATION_SUCCESS,
	userActionTypes.AUTHORIZATION_FAILURE,
)<
	undefined,
	IUserData & IFullStatistics,
	undefined
>();

export const changeFollowersList = createAction(userActionTypes.CHANGE_FOLLOWERS_LIST)<UUID>();

export const changeFollowingsList = createAction(userActionTypes.CHANGE_FOLLOWINGS_LIST)<UUID>();

export const loginAsync = createAsyncAction(
	userActionTypes.LOGIN_REQUEST,
	userActionTypes.LOGIN_SUCCESS,
	userActionTypes.LOGIN_FAILURE,
)<{email: string, password: string}, undefined, undefined>();

export const logoutAsync = createAsyncAction(
	userActionTypes.LOGOUT_REQUEST,
	userActionTypes.LOGOUT_SUCCESS,
	userActionTypes.LOGOUT_FAILURE,
)<undefined, undefined, undefined>();

export const changeUserData = createCustomAction(userActionTypes.CHANGE_USER_DATA,
	(data: IChangedUserData) => ({data}));

export const setSocketAliveStatus = createCustomAction(userActionTypes.SET_SOCKET_ALIVE_STATUS,
	(status: boolean) => ({status}));

export const setUserAvatar = createCustomAction(userActionTypes.SET_AVATAR_URL, (url?: string) => ({url}));
