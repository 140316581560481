import React from 'react';
import {
	Box, Stack, SxProps, Theme,
} from '@mui/material';
import { declOfNum, getFullDuration } from 'library/utils/workWithData';

interface IProps {
    duration: number;
    totalResources: number;
    totalSteps: number;

}

const sxStyles: SxProps<Theme> = {
	flex: 1,
	minHeight: '48px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
};

const AmountData: React.FunctionComponent<IProps> = ({ duration, totalResources, totalSteps }) => (
	<Stack direction={{ xs: 'column', md: 'row' }} mt={4} sx={{ width: '100%', background: ({palette}) => (palette as any).gradients.info }}>
		<Box sx={{...sxStyles, background: 'rgba(255, 255, 255, 0.7)'}}>{`you need ${getFullDuration(duration)}`}</Box>
		<Box sx={{...sxStyles, background: 'rgba(255, 255, 255, 0.8)'}}>
			{`for ${totalSteps} ${declOfNum(totalSteps, ['step', 'steps', 'steps'])}`}
		</Box>
		<Box sx={{...sxStyles, background: 'rgba(255, 255, 255, 0.9)'}}>
			{`${totalResources} ${declOfNum(totalResources, ['component', 'components', 'components'])}`}
		</Box>
	</Stack>
);

export default AmountData;
