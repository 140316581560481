import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const AddCardIcon = (props: SvgIconProps) => (
	<SvgIcon viewBox="0 0 32 32" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.1971 6H25.5999C26.2363 6.00099 26.8463 6.25431 27.2962 6.70441C27.7462 7.15451
			27.9993 7.76466 28 8.40108V18.9623C27.999 19.5985 27.7458 20.2084 27.2959 20.6583C26.846
			21.1082 26.2361 21.3614 25.5999 21.3624H16.3599C16.41 21.032 16.436 20.6937 16.436
			20.3493C16.436 18.0138 15.2404 15.9575 13.4278 14.7592C13.7369 14.4805 14.1392 14.3251
			14.5574 14.3251C15.0038 14.3251 15.4319 14.502 15.7481 14.8171L16.9102 15.9726L20.4762
			11.6925C20.6329 11.504 20.829 11.3522 21.0506 11.2476C21.2723 11.143 21.5142 11.0882 21.7593
			11.0871C22.0035 11.0884 22.2447 11.1417 22.4667 11.2435C22.6888 11.3453 22.8866 11.4933
			23.047 11.6775L26.0797 15.2163V8.40014C26.0797 8.27288 26.0291 8.15083 25.9392 8.06084C25.8492
			7.97086 25.7271 7.9203 25.5999 7.9203H11.1971C11.134 7.92018 11.0714 7.93253 11.013
			7.95665C10.9546 7.98076 10.9016 8.01617 10.8569 8.06084C10.8122 8.10551 10.7768 8.15856
			10.7527 8.21695C10.7286 8.27533 10.7162 8.33791 10.7163 8.40108V13.7237C10.3975 13.6771
			10.0714 13.6529 9.7397 13.6529C9.41972 13.6529 9.10499 13.6754 8.79699 13.7188V8.40108C8.79773
			7.76466 9.05081 7.15451 9.50074 6.70441C9.95066 6.25431 10.5607 6.00099 11.1971 6ZM9.73971
			26.089C12.9097 26.089 15.4794 23.5192 15.4794 20.3493C15.4794 17.1793 12.9097 14.6096 9.73971
			14.6096C6.56975 14.6096 4 17.1793 4 20.3493C4 23.5192 6.56975 26.089 9.73971 26.089ZM8.78309
			17.4794C8.78309 16.9511 9.21138 16.5228 9.73971 16.5228C10.268 16.5228 10.6963 16.9511 10.6963
			17.4794V19.3927H12.6096C13.1379 19.3927 13.5662 19.821 13.5662 20.3493C13.5662 20.8776 13.1379
			21.3059 12.6096 21.3059H10.6963V23.2191C10.6963 23.7475 10.268 24.1758 9.73971 24.1758C9.21138
			24.1758 8.78309 23.7475 8.78309 23.2191V21.3059H6.86985C6.34153 21.3059 5.91324 20.8776 5.91324
			20.3493C5.91324 19.821 6.34153 19.3927 6.86985 19.3927H8.78309V17.4794ZM14.6652 12.3979C14.3494
			12.6089 13.9781 12.7215 13.5983 12.7215C13.0892 12.721 12.6011 12.5186 12.241 12.1585C11.881
			11.7985 11.6785 11.3104 11.6781 10.8012C11.6781 10.4214 11.7907 10.0501 12.0017 9.73435C12.2127
			9.41856 12.5126 9.17244 12.8635 9.02709C13.2144 8.88175 13.6005 8.84372 13.973 8.91782C14.3455
			8.99191 14.6876 9.1748 14.9562 9.44336C15.2248 9.71192 15.4077 10.0541 15.4818 10.4266C15.5558
			10.7991 15.5178 11.1852 15.3725 11.5361C15.2271 11.887 14.981 12.1869 14.6652 12.3979Z"
		/>
	</SvgIcon>
);

export default AddCardIcon;
