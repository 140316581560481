import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const ArrowIcon = ({...props}: SvgIconProps) => (
	<SvgIcon viewBox="0 0 8 14" {...props}>
		<path
			d="M6.7619 0.205541C6.89604 0.0749226 7.08984 0 7.29355 0C7.68371 0 8 0.269473 8 0.601885V0.726885C8
            0.902953 7.92499 1.07304 7.78891 1.20554L2.02729 6.81562C1.91887 6.92119 1.91887 7.07881 2.02729
            7.18438L7.78891 12.7945C7.92499 12.927 8 13.097 8 13.2731V13.3981C8 13.7305 7.68371 14 7.29355
            14C7.08984 14 6.89604 13.9251 6.7619 13.7945L0.162628 7.36876C-0.0542094 7.15763 -0.0542094 6.84237
            0.162628 6.63124L6.7619 0.205541Z"
		/>
	</SvgIcon>
);

export default ArrowIcon;
