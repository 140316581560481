import React, { SyntheticEvent, useRef } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import TouchRipple, { TouchRippleActions } from '@mui/material/ButtonBase/TouchRipple';

interface IProps {
    title: string;
    icon?: string;
	onSelect(): void;
}

const sizes = {
	width: {
		xs: 50,
	},
	height: {
		xs: 50,
	},
};

const ResourceItem: React.FunctionComponent<IProps> = ({icon, title, onSelect}) => {
	const rippleRef = useRef<TouchRippleActions>(null);

	const onRippleStart = (e: SyntheticEvent) => {
		rippleRef?.current?.start(e);
	};

	const onRippleStop = (e: SyntheticEvent) => {
		rippleRef?.current?.stop(e);
	};

	return (
		<Stack
			direction="row"
			justifyContent="flex-start"
			alignItems="center"
			spacing={2}
			onClick={onSelect}
			onMouseDown={onRippleStart}
			onMouseUp={onRippleStop}
			onMouseLeave={onRippleStop}
			sx={{
				position: 'relative',
				cursor: 'pointer',
			}}
		>
			<Box
				component="img"
				src={icon}
				alt={title}
				sx={{...sizes}}
			/>
			<Typography
				gutterBottom
				variant="h6"
				component="h6"
				sx={{
					color: (theme) => theme.palette.secondary.main,
					fontWeight: 600,
					overflow: 'hidden',
					display: '-webkit-box',
					textOverflow: 'ellipsis',
					whiteSpace: 'normal',
					WebkitLineClamp: '3',
					WebkitBoxOrient: 'vertical',
				}}
			>
				{title}
			</Typography>
			<TouchRipple style={{margin: 0}} ref={rippleRef} center={false} />
		</Stack>
	);
};

export default ResourceItem;
