// eslint-disable-next-line no-shadow
enum userActionTypes {
	CREATE_USER_REQUEST = '@@USER/CREATE_USER_REQUEST',
	CREATE_USER_SUCCESS = '@@USER/CREATE_USER_SUCCESS',
	CREATE_USER_FAILURE = '@@USER/CREATE_USER_FAILURE',

	LOGIN_REQUEST = '@@USER/LOGIN_REQUEST',
	LOGIN_SUCCESS = '@@USER/LOGIN_SUCCESS',
	LOGIN_FAILURE = '@@USER/LOGIN_FAILURE',

	LOGOUT_REQUEST = '@@USER/LOGOUT_REQUEST',
	LOGOUT_SUCCESS = '@@USER/LOGOUT_SUCCESS',
	LOGOUT_FAILURE = '@@USER/LOGOUT_FAILURE',

	AUTHORIZATION_REQUEST = '@@USER/AUTHORIZATION_REQUEST',
	AUTHORIZATION_SUCCESS = '@@USER/AUTHORIZATION_SUCCESS',
	AUTHORIZATION_FAILURE = '@@USER/AUTHORIZATION_FAILURE',

	CHANGE_USER_DATA = '@@USER/CHANGE_USER_DATA',
	SET_SOCKET_ALIVE_STATUS = '@@USER/SET_SOCKET_ALIVE_STATUS',

	SET_AVATAR_URL = '@@USER/SET_AVATAR_URL',
	CHANGE_FOLLOWERS_LIST = '@@USER/CHANGE_FOLLOWERS_LIST',
	CHANGE_FOLLOWINGS_LIST = '@@USER/CHANGE_FOLLOWINGS_LIST',
}

export default userActionTypes;
