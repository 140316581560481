import React from 'react';
import { Box, Container } from '@mui/material';

import { APPBAR_MOBILE, MOBILE_SIDEBAR_HEIGHT } from 'resources/styles/constants';
import useWQReels from 'library/common/hook/useWQReels';
import LoadingWrapper from 'library/common/components/LoadingWrapper';
import WQMobileReel from './components/WQMobileReel';

interface IProps {}

const MobileReels: React.FunctionComponent<IProps> = () => {
	const { cardsState, isPending, onLikeHandle } = useWQReels();

	return (
		<Container
			disableGutters
			sx={{
				width: '100vw',
				height: {
					xs: `calc(100vh - ${MOBILE_SIDEBAR_HEIGHT}px - ${APPBAR_MOBILE}px)`,
					md: `calc(100vh - ${APPBAR_MOBILE}px)`,
				},
				overflowY: 'auto',
				overflowX: 'hidden',
				scrollSnapType: 'y mandatory',
				WebkitOverflowScrolling: 'touch',

				'&::-webkit-scrollbar': {
					display: 'none',
				},
			}}
		>
			<LoadingWrapper showLoader={isPending}>
				{
					cardsState.map((card) => (
						<Box
							key={card.id}
							sx={{
								p: 2,
								scrollSnapAlign: 'start',
								width: '100%',
								height: '100%',
							}}
						>
							<WQMobileReel cardsState={card} onLikeHandle={onLikeHandle} />
						</Box>
					))
				}
			</LoadingWrapper>
		</Container>
	);
};

export default MobileReels;
