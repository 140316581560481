import { useCallback, useEffect, useState } from 'react';

import { logger } from 'library/utils/lintersSugar';

import cardsService from '../services/cards.service';
import { IPoorCard } from '../types/cards/cards.d';

// eslint-disable-next-line no-shadow
export enum ModeType {
    PUBLISHED = 'published',
    ACTIVATED = 'activated',
    FAVORITE = 'favorite',
}

interface IProps {
	authorId: string;
	defaultMode?: ModeType;
}

interface IResult {
	mode: ModeType;
    activatedCards: IPoorCard[];
    publishedCards: IPoorCard[];
    favoriteCards: IPoorCard[];
	publishedCardsCount: number;
    isPending: boolean;
    setPublishedModeHandler(): void;
    setActivatedModeHandler(): void;
    setFavoriteModeHandler(): void;
	getPublishedCards(searchValue?: string): void;
}

const useCard: (props: IProps) => IResult = ({ authorId, defaultMode = ModeType.PUBLISHED }) => {
	const [activatedCards, setActivatedCards] = useState<IPoorCard[]>([]);
	const [publishedCards, setPublishedCards] = useState<IPoorCard[]>([]);
	const [favoriteCards, setFavoriteCards] = useState<IPoorCard[]>([]);

	const [publishedCardsCount, setPublishedCardsCount] = useState(0);
	const [isPending, setIsPending] = useState(true);
	const [mode, setMode] = useState<ModeType>(defaultMode);

	const setPublishedModeHandler = useCallback(
		() => {
			if (ModeType.PUBLISHED === mode) {
				return;
			}

			setIsPending(true);
			setMode(ModeType.PUBLISHED);
		},
		[setMode, mode],
	);

	const setActivatedModeHandler = useCallback(
		() => {
			if (ModeType.ACTIVATED === mode) {
				return;
			}

			setIsPending(true);
			setMode(ModeType.ACTIVATED);
		},
		[setMode, mode],
	);

	const setFavoriteModeHandler = useCallback(
		() => {
			if (ModeType.FAVORITE === mode) {
				return;
			}

			setIsPending(true);
			setMode(ModeType.FAVORITE);
		},
		[setMode, mode],
	);

	const getActivatedCards = useCallback(
		() => {
			cardsService
				.getActivationsList()
				.then((res) => setActivatedCards(res.data.payload))
				.catch((error) => logger(error, 'error'))
				.finally(() => setIsPending(false));
		},
		[setActivatedCards, setIsPending],
	);

	const getFavoriteCards = useCallback(
		() => {
			cardsService
				.getFavoriteList()
				.then((res) => setFavoriteCards(res.payload))
				.catch((error) => logger(error, 'error'))
				.finally(() => setIsPending(false));
		},
		[setFavoriteCards, setIsPending],
	);

	const getPublishedCards = useCallback(
		(searchValue?: string) => {
			cardsService.getUserCreatedCards(authorId, searchValue)
				.then((res) => {
					setPublishedCards(res.data.payload);
					setPublishedCardsCount(res.data.total_elements);
				})
				.catch((error) => logger(error, 'error'))
				.finally(() => setIsPending(false));
		},
		[authorId, setIsPending, setPublishedCards],
	);

	useEffect(() => {
		if (mode === ModeType.ACTIVATED) {
			getActivatedCards();
		} else if (mode === ModeType.PUBLISHED) {
			getPublishedCards();
		} else if (
			mode === ModeType.FAVORITE
		) {
			getFavoriteCards();
		}
	}, [getActivatedCards, getPublishedCards, getFavoriteCards, mode]);

	return {
		mode,
		activatedCards,
		publishedCards,
		favoriteCards,
		publishedCardsCount,
		isPending,
		setActivatedModeHandler,
		setPublishedModeHandler,
		setFavoriteModeHandler,
		getPublishedCards,
	};
};

export default useCard;
