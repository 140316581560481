import React, { useEffect, useRef } from 'react';
import {
	Container, Link, Theme, Typography,
} from '@mui/material';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import isNonNullable from 'library/utils/isNonNullable';
import Template from './Template';

const hStyles = {
	fontWeight: 500,
	fontSize: '1.25rem',
	lineHeight: 1.6,
	letterSpacing: '0.0075em',
};

const styles = {
	fontSize: '1rem',
	lineHeight: '1.5',
	letterSpacing: '0.00938em',
	color: (theme: Theme) => theme.palette.grey['700'],
};

const Block = ({top, bottom}: {top: string, bottom: string}) => (
	<>
		<Typography
			variant="body1"
			sx={{
				...styles, mt: '0.8rem', fontWeight: 500, color: (theme: Theme) => theme.palette.grey['900'],
			}}
		>
			{top}
		</Typography>
		<Typography variant="body1" sx={{ ...styles, mb: '0.8rem' }}>
			{bottom}
		</Typography>
	</>
);

const TermsOfUse = () => {
	const { hash } = useLocation();

	const referralProgramRef = useRef<HTMLSpanElement>(null);

	useEffect(() => {
		if (hash === '#referral_program' && isNonNullable(referralProgramRef.current)) {
			referralProgramRef.current.scrollIntoView();
		}
	}, [hash]);

	return (
		<Template>
			<Container sx={{pt: 2, pb: 2}}>
				<Typography variant="h3" sx={{fontWeight: 700}}>
					Terms of Service
				</Typography>

				<Block
					top="Welcome to WodaQota!"
					bottom="This section outlines our relationship with you. It includes a description of the Service,
				defines our Agreement, and names your service provider."
				/>
				<Block
					top="Who May Use the Service?"
					bottom="This section sets out certain requirements for use of the Service,
				and defines categories of users."
				/>
				<Block
					top="Your Use of the Service"
					bottom="This section explains your rights to use the Service, and the conditions that
				apply to your use of the Service. It also explains how we may make changes to the Service."
				/>
				<Block
					top="Your Content and Conduct"
					bottom="This section applies to users who provide Content to the Service. It defines the scope of the
				permissions that you grant by uploading your Content, and includes your agreement not to upload
				anything that infringes on anyone else’s rights."
				/>
				<Block
					top="Account Suspension and Termination"
					bottom="This section explains how you and WodaQota may terminate this relationship."
				/>
				<Block
					top="About Software in the Service"
					bottom="This section includes details about software on the Service."
				/>
				<Block
					top="Other Legal Terms"
					bottom="This section includes our service commitment to you.
				It also explains that there are some things we will not be responsible for."
				/>
				<Block
					top="About this Agreement"
					bottom="This section includes some further important details about our contract,
				including what to expect if we need to make changes to these Terms; or which law applies to them."
				/>

				<Typography variant="h6" sx={{ ...hStyles, m: '0.8rem 0' }}>
					Welcome to WodaQota!
				</Typography>

				<Block
					top="Introduction"
					bottom="Thank you for using the WodaQota platform and the products,
				services and features we make available to you as part of the platform (collectively, the “Service”)."
				/>
				<Block
					top="Our Service"
					bottom="The Service allows you to discover, watch and share created cards and other content,
				provides a forum for people to connect, inform, and inspire others across the globe, and acts
				as a distribution platform for original content creators and advertisers large and small."
				/>
				<Block
					top="Your Service Provider"
					bottom="The entity providing the Service is WodaQota Inc., a company operating under the laws
				of Delaware, located at 919 North Market Street, Suite 950 in the City of Wilmington,
				County of New Castle, 19801 (referred to as “WodaQota”, “we”, “us”, or “our”)."
				/>
				<Block
					top="Applicable Terms"
					bottom="Please read this Agreement carefully and make sure you understand it.
				If you do not understand the Agreement, or do not accept any part of it,
				then you may not use the Service."
				/>

				<Typography variant="h6" sx={{ ...hStyles, m: '0.8rem 0'}}>
					Who may use the Service?
				</Typography>

				<Block
					top="Age Requirements"
					bottom="You may use the Service if you are at least 13 years old; however,
				children of all ages may use the Service if enabled by a parent or legal guardian."
				/>
				<Block
					top="Permission by Parent or Guardian"
					bottom="If you are under 18, you must have your parent or legal guardian&apos;s permission
				to use the Service. Please have them read this Agreement with you.If you are a
				parent or legal guardian of a user under the age of 18, by allowing your child to use the
				Service, you are subject to the terms of this Agreement and responsible for
				your child’s activity on the Service."
				/>
				<Block
					top="Businesses"
					bottom="If you are using the Service on behalf of a company or organization,
				you confirm to us that you have authority to act on behalf of that entity,
				and that entity accepts this Agreement."
				/>

				<Typography variant="h6" sx={{ ...hStyles, m: '0.8rem 0'}}>
					Your Use of the Service
				</Typography>

				<Block
					top="Content on the Service"
					bottom="The content on the Service includes videos, audio (for example music and other sounds),
				graphics, photos, text (such as comments and scripts), branding (including trade names,
				trademarks, service marks, or logos), interactive features, software, metrics, and other
				materials whether provided by you, WodaQota or a third party (collectively, 'Content').
				Content may be provided to the Service and distributed by our users and WodaQota is a
				provider of hosting services for such Content. Content is the responsibility of the person
				or entity that provides it to the Service, but in the future, the company may reserve the right
				to potential ownership of the content. If you see any Content you believe does not comply with
				this Agreement you can report it to us. "
				/>
				<Block
					top="Permissions and Restrictions"
					bottom="You may access and use the Service as made available to you, as long as you comply with
				this Agreement and the law. You may view or listen to Content for your personal, non-commercial use."
				/>

				<Typography variant="body1" sx={{...styles, mt: '0.8rem'}}>
					The following restrictions apply to your use of the Service. You are not allowed to:
				</Typography>

				<ol>
					<li style={{fontSize: '0.9rem', color: '#616161'}}>
						<Typography variant="body1" sx={{...styles, mt: '0.8rem'}}>
							access, reproduce, download, distribute, transmit, broadcast, display, sell, license, alter,
							modify or otherwise use any part of the Service or any Content except: (a) as specifically
							permitted by the Service; (b) with prior written permission from WodaQota and, if applicable,
							the respective rights holders; or (c) as permitted by applicable law;
						</Typography>
					</li>
					<li style={{fontSize: '0.9rem', color: '#616161'}}>
						<Typography variant="body1" sx={{...styles, mt: '0.8rem'}}>
							circumvent, disable, fraudulently engage with, or otherwise interfere with the Service
							(or attempt to do any of these things), including security-related features or features
							that: (a) prevent or restrict the copying or other use of Content; or (b) limit the use of
							the Service or Content;
						</Typography>
					</li>
					<li style={{fontSize: '0.9rem', color: '#616161'}}>
						<Typography variant="body1" sx={{...styles, mt: '0.8rem'}}>
							access the Service using any automated means (such as robots, botnets or scrapers) except:
							(a) in the case of public search engines, in accordance with WodaQota’s robots.txt file; (b)
							with WodaQota’s prior written permission; or (c) as permitted by applicable law;
						</Typography>
					</li>
					<li style={{fontSize: '0.9rem', color: '#616161'}}>
						<Typography variant="body1" sx={{...styles, mt: '0.8rem'}}>
							collect or use any information that might identify a person (for example, harvesting usernames
							or faces), unless permitted by that person or allowed under section 3 above;
						</Typography>
					</li>
					<li style={{fontSize: '0.9rem', color: '#616161'}}>
						<Typography variant="body1" sx={{...styles, mt: '0.8rem'}}>
							use the Service to distribute unsolicited promotional or commercial content or
							other unwanted or mass solicitations (spam);
						</Typography>
					</li>
					<li style={{fontSize: '0.9rem', color: '#616161'}}>
						<Typography variant="body1" sx={{...styles, mt: '0.8rem'}}>
							cause or encourage any inaccurate measurements of genuine user engagement with the Service,
							including by paying people or providing them with incentives to increase account’s subscribers,
							or otherwise manipulate metrics;
						</Typography>
					</li>
					<li style={{fontSize: '0.9rem', color: '#616161'}}>
						<Typography variant="body1" sx={{...styles, mt: '0.8rem'}}>
							misuse any reporting, flagging, complaint, dispute, or appeals process, including by making
							groundless, vexatious, or frivolous submissions;
						</Typography>
					</li>
					<li style={{fontSize: '0.9rem', color: '#616161'}}>
						<Typography variant="body1" sx={{...styles, mt: '0.8rem'}}>
							use the Service to view or listen to Content other than for personal, non-commercial use
							(for example, you may not publicly screen videos or stream music from the Service); or
						</Typography>
					</li>
					<li style={{fontSize: '0.9rem', color: '#616161'}}>
						<Typography variant="body1" sx={{...styles, mt: '0.8rem'}}>
							use the Service to sell advertising, sponsorships, or promotions on any page of any website or
							application that only contains Content from the Service or where Content from the Service is
							the primary basis for such sales (for example, selling ads on a webpage where WodaQota videos
							are the only content of value).
						</Typography>
					</li>
				</ol>

				<Block
					top="Reservation"
					bottom="Any right not expressly granted to you in this Agreement remains the right of WodaQota or the
				respective rights holders. This means, for example, that using the Service does not give you ownership
				of any intellectual property rights in the Content you access (including any branding used on or
				displayed in the Service)."
				/>

				<Block
					top="Changes to the Service"
					bottom="WodaQota is constantly changing and improving the Service. We may also need to alter or
				discontinue the Service, or any part of it, in order to make performance or security improvements,
				change functionality and features, make changes to comply with law, or prevent illegal activities
				on or abuse of our systems. We will always consider and balance the impact of such changes on the
				use of the Service. Whenever reasonably possible, we will provide notice when we discontinue or
				make material changes to our Service that will have an adverse impact on the use of our Service.
				However, there will be times when we need to make such changes without notice, such as where we
				need to take action to improve the security and operability of our Service, prevent abuse,
				or meet our legal requirements."
				/>

				<Typography variant="h6" sx={{ ...hStyles, m: '0.8rem 0' }}>
					Your Content and Conduct
				</Typography>

				<Block
					top="Uploading Content"
					bottom="If you have a WodaQota account, you may be able to upload Content to the Service.
				You may use your Content to promote your business or artistic enterprise. If you choose to
				upload Content, you must not submit to the Service any Content that does not comply with this
				Agreement or the law. For example, the Content you submit must not include third-party
				intellectual property (such as copyrighted material) unless you have permission from that
				party or are otherwise legally entitled to do so. You are legally responsible for the Content
				you submit to the Service. We may use automated systems that analyze your Content to help detect
				infringement and abuse, such as spam, malware, and illegal content."
				/>
				<Block
					top="Rights you Grant"
					bottom="You retain all of your ownership rights in your Content. However, we do require you to
				grant certain rights to WodaQota and other users of the Service, as described below."
				/>
				<Block
					top="Licence to WodaQota"
					bottom="By providing Content to the Service, you grant to WodaQota a worldwide, non-exclusive,
				royalty-free, transferable, sublicensable licence to use that Content (including to reproduce,
				distribute, modify, display and perform it) for the purpose of operating, promoting, and
				improving the Service."
				/>
				<Block
					top="Licence to Other Users"
					bottom="You also grant each other user of the Service a worldwide, non-exclusive, royalty-free
				licence to access your Content through the Service, and to use that Content (including to reproduce,
				distribute, modify, display, and perform it) only as enabled by a feature of the Service."
				/>
				<Block
					top="Duration of Licence"
					bottom="The licences granted by you continue until the Content is removed as described below.
				Once removed, the licences will terminate, except where the operation of the Service, use of
				Content permitted before your removal, or the law requires otherwise. For example, removal of
				Content by you does not require WodaQota to: (a) recall Content that is being used by other users
				within any limited offline viewing functionality of the Service; or (b) delete copies we reasonably
				need to keep for legal purposes."
				/>
				<Block
					top="Right to Monetize"
					bottom="You grant to WodaQota the right to monetize your Content on the Service (and such monetization
				may include displaying ads on or within Content or charging users a fee for access). This Agreement does
				not entitle you to any payments."
				/>

				<Typography variant="body1" sx={{ ...styles, m: '0.8rem 0' }}>
					You may remove your Content from the Service at any time. You also have the option to make a copy of
					your Content before removing it. You must remove your Content if you no longer have the rights
					required by these terms.
				</Typography>

				<Block
					top="Removal of Content By WodaQota"
					bottom="If we reasonably believe that any Content is in breach of this Agreement or may cause harm to
				WodaQota, our users, or third parties, we may remove or take down some or all of such Content. We
				will notify you with the reason for our action unless we reasonably believe that to do so: (a) would
				violate the law or the direction of a legal enforcement authority, or would otherwise risk legal
				liability for WodaQota; (b) would compromise an investigation or the integrity or operation of the
				Service; or (c) would cause harm to any user, other third party, WodaQota."
				/>
				<Typography variant="body1" sx={{ ...styles }}>
					Copyright Protection
				</Typography>

				<Typography variant="h6" sx={{ ...hStyles, m: '0.8rem 0'}}>
					Account Suspension and Termination
				</Typography>

				<Block
					top="Terminations by You"
					bottom="You may stop using the Service at any time. "
				/>
				<Block
					top="Notice for Termination or Suspension"
					bottom="We will notify you with the reason for termination or suspension by WodaQota unless we
				reasonably believe that to do so: (a) would violate the law or the direction of a legal enforcement
				authority, or would otherwise risk legal liability for WodaQota or our Affiliates; (b) would
				compromise an investigation or the integrity or operation of the Service; or (c) would cause harm
				to any user, other third party, WodaQota or our Affiliates. Where WodaQota is terminating your access
				for Service changes, where reasonably possible, you will be provided with sufficient time to export
				your Content from the Service."
				/>
				<Block
					top="Effect of Account Suspension or Termination"
					bottom="If your WodaQota account is terminated or your account’s access to the Service is restricted,
				you may continue using certain aspects of the Service (such as viewing only) without an account,
				and this Agreement will continue to apply to such use."
				/>

				<Typography ref={referralProgramRef} variant="h6" sx={{ ...hStyles, m: '0.8rem 0'}}>
					WodaQota Referral Program
				</Typography>

				<Typography variant="body1" sx={{ ...styles, mb: '0.8rem' }}>
					WodaQota has own Referral Program which aimed at Service development.
				</Typography>

				<Typography variant="body1" sx={{ ...styles, mb: '0.8rem' }}>
					Any user can use his own referral link and get WodaBonuses (future WodaQoin) for registration of own friends/ relatives/ colleagues etc.
				</Typography>

				<Typography variant="body1" sx={{ ...styles, mb: '0.8rem' }}>
					WodaBonus is a digital reward for WodaQota development.
					Soon will be launched WodaBonus Programs which give additional ways for registered users to earn WodaBonuses.
				</Typography>

				<Typography variant="body1" sx={{ ...styles, mb: '0.8rem' }}>
					The market value and price of the WodaBonus will increase as well as the difficulty of obtaining it.
					Those users who choose WodaBonuses as a reward will have opportunity to take advantage of them in the future.
				</Typography>

				<Typography variant="body1" sx={{ ...styles, mb: '0.8rem' }}>
					To get WodaBonuses, a user should:
				</Typography>

				<ol>
					<li style={{fontSize: '0.9rem', color: '#616161'}}>
						<Typography variant="body1" sx={{ ...styles, mt: '0.8rem' }}>
							Be registered at WodQota;
						</Typography>
					</li>
					<li style={{fontSize: '0.9rem', color: '#616161'}}>
						<Typography variant="body1" sx={{ ...styles, mt: '0.8rem' }}>
							Copy personal referral link at Profile page (
							<Link component={RouterLink} to="/app/card/1a32f08d-b50c-4ace-b3d3-f954550b64c6">look at WodaQard with detailed instruction</Link>
							)
						</Typography>
					</li>
					<li style={{fontSize: '0.9rem', color: '#616161'}}>
						<Typography variant="body1" sx={{ ...styles, mt: '0.8rem' }}>
							Send this referral link by email or share it on own social media profiles, personal blog, and other platforms to attract new users
						</Typography>
					</li>
					<li style={{fontSize: '0.9rem', color: '#616161'}}>
						<Typography variant="body1" sx={{ ...styles, mt: '0.8rem' }}>
							WodaBonuses will be credited to the user&apos;s wallet (1 WodaBonus per 1 registered user)
						</Typography>
					</li>
					<li style={{fontSize: '0.9rem', color: '#616161'}}>
						<Typography variant="body1" sx={{ ...styles, mt: '0.8rem' }}>
							This referral campaign limits the earnings to 5 000 users per referred account, with a limit of 500 users/year
						</Typography>
					</li>
				</ol>

				<Typography variant="body1" sx={{ ...styles, mb: '0.8rem' }}>
					There is also option to get money for each registration.
				</Typography>

				<Typography variant="body1" sx={{ ...styles, mb: '0.8rem' }}>
					To get money for registration ($0.8 per 1 registration), a user should:
				</Typography>

				<ol>
					<li style={{fontSize: '0.9rem', color: '#616161'}}>
						<Typography variant="body1" sx={{ ...styles, mt: '0.8rem' }}>
							Be registered at WodQota;
						</Typography>
					</li>
					<li style={{fontSize: '0.9rem', color: '#616161'}}>
						<Typography variant="body1" sx={{ ...styles, mt: '0.8rem' }}>
							Text about his desire to participate in Referral Program for money at this email
							&nbsp;
							<Link href="mailto:support@wodaqota.com">support@wodaqota.com</Link>
							&nbsp;
							and get approval with limit of user’s attraction;
						</Typography>
					</li>
					<li style={{fontSize: '0.9rem', color: '#616161'}}>
						<Typography variant="body1" sx={{ ...styles, mt: '0.8rem' }}>
							Copy personal referral link at Profile page (
							<Link component={RouterLink} to="/app/card/1a32f08d-b50c-4ace-b3d3-f954550b64c6">look at WodaQard with detailed instruction</Link>
							)
						</Typography>
					</li>
					<li style={{fontSize: '0.9rem', color: '#616161'}}>
						<Typography variant="body1" sx={{ ...styles, mt: '0.8rem' }}>
							Send this referral link by email or share it on own social media profiles, personal blog, and other platforms to attract new users
						</Typography>
					</li>
					<li style={{fontSize: '0.9rem', color: '#616161'}}>
						<Typography variant="body1" sx={{ ...styles, mt: '0.8rem' }}>
							After limit of user’s attraction achievement, a user should make print screen of his Profile Page with number of registered users and send this print screen, own login and email at support@wodaqota.com
						</Typography>
					</li>
					<li style={{fontSize: '0.9rem', color: '#616161'}}>
						<Typography variant="body1" sx={{ ...styles, mt: '0.8rem' }}>
							The user will be offered a way to transfer money (PayPal, by card or other methods) for each registered user
						</Typography>
					</li>
				</ol>

				<Typography variant="body1" sx={{ ...styles, mb: '0.8rem' }}>
					A user can choose either a reward in WodaBonus or in money. A user cannot receive both WodaBonuses and money.
				</Typography>

				<Typography variant="body1" sx={{ ...styles, mb: '0.8rem' }}>
					The following restrictions apply to a user who choose the second option (to get money for each registration):
				</Typography>

				<ol>
					<li style={{fontSize: '0.9rem', color: '#616161'}}>
						<Typography variant="body1" sx={{ ...styles, mt: '0.8rem' }}>
							WodaQota may refuse without explanation a user to participate in a paid program;

						</Typography>
					</li>
					<li style={{fontSize: '0.9rem', color: '#616161'}}>
						<Typography variant="body1" sx={{ ...styles, mt: '0.8rem' }}>
							If a user choose an option to get money for each registration, WodaBonuses will continue to credit at
							user’s wallet but after money transfer to a user , WodaBonuses will be withdrawn from user’s wallet;
						</Typography>
					</li>
					<li style={{fontSize: '0.9rem', color: '#616161'}}>
						<Typography variant="body1" sx={{ ...styles, mt: '0.8rem' }}>
							If a user hasn’t achieved limit of user’s attraction got from
							&nbsp;
							<Link href="mailto:support@wodaqota.com">support@wodaqota.com</Link>
							, he/she can also pretend at payment. But after a user send own print screen of his Profile
							Page with number of registered users the Referral Program will be terminated and all other
							attracted users will not be paid by money, but will be rewarded by WodaBonuses;
						</Typography>
					</li>
				</ol>

				<Typography variant="h6" sx={{ ...hStyles, m: '0.8rem 0'}}>
					Other Legal Terms
				</Typography>

				<Block
					top="Warranty"
					bottom="We provide the Service with reasonable care and skill."
				/>
				<Block
					top="Disclaimer"
					bottom="By law, consumers have certain rights that cannot be excluded or altered by a contract.
				Nothing in this Agreement affects those rights you may have as a consumer. Other than as expressly
				stated in this Agreement or as required by law, WodaQota does not make any specific promises about
				the Service. For example, we don’t make any promises about: the Content provided through the Service;
				the specific features of the Service, or its accuracy, reliability, availability, or ability to meet
				your needs; or that any Content you submit will be accessible or stored on the Service."
				/>
				<Block
					top="Limitation of Liability"
					bottom="All users: Nothing in this Agreement is intended to exclude or limit any party’s liability for:
				death or personal injury; fraud; fraudulent misrepresentation; or any liability that cannot be excluded
				or limited by law."
				/>

				<Typography variant="body1" sx={{ ...styles, mt: '0.8rem' }}>
					To the extent permitted by applicable law, WodaQota and its Affiliates will not be responsible for:
				</Typography>
				<ol>
					<li style={{fontSize: '0.9rem', color: '#616161'}}>
						<Typography variant="body1" sx={{ ...styles, mt: '0.8rem' }}>
							losses that were not caused by WodaQota or its Affiliates’ breach of this Agreement;
						</Typography>
					</li>
					<li style={{fontSize: '0.9rem', color: '#616161'}}>
						<Typography variant="body1" sx={{ ...styles, mt: '0.8rem' }}>
							any loss or damage that was not, at the time that this Agreement was formed between you and
							WodaQota, a reasonably foreseeable consequence of WodaQota or its Affiliates breaching this
							Agreement; or
						</Typography>
					</li>
					<li style={{fontSize: '0.9rem', color: '#616161'}}>
						<Typography variant="body1" sx={{ ...styles, mt: '0.8rem' }}>
							the Content submitted by any user, or for the defamatory, offensive, or illegal conduct of
							any user.
						</Typography>
					</li>
				</ol>

				<Typography variant="body1" sx={{ ...styles, m: '0.8rem 0' }}>
					Business Users only: If you are using the Service for the purpose of your trade, business, craft or
					profession, (a “Business User”), to the extent permitted by applicable law, the following limitation
					of liability will also apply: WodaQota will not be responsible for lost profits, revenues, or data;
					loss of opportunity or anticipated savings; indirect or consequential losses, or punitive damages
					(in all cases whether such losses were foreseeable or not); and
				</Typography>

				<Block
					top="Third-Party Links"
					bottom="The Service may contain links to third-party websites and online services that are not owned
				or controlled by WodaQota. WodaQota has no control over, and assumes no responsibility for, such
				websites and online services. Be aware when you leave the Service; we suggest you read the terms
				and privacy policy of each third-party website and online service that you visit."
				/>

				<Typography variant="h6" sx={{ ...hStyles, m: '0.8rem 0' }}>
					About this Agreement
				</Typography>

				<Block
					top="Modifying this Agreement"
					bottom="We may modify this Agreement, for example, to reflect changes to our Service or for legal,
				regulatory, or security reasons. WodaQota will provide reasonable advance notice of any material
				modifications to this Agreement and the opportunity to review these changes. However, changes
				addressing newly available features of the Service or changes made for legal reasons may be effective
				immediately without notice. Changes will only apply going forward. If you do not agree to the
				modified terms, you should remove any Content you uploaded and discontinue your use of the Service."
				/>
				<Block
					top="Continuation of this Agreement"
					bottom="If your use of the Service ends, the following terms of this Agreement will continue to apply
				to you: “Other Legal Terms”, “About This Agreement”, and the licenses granted by you will continue
				in limited cases as described under “Duration of License”."
				/>
				<Block
					top="Severance"
					bottom="If it turns out that a particular term of this Agreement is not enforceable for any reason,
				this will not affect any other terms."
				/>
				<Block
					top="Assignment"
					bottom="WodaQota may transfer all or part of this Agreement to an Affiliate or, if WodaQota is sold,
				to a third party."
				/>
				<Block
					top="No Waiver"
					bottom="If you do not comply with this Agreement and we do not take action immediately, this doesn’t
				mean that we are giving up any rights that we may have (such as the right to take action in the
				future)."
				/>
				<Block
					top="Governing Law"
					bottom="This Agreement, and your relationship with WodaQota under this Agreement, will be governed
				by United States Law, and legal disputes may be brought in the courts of United States of America."
				/>

				<Typography variant="body1" sx={{ ...styles, m: '0.8rem 0' }}>
					Effective as of October 20, 2021
				</Typography>
			</Container>
		</Template>
	);
};

export default TermsOfUse;
