import { v1 as uuidv1 } from 'uuid';

import { ICardActivationMessagePayload } from 'library/common/types/cards/comments.d';
import { IBaseNotificationMessage, INotificationActivation } from 'library/common/types/notifications/notifications.d';
import { mapAuthorData } from 'library/utils/mappers/user.mapper';
import NotificationType from 'library/common/types/notifications/notificationTypes';

import { MessageEventCode } from '../eventCode';

export type IActivationMessage = IBaseNotificationMessage<
    MessageEventCode.ACTIVATION,
    ICardActivationMessagePayload>;

export const mapCardActivationMessage = (data: ICardActivationMessagePayload): INotificationActivation => ({
	...mapAuthorData(data.author),
	// TODO подстановку id удалить после добаления хранилица у бекенда (должны и id добавить)
	id: uuidv1(),
	type: NotificationType.ACTIVATION,
	cardId: data.data.cardId,
	cardTitle: '',
	createdAt: data.data.created,
	viewed: false,
});
