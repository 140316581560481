import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import cardsService from 'library/common/services/cards.service';
import { UUID } from 'library/common/types/app.d';
import { CommentResponse, ICommentEntity } from 'library/common/types/cards/comments.d';
import { CardApiData } from 'library/common/types/cards/request.d';
import { mapAxiosCardApiResponse } from 'library/utils/mappers/api.mapper';
import isNonNullable from 'library/utils/isNonNullable';

import axiosErrorHandler from 'library/utils/axiosErrorHandler';
import commentsQueryKeys from './queryKeys';

interface IMutationParams {
	commentMessage: string;
	cardId: UUID;
}

const useAddCommentMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: ({cardId, commentMessage}: IMutationParams) => cardsService
			.addComment<CardApiData<ICommentEntity>>(commentMessage, cardId)
			.then(mapAxiosCardApiResponse),
		onSuccess: (data, { cardId }) => {
			const key = commentsQueryKeys.byCardId(cardId);
			const currentData: CommentResponse | undefined = queryClient.getQueryData(key);

			if (isNonNullable(currentData)) {
				queryClient.setQueriesData(key, {
					...currentData,
					[data.comment.id]: data,
				});
			}
		},
		onError: (error: AxiosError) => {
			axiosErrorHandler(error);
		},
	});
};

export default useAddCommentMutation;
