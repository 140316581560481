import { AnyAction, Action, Dispatch } from 'redux';

import appActionTypes from 'library/common/constants/app.constant';

import messageSound from 'resources/sounds/message.wav';

const soundsMiddleware = () => {
	const messageSignal = new Audio(messageSound);

	return (next: Dispatch<AnyAction>) => (action: Action) => {
		if (action.type === appActionTypes.ADD_NOTIFICATION) {
			const resp = messageSignal.play();

			if (resp !== undefined) {
				resp.then(() => {
					// autoplay starts!
				}).catch(() => {
					// show error
				});
			}
		}

		next(action);
	};
};

export default soundsMiddleware;
