import { AxiosRequestConfig } from 'axios';

import { MediaUUID, UUID } from 'library/common/types/app.d';
import { IChangeCardForm, ICreateCardForm, IStepData } from 'library/common/types/cards/cardsCreation.d';
import { ICategory, IDetailedCard, IPoorCard } from 'library/common/types/cards/cards.d';
import { AxiosCardApiPaginationResponse, CardApiData } from 'library/common/types/cards/request.d';
import { getReelsCardDataMapper } from 'library/utils/mappers/cards.mapper';
import { cardApiV1, cardQueryApiV1 } from './urls';
import { IReelsCard } from '../types/cards/reels.d';
import { mapPoorCards } from '../data/card/mappers';

const createCard = (data: ICreateCardForm) => cardApiV1
	.post('cards', data)
	.then((r) => r);

const deleteCard = (id: UUID) => cardApiV1
	.delete(`cards/${id}`)
	.then((r) => r);

const changeCard = (id: UUID, data: IChangeCardForm) => cardApiV1
	.patch(`cards/${id}/`, data)
	.then((r) => r);

const getCardsList = <T = any>(
	params: {
		page?: number,
		size?: number,
		user_id?: number,
		title?: string,
		category_id?: UUID
	},
) => cardApiV1
		.get<T>('cards', {params})
		.then((r) => r);

/**
 * @deprecated
 */
const getOwnCreatedCards = (title?: string) => cardApiV1
	.get('cards/own', { params: { title }})
	.then((r) => r);

const getUserCreatedCards = (user_id: string, title?: string) => cardApiV1
	.get('cards', { params: { user_id, title } })
	.then((r) => r);

const getCardById = (id: UUID) => cardApiV1
	.get<CardApiData<IDetailedCard>>(`cards/${id}/`)
	.then((r) => r);

const getComments = <T = any>(cardId: UUID) => cardApiV1
	.get<T>('comments', { params: { cardId } })
	.then((r) => r);

const addComment = <T = any>(text: string, cardId: UUID) => cardApiV1
	.post<T>('comments', { text }, { params: { cardId } })
	.then((res) => res);

const changeComment = <T = any>(text: string, card: UUID) => cardApiV1
	.put<T>(`comments/${card}`, text)
	.then((res) => res);

const removeComment = <T = any>(commentId: UUID) => cardApiV1
	.delete<T>(`comments/${commentId}`)
	.then((res) => res);

const getChildComments = <T = any>(commentId: UUID) => cardApiV1
	.get<T>(`comments/${commentId}/children`)
	.then((r) => r);

const addChildComment = <T = any>(commentId: UUID, text: string) => cardApiV1
	.post<T>(`comments/${commentId}/children`, { text })
	.then((res) => res);

const toggleCommentLike = <T = any>(commentId: UUID) => cardApiV1
	.put<T>(`comments/${commentId}/likes`)
	.then((res) => res);

const activateCard = (card_id: UUID) => cardApiV1
	.put(`cards/${card_id}/activate`)
	.then((res) => res);

const deactivateCard = (card_id: UUID) => cardApiV1
	.delete(`cards/${card_id}/activate`)
	.then((res) => res);

const getActivationsList = <T = any>() => cardApiV1
	.get<T>('cards/activate')
	.then((res) => res);

const getFavoriteList = (params?: { page?: number, size?: number }) => cardApiV1
	.get<AxiosCardApiPaginationResponse<IPoorCard[]>>('cards/favorite', { params })
	.then(mapPoorCards);

const addToFavoriteCard = (cardId: UUID) => cardApiV1
	.put(`cards/${cardId}/favorite`)
	.then((res) => res);

const removeFromFavoriteCard = (cardId: UUID) => cardApiV1
	.delete(`cards/${cardId}/favorite`)
	.then((res) => res);

// TODO integrations with card API service
// const getCardStageById = (id: number) => axiosApiV2
// 	.get(`stage/${id}/`)
// 	.then((res) => res);

const completeStep = (stepId: UUID) => cardApiV1
	.put(`steps/${stepId}/complete`)
	.then((res) => res);

const resetStepComplete = (stepId: UUID) => cardApiV1
	.delete(`steps/${stepId}/complete`)
	.then((res) => res);

const changeStep = (
	step_id: UUID,
	stage: object,
) => cardApiV1
	.patch(`steps/${step_id}/`, stage)
	.then((res) => res);

const createNewStep = (
	cardId: UUID,
	step: Omit<IStepData, 'resources' | 'id'> & { resource_ids?: UUID[] },
) => cardApiV1
	.post(`steps/card/${cardId}`, step)
	.then((res) => res);

const deleteStep = (step_id: UUID) => cardApiV1
	.delete(`steps/${step_id}`)
	.then((res) => res);

const setStepsWeight = (stepsData: {id: UUID, weight: number}[]) => cardApiV1
	.patch('steps', stepsData)
	.then((res) => res);

const getCategories = () => cardQueryApiV1
	.get<AxiosCardApiPaginationResponse<ICategory[]>>('category')
	.then((res) => res);

const getResults = <T = any>(params: {category_id?: UUID, title?: string}) => cardApiV1
	.get<T>('results', {params})
	.then((res) => res);

const addResult = <T = any>(data: {title: string, category_id: UUID}) => cardApiV1
	.post<T>('/results/', data, {})
	.then((res) => res);

const getResources = <T = any>(params: {page?: number, size?: number, title?: string}) => cardApiV1
	.get<T>('resources', {params})
	.then((res) => res);

const addResources = <T = any>(data: {title: string, image?: MediaUUID}) => cardApiV1
	.post<T>('resources', data)
	.then((res) => res);

const uploadMediaData = <T = any>(formData: FormData, config: AxiosRequestConfig) => cardApiV1
	.post<T>('files/upload', formData, config)
	.then((res) => res);

const getReelsCardData = () => cardQueryApiV1
	.get<CardApiData<IReelsCard[]>>('/card')
	.then(getReelsCardDataMapper);

export default {
	removeComment,

	createCard,

	deleteCard,
	changeCard,
	getUserCreatedCards,
	getOwnCreatedCards,
	getCardById,

	getFavoriteList,
	addToFavoriteCard,
	removeFromFavoriteCard,

	getComments,
	addComment,
	changeComment,
	getChildComments,
	addChildComment,
	toggleCommentLike,

	activateCard,
	deactivateCard,

	getActivationsList,

	completeStep,
	resetStepComplete,

	changeStep,
	createNewStep,
	deleteStep,
	setStepsWeight,

	getCardsList,

	uploadMediaData,

	getCategories,

	getResults,
	addResult,

	getResources,
	addResources,
	getReelsCardData,
};
