import React from 'react';
import {useTranslation} from 'react-i18next';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { Typography } from '@mui/material';

interface IShowMoreButton {
	childrenCount: number;
	showMore: boolean;
	onClickHandler: () => void;
}

const ShowMoreButton = ({
	childrenCount,
	showMore,
	onClickHandler,
}: IShowMoreButton) => {
	const {t} = useTranslation();

	if (childrenCount > 0) {
		return (
			<Typography
				role="button"
				aria-hidden="true"
				variant="h6"
				onClick={onClickHandler}
				sx={{
					cursor: 'pointer',
					display: 'flex',
					userSelect: 'none',
					alignItems: 'center',
					fontWeight: '600',
					color: ({ palette }) => palette.text.secondary,
				}}
			>
				{t('replies')?.toUpperCase()}
				{showMore ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
			</Typography>
		);
	}

	return null;
};

export default ShowMoreButton;
