import React, {
	ChangeEvent, forwardRef, useCallback,
} from 'react';
import {
	FormControl,
	IconButton,
	InputBase,
	InputBaseProps,
	Stack,
	Typography,
} from '@mui/material';
import {ChangeHandler} from 'react-hook-form';
import ArrowIcon from 'resources/icons/ArrowIcon';
/*
const CustomInput = withStyles((theme: Theme) => createStyles({
	root: {
		position: 'relative',
		backgroundColor: theme.palette.common.white,
		fontSize: 16,
		width: 'auto',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
	},
	input: {
		border: '1px solid #8181A5',
		borderRadius: 4,
		padding: '8px',
		width: '20px',
		'-moz-appearance': 'textfield',

		'&:focus': {
			boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 4px 0.2rem`,
			borderColor: `${theme.palette.primary.main}!important`,
		},

		'&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
			'-webkit-appearance': 'none',
			margin: 0,
		},
	},
}))(InputBase) as typeof InputBase;

const CustomLabel = withStyles({
	formControl: {
		position: 'initial',
		transform: 'translate(6px, 3px) scale(0.75)',
		marginLeft: 8,
		fontSize: '1.1rem',
	},
})(InputLabel) as typeof InputLabel;

const CustomFormControl = withStyles({
	root: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
})(FormControl) as typeof FormControl; */

const arrowButtonStyle = {
	position: 'absolute',
	top: '20px',
	fontSize: '12px',
	width: '18px',
	height: '18px',
};

interface ISimpleNumberInput extends InputBaseProps{
	value: number;
	label: string;
	onChange: ChangeHandler;
	error: boolean;
	innerRef: any;
}

const SimpleNumberInput = ({
	name, label, error, innerRef, value, onChange, ...props
}: ISimpleNumberInput) => {
	// TODO cтилизовать borders как в остальных инпутах
	const onDecrease = useCallback(
		() => {
			if (value > 0) {
				onChange((value - 1) as any);
			}
		},
		[value, onChange],
	);

	const onIncrease = useCallback(
		() => {
			onChange((value + 1) as any);
		},
		[value, onChange],
	);

	return (
		<Stack direction="column" alignItems="center" spacing={1}>
			<Typography variant="caption" sx={{ color: (theme) => theme.palette.text.secondary }}>
				{label}
			</Typography>
			<FormControl
				error={error}
			>
				<InputBase
					id={`${name}-input`}
					ref={innerRef}
					name={name}
					placeholder="00"
					type="number"
					onInput={(e: ChangeEvent<HTMLInputElement>) => {
						e.target.value = Math.max(0, parseInt(e.target.value, 10)).toString().slice(0, 2);
					}}
					error={error}
					inputProps={{
						style: error ? {borderColor: '#F44336', color: '#F44336'} : undefined,
					}}
					value={value}
					onChange={onChange}
					{...props}
					sx={{
						position: 'relative',
						backgroundColor: (theme) => theme.palette.common.white,
						fontSize: 16,
						width: 'auto',
						transition: (theme) => theme.transitions.create(['border-color', 'box-shadow']),
						input: {
							width: '69px',
							height: '40px',
							textAlign: 'center',
							padding: '8px',
							mozAappearance: 'textfield',
							borderWidth: '1px',
							borderStyle: 'solid',
							borderColor: (theme) => `${theme.palette.secondary.dark}4D`,
							borderRadius: 2,

							'&:focus': {
								borderColor: (theme) => theme.palette.primary.main,
							},

							'&:hover': {
								borderColor: (theme) => `${theme.palette.primary.dark}4D`,
							},

							'&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
								WebkitAppearance: 'none',
								margin: 0,
							},
						},
					}}
				/>
				<IconButton
					aria-label="decrease"
					onClick={onDecrease}
					sx={{
						left: '4px',
						...arrowButtonStyle,
					}}
				>
					<ArrowIcon fontSize="inherit" />
				</IconButton>
				<IconButton
					aria-label="increase"
					onClick={onIncrease}
					sx={{
						right: '4px',
						...arrowButtonStyle,
					}}
				>
					<ArrowIcon fontSize="inherit" sx={{ transform: 'rotate(180deg)' }} />
				</IconButton>
			</FormControl>
		</Stack>
	);
};

export default forwardRef((props: any, ref) => (
	<SimpleNumberInput innerRef={ref} {...props} />
));
