import React, { useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
	Card,
	Container,
	Tab,
	Tabs,
	useTheme,
} from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import ShareIcon from '@mui/icons-material/Share';
import PasswordIcon from '@mui/icons-material/Password';
import {styled} from '@mui/material/styles';

import {RootState} from 'main/rootReducer';
import TabPanel from 'library/common/components/Tabs/TabPanel';
import WQLoader from 'library/common/components/WQLoader/WQLoader';
import {initSettingsPage} from 'library/common/actions/app.action';

import { useMount } from 'react-use';
import General from './components/General';
import SocialLinks from './components/SocialLinks';
import ChangePassword from './components/ChangePassword';

const MyTab = styled(Tab)(() => ({
	minHeight: '42px',
}));

const Settings = () => {
	const loader = useSelector((state: RootState) => state.app.settingsLoader);

	const dispatch = useDispatch();
	const [value, setValue] = useState(0);
	const theme = useTheme();

	useMount(() => dispatch(initSettingsPage.request()));

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const handleIndexChange = (newValue: number) => {
		setValue(newValue);
	};

	const a11yProps = (index: number) => ({
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	});

	if (loader) {
		return (
			<Container
				sx={{
					height: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<WQLoader />
			</Container>
		);
	}

	return (
		<Container disableGutters maxWidth="xl">
			<Tabs
				value={value}
				onChange={handleChange}
				variant="scrollable"
				scrollButtons="auto"
				allowScrollButtonsMobile
				aria-label="tabs settings"
			>
				<MyTab label="General" icon={<ContactPageIcon />} iconPosition="start" {...a11yProps(0)} />
				<MyTab label="Social Links" icon={<ShareIcon />} iconPosition="start" {...a11yProps(1)} />
				<MyTab label="Change Password" icon={<PasswordIcon />} iconPosition="start" {...a11yProps(2)} />
			</Tabs>
			<SwipeableViews
				axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
				index={value}
				onChangeIndex={handleIndexChange}
			>
				<TabPanel value={value} index={0}>
					<General />
				</TabPanel>
				<TabPanel value={value} index={1}>
					<Card sx={{ minHeight: '200px', padding: 4 }}>
						<SocialLinks />
					</Card>
				</TabPanel>
				<TabPanel value={value} index={2}>
					<ChangePassword />
				</TabPanel>
			</SwipeableViews>
		</Container>
	);
};

export default Settings;
