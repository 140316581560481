import React, { useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Tooltip,
	useMediaQuery,
} from '@mui/material';

import { Theme } from '@mui/system';
import DeleteIcon from 'resources/icons/DeleteIcon';

interface IDeleteCard {
	title: string;
	deleteCardHandler(): Promise<void>;
}

const DeleteCard = ({ title, deleteCardHandler }: IDeleteCard) => {
	const [open, setOpen] = React.useState(false);
	const [isDeletingInProgress, setDeletingInProgress] = useState(false);

	const fullScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const deleteCard = () => {
		setDeletingInProgress(true);
		deleteCardHandler()
			.then(() => {
				setDeletingInProgress(false);
				setOpen(false);
			});
	};

	return (
		<>
			<Tooltip title="Delete card">
				<IconButton
					size="small"
					aria-label="to_favorite"
					sx={{
						color: ({ palette }) => palette.text.secondary,
					}}
					onClick={handleClickOpen}
				>
					<DeleteIcon fontSize="inherit" />
				</IconButton>
			</Tooltip>
			<Dialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-delete-card-title"
			>
				<DialogTitle id="responsive-delete-card-title">
					Card deleting dialog
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{`Are you sure you want to delete the card ${title}?`}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button disabled={isDeletingInProgress} autoFocus onClick={handleClose} color="inherit">
						No
					</Button>
					<Button disabled={isDeletingInProgress} onClick={deleteCard} color="error" autoFocus>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default DeleteCard;
