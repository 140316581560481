import React, {
	useEffect, useMemo, useRef, useState,
} from 'react';
import { useUnmount, useUpdateEffect } from 'react-use';

import { Box } from '@mui/material';
import Slide from './Slide';
import { ISlideProps } from './types.d';
import {
	clampOffsetRadius,
	getShortestDirection,
	mod,
	modBySlidesLength,
} from './utils';

const DEFAULT_GO_TO_SLIDE_DELAY = 200;

interface IState {
  index: number;
  goToSlide: number | null;
  prevPropsGoToSlide: number;
  newSlide: boolean;
  offsetRadius: number;
}

interface IProps {
  slides: Pick<ISlideProps, 'key' | 'onClick' | 'content'>[];
  activeSlide: number
  offsetRadius?: number;
  animationConfig: object;
  goToSlideDelay?: number;
}

const Carousel: React.FunctionComponent<IProps> = ({
	slides,
	activeSlide,
	animationConfig = { tension: 120, friction: 14 },
	goToSlideDelay = DEFAULT_GO_TO_SLIDE_DELAY,
	offsetRadius = 2,
}) => {
	const [state, setState] = useState<IState>({
		index: 0,
		goToSlide: null,
		prevPropsGoToSlide: 0,
		newSlide: false,
		offsetRadius: clampOffsetRadius(offsetRadius, slides.length),
	});

	const goToIn = useRef<number>();

	const handleGoToSlide = () => {
		if (typeof state.goToSlide !== 'number') {
			return;
		}

		const { index } = state;

		const goToSlide = mod(state.goToSlide, slides.length);

		if (goToSlide !== index) {
			const direction = getShortestDirection(index, goToSlide, slides.length);
			const isFinished = modBySlidesLength(index + direction, slides.length) === goToSlide;

			setState((prev) => ({
				...prev,
				index: modBySlidesLength(index + direction, slides.length),
				newSlide: isFinished,
				goToSlide: isFinished ? null : goToSlide,
			}));
		}
	};

	useEffect(() => {
		if (activeSlide !== state.prevPropsGoToSlide) {
			setState((prev) => ({
				...prev,
				prevPropsGoToSlide: activeSlide,
				goToSlide: activeSlide,
				newSlide: true,
			}));
		}
	}, [activeSlide, state.prevPropsGoToSlide]);

	useUpdateEffect(() => {
		const { index, goToSlide, newSlide } = state;
		if (typeof goToSlide === 'number') {
			if (newSlide) {
				handleGoToSlide();
			} else if (index !== goToSlide && window) {
				window.clearTimeout(goToIn.current);
				goToIn.current = window.setTimeout(handleGoToSlide, goToSlideDelay);
			} else if (window) {
				window.clearTimeout(goToIn.current);
			}
		}
	});

	useUnmount(() => {
		if (typeof window !== 'undefined') {
			window.clearTimeout(goToIn.current);
		}
	});

	const presentableSlides = useMemo(() => Array
		.from(Array(Math.abs((state.offsetRadius * 2) + 1)), (_, i) => modBySlidesLength(-state.offsetRadius + i + state.index, slides.length))
		.map((i) => slides[i]),
	[state.index]);

	return (
		<Box sx={{
			position: 'relative',
			width: '100%',
			height: '100%',
		}}
		>
			{presentableSlides.map(
				(slide, presentableIndex: number) => (
					<Slide
						key={slide.key}
						content={slide.content}
						onClick={slide.onClick}
						offsetRadius={clampOffsetRadius(state.offsetRadius, slides.length)}
						index={presentableIndex}
						animationConfig={animationConfig}
					/>
				),
			)}
		</Box>
	);
};

export default Carousel;
