import React from 'react';
import { Box } from '@mui/material';

import Title from 'library/common/components/Title/Title';

const Header = () => (
	<Box sx={{
		width: '100%',
		height: '104px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	}}
	>
		<Title withoutText />
	</Box>
);

export default Header;
