import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const SearchIcon = (props: SvgIconProps) => (
	<SvgIcon viewBox="0 0 32 32" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M18.9056 20.3199C17.5509 21.3729 15.8487 22 14 22C9.58172 22 6 18.4183 6 14C6 9.58172 9.58172 6
            14 6C18.4183 6 22 9.58172 22 14C22 15.8487 21.3729 17.551 20.3198 18.9057L25.7781 24.364C26.1687
            24.7545 26.1687 25.3876 25.7781 25.7782C25.3876 26.1687 24.7545 26.1687 24.3639 25.7782L18.9056
            20.3199ZM20 14C20 17.3137 17.3137 20 14 20C10.6863 20 8 17.3137 8 14C8 10.6863 10.6863 8 14 8C17.3137
            8 20 10.6863 20 14Z"
		/>
	</SvgIcon>
);

export default SearchIcon;
