import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Container, Stack } from '@mui/material';

import {RootState} from 'main/rootReducer';
import useDetailedCard from 'library/common/hook/useDetailedCard';
import MHidden from 'library/common/components/MHidden/MHidden';
import Trends from 'library/common/components/Trends/Trends';
import FullScreenLoader from 'library/common/components/FullScreenLoader';
import { UUID } from 'library/common/types/app.d';

import AllResourcesList from './components/AllResourcesList';
import Steps from './components/Steps';
import Comments from './components/Comments';
import CardHeader from './components/CardHeader';
import Edit from './components/Edit';
import { EditMode } from './environments';
import { EditStateType } from './types.d';

// TODO Адаптировать видео
// TODO подобрать размеры к картинкам

const Card = () => {
	const params = useParams<{id?: string}>();

	const isAuth = useSelector((state: RootState) => state.user.isAuth);

	const [editState, setEditState] = useState<EditStateType>({ editMode: EditMode.NONE });

	const openDescriptionMode = () => setEditState({ editMode: EditMode.DESCRIPTION });

	const openCreateStepMode = (sourceStepWeight: number, sourceStepIndex: number) => setEditState({ editMode: EditMode.CREATE_STEP, sourceStepWeight, sourceStepIndex });

	const openEditStepMode = (id: UUID) => setEditState({ editMode: EditMode.EDIT_STEP, editingStepId: id });

	const closeEditMode = () => setEditState({ editMode: EditMode.NONE, editingStep: undefined, sourceStep: undefined });

	const {
		detailedCard,
		isPending,
		// isActionPending,
		authorMode,

		totalResources,

		// toggleCardActivation,
		// toggleStepActivation,
		toggleCardLike,
		deleteCardHandler,
		changeDescriptionHandler,

		deleteStepHandler,
		changeStepHandler,
		createStepHandler,
		handleShareCard,
		moveStepUpHandler,
		moveStepDownHandler,
	} = useDetailedCard({
		id: params.id,
		noIdRedirectUrl: '/app/main',
	});

	return (
		<Container maxWidth="xl" sx={{ height: '100%' }}>
			{
				isPending && (
					<FullScreenLoader />
				)
			}
			{
				!isPending && detailedCard && (
					<Stack direction="row" mt={1}>
						<Box sx={{ flex: 1 }}>

							<CardHeader
								sx={{ px: { xs: 0, sm: 5 } }}
								isAuthorized={isAuth}
								authorMode={authorMode}
								// isActionPending={isActionPending}
								totalResources={totalResources}
								detailedCard={detailedCard}
								// toggleCardActivation={toggleCardActivation}
								toggleCardLike={toggleCardLike}
								deleteCardHandler={deleteCardHandler}
								toggleEditMode={openDescriptionMode}
								handleShareCard={handleShareCard}
							/>

							{/* <StepProgress
								sx={{ mt: 8, px: { xs: 0, sm: 5 } }}
								isActivated={isNonNullable(detailedCard.client_info?.is_activated)}
								totalSteps={detailedCard.steps.length}
								completedSteps={detailedCard.client_info?.number_of_completed_steps}
							/> */}

							<AllResourcesList
								sx={{ mt: 8, px: { xs: 0, sm: 5 } }}
								steps={detailedCard.steps}
							/>

							<Steps
								cardTitle={detailedCard.title}
								sx={{ mt: 12, px: { xs: 0, sm: 5 } }}
								isAuthorized={isAuth}
								authorMode={authorMode}
								steps={detailedCard.steps}
								// isCardActivate={!!detailedCard?.client_info?.is_activated}
								// toggleStepActivation={toggleStepActivation}
								deleteStepHandler={deleteStepHandler}
								toggleCreateMode={openCreateStepMode}
								toggleEditMode={openEditStepMode}
								moveStepUpHandler={moveStepUpHandler}
								moveStepDownHandler={moveStepDownHandler}
							/>

							<Comments
								sx={{
									pt: { xs: 8, sm: 11 },
									pb: { xs: 10, sm: 30 },
									px: { xs: 0, sm: 5 },
								}}
								cardID={detailedCard.id}
								commentsCount={detailedCard.statistics.number_of_comments}
							/>
						</Box>
						<MHidden breakpoint="md" direction="Down">
							<Trends currentCardId={detailedCard.id} category={detailedCard.category} />
						</MHidden>
					</Stack>
				)
			}
			{authorMode && isAuth && detailedCard && (
				<Edit
					detailedCard={detailedCard}
					editState={editState}
					handleClose={closeEditMode}
					changeDescriptionHandler={changeDescriptionHandler}
					changeStepHandler={changeStepHandler}
					createStepHandler={createStepHandler}
				/>
			)}
		</Container>
	);
};

export default Card;
