import React from 'react';
import { declOfNum, getDuration } from 'library/utils/workWithData';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import {
	Box, Divider, Stack, Typography,
} from '@mui/material';

import Label from 'library/common/components/Label/Label';

interface IContent {
	title: string;
	duration: number;
	stageCount: number;
	result?: string;
}

const Content = ({
	title, duration, stageCount, result,
}: IContent) => (
	<Stack
		direction="column"
		sx={{
			pt: 3, pb: 2, pl: 2, pr: 2,
		}}
	>
		<Box sx={{ height: 40 }}>
			<Label title={title} sx={{ fontWeight: 600 }} />
		</Box>

		<Stack direction="column" spacing={0.5}>
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				<Typography variant="body1">
					{`#${result}`}
				</Typography>

				<EmojiEventsIcon sx={{
					color: (theme) => theme.palette.grey[400],
					opacity: 0.8,
					width: 30,
					height: 32,
				}}
				/>
			</Stack>

			<Box
				sx={{
					background: (theme) => (theme.palette as any).gradients.info,
					height: '2px',
					borderRadius: '2px',
				}}
			/>

			<Stack direction="row" spacing={1}>
				<Typography variant="body1">
					{getDuration(duration)}
				</Typography>
				<Divider
					orientation="vertical"
					sx={{
						width: '2px',
						height: '16px',
						borderRadius: '2px',
						color: (theme) => theme.palette.grey[400],
					}}
				/>
				<Typography variant="body1">
					{`${stageCount} ${declOfNum(stageCount, ['step', 'steps', 'steps'])}`}
				</Typography>
			</Stack>
		</Stack>
	</Stack>
);

export default Content;
