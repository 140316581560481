import React, { useState } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import {
	Box, Dialog, SxProps, Theme, useMediaQuery,
} from '@mui/material';

import ErrorComponent from 'library/common/components/Error';
import MyImageEditor from 'library/common/components/MyImageEditor';
import Input from './Input';
import View from './View';

interface IDraw {
    id: string;
    setData: (data: string) => void;
	disabled: boolean;
    sx?: SxProps<Theme>;
	openDrawInputRef?: React.MutableRefObject<(() => void) | undefined>;
	value?: string;
}

const Draw = ({
	id, setData, disabled, sx, openDrawInputRef, value,
}: IDraw) => {
	const [file, setFile] = useState<File | undefined>(undefined);
	const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

	const mobileMode = useMediaQuery((theme: Theme) => (theme.breakpoints.down('md')));

	/**
	 * Закрытие диалогового окна с редактором
	 */
	const onClose = () => {
		setFile(undefined);
		setData('');
		setIsDialogOpen(false);
	};

	/**
	 *
	 * Функция которая срабатывает после загрузки картинки на сервер
	 *
	 * @param file выбранная картинка для отображения
	 * @param res id загруженной картинки
	 */
	const onUploadHandle = (data: File, res: number) => {
		setData(`${res}`);
		setFile(data);
		setIsDialogOpen(false);
	};

	const onOpenEditor = () => {
		if (!disabled) setIsDialogOpen(true);
	};

	if (openDrawInputRef) {
		// eslint-disable-next-line no-param-reassign
		openDrawInputRef.current = onOpenEditor;
	}

	const source = file ? URL.createObjectURL(file) : value;

	return (
		<Box sx={sx}>
			{
				(source && !isDialogOpen) ? (
					<View src={source} alt={`step_${id}`} />
				) : (
					<Input openEditor={onOpenEditor} />
				)
			}
			<Dialog
				open={isDialogOpen}
				onClose={onClose}
				fullWidth
				maxWidth="xl"
				disableEnforceFocus
				fullScreen={mobileMode}
			>
				<MyImageEditor
					menu={['draw', 'text']}
					path="/empty_image.png"
					fileName={`draw-${id}.png`}
					onCancelHandle={onClose}
					onLoad={onUploadHandle}
					mobileMode={mobileMode}
				/>
			</Dialog>
		</Box>
	);
};

// Until they fix the import problem in the library https://github.com/bvaughn/react-error-boundary/issues/111
export default withErrorBoundary(Draw, {
	FallbackComponent: ErrorComponent,
}) as (props : IDraw) => React.ReactElement;
