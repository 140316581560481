import React from 'react';
import {
	Box, Button, Container, Grid, Skeleton, Stack, Typography,
} from '@mui/material';

import { IProfile } from 'library/common/hook/useProfile';
import DoneIcon from 'resources/icons/DoneIcon';
import { getUserDataSchema } from 'pages/Profile/data';
import isNonNullable from 'library/utils/isNonNullable';
import VerifiedIcon2 from 'resources/icons/VerifiedIcon2';
import ProfileCounters from '../../../components/ProfileCounters';
import ProfileAvatar from '../../../components/ProfileAvatar';
import WodaWallet from '../WodaWallet';

interface IProps extends Partial<IProfile> {
	isPending: boolean;
	isWodaBalanceLoading?: boolean;
	selfPage?: boolean;
	isSubscribeButtonAvailable?:boolean;
	isSubscribed?: boolean;
	isSubscribing?: boolean;
	progress?: number | null;
	onSubscribe?: () => void;
	setAvatarRequest?: (newAvatar: File[] | undefined) => void;
	publishedCardsCount?: number;
	wodaBalance?: number;
}

const Profile = ({
	username,
	selfPage,
	logoURL,
	isPending,
	isWodaBalanceLoading,
	isSubscribeButtonAvailable,
	onSubscribe,
	isSubscribed,
	isSubscribing,
	progress,
	setAvatarRequest,
	referredUsersCount,
	publishedCardsCount,
	wodaBalance,
	...profileData
}: IProps) => (
	<Box
		pt="176px"
		pb="64px"
		sx={{
			background: (theme) => (theme.palette as any).gradients.info,
			display: 'flex',
		}}
	>
		<Container maxWidth="xl" sx={{ minHeight: '302px' }}>
			<Grid container px={4}>
				<Grid item xs={12} md={8} overflow="hidden">
					<Stack
						direction={{ xs: 'column-reverse', md: 'row' }}
						alignItems={{ xs: 'center', md: 'start' }}
						spacing={8}
					>
						<ProfileAvatar
							selfPage={selfPage}
							progress={progress}
							isPending={isPending}
							username={username}
							logoURL={logoURL}
							setAvatarRequest={setAvatarRequest}
						/>

						<Stack direction="column" sx={{ minWidth: 0}}>
							<Typography
								variant="h6"
								sx={{
									color: (theme) => theme.palette.common.white,
									fontWeight: 600,
								}}
							>
								{selfPage ? 'Profile' : 'Author'}
							</Typography>

							<Box>
								<Typography
									variant="h3"
									sx={{
										color: (theme) => theme.palette.common.white,
										fontWeight: 600,
										// TODO Вынести в отдельный объект
										display: 'block',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										whiteSpace: 'normal',
										WebkitLineClamp: 2,
									}}
								>
									{isPending ? <Skeleton variant="text" /> : username}
									{profileData.isVerified && <VerifiedIcon2 sx={{ ml: 1, width: '18px', height: '18px' }} />}
								</Typography>
							</Box>

							<ProfileCounters
								isPending={isPending}
								data={{
									followers: profileData.followers,
									followings: profileData.followings,
									cardsCount: publishedCardsCount ?? 0,
								}}
								schema={getUserDataSchema(!!selfPage)}
							/>
						</Stack>
					</Stack>
				</Grid>
				<Grid
					item
					container
					xs={12}
					md={4}
					justifyContent="center"
					alignItems="start"
				>
					{
						isSubscribeButtonAvailable && onSubscribe && (
							<Button
								sx={{
									mt: 10,
									width: '238px',
									background: (theme) => (isSubscribed ? `${theme.palette.common.white}4D` : theme.palette.secondary.main),
								}}
								variant="contained"
								disabled={isPending || isSubscribing}
								onClick={onSubscribe}
								startIcon={isSubscribed ? <DoneIcon /> : undefined}
							>
								{isSubscribed ? 'You subscribed' : 'Follow author'}
							</Button>
						)
					}
					{
						selfPage
							&& isNonNullable(profileData.id)
							&& isNonNullable(referredUsersCount)
							&& isNonNullable(wodaBalance) && (
							<WodaWallet
								id={profileData.id}
								isPending={isPending}
								isWodaBalanceLoading={isWodaBalanceLoading ?? true}
								referredUsersCount={referredUsersCount}
								wodaBalance={wodaBalance}
							/>
						)
					}
				</Grid>
			</Grid>
		</Container>
	</Box>
);

export default Profile;
