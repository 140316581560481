import React, { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
	Stack, Box, Chip, Theme, Link, Tooltip,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import toCardPage from 'library/utils/routes/getCardPageUrl';
import { CountedResource } from 'library/common/types/cards/cards.d';
import { getResourcePictureFrom } from 'library/utils/getters/getPicture';
import countDuplicateResources from 'library/utils/countDuplicateResources';
import { IReelsStep } from 'library/common/types/cards/reels.d';
import { UUID } from 'library/common/types/app.d';

interface IProps {
    cardId: UUID;
    steps: IReelsStep[];
}

const ResourcesBlock: React.FunctionComponent<IProps> = ({ steps, cardId }) => {
	const countedResources: CountedResource[] = useMemo(() => countDuplicateResources(steps).slice(0, 5), [steps]);

	return (
		<Stack direction="row" spacing={1}>
			{countedResources
				.map((resource) => (
					<Tooltip key={resource.title} title={resource.title}>
						<Box
							sx={{
								borderRadius: '50%',
								width: '40px',
								height: '40px',
								background: (theme) => theme.palette.grey[200],
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								userSelect: 'none',
							}}
						>
							<Box
								component="img"
								sx={{
									width: '24px',
									height: '24px',
								}}
								src={getResourcePictureFrom(resource)}
							/>
						</Box>
					</Tooltip>
				))}
			<Link
				component={RouterLink}
				underline="none"
				to={toCardPage(cardId)}
			>
				<Chip
					sx={{
						px: 1,
						borderRadius: '12px',
						backgroundColor: (theme: Theme) => theme.palette.grey[200],
						height: '40px',
						cursor: 'pointer',

						'&:hover': {
							background: (theme) => theme.palette.grey[300],
						},
					}}
					deleteIcon={<MoreVertIcon />}
					// TODO FIX THIS BULLSHIT
					onDelete={() => {}}
					label={countedResources.length > 0 ? `Only ${countedResources.length}` : 'resources 0'}
				/>
			</Link>
		</Stack>
	);
};

export default ResourcesBlock;
