import axios from 'axios';

import { AxiosCardApiResponse } from 'library/common/types/cards/request.d';
import cardsService from 'library/common/services/cards.service';
import {showSnackbarMessage} from './showSnackbarMessage';
import {logger} from './lintersSugar';
import i18n from './language/conf';

export default (
	type: 'image' | 'video',
	file: File,
	setProgress: (value: number | null) => void,
	callBack: (data: File, res: number) => void,
) => {
	setProgress(0);
	const cancelTokenSource = axios.CancelToken.source();
	const formData = new FormData();
	formData.append('file', file);

	cardsService.uploadMediaData(
		formData,
		{
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			cancelToken: cancelTokenSource.token,
			onUploadProgress: (progressEvent: ProgressEvent) => (
				setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
			),
		},
	)
		.then((res: AxiosCardApiResponse) => {
			callBack(file, res.data.payload);
		})
		.catch((err) => {
			showSnackbarMessage(i18n.t('card_changing_error'), 'error');
			logger(err, 'error');
		})
		.finally(() => setProgress(null));
};
