import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink, useSearchParams} from 'react-router-dom';

import {
	Box, Button, CircularProgress, Link, Stack, Typography, useTheme,
} from '@mui/material';

import {RootState} from 'main/rootReducer';
import passwordValidator from 'library/utils/passwordValidator';
import {createUserAsync} from 'library/common/actions/user.action';
import PasswordErrors from 'library/common/components/PasswordErrors/PasswordErrors';
import TextFieldWithCount from 'library/common/components/TextFieldWithCount';

import AuthorizationLayout from './components/AuthorizationLayout';
import { MAX_LOGIN_LENGTH, MAX_MAIL_LENGTH, MAX_PASSWORD_LENGTH } from './values';

type AuthorizationData = {
	login: string;
	email: string;
	password: string;
	repeatPassword: string;
};

const SingUp = () => {
	const {
		control,
		handleSubmit,
		watch,
	} = useForm<AuthorizationData>({
		defaultValues: {
			email: '', login: '', password: '', repeatPassword: '',
		},
	});

	const [searchParams] = useSearchParams();

	const watchPassword = watch('password');

	const theme = useTheme();
	const dispatch = useDispatch();
	const {t} = useTranslation();

	const loading = useSelector((state: RootState) => state.user.isPending);

	const onSubmit = handleSubmit(async (data) => {
		dispatch(createUserAsync.request(
			{
				method: 'password',
				login: data.login,
				email: data.email,
				password: data.password,
				referral_id: searchParams.get('referral_link') || undefined,
			},
		));
	});

	return (
		<AuthorizationLayout>
			<Box mt={3}>
				<form onSubmit={onSubmit} autoComplete="off">
					<Stack direction="column" spacing={2}>
						<Controller
							name="login"
							control={control}
							rules={{
								required: {
									value: true,
									message: t('the_field_cannot_be_empty'),
								},
							}}
							render={({ field, fieldState: {error} }) => (
								<TextFieldWithCount
									{...field}
									maxLength={MAX_LOGIN_LENGTH}
									label="Login"
									sx={{height: '77px'}}
									fullWidth
									autoComplete="off"
									autoFocus
									disabled={loading}
									error={!!(error && error.type)}
									helperText={error?.message}
								/>
							)}
						/>

						<Controller
							name="email"
							control={control}
							rules={{
								required: {
									value: true,
									message: t('the_field_cannot_be_empty'),
								},
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
									message: t('invalid_email_format'),
								},
							}}
							render={({ field, fieldState: {error} }) => (
								<TextFieldWithCount
									{...field}
									maxLength={MAX_MAIL_LENGTH}
									label="Email"
									sx={{height: '77px'}}
									fullWidth
									autoComplete="off"
									disabled={loading}
									error={!!(error && error.type)}
									helperText={error?.message}
								/>
							)}
						/>

						<Controller
							name="password"
							control={control}
							rules={{
								validate: (value) => {
									const {haveError, errorText} = passwordValidator(value);

									return !haveError || errorText;
								},
							}}
							render={({ field, fieldState: {error} }) => (
								<div>
									<TextFieldWithCount
										{...field}
										type="password"
										maxLength={MAX_PASSWORD_LENGTH}
										label="Password"
										fullWidth
										autoComplete="off"
										disabled={loading}
										error={!!(error && error.type)}
									/>

									<PasswordErrors errors={error?.message} />
								</div>
							)}
						/>

						<Controller
							name="repeatPassword"
							control={control}
							rules={{
								required: {
									value: true,
									message: t('the_field_cannot_be_empty'),
								},
								validate: (value) => value === watchPassword || t('password_mismatch') as string,
							}}
							render={({ field, fieldState: {error} }) => (
								<TextFieldWithCount
									{...field}
									type="password"
									maxLength={MAX_PASSWORD_LENGTH}
									label="Repeat password"
									sx={{height: '77px'}}
									fullWidth
									autoComplete="off"
									disabled={loading}
									error={!!(error && error.type)}
									helperText={error?.message}
								/>
							)}
						/>
					</Stack>

					<Button
						type="submit"
						variant="contained"
						disabled={loading}
						color="primary"
						sx={{mt: 5, mb: 3}}
						fullWidth
						startIcon={
							loading && <CircularProgress size={24} color="inherit" />
						}
					>
						{t('sign_up')}
					</Button>
				</form>

				<Stack direction="column" alignItems="center" justifyContent="center" spacing={1}>
					<Typography component="p">
						{t('by_creating_an_account_you_agree_to_woda_qota_conditions_of')}
						&nbsp;
						{
							loading ? (
								t('terms_of_use')
							) : (
								<NavLink to="/terms">{t('terms_of_use')}</NavLink>
							)
						}
						&nbsp;
						{t('and')}
						&nbsp;
						{
							loading ? (
								t('privacy_notice')
							) : (
								<NavLink to="/privacy-notice">
									{t('privacy_notice')}
								</NavLink>
							)
						}
					</Typography>

					<Typography component="p">
						{t('already_registered')}
						&nbsp;
						{
							loading ? (
								<span style={{color: theme.palette.primary.main}}>{t('sign_in')}</span>
							) : (
								<Link to="/login" color="primary" component={NavLink}>{t('sign_in')}</Link>
							)
						}
					</Typography>
				</Stack>

			</Box>
		</AuthorizationLayout>
	);
};

export default SingUp;
