interface Color {
	50: string;
	100: string;
	200: string;
	300: string;
	400: string;
	500: string;
	600: string;
	700: string;
	800: string;
	900: string;
	A100: string;
	A200: string;
	A400: string;
	A700: string;
}

interface Common {
	common: {
		white: string;
		black: string;
	}
}

interface ColorsObj {
	amber: Color;
	red: Color;
	pink: Color;
	purple: Color;
	deepPurple: Color;
	indigo: Color;
	blue: Color;
	lightBlue: Color;
	cyan: Color;
	teal: Color;
	green: Color;
	lightGreen: Color;
	lime: Color;
	yellow: Color;
	orange: Color;
	deepOrange: Color;
	brown: Color;
	grey: Color;
	blueGrey: Color;
}

// Если зайдет - создать объект, который будет инициализироваться при запуске app в целях оптимизации
export const takeColor = (colorsObj: ColorsObj & Common) => {
	const colorsArr = Object.keys(colorsObj).filter((val) => val !== 'common') as (keyof ColorsObj)[];
	const selected = colorsArr[Math.floor(Math.random() * colorsArr.length)];

	return colorsObj[selected][500];
};

export const takeColorByFirstLetter = (colorsObj: ColorsObj & Common, text: string = 'A') => {
	try {
		const colorsArr = Object.keys(colorsObj).filter((val) => val !== 'common') as (keyof ColorsObj)[];
		const charCodes = parseInt(text
			.split('')
			.map((char) => char.charCodeAt(0))
			.join(''),
		10);

		return colorsObj[colorsArr[charCodes % colorsArr.length]]['500'];
	} catch (error) {
		return colorsObj.red['500'];
	}
};
