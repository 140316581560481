/* eslint-disable import/prefer-default-export */
import { UUID } from 'library/common/types/app.d';
import { ICommentEntity } from 'library/common/types/cards/comments.d';

export const getCommentsMapper = (commentsHashMap?: { [key: UUID]: ICommentEntity }) => {
	if (commentsHashMap === undefined) {
		return [];
	}
	return Object
		.values(commentsHashMap)
		.sort((a, b) => new Date(b.comment.updated).getTime() - new Date(a.comment.updated).getTime());
};
