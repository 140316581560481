import { BackendData, UUID } from 'library/common/types/app.d';
import { AxiosResponse, CancelToken, AxiosRequestConfig } from 'axios';
import {
	SelfServiceLoginFlow,
	SelfServiceRecoveryFlow,
	SelfServiceRegistrationFlow,
	Session,
} from '@ory/kratos-client/api';
import { mapExternalProfileData, mapStatistics } from 'library/utils/mappers/user.mapper';
import { IExternalProfileData, IFullStatistics } from 'library/common/types/user.d';
import {
	identityApi, profileApi, cardApiV1, cardApiV2,
} from './urls';

const registrationInit = () => identityApi
	.get('self-service/registration/browser')
	.then((res: AxiosResponse<SelfServiceRegistrationFlow>) => res);

const registration = ({
	method, flowId, csrfToken, email, password, provider, login, referral_id,
}: {
	flowId: string,
	csrfToken: string,
	method: 'password' | 'oidc',
	provider?: 'facebook' | 'google',
	email?: string,
	login?: string,
	password?: string,
	referral_id?: UUID,
}) => (
	identityApi.post(
		'self-service/registration',
		{
			csrf_token: csrfToken,
			method,
			provider,
			traits: (email && login) && {
				email,
				login,
				referral_id,
			},
			password,
		},

		{
			params: {
				flow: flowId,
			},
		},
	)
		.then((r) => r)
);

const loginInit = () => identityApi('self-service/login/browser')
	.then((res: AxiosResponse<SelfServiceLoginFlow>) => res);

const login = (flowId: string, csrfToken: string, password_identifier: string, password: string) => identityApi
	.post(
		'self-service/login',
		{
			csrf_token: csrfToken,
			method: 'password',
			password_identifier,
			password,
		},
		{
			params: {
				flow: flowId,
			},
		},
	);

const logoutInit = () => identityApi.get('self-service/logout/browser');

const logout = (csrfToken: string) => identityApi.get('self-service/logout', {params: {token: csrfToken}});

const getUserData = (id: string) => profileApi
	.get(`user/${id}/partial`)
	.then((r) => r);

const getUserDataByCookie = async () => profileApi
	.get<Session>('sessions/whoami')
	.then((r) => r);

const initRecovery = () => 	identityApi
	.get<SelfServiceRegistrationFlow>('self-service/recovery/browser')
	.then((res) => res);

const recovery = (csrfToken: string, flowId: string, email: string, cancelToken?: CancelToken) => identityApi
	.post<SelfServiceRecoveryFlow>(
		'self-service/recovery',
		{
			csrf_token: csrfToken,
			method: 'link',
			email,
		},
		{
			params: {
				flow: flowId,
			},
			cancelToken,
		},
	).then((res) => res);

const getProfile = (id?: string) => profileApi
	.get<IExternalProfileData>(`api/profile/${id || ''}`)
	.then(mapExternalProfileData);

const getFullStatistics = (id?: UUID) => cardApiV2
	.get<BackendData<IFullStatistics>>(`/subscription/statistics/${id || ''}`)
	.then(mapStatistics);

const setAvatar = <T = any>(formData: FormData, config?: AxiosRequestConfig) => profileApi
	.post<T>('api/profile/photo', formData, config)
	.then((res) => res);

const dropAvatar = (config?: AxiosRequestConfig) => profileApi
	.delete('api/profile/photo', config)
	.then((res) => res);

const followUser = (author_id: UUID) => cardApiV1.put(`subscription/${author_id}`);

const unfollowUser = (author_id: UUID) => cardApiV1.delete(`subscription/${author_id}`);

export default {
	registrationInit,
	registration,
	getUserDataByCookie,
	getUserData,
	loginInit,
	login,
	logoutInit,
	logout,
	initRecovery,
	recovery,
	getProfile,
	followUser,
	unfollowUser,

	setAvatar,
	dropAvatar,
	getFullStatistics,
};
