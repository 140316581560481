import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const AddIcon = (props: SvgIconProps) => (
	<SvgIcon viewBox="0 0 32 32" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4 7C4 5.89543 4.89543 5 6 5H24C25.1046 5 26 5.89543 26 7V9H14C12.8954 9 12 9.89543 12
			11V21C12 22.1046 12.8954 23 14 23H26V25C26 26.1046 25.1046 27 24 27H6C4.89543 27 4 26.1046 4
			25V7ZM14 10.6154H28V21.3846H14V10.6154ZM21.9999 16C21.9999 17.1895 21.1045 18.1538 19.9999
			18.1538C18.8954 18.1538 17.9999 17.1895 17.9999 16C17.9999 14.8105 18.8954 13.8462 19.9999
			13.8462C21.1045 13.8462 21.9999 14.8105 21.9999 16Z"
		/>
	</SvgIcon>
);

export default AddIcon;
