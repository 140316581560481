import React from 'react';
import { useSelector } from 'react-redux';

import MHidden from 'library/common/components/MHidden/MHidden';
import useProfile from 'library/common/hook/useProfile';
import useCard from 'library/common/hook/useCard';
import useSelfVault from 'library/common/data/wodabonuses/useSelfVault';
import { selectUserId } from 'library/common/selectors/user.selector';

import DesktopSelfProfile from './desktop/DesktopSelfProfile';
import MobileSelfProfile from './mobile/MobileSelfProfile';

interface IProps {}

const SelfProfile: React.FunctionComponent<IProps> = () => {
	const {
		profileData,
		isPending,
		progress,
		setAvatarRequest,
	} = useProfile();

	// @TODO вот эту прелесть нужно переделать. данные пользователя запрашиватся выше, а id тянем из redux
	const authorId = useSelector(selectUserId);

	const {
		activatedCards,
		favoriteCards,
		publishedCards,
		setFavoriteModeHandler,
		setActivatedModeHandler,
		setPublishedModeHandler,
		isPending: isCardsPending,
		mode: ListMode,
		getPublishedCards,
	} = useCard({ authorId });

	const { data: wodaBalance, isLoading: isWodaBalanceLoading } = useSelfVault();

	return (
		<>
			<MHidden breakpoint="md" direction="Down">
				<DesktopSelfProfile
					progress={progress}
					profileData={profileData}
					wodaBalance={wodaBalance ?? 0}
					isPending={isPending}
					isWodaBalanceLoading={isWodaBalanceLoading}
					ListMode={ListMode}
					favoriteCards={favoriteCards}
					activatedCards={activatedCards}
					publishedCards={publishedCards}
					isCardsPending={isCardsPending}
					setPublishedModeHandler={setPublishedModeHandler}
					setActivatedModeHandler={setActivatedModeHandler}
					setFavoriteModeHandler={setFavoriteModeHandler}
					setAvatarRequest={setAvatarRequest}
					getPublishedCards={getPublishedCards}
				/>
			</MHidden>
			<MHidden breakpoint="md" direction="Up">
				<MobileSelfProfile
					progress={progress}
					profileData={profileData}
					wodaBalance={wodaBalance ?? 0}
					isPending={isPending}
					isWodaBalanceLoading={isWodaBalanceLoading}
					ListMode={ListMode}
					favoriteCards={favoriteCards}
					activatedCards={activatedCards}
					publishedCards={publishedCards}
					isCardsPending={isCardsPending}
					setPublishedModeHandler={setPublishedModeHandler}
					setActivatedModeHandler={setActivatedModeHandler}
					setFavoriteModeHandler={setFavoriteModeHandler}
					setAvatarRequest={setAvatarRequest}
					getPublishedCards={getPublishedCards}
				/>
			</MHidden>
		</>
	);
};

export default SelfProfile;
