import React, {useMemo, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Link as RouterLink} from 'react-router-dom';
import {
	alpha, Avatar, Box, Button, Divider, MenuItem, Typography, IconButton,
} from '@mui/material';

import {takeNameToAvatar} from 'library/utils/textGenerators';
import {logoutAsync} from 'library/common/actions/user.action';

import MenuPopover from '../MenuPopover/MenuPopover';
import menuItems from './menuItems';

interface IAccountPopover {
	email?: string;
	logoURL?: string;
	username?: string;
	backgroundColor?: string;
}

const AccountPopover = ({
	logoURL, username, email, backgroundColor,
}: IAccountPopover) => {
	const dispatch = useDispatch();
	const anchorRef = useRef(null);
	const [open, setOpen] = useState(false);

	const avatarFirstLetter = useMemo(() => takeNameToAvatar(username), [username]);

	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	const onLogoutHandle = () => {
		dispatch(logoutAsync.request());
	};

	return (
		<>
			<IconButton
				ref={anchorRef}
				onClick={handleOpen}
				sx={{
					padding: 0,
					width: 50,
					height: 50,
					...(open && {
						'&:before': {
							zIndex: 1,
							content: "''",
							width: '100%',
							height: '100%',
							borderRadius: '32px',
							position: 'absolute',
							bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
						},
					}),
				}}
			>
				<Avatar
					variant="circular"
					src={logoURL}
					sx={{
						width: '100%',
						height: '100%',
						bgcolor: backgroundColor || 'transparent',
					}}
					alt="photoURL"
				>
					{avatarFirstLetter}
				</Avatar>
			</IconButton>
			<MenuPopover
				open={open}
				onClose={handleClose}
				anchorEl={anchorRef.current}
				sx={{ width: 220 }}
			>
				<Box sx={{ my: 1.5, px: 2.5 }}>
					{
						username && (
							<Typography variant="subtitle1" noWrap>
								{username}
							</Typography>
						)
					}
					{
						email && (
							<Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
								{email}
							</Typography>
						)
					}
				</Box>

				<Divider sx={{ my: 1 }} />

				{menuItems.map((option) => (
					<MenuItem
						key={option.label}
						to={option.path}
						component={RouterLink}
						onClick={handleClose}
						sx={{ typography: 'body2', py: 1, px: 2.5 }}
					>
						<Box
							component={option.icon}
							sx={{
								mr: 2,
								width: 24,
								height: 24,
							}}
						/>

						{option.label}
					</MenuItem>
				))}

				<Box sx={{ p: 2, pt: 1.5 }}>
					<Button
						fullWidth
						color="inherit"
						variant="outlined"
						onClick={onLogoutHandle}
					>
						Logout
					</Button>
				</Box>
			</MenuPopover>
		</>
	);
};

export default AccountPopover;
