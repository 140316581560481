import React from 'react';

import { Box, Stack, Typography } from '@mui/material';

interface IProps {
    count: string;
}

const CountNumber: React.FunctionComponent<IProps> = ({ count }) => (
	<Stack direction="row" spacing={0.6}>
		{count.split('').map((number, index) => (
			<Box
				// eslint-disable-next-line react/no-array-index-key
				key={index}
				sx={{
					userSelect: 'none',
					p: 0.8,
					borderRadius: '8px',
					background: 'linear-gradient(0deg, rgba(65, 154, 242, 0.2), rgba(65, 154, 242, 0.2)), #FFFFFF',
				}}
			>
				<Typography
					variant="h5"
					sx={{ color: ({ palette }) => palette.text.secondary }}
				>
					{number}
				</Typography>
			</Box>
		))}
	</Stack>
);

export default CountNumber;
