import { IAuthor } from 'library/common/types/cards/cards.d';
import { BackendData } from 'library/common/types/app.d';
import { AxiosResponse } from 'axios';
import { colors } from '@mui/material';
import { IUserData, IExternalProfileData, IFullStatistics } from 'library/common/types/user.d';
import { getProfileAvatar } from 'library/utils/getters/getPicture';
import { takeColorByFirstLetter } from 'library/utils/myColors';

export const mapExternalProfileData = (response: AxiosResponse<IExternalProfileData>): Omit<IUserData, 'followings' | 'followers'> => {
	const {
		data: {
			payload: {
				avatar, identity, referrals, is_verified,
			},
		},
	} = response;

	return {
		id: identity.id,
		email: identity.email,
		username: identity.login,
		backgroundColor: takeColorByFirstLetter(colors, identity.login || 'A'),
		logoURL: getProfileAvatar(avatar),
		referrals,
		isVerified: is_verified,
	};
};

export const mapAuthorData = (data: IAuthor) => ({
	authorId: data.id,
	author: data.login,
	avatar: data.avatar,
});

export const mapStatistics = (data: AxiosResponse<BackendData<IFullStatistics>>) => data.data?.payload;
