import { omitBy } from 'lodash';
import {
	useMutation,
	useQueryClient,
	UseMutationOptions,
	QueryClient,
} from '@tanstack/react-query';

import cardsService from 'library/common/services/cards.service';
import { UUID } from 'library/common/types/app.d';
import { mapAxiosCardApiResponse } from 'library/utils/mappers/api.mapper';

import { showSnackbarMessage } from 'library/utils/showSnackbarMessage';
import { CommentResponse } from 'library/common/types/cards/comments.d';
import axiosErrorHandler from 'library/utils/axiosErrorHandler';
import commentsQueryKeys from './queryKeys';

type MutationParams = {
	root?: boolean;
    commentId: UUID;
    commentParentId: UUID;
 };

const getRemoveCommentMutation = (queryClient: QueryClient): UseMutationOptions<
    void,
    any,
    MutationParams,
    { key: ReturnType<typeof commentsQueryKeys.byCardId | typeof commentsQueryKeys.byCommentId>; oldValue?: CommentResponse; commentId: UUID }
> => ({
	mutationFn: ({ commentId }: MutationParams) => cardsService
		.removeComment(commentId)
		.then<void>(mapAxiosCardApiResponse),
	onMutate: ({ commentId, root, commentParentId }) => {
		const key = commentsQueryKeys[root ? 'byCardId' : 'byCommentId'](commentParentId);

		const oldValue = queryClient.getQueryData<CommentResponse>(key);

		queryClient.setQueryData(key, omitBy(oldValue, { comment: { id: commentId } }));

		return { key, oldValue, commentId };
	},
	onSuccess: () => undefined,
	onError: (error, _vars, context) => {
		if (context?.oldValue) {
			const deletedComment = context.oldValue[context.commentId];
			showSnackbarMessage(`Error when deleting comment: ${deletedComment.comment.text}`, 'error');

			queryClient.setQueryData(context.key, context.oldValue);
		}

		axiosErrorHandler(error);
	},
});

const useRemoveCommentMutation = () => {
	const queryClient = useQueryClient();

	return useMutation(getRemoveCommentMutation(queryClient));
};

export default useRemoveCommentMutation;
