import Pica from 'pica';
import { logger } from './lintersSugar';

const maxWidth = 800;
const maxHeight = 660;

const sendData = async (imageSrc: string, fileName: string, callback: (file: File) => void) => {
	const base64 = await fetch(imageSrc);
	const blob = await base64.blob();
	const file = new File([blob], fileName || 'step-picture', {type: blob?.type || 'png'});
	callback(file);
};

export default (data: string, fileName: string, callback: (file: File) => void) => {
	const pica = Pica();

	const srcCanvas = document.createElement('canvas');

	const image = new Image();
	image.src = data;
	image.onload = () => {
		const origAspectRatio = image.width / image.height;
		let targetWidth = 0;
		let targetHeight = 0;

		// determine which dimension is going to be the limiting one (hit the max),
		// so we can us it and scale the other by it
		const maxWidthRatio = image.width ? maxWidth / image.width : 0;
		const maxHeightRatio = image.height ? maxHeight / image.height : 0;

		if (maxWidthRatio >= 1 && maxHeightRatio >= 1) {
			logger('image is already small enough, nothing to do');
			sendData(data, fileName, callback);
			return;
		} if (maxWidthRatio === 0 && maxHeightRatio === 0) {
			logger('no resize dimensions specified, nothing to do');
			sendData(data, fileName, callback);
			return;
		} if (maxHeightRatio > maxWidthRatio) {
			targetWidth = maxWidth;
			targetHeight = maxWidth / origAspectRatio;
			logger(`clamping width to max, resizing dimensions to: ${targetWidth} x ${targetHeight}`);
		} else if (maxWidthRatio > maxHeightRatio) {
			targetHeight = maxHeight;
			targetWidth = maxHeight * origAspectRatio;
			logger(`clamping height to max, resizing dimensions to: ${targetWidth} x ${targetHeight}`);
		}

		srcCanvas.width = image.width;
		srcCanvas.height = image.height;

		const ctx = srcCanvas.getContext('2d');

		if (ctx) {
			ctx.drawImage(image, 0, 0);

			// create the destination canvas
			const destCanvas = document.createElement('canvas');
			destCanvas.width = targetWidth;
			destCanvas.height = targetHeight;

			pica.resize(srcCanvas, destCanvas).then(async () => {
				sendData(destCanvas.toDataURL(), fileName, callback);
			});
		}
	};
};
