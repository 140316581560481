import React from 'react';
import { Skeleton, Stack, Typography } from '@mui/material';

import ProfileAvatar from 'pages/Profile/components/ProfileAvatar';
import Label from 'library/common/components/Label/Label';
import VerifiedIcon from 'resources/icons/VerifiedIcon';

interface IProps {
    selfPage?: boolean;
    progress?: number | null;
    isPending: boolean;
    username?: string;
	logoURL?: string;
	isVerified?: boolean;
    setAvatarRequest?: (newAvatar: File[] | undefined) => void;
}

const ProfileHeader: React.FunctionComponent<IProps> = ({
	progress,
	selfPage,
	username,
	logoURL,
	isPending,
	isVerified,
	setAvatarRequest,
}) => (
	<Stack direction="row" px={2}>
		<ProfileAvatar
			selfPage={selfPage}
			progress={progress}
			isPending={isPending}
			username={username}
			logoURL={logoURL}
			setAvatarRequest={setAvatarRequest}
		/>

		<Stack sx={{ flexGrow: 1, mx: 1.5 }} spacing={1}>
			<Typography
				variant="caption"
				sx={{
					color: (theme) => theme.palette.text.secondary,
					fontWeight: 600,
				}}
			>
				{selfPage ? 'Profile' : 'Author'}
			</Typography>
			{
				isPending ? (
					<Typography component="h6">
						<Skeleton variant="text" />
					</Typography>
				) : (
					<Stack direction="row" spacing={1}>
						<Label
							component="h6"
							title={username || ''}
							sx={{
								background: (theme) => (theme.palette as any).gradients.primary,
								fontWeight: 600,
								WebkitBackgroundClip: 'text',
								WebkitTextFillColor: 'transparent',
							}}
						/>
						{isVerified && <VerifiedIcon sx={{ width: '16px', height: '16px' }} />}
					</Stack>
				)
			}
		</Stack>
	</Stack>
);

export default ProfileHeader;
