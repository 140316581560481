import React from 'react';
import {NavLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import { Box } from '@mui/material';

const NoCard = () => {
	const {t} = useTranslation();

	return (
		<Box
			sx={{
				width: '100%',
				height: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			{t('unfortunately,_there_is_no_such_card')}
			&nbsp;
			<NavLink to="/app/main">{t('you_can_go_back_to_the_general_list_of_cards')}</NavLink>
		</Box>
	);
};

export default NoCard;
