import {
	call, put, takeLatest, select,
} from 'redux-saga/effects';
import {AxiosResponse} from 'axios';

import {RootState} from 'main/rootReducer';
import i18n from 'library/utils/language/conf';
import itemsCountCalc from 'library/utils/itemsCountCalc';
import {showSnackbarMessage} from 'library/utils/showSnackbarMessage';
import {IPoorCard} from 'library/common/types/cards/cards.d';
import * as cardActions from 'library/common/actions/cards.action';
import cardActionTypes from 'library/common/constants/cards.constant';
import cardsService from 'library/common/services/cards.service';
import { AxiosCardApiPaginationResponse } from 'library/common/types/cards/request.d';

function* getCardsSaga() {
	try {
		const pageNumber: number | undefined = yield select((state: RootState) => state.cards.pageNumber);

		// TODO переписать расчет limit
		const pageSize = itemsCountCalc();
		const { selectedCategory, searchPhrase } = yield select((state: RootState) => state.cards);

		const {
			data: {
				total_pages,
				page_number,
				payload,
			},
		}: AxiosResponse<AxiosCardApiPaginationResponse<IPoorCard[]>> = yield call(
			cardsService.getCardsList,
			{
				page: pageNumber || 0,
				size: pageSize,
				category_id: selectedCategory,
				title: searchPhrase,
			},
		);

		yield put(cardActions.getCardsAsync.success(
			{
				cards: payload,
				pageNumber: page_number + 1,
				totalPages: total_pages,
			},
		));
	} catch (e) {
		yield put(cardActions.getCardsAsync.failure(e));
		showSnackbarMessage(i18n.t('error_loading_data'), 'error', 3000);
	}
}

export default function* watchCommonLayout() {
	yield takeLatest(cardActionTypes.GET_CARDS_REQUEST, getCardsSaga);
}
