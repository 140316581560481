import React from 'react';
import {
	Box, Divider, Grid, Typography,
} from '@mui/material';
// import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CommentIcon from '@mui/icons-material/Comment';

import { declOfNum, getFullDuration } from 'library/utils/workWithData';
import { IPoorCard } from 'library/common/types/cards/cards.d';
import { getPoster } from 'library/utils/getters/getPicture';
import LabelWithIcon from 'library/common/components/LabelWIthIcon';
import BorderLine from 'pages/Profile/components/List/BorderLine';

import ListItem from './ListItem';

interface IProps {
    cards: IPoorCard[];
}

const PublishedList: React.FunctionComponent<IProps> = ({ cards }) => (
	<>
		{
			cards.map((card) => (
				<ListItem key={card.id} id={card.id}>
					<>
						<Grid item xs={12} lg={1}>
							<Box
								component="img"
								sx={{
									width: '64px',
									height: '64px',
								}}
								src={getPoster(card, 'LIST')}
							/>
						</Grid>

						<Grid container item sm={12} lg={10} position="relative">
							<Grid item xs={4} display="flex" alignItems="center">
								<Typography
									variant="h6"
									sx={{
										fontWeight: 600,
										color: (theme) => theme.palette.common.white,
									}}
								>
									{card.title}
								</Typography>
							</Grid>

							<Grid item xs={4} display="flex" alignItems="center" justifyContent="center">
								<Typography
									variant="h6"
									className="info"
									sx={{
										color: (theme) => theme.palette.secondary.light,
									}}
								>
									{card.duration && getFullDuration(card.duration)}
								</Typography>
								<Divider
									orientation="vertical"
									flexItem
									sx={{
										mx: 1,
										color: (theme) => theme.palette.secondary.light,
										height: '16px',
										alignSelf: 'auto',
									}}
								/>
								<Typography
									variant="h6"
									className="info"
									sx={{
										color: (theme) => theme.palette.secondary.light,
									}}
								>
									{`${card.number_of_steps || 0} ${declOfNum(card.number_of_steps || 0, ['step', 'steps', 'steps'])}`}
								</Typography>
							</Grid>

							<Grid item xs={4} display="flex" alignItems="center" justifyContent="flex-end">
								<Typography
									variant="h6"
									className="info"
									sx={{ color: (theme) => theme.palette.secondary.light }}
								>
									{card.category.title}
								</Typography>
							</Grid>
							<BorderLine />
						</Grid>

						<Grid
							item
							xs={12}
							lg={1}
							gap={1}
							display="flex"
							alignItems="center"
							justifyContent="center"
							direction="column"
							sx={{color: (theme) => theme.palette.secondary.light}}
						>

							{/* <LabelWithIcon
								sx={{ color: (theme) => theme.palette.text.secondary }}
								tooltip="Number of activations"
								title={`${card.statistics.number_of_activation || 0}`}
								icon={(
									<CheckCircleRoundedIcon sx={{
										color: (theme) => theme.palette.text.secondary,
									}}
									/>
								)}
							/> */}

							<LabelWithIcon
								sx={{ color: (theme) => theme.palette.text.secondary }}
								tooltip="Number of likes"
								title={`${card.statistics.number_of_favorites || 0}`}
								icon={<ThumbUpIcon sx={{ color: (theme) => theme.palette.text.secondary }} />}
							/>
							<LabelWithIcon
								sx={{ color: (theme) => theme.palette.text.secondary }}
								tooltip="Number of comments"
								title={`${card.statistics.number_of_comments || 0}`}
								icon={<CommentIcon sx={{ color: (theme) => theme.palette.text.secondary }} />}
							/>
						</Grid>
					</>
				</ListItem>
			))
		}
	</>
);

export default PublishedList;
