import React, { useCallback, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v1 as uuidv1 } from 'uuid';
import {
	Box, Button, CircularProgress, Container, Stack, styled, Typography,
} from '@mui/material';

import useCard, { ModeType } from 'library/common/hook/useCard';
import useCardCreation from 'library/common/hook/useCardCreation';
import stepValidator from 'library/utils/validators/step.validator';
import { ICardFormData } from 'library/common/types/cards/cardsCreation.d';
import TextFieldWithCount from 'library/common/components/TextFieldWithCount';
import ResultsSelector from 'library/common/components/ResultsSelector/ResultsSelector';

import isNonNullable from 'library/utils/isNonNullable';
import { useSelector } from 'react-redux';
import { selectUserId } from 'library/common/selectors/user.selector';
import Stages from './components/Stages';
import TimeInput from './components/TimeInput';
import CoverInput from './components/CoverInput';
import CategoriesSelector from './components/CategoriesSelector';
import getErrorField from './helpers/getErrorField';

const StyledTextField = styled(TextFieldWithCount)(({ theme }) => ({
	fieldset: {
		borderColor: `${theme.palette.secondary.dark}4D`,
	},
}));

const CardCreation = () => {
	const {t} = useTranslation();

	const refArray = useRef<{[key: string]: HTMLDivElement | null}>({});

	const authorId = useSelector(selectUserId);

	const { isPending: cardCountPending, publishedCardsCount } = useCard({ authorId, defaultMode: ModeType.PUBLISHED });
	const { isPending, saveCardHandle } = useCardCreation();

	const {
		control, handleSubmit, watch, formState: { errors, submitCount },
	} = useForm<ICardFormData>({
		defaultValues: {
			title: '',
			days: 0,
			hours: 0,
			minutes: 0,
			seconds: 0,
			stages: [
				{ id: uuidv1(), resources: [] },
			],
		},
	});

	const formState = watch();

	const timeWatcher = watch(['days', 'hours', 'minutes', 'seconds']);

	const timeValidation = useCallback((): boolean => (
		timeWatcher[0] + timeWatcher[1] + timeWatcher[2] + timeWatcher[3] > 0
	),
	[timeWatcher]);

	const onSubmit = handleSubmit(async (data) => {
		saveCardHandle(data);
	});

	useEffect(() => {
		const firstError = getErrorField(Object.keys(errors)?.[0]);

		if (isNonNullable(firstError)) {
			refArray.current?.[firstError]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}, [submitCount, errors]);

	if (isPending || cardCountPending) {
		return (
			<Box
				sx={{
					width: '100%',
					height: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<CircularProgress />
			</Box>
		);
	}

	return (
		<Container
			maxWidth="xl"
			sx={(theme) => ({
				padding: {
					xs: theme.spacing(0, 2, 10),
					md: theme.spacing(0, 15, 10, 12),
				},
			})}
		>
			<Box>
				<Typography
					component="h5"
					variant="h5"
					sx={{
						fontWeight: 800,
						color: (theme) => theme.palette.text.secondary,
					}}
				>
					{`${t('my_wodaqard_number')} ${publishedCardsCount + 1}`}
				</Typography>
			</Box>

			<Stack mt={5} component="form" onSubmit={onSubmit} spacing={6}>
				<div ref={(ref) => { refArray.current.title = ref; }}>
					<Controller
						control={control}
						name="title"
						rules={{
							minLength: {
								value: 3,
								message: t('minimum_3_characters'),
							},
							maxLength: {
								value: 100,
								message: t('maximum_100_characters'),
							},
							required: {
								value: true,
								message: t('please_enter_a_name'),
							},
						}}
						render={({ field, fieldState: {error} }) => (
							<StyledTextField
								{...field}
								maxLength={120}
								label={`${t('text_your_wodaqard_title')}`}
								variant="outlined"
								size="small"
								disabled={isPending}
								fullWidth
								error={Boolean(error)}
								helperText={error?.message}
							/>
						)}
					/>
				</div>

				<div ref={(ref) => { refArray.current.category = ref; }}>
					<Controller
						control={control}
						name="category"
						rules={{
							required: {
								value: true,
								message: t('please_select_at_least_one_category'),
							},
						}}
						render={({ field: { value, onChange }, fieldState: {error} }) => (
							<CategoriesSelector
								selectionColor="primary"
								onChange={onChange}
								value={value}
								error={error?.message}
							/>
						)}
					/>
				</div>

				<div ref={(ref) => { refArray.current.result = ref; }}>
					<Controller
						control={control}
						name="result"
						rules={{
							required: {
								value: true,
								message: t('please_select_the_result'),
							},
						}}
						render={({ field: { onChange, value }, fieldState: {error} }) => (
							<ResultsSelector
								value={value}
								setValue={onChange}
								categoryId={formState?.category?.id}
								error={error?.message}
							/>
						)}
					/>
				</div>

				<div ref={(ref) => { refArray.current.time = ref; }}>
					<TimeInput
						timeMetrics={['days', 'hours', 'minutes', 'seconds']}
						timeValidation={timeValidation}
						control={control}
						daysError={errors.days}
						hoursError={errors.hours}
						minError={errors.minutes}
						secError={errors.seconds}
						errorMessage={t('set_the_time')}
					/>
				</div>

				<div ref={(ref) => { refArray.current.stages = ref; }}>
					<Controller
						control={control}
						name="stages"
						rules={{
							validate: stepValidator,
						}}
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<Stages
								data={value}
								setValue={onChange}
								errors={error?.message}
								disabled={isPending}
							/>
						)}
					/>
				</div>

				<Controller
					control={control}
					name="cover"
					rules={{
						required: {
							value: true,
							message: t('add_a_card_cover'),
						},
					}}
					render={({ field: { onChange }, fieldState: {error} }) => (
						<CoverInput
							setCover={onChange}
							error={Boolean(error)}
							helperText={error?.message}
							formState={formState}
							disabled={isPending}
						/>
					)}
				/>

				<Button
					variant="contained"
					type="submit"
					color="primary"
					disabled={isPending}
					fullWidth
				>
					{t('create_card')}
				</Button>
			</Stack>

		</Container>
	);
};

export default CardCreation;
