// eslint-disable-next-line no-shadow
enum cardActionTypes {
	GET_CARDS_REQUEST = '@@CARD/GET_CARDS_REQUEST',
	GET_CARDS_SUCCESS = '@@CARD/GET_CARDS_SUCCESS',
	GET_CARDS_FAILURE = '@@CARD/GET_CARDS_FAILURE',

	DROP_PAGE_DATA = '@@CARD/DROP_PAGE_DATA',
}

export default cardActionTypes;
