import { QueryKey } from '@tanstack/react-query';

const wodabonusesQueryKeys = {
	rules: () => ['rules'] as QueryKey,
	transactions: () => ['transactions'] as QueryKey,
	vault: () => ['vault'] as QueryKey,
	vaultLast: () => [...wodabonusesQueryKeys.vault(), 'last'] as QueryKey,
	vaultSelf: () => [...wodabonusesQueryKeys.vault(), 'self'] as QueryKey,
	transactionsLast: () => [...wodabonusesQueryKeys.transactions(), 'last'] as QueryKey,

};

export default wodabonusesQueryKeys;
