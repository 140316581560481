import React from 'react';
import { Box, Stack } from '@mui/material';

import Label from 'library/common/components/Label/Label';
import { IResult } from 'library/common/types/cards/cards.d';
import UnionIcon from 'resources/icons/UnionIcon';

interface IProps {
    result: IResult;
}

const ResultWithLabel: React.FunctionComponent<IProps> = ({ result }) => (
	<Stack direction="row" alignItems="center" sx={{ position: 'relative', zIndex: 1 }}>
		<Box
			sx={{
				width: '56px',
				height: '56px',
				position: 'relative',
				borderRadius: '50px',
				background: '#fff',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',

				'&:before': {
					userSelect: 'none',
					content: "''",
					position: 'absolute',
					top: '-1px',
					bottom: '-1px',
					left: '-1px',
					right: '-1px',
					opacity: 0.5,
					background: ({palette}) => (palette as any).gradients.info,
					borderRadius: '50px',
					zIndex: -1,
				},
			}}
		>
			<UnionIcon
				viewBox="0 0 20 20"
				sx={{
					width: '28px',
					height: '30px',
					color: ({palette}) => palette.text.secondary,
				}}
			/>
		</Box>

		<Label
			title={result.title}
			variant="body1"
			sx={{
				ml: 2,
				color: ({palette}) => palette.text.primary,
				fontWeight: 600,
			}}
		/>
	</Stack>
);

export default ResultWithLabel;
