import React, { useEffect, useMemo, useState } from 'react';
import {AxiosResponse} from 'axios';
import { debounce, uniqBy } from 'lodash';
import { useSelector } from 'react-redux';
import {useTranslation} from 'react-i18next';
import {
	Autocomplete,
	CircularProgress,
	IconButton,
	InputAdornment,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { RootState } from 'main/rootReducer';
import { AxiosCardApiPaginationResponse } from 'library/common/types/cards/request.d';
import {IPoorCard} from 'library/common/types/cards/cards.d';
import cardServices from 'library/common/services/cards.service';
import { UUID } from 'library/common/types/app.d';
import {logger} from 'library/utils/lintersSugar';
import SearchIcon from 'resources/icons/SearchIcon';
import { getMainPageWithSearchQueryUrl, getMainSearchPageUrl } from 'library/utils/routes/mainPageUrls';
import MainPageQueryParams from 'library/utils/routes/mainPageQueryParams';

interface IOption {
	id: UUID;
	title: string;
}

const SearchInput = () => {
	const {t} = useTranslation();

	const navigate = useNavigate();

	const { pathname } = useLocation();

	const [searchQuery, setSearchParams] = useSearchParams({});

	const searchPhrase = useSelector((state: RootState) => state.cards.searchPhrase) || '';

	const [open, setOpen] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [option, setOption] = useState<IOption[]>([]);
	const [searchInputValue, setSearchInputValue] = useState<string>('');

	// TODO На самом деле - это бред. Нужно сделать нормально.
	useEffect(() => {
		setSearchInputValue(searchPhrase);
	}, [searchPhrase]);

	const getOptions = useMemo(() => debounce((value: string) => {
		setLoading(true);
		// TODO Обсудить этот вопрос. Слишком много лишних данных собираются.
		cardServices
			.getCardsList({title: value.replace(/\s+/g, ' ').trim()})
			.then((res: AxiosResponse<AxiosCardApiPaginationResponse<IPoorCard[]>>) => (
				setOption(
					uniqBy(res.data.payload, 'title')
						.map((card: IPoorCard) => ({id: card.id, title: card.title})),
				)))
			.catch((error) => logger(error, 'error'))
			.finally(() => setLoading(false));
	}, 500), []);

	const onSearchHandle = (search_query: string = '') => {
		if (pathname !== getMainSearchPageUrl() && search_query.length > 0) {
			navigate(getMainPageWithSearchQueryUrl(search_query));
		} else {
			// eslint-disable-next-line no-unused-expressions
			search_query.length > 0
				? searchQuery.set(MainPageQueryParams.SEARCH_QUERY, search_query)
				: searchQuery.delete(MainPageQueryParams.SEARCH_QUERY);

			setSearchParams(searchQuery);
		}
	};

	return (
		<Autocomplete
			id="cards search"
			freeSolo
			open={open}
			onOpen={() => setOpen(true)}
			onClose={() => setOpen(false)}
			options={option}
			loading={loading}
			loadingText={`${t('loading')}...`}
			getOptionLabel={(item: any) => item.title ?? ''}
			inputValue={searchInputValue}
			onChange={(e, val) => {
				if (val && typeof val === 'object' && 'title' in val) {
					onSearchHandle(val.title);
				}
			}}
			disableClearable
			renderInput={(params) => (
				<TextField
					{...params}
					size="small"
					label={t('search_text')}
					margin="none"
					variant="outlined"
					onChange={(e) => {
						setSearchInputValue(e.target.value);
						getOptions(e.target.value);
					}}
					onKeyPress={(e) => {
						if (e.key === 'Enter') onSearchHandle(searchInputValue);
					}}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<>
								{loading ? <CircularProgress color="inherit" size={20} /> : null}
								<InputAdornment position="end">
									{searchInputValue.length > 0 && (
										<IconButton
											aria-label="delete search request text"
											size="small"
											onClick={() => onSearchHandle()}
										>
											<CloseIcon fontSize="inherit" />
										</IconButton>
									)}
									<IconButton
										aria-label={t('search')}
										size="small"
										onClick={() => onSearchHandle(searchInputValue)}
									>
										<SearchIcon fontSize="inherit" />
									</IconButton>
								</InputAdornment>
							</>
						),
					}}
					sx={{
						fieldset: {
							borderColor: '#4198EF4D',
						},
					}}
				/>
			)}
		/>
	);
};

export default SearchInput;
