import { createLogger } from 'redux-logger';

// TODO разобраться с линтером и enum
// eslint-disable-next-line no-shadow
enum COLORS {
    REQUEST = '#00AAE7',
    SUCCESS = '#5DC600',
    FAILURE = '#FF0C0C',
}

const getTitleColor = ({ type }: any): string => {
	if (type.match(/REQUEST/gm)) {
		return COLORS.REQUEST;
	}

	if (type.match(/SUCCESS/gm)) {
		return COLORS.SUCCESS;
	}

	if (type.match(/FAILURE/gm)) {
		return COLORS.FAILURE;
	}

	return '';
};

const logger = createLogger({
	collapsed: true,
	colors: {
		title: (action) => `${getTitleColor(action)};`,
	},
});

export default logger;
