import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resources from './lang.json';

i18n
	.use(LanguageDetector)
	.init({
	// we init with resources
		resources: {
			en: {
				translations: resources.en,
			},
			rus: {
				translations: resources.rus,
			},
		},
		lng: 'en',
		fallbackLng: 'en',
		debug: false,

		// have a common namespace used around the full app
		ns: ['translations'],
		defaultNS: 'translations',

		keySeparator: false, // we use content as keys

		interpolation: {
			escapeValue: false, // not needed for react!!
			formatSeparator: ',',
		},

		react: {
			useSuspense: true,
		},
	});

export default i18n;
