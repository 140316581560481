import React, {SyntheticEvent} from 'react';
import { Box, Stack, Typography } from '@mui/material';
import BrushIcon from '@mui/icons-material/Brush';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import {SxProps} from '@mui/system';
import {Theme} from '@mui/material/styles';
import TouchRipple, {TouchRippleActions} from '@mui/material/ButtonBase/TouchRipple';

interface IInput {
    openEditor: () => void;
    sx?: SxProps<Theme>;
}

const onHoverStyle = {
	borderColor: '#4198EF',
	background: (theme: Theme) => (theme.palette.secondary as any).lighter,
	color: (theme: Theme) => theme.palette.grey[600],
};

const Input = ({openEditor, sx}: IInput) => {
	const rippleRef = React.useRef<TouchRippleActions>(null);

	const onRippleStart = (e: SyntheticEvent) => {
		rippleRef.current?.start(e);
	};

	const onRippleStop = (e: SyntheticEvent) => {
		rippleRef.current?.stop(e);
	};

	return (
		<>
			<Stack
				flexDirection="column"
				sx={{
					cursor: 'pointer',
					position: 'relative',
					minHeight: 336,
					padding: 3,
					borderRadius: '4px',
					borderWidth: '1px',
					borderStyle: 'dashed',
					borderColor: (theme) => `${theme.palette.secondary.dark}4D`,
					userSelect: 'none',
					color: (theme) => theme.palette.grey[400],
					...sx,
					'&:hover': {
						...onHoverStyle,
					},
				}}
				onClick={openEditor}
				onMouseDown={onRippleStart}
				onMouseUp={onRippleStop}
			>
				<Box>
					<BrushIcon fontSize="large" />
				</Box>
				<Stack mt="auto" spacing={1}>
					<CloudUploadOutlinedIcon fontSize="large" />
					<Typography component="h5" variant="h5">Create a drawing</Typography>
					<Typography variant="subtitle1">Draw here with tools and save illustrations</Typography>
				</Stack>
				<TouchRipple ref={rippleRef} center={false} />
			</Stack>
		</>
	);
};

export default Input;
