import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	Box, Button, Skeleton, Stack, Theme, Typography, Link,
} from '@mui/material';
import {Link as RouterLink} from 'react-router-dom';

import { UUID } from 'library/common/types/app.d';
import CopyToClipboard from 'library/common/components/CopyToClipboard';
import { getReferralLink } from 'library/common/services/urls';
import SubtractIcon from 'resources/icons/Subtract';
import Bonuses from 'resources/image/Bonuses_desktop.svg';

interface IProps {
    id: UUID;
    isPending: boolean;
	isWodaBalanceLoading: boolean;
    referredUsersCount: number;
	wodaBalance: number;
}

const referredUsersCountBlockStyles = {
	padding: 2.5,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	width: '256px',
	height: '60px',
	borderRadius: 1,
	backgroundColor: (theme: Theme) => (theme.palette.primary as any).darker,
};

const WodaWallet: React.FunctionComponent<IProps> = ({
	id, isPending, referredUsersCount, wodaBalance, isWodaBalanceLoading,
}) => {
	const { t } = useTranslation();

	return (
		<Stack direction="column">
			<Box sx={{ position: 'relative' }}>
				<Box
					component="img"
					src={Bonuses}
					alt="Card balance"
				/>
				<Typography
					variant="h3"
					sx={{
						position: 'absolute',
						top: 12,
						right: 24,
						color: ({palette}) => palette.common.white,
					}}
				>
					{!isWodaBalanceLoading && wodaBalance}
				</Typography>
			</Box>
			<Stack mt={4} spacing={2} direction="row">
				{isPending ? (
					<Skeleton variant="rectangular" sx={referredUsersCountBlockStyles} />
				) : (
					<Box sx={referredUsersCountBlockStyles}>
						<Typography
							variant="body1"
							sx={{ color: (theme) => theme.palette.common.white, fontWeight: 600 }}
						>
							{t('registered_users')}
						</Typography>
						<Typography
							variant="h4"
							sx={{ color: (theme) => theme.palette.common.white }}
						>
							{referredUsersCount}
						</Typography>
					</Box>
				)}
				<CopyToClipboard>
					{({ copy }) => (
						<Button
							onClick={() => {
								if (id) copy(getReferralLink(id));
							}}
							variant="contained"
							sx={{
								background: (theme) => (theme.palette.primary as any).darker,
								borderRadius: 1,
							}}
						>
							<SubtractIcon sx={{ fontSize: 32 }} />
						</Button>
					)}
				</CopyToClipboard>
			</Stack>
			<Typography
				variant="caption"
				sx={{ inlineSize: '256px', mt: 1, px: 3 }}
				color="secondary"
			>
				<Link
					color="secondary"
					component={RouterLink}
					to={{ pathname: '/terms', hash: '#referral_program' }}
				>
					Referral program
				</Link>
				: Earn for Each Registration
			</Typography>
		</Stack>
	);
};

export default WodaWallet;
