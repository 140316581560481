import { useSelector } from 'react-redux';
import {
	useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { keyBy, reduce, sortBy } from 'lodash';

import { RootState } from 'main/rootReducer';
import { UUID } from 'library/common/types/app.d';
import { logger } from 'library/utils/lintersSugar';
import swapStepsWeights from 'library/utils/swapStepsWeights';
import cardsService from 'library/common/services/cards.service';
import { showSnackbarMessage } from 'library/utils/showSnackbarMessage';
import { IDetailedCard, IStep } from 'library/common/types/cards/cards.d';
import { mediaChanges, resourcesChanges, textChanges } from 'library/utils/getters/getChages';

import getLoginPageUrl from 'library/utils/routes/getLoginPageUrl';
import { ICardDetailedFormData, IChangeCardForm, IStepData } from '../types/cards/cardsCreation.d';
import { isAuthorMode } from '../selectors/card.selector';
import ShareContext from '../context/ShareContext';

interface IProps {
	id?: UUID;
	noIdRedirectUrl?: string;
	authorMode?: boolean;
}

interface IResult {
    isPending: boolean;
	authorMode: boolean;
    isActionPending: boolean;
    detailedCard: IDetailedCard | undefined;
	totalResources: number;
	toggleCardActivation(status?: boolean, cardId?: UUID): void;
	toggleCardLike(status?: boolean, cardId?: UUID): void;
	toggleStepActivation(stepId: UUID): void;
	deleteCardHandler(): Promise<void>;
	changeDescriptionHandler(newCardState: Partial<ICardDetailedFormData>): Promise<boolean>;
	changeStepHandler(stepId: UUID, newStepData: IStepData): Promise<boolean>;
	deleteStepHandler(stepId: UUID): Promise<void>;
	createStepHandler(sourceStepWeight: number, newStepData: IStepData): Promise<boolean>;
	moveStepUpHandler(step: IStep, stepIndex: number): Promise<void>;
	moveStepDownHandler(step: IStep, stepIndex: number): Promise<void>;
	handleShareCard(): void;
}

// TODO узнать как считать дубликаты у Миши
const countTotalResources = (resources: IStep[]): number => resources.reduce(
	(accumulator, currentValue) => accumulator + currentValue.resources.length, 0,
);

const useDetailedCard: (props: IProps) => IResult = ({ id, noIdRedirectUrl }) => {
	const {t} = useTranslation();

	const navigate = useNavigate();

	const shareContext = useContext(ShareContext);

	const [detailedCard, setDetailedCard] = useState<IDetailedCard | undefined>(undefined);
	const [isPending, setIsPending] = useState(true);
	const [isActionPending, setIsActionPending] = useState(false);

	const authorMode = useSelector((state: RootState) => isAuthorMode(state, detailedCard?.author?.id));
	const isAuth = useSelector((state: RootState) => state.user.isAuth);

	const totalResources = useMemo(() => {
		if (detailedCard === undefined) {
			return 0;
		}
		return countTotalResources(detailedCard.steps);
	}, [detailedCard]);

	/**
	 * private
	 */
	const changeStepsWeight = useCallback(async (steps: IStep[]) => {
		if (detailedCard) {
			try {
				await cardsService
					.setStepsWeight(steps.map((step) => ({ id: step.id, weight: step.weight })));
				setDetailedCard({
					...detailedCard,
					steps: sortBy(steps, (step_1) => step_1.weight),
				});
			} catch (error) {
				showSnackbarMessage(t('card_changing_error'), 'error');
				logger(`${error}`, 'error');
			}
		}

		return new Promise<void>((resolve) => resolve());
	}, [detailedCard, t]);

	const updateCardData = useCallback(
		(props?: {onSuccess?: () => void, onError?: (error: any) => void}) => {
			setIsPending(true);

			if (id) {
				cardsService
					.getCardById(id)
					.then((res) => setDetailedCard({
						...res.data.payload,
						steps: sortBy(res.data.payload.steps, (step) => step.weight),
					}))
					.catch((error) => {
						if (props?.onError) props.onError(error);
						logger(error, 'error');
					})
					.finally(() => setIsPending(false));
			} else if (noIdRedirectUrl) {
				navigate(noIdRedirectUrl);
			}
		},
		[id, navigate, noIdRedirectUrl],
	);

	useEffect(() => {
		updateCardData();
	}, [id, updateCardData]);

	const handleShareCard = () => {
		if (id) shareContext?.openShareDialog({ url: id, target: 'card' });
	};

	const toggleCardActivation = useCallback(
		(status: boolean | undefined = detailedCard?.client_info?.is_activated, cardId: UUID | undefined = detailedCard?.id) => {
			if (!isAuth) {
				navigate('/login');
				return;
			}

			if (cardId !== undefined && status !== undefined) {
				cardsService[status ? 'deactivateCard' : 'activateCard'](cardId)
					.then(() => {
						setIsActionPending(true);
						setDetailedCard((prev) => {
							if (prev) {
								return {
									...prev,
									client_info: {
										...prev?.client_info,
										is_activated: !status,
									},
									statistics: {
										...prev.statistics,
										number_of_activation: status
											? prev.statistics.number_of_activation - 1
											: prev.statistics.number_of_activation + 1,
									},
								};
							}

							return prev;
						});
					})
					.catch(() => {
						showSnackbarMessage(t('card_activation_error'), 'error');
						logger('activate/deactivate falls', 'error');
					})
					.finally(() => setIsActionPending(false));
			} else {
				logger('No card id for activate/deactivate', 'error');
			}
		},
		[detailedCard?.client_info?.is_activated, detailedCard?.id, isAuth, navigate, t],
	);

	const toggleCardLike = useCallback(
		async (status: boolean | undefined = detailedCard?.client_info?.is_favorite, cardId: UUID | undefined = detailedCard?.id) => {
			if (!isAuth) {
				navigate(getLoginPageUrl());
				return;
			}

			if (cardId !== undefined && status !== undefined) {
				try {
					await cardsService[status ? 'removeFromFavoriteCard' : 'addToFavoriteCard'](cardId);

					setDetailedCard((prev) => {
						if (prev) {
							return ({
								...prev,
								client_info: {
									...prev?.client_info,
									is_favorite: !status,
								},
								statistics: {
									...prev.statistics,
									number_of_favorites: status
										? prev.statistics.number_of_favorites - 1
										: prev.statistics.number_of_favorites + 1,
								},
							});
						}

						return prev;
					});
				} catch (error) {
					showSnackbarMessage(t('card_add_to_favorites_error'), 'error');
					logger('Add to favorite falls', 'error');
				}
			} else {
				logger('No card id for like this card', 'error');
			}
		},
		[detailedCard?.client_info?.is_favorite, detailedCard?.id, isAuth, navigate, t],
	);

	const toggleStepActivation = useCallback(
		(stepId: UUID) => {
			if (detailedCard) {
				const { steps } = detailedCard;
				const stepsHashMap = keyBy(steps, (step) => step.id);
				const currentStep = stepsHashMap[stepId];

				if (currentStep) {
					cardsService[currentStep.client_info?.is_completed ? 'resetStepComplete' : 'completeStep'](stepId)
						.then(() => {
							setIsActionPending(true);
							setDetailedCard((prevState) => {
								if (prevState) {
									const updatedSteps = {
										...stepsHashMap,
										[stepId]: {
											...stepsHashMap[stepId],
											client_info: {
												...stepsHashMap[stepId].client_info,
												is_completed: !stepsHashMap[stepId].client_info?.is_completed,
											},
										},
									};
									return ({
										...prevState,
										client_info: {
											...prevState.client_info,
											number_of_completed_steps: currentStep.client_info?.is_completed
												? (prevState.client_info?.number_of_completed_steps || 1) - 1
												: (prevState.client_info?.number_of_completed_steps || 0) + 1,
										},
										steps: Object.values(updatedSteps),
									});
								}

								return prevState;
							});
						})
						.catch(() => {
							logger('completeStep/resetStepComplete falls', 'error');
							showSnackbarMessage(t('stage_activation_error'), 'error');
						})
						.finally(() => setIsActionPending(false));
				}
			}
		},
		[detailedCard, t],
	);

	const deleteCardHandler = useCallback(
		() => {
			if (authorMode && detailedCard) {
				cardsService
					.deleteCard(detailedCard.id)
					.then(() => {
						navigate('/app/main');
						showSnackbarMessage(t('card_deleted_successfully'), 'success');
					})
					.catch((error) => {
						showSnackbarMessage(t('card_changing_error'), 'error');
						logger(`${error}`, 'error');
					});
			}

			return new Promise<void>((resolve) => resolve());
		},
		[authorMode, detailedCard, navigate, t],
	);

	const changeDescriptionHandler = useCallback(
		(newCardState: Partial<ICardDetailedFormData>) => {
			if (detailedCard) {
				const currentDuration = (newCardState?.days ? newCardState.days * 86400 : 0)
					+ (newCardState?.hours ? newCardState.hours * 3600 : 0)
					+ (newCardState?.minutes ? newCardState.minutes * 60 : 0)
					+ (newCardState?.seconds ? newCardState.seconds : 0);

				const editData = reduce(
					{
						title: newCardState.title === detailedCard.title ? undefined : newCardState.title,
						duration: detailedCard.duration === currentDuration ? undefined : currentDuration,
						result_id: newCardState.result?.id === detailedCard.result?.id ? undefined : newCardState.result?.id,
						cover: typeof newCardState?.cover === 'string' ? newCardState?.cover : undefined,
					},
					(res, value, key) => (value !== undefined
						? {...res, [key]: value}
						: res),
					{} as IChangeCardForm,
				);

				return cardsService
					.changeCard(detailedCard.id, editData)
					.then(() => {
						updateCardData({
							onError: () => window.location.reload(),
						});

						showSnackbarMessage(t('card_changing_success'), 'success');
						return new Promise<boolean>((resolve) => resolve(true));
					})
					.catch((error) => {
						showSnackbarMessage(t('card_changing_error'), 'error');
						logger(`${error}`, 'error');

						return new Promise<boolean>((resolve) => resolve(false));
					});
			}

			return new Promise<boolean>((resolve) => resolve(false));
		},
		[detailedCard, t, updateCardData],
	);

	const deleteStepHandler = useCallback(
		(stepId: UUID) => cardsService
			.deleteStep(stepId)
			.then(() => {
				setDetailedCard((prevState) => {
					if (!prevState) {
						return prevState;
					}

					return {
						...prevState,
						steps: prevState.steps
							.filter((step) => step.id !== stepId),
						// .map((step, index) => ({ ...step, weight: index })),
					};
				});

				return new Promise<void>((resolve) => resolve());
			})
			.catch(() => new Promise<void>((resolve) => resolve())),
		[],
	);

	const changeStepHandler = useCallback(
		(stepId: UUID, newStepData: IStepData) => {
			if (detailedCard) {
				const currentStep = detailedCard.steps.find((step) => step.id === stepId);

				if (currentStep) {
					const editData = reduce(
						{
							resourceIds: resourcesChanges(currentStep.resources, newStepData.resources),
							text: textChanges(currentStep.text, newStepData.text),
							image: mediaChanges(currentStep.image, newStepData.image),
							video: textChanges(currentStep.video, newStepData.video),
							draw: mediaChanges(currentStep.draw, newStepData.draw),
						},
						(res, value, key) => (value !== undefined
							? {...res, [key]: value}
							: res),
						{},
					);

					return cardsService
						.changeStep(stepId, editData)
						.then(() => {
							cardsService
								.getCardById(detailedCard.id)
								.then(() => {
									updateCardData({
										onError: () => window.location.reload(),
									});
								})
								.catch(() => window.location.reload())
								.finally(() => setIsPending(false));

							showSnackbarMessage(t('card_changing_success'), 'success');
							return new Promise<boolean>((resolve) => resolve(true));
						})
						.catch((error) => {
							showSnackbarMessage(t('card_changing_error'), 'error');
							logger(`${error}`, 'error');

							return new Promise<boolean>((resolve) => resolve(false));
						});
				}
			}

			return new Promise<boolean>((resolve) => resolve(false));
		},
		[detailedCard, t, updateCardData],
	);

	const createStepHandler = useCallback(
		async (sourceStepWeight: number, newStepData: IStepData) => {
			if (detailedCard) {
				const haveNextWeight = detailedCard.steps.some((step) => step.weight === sourceStepWeight + 1);

				if (haveNextWeight) {
					await changeStepsWeight(detailedCard.steps.map((step) => {
						if (step.weight > sourceStepWeight) {
							return { ...step, weight: step.weight + 1 };
						}

						return step;
					}));
				}

				const resourceIds = newStepData.resources.map((resource) => resource.id);

				const data = {
					resource_ids: resourceIds.length > 0 ? resourceIds : undefined,
					text: newStepData.text,
					image: newStepData.image,
					video: newStepData.video,
					draw: newStepData.draw,
					weight: sourceStepWeight + 1,
				};

				try {
					await cardsService
						.createNewStep(detailedCard.id, data);
					updateCardData({
						onError: () => window.location.reload(),
					});

					showSnackbarMessage(t('card_changing_success'), 'success');
					return await new Promise<boolean>((resolve) => resolve(true));
				} catch (error) {
					showSnackbarMessage(t('card_changing_error'), 'error');
					logger(`${error}`, 'error');
					return new Promise<boolean>((resolve_1) => resolve_1(false));
				}
			}
			return new Promise<boolean>((resolve) => resolve(false));
		},
		[changeStepsWeight, detailedCard, t, updateCardData],
	);

	const moveStepUpHandler = useCallback((currentStep: IStep, stepIndex: number) => {
		const stepBeforeIndex = stepIndex - 1;
		const stepBefore = stepBeforeIndex >= 0 ? detailedCard?.steps?.[stepBeforeIndex] : undefined;

		if (stepBefore && detailedCard && detailedCard.steps.length > 1) {
			return changeStepsWeight(swapStepsWeights(detailedCard.steps, currentStep, stepBefore));
		}

		return new Promise<void>((resolve) => resolve());
	}, [changeStepsWeight, detailedCard]);

	const moveStepDownHandler = useCallback((currentStep: IStep, stepIndex: number) => {
		const stepAfterIndex = stepIndex + 1;
		const stepAfter = detailedCard && stepAfterIndex < detailedCard.steps.length ? detailedCard?.steps?.[stepAfterIndex] : undefined;

		if (detailedCard && stepAfter) {
			return changeStepsWeight(swapStepsWeights(detailedCard.steps, currentStep, stepAfter));
		}

		return new Promise<void>((resolve) => resolve());
	}, [changeStepsWeight, detailedCard]);

	return {
		isPending,
		authorMode,
		isActionPending,
		detailedCard,
		totalResources,
		toggleCardActivation,
		toggleCardLike,
		toggleStepActivation,
		deleteCardHandler,
		changeDescriptionHandler,
		deleteStepHandler,
		changeStepHandler,
		createStepHandler,
		handleShareCard,
		moveStepUpHandler,
		moveStepDownHandler,
	};
};

export default useDetailedCard;
