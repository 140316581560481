import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IView {
    src: string;
}

const View = ({src}: IView) => {
	const {t} = useTranslation();

	return (
		<Box
			sx={{
				width: {
					xs: '90%',
					sm: 'auto',
					md: 'auto',
					lg: 'auto',
					xl: 'auto',
				},
			}}
		>

			<Box
				component="video"
				controls
				playsInline
				sx={{width: '100%', maxHeight: '400px'}}
			>
				<source
					type="video/mp4"
					src={src}
				/>
				<track kind="captions" />
				{t('browser_doesnt_support')}
				{' '}
				<a href={`${src}`}>{t('by_this_link')}</a>
				{t('and_open_it_on_your_device')}
			</Box>

		</Box>
	);
};

export default View;
