import React from 'react';
import { Box, IconButton } from '@mui/material';

import { UUID } from 'library/common/types/app.d';
import {
	CountedPoorResource,
	CountedResource,
	PoorResource,
	Resource,
} from 'library/common/types/cards/cards.d';
import { getResourcePictureFrom } from 'library/utils/getters/getPicture';
import CrossIcon from 'resources/icons/CrossIcon';

import CustomBadge from '../overrides/CustomBadge';

interface IProps {
    resource: PoorResource | Resource | CountedPoorResource | CountedResource;
	onResourceRemoveHandler?: (id: UUID) => void;
}

const sizes = {
	width: {
		xs: 64,
		sm: 64,
		md: 64,
		lg: 82,
		xl: 100,
	},
	height: {
		xs: 64,
		sm: 64,
		md: 64,
		lg: 82,
		xl: 100,
	},
};

const ResourceItem: React.FunctionComponent<IProps> = ({ resource, onResourceRemoveHandler }) => (
	<Box
		sx={{
			m: 1,
			position: 'relative',
		}}
	>
		<Box
			component="img"
			src={getResourcePictureFrom(resource)}
			alt={resource.title}
			sx={{...sizes}}
		/>
		{
			onResourceRemoveHandler && (
				<IconButton
					aria-label="drop resource"
					size="small"
					sx={{
						position: 'absolute',
						top: { xs: '-16px', md: '4px' },
						right: { xs: '-16px', md: '4px' },
						fontSize: '0.8rem',
						color: (theme) => theme.palette.common.white,
						background: (theme) => theme.palette.secondary.light,
						'&:hover': {
							background: (theme) => `${theme.palette.secondary.light}66`,
						},
					}}
					onClick={() => onResourceRemoveHandler(resource.id)}
				>
					<CrossIcon fontSize="inherit" />
				</IconButton>
			)
		}
	</Box>
);

export default ({ resource, onResourceRemoveHandler }: IProps) => {
	if ('count' in resource) {
		return (
			<CustomBadge badgeContent={resource.count} color="primary">
				<ResourceItem resource={resource} onResourceRemoveHandler={onResourceRemoveHandler} />
			</CustomBadge>
		);
	}

	return <ResourceItem resource={resource} onResourceRemoveHandler={onResourceRemoveHandler} />;
};
