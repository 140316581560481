import PasswordValidator from 'password-validator';
import i18n from 'library/utils/language/conf';

const schema = new PasswordValidator();
schema.is().min(8);
schema.is().max(100);
schema.has(/[a-zA-z]/g);
schema.has().uppercase();
schema.has().lowercase();
schema.has().digits(1);
schema.has().not().spaces();
schema.has().symbols(1);

export default (password: string): {haveError: boolean, errorText: string} => {
	const result = schema.validate(password, { list: true });
	let errorText = '';
	if (result.length > 0) {
		if (result.length === 6) {
			errorText = i18n.t('the_field_cannot_be_empty');
		} else {
			errorText = result.map((r) => {
				if (r === 'min') {
					return i18n.t('length_at_least_8_characters');
				}

				if (r === 'uppercase') {
					return i18n.t('must_contain_an_uppercase_letter');
				}

				if (r === 'lowercase') {
					return i18n.t('must_contain_a_lowercase_letter');
				}

				if (r === 'digits') {
					return i18n.t('must_contain_a_number');
				}

				if (r === 'symbols') {
					return i18n.t('must_contain_the_character');
				}

				if (r === 'has') {
					return i18n.t('only_latin_letters');
				}

				if (r === 'spaces') {
					return i18n.t('cannot_contain_spaces');
				}

				return '';
			}).join(' ');
		}
		return {
			haveError: true,
			errorText,
		};
	}

	return {
		haveError: false,
		errorText: '',
	};
};
