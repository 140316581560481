import React from 'react';
import { useSelector } from 'react-redux';

import { selectUserData } from 'library/common/selectors/user.selector';
import MHidden from 'library/common/components/MHidden/MHidden';

import DesktopHeader from './components/DescktopHeader';
import MobileHeader from './components/MobileHeader';

interface IProps {
    isAuth?: boolean;
	onSignIn: () => void;
}

const Header: React.FunctionComponent<IProps> = ({ onSignIn, isAuth }) => {
	const userData = useSelector(selectUserData);

	return (
		<>
			<MHidden breakpoint="md" direction="Down">
				<DesktopHeader isAuth={isAuth} userData={userData} onSignIn={onSignIn} />
			</MHidden>
			<MHidden breakpoint="md" direction="Up">
				<MobileHeader isAuth={isAuth} userData={userData} onSignIn={onSignIn} />
			</MHidden>
		</>
	);
};

export default Header;
