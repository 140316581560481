import React, { useContext, useRef } from 'react';
import { OnProgressProps } from 'react-player/base';
import { Box } from '@mui/material';

import WqReelsContext from 'library/common/context/WqReelsContext';
import { IMetadata } from 'library/common/types/cards/reels.d';
import { URL } from 'library/common/types/app.d';
import getReelSize from 'library/utils/getReelSize';

import { defaultThumbnail } from 'resources/styles/constants';
import WodaStoryPlayer from '../../../../../../library/common/components/WodaReelsPlayer';

interface IProps {
	source: URL;
	thumbnail?: URL;
	metadata?: IMetadata;
    index: number;
    goNextSlide(): void;
    setProgress(progress: number): void;
}

const SlideStep: React.FunctionComponent<IProps> = ({
	source, thumbnail, index, goNextSlide, setProgress, metadata,
}) => {
	const shareContext = useContext(WqReelsContext);

	const isActive = shareContext?.activeIndex === index;

	const thumbnailImgRef = useRef<HTMLImageElement>(null);

	return (
		<Box
			sx={{
				...getReelSize(metadata),
				overflow: 'hidden',
				userSelect: 'none',
				borderRadius: '16px',
				position: 'relative',
				zIndex: 3,

				'&:before': !isActive ? {
					content: "''",
					position: 'absolute',
					zIndex: 1,
					background: 'rgba(255, 255, 255, 0.6)',
					backdropFilter: 'blur(5px)',
					width: '100%',
					height: '100%',
				} : undefined,
			}}
		>
			<Box
				ref={thumbnailImgRef}
				sx={{
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					zIndex: 0,
				}}
				component="img"
				src={metadata ? thumbnail : defaultThumbnail}
				onError={() => {
					if (thumbnailImgRef.current) {
						thumbnailImgRef.current.onerror = null; // prevents looping
						thumbnailImgRef.current.src = defaultThumbnail;
					}
				}}
				alt={`WodaVideoThumbnail ${index}`}
			/>
			<Box
				sx={{
					width: '100%',
					height: '100%',
					position: 'absolute',
					top: 0,
					left: 0,
					zIndex: isActive && shareContext?.isIntersecting ? 1 : 0,
				}}
			>
				{isActive && (
					<WodaStoryPlayer
						onEnded={goNextSlide}
						onProgress={(e: OnProgressProps) => setProgress(e.played)}
						isActive={isActive}
						isIntersecting={shareContext.isIntersecting}
						url={`${source}#t=0.05`}
					/>
				)}
			</Box>
		</Box>
	);
};

export default SlideStep;
