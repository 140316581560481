import React from 'react';
import { Box, SxProps, Theme } from '@mui/material';

interface IView {
    src: string,
    alt: string,
    sx?: SxProps<Theme>;
}

const View = ({src, alt, sx}: IView) => (
	<Box
		component="img"
		src={src}
		alt={alt}
		sx={{
			maxWidth: '100%',
			borderRadius: '4px',
			...sx,
		}}
	/>
);

export default View;
