const InputBase = () => ({
	MuiInputBase: {
		styleOverrides: {
			root: {
				borderRadius: '4px',
			},
		},
	},
});

export default InputBase;
