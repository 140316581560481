import React from 'react';
import { IconButton, Stack, Tooltip } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

import EditIcon from 'resources/icons/EditIcon';
import ShareIcon from 'resources/icons/ShareIcon';
import DeleteCard from './DeleteCard';

interface ICardMenu {
	title: string;
	authorMode: boolean;
	isAuth: boolean;
	isLiked?: boolean;
	deleteCardHandler(): Promise<void>;
	toggleEditMode(): void;
	handleShareCard(): void;
	handleLikeCard(): void;
}

// TODO после деплоя можно удалить isAuth, тк как авторизация будет работать корректно (не надо мокать authorMode)
const CardMenu = ({
	title, authorMode, isAuth, isLiked, deleteCardHandler, toggleEditMode, handleShareCard, handleLikeCard,
}: ICardMenu) => (
	<Stack direction="row" alignItems="center" sx={{ ml: 'auto' }} spacing={1}>
		{
			isAuth && authorMode && (
				<Tooltip title="Edit card">
					<IconButton
						size="small"
						aria-label="to_favorite"
						sx={{
							color: ({ palette }) => palette.text.secondary,
						}}
						onClick={toggleEditMode}
					>
						<EditIcon fontSize="inherit" />
					</IconButton>
				</Tooltip>
			)
		}

		{!authorMode && (
			<Tooltip title={!isLiked ? 'Click if you like this card' : 'Click if you change your mind'}>
				<IconButton
					size="small"
					onClick={() => handleLikeCard()}
				>
					<ThumbUpIcon sx={{ fill: isLiked ? 'url(#linearColors)' : undefined }} />
					<svg width={0} height={0}>
						<linearGradient id="linearColors">
							<stop offset={0} stopColor="rgba(65, 154, 242, 1)" />
							<stop offset={1} stopColor="rgba(57, 69, 240, 1)" />
							<stop offset={2} stopColor="rgba(80, 62, 229, 1)" />
						</linearGradient>
					</svg>
				</IconButton>
			</Tooltip>
		)}

		<Tooltip title="Share card">
			<span>
				<IconButton
					size="small"
					aria-label="to_favorite"
					onClick={handleShareCard}
					sx={{
						color: ({ palette }) => palette.text.secondary,
					}}
				>
					<ShareIcon />
				</IconButton>
			</span>
		</Tooltip>

		{
			authorMode && isAuth && (<DeleteCard title={title} deleteCardHandler={deleteCardHandler} />)
		}
	</Stack>
);

export default CardMenu;
