import React, {useState} from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import { APPBAR_DESKTOP, APPBAR_MOBILE } from 'resources/styles/constants';
import MHidden from 'library/common/components/MHidden/MHidden';

import DesktopSidebar from './children/Sidebar/Desktop/DesktopSidebar';
import DashboardNavbar from './children/Navbar/DashboardNavbar';
import MobileSidebar from './children/Sidebar/Mobile/MobileSidebar';

const RootStyle = styled('div')(({ theme }) => ({
	display: 'flex',
	minHeight: '100%',
	overflow: 'hidden',
	[theme.breakpoints.down('sm')]: {
		flexDirection: 'column',
	},
}));

const MainStyle = styled('div')(({ theme }) => ({
	position: 'relative',
	flexGrow: 1,
	display: 'flex',
	overflow: 'auto',
	minHeight: '100%',
	paddingTop: APPBAR_DESKTOP,
	[theme.breakpoints.down('md')]: {
		paddingTop: APPBAR_MOBILE,
	},
}));

const Dashboard = () => {
	const [open, setOpen] = useState<boolean>(false);

	const {pathname} = useLocation();

	return (
		<RootStyle>
			<DashboardNavbar onOpenSidebar={() => setOpen(true)} />

			<MHidden breakpoint="sm" direction="Down">
				<DesktopSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
			</MHidden>
			<MainStyle
				style={{
					...(pathname.includes('/app/profile') && { paddingTop: 0 }),
					...(pathname.includes('/app/card') && { paddingTop: 88 }),
					...(pathname.includes('/app/card-create') && { paddingTop: 120 }),
				}}
			>
				<Outlet />
			</MainStyle>
			<MHidden breakpoint="sm" direction="Up">
				<MobileSidebar />
			</MHidden>
		</RootStyle>
	);
};

export default Dashboard;
