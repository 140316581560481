import { merge } from 'lodash';
import Button from './Button';
import Card from './Card';
import InputBase from './InputBase';
import OutlinedInput from './OutlinedInput';

import {CustomTheme} from './Theme.d';

const componentsOverride = (theme: CustomTheme) => merge(
	Button(theme),
	Card(theme),
	InputBase(),
	OutlinedInput(),
);

export default componentsOverride;
