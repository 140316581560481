import React from 'react';
import {useTranslation} from 'react-i18next';
import {
	Box, Button, Stack, Typography,
} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import {RootState} from 'main/rootReducer';
import {loginAsync} from 'library/common/actions/user.action';
import TextFieldWithCount from 'library/common/components/TextFieldWithCount';

import SocialNetworkEnter from '../components/SocialNetworkEnter';
import GradientButton from '../components/GradientButton';
import { MAX_MAIL_LENGTH, MAX_PASSWORD_LENGTH } from '../values';

type AuthorizationData = {
  email: string;
  password: string;
};

type FieldData = {
	id: string,
	name: 'email' | 'password',
	label: string,
	autoComplete: string,
	type: 'text' | 'password',
	rules: object,
	maxLength: number,
	autoFocus: boolean,
}

type ISignInForm = {
	resetPasswordMode: () => void;
}

const SignInForm = ({resetPasswordMode}: ISignInForm) => {
	const {control, handleSubmit} = useForm<AuthorizationData>(
		{defaultValues: {email: '', password: ''}},
	);
	const {t} = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const loading = useSelector((state: RootState) => state.user.isPending);

	const onSubmit = handleSubmit(async (data) => {
		dispatch(loginAsync.request(data));
	});

	const fields: FieldData[] = [
		{
			id: 'email/login',
			name: 'email',
			label: 'Email/login',
			autoComplete: 'email/login',
			type: 'text',
			autoFocus: true,
			rules: {
				required: {
					value: true,
					message: t('the_field_cannot_be_empty'),
				},
				pattern: {
					value: /^[\S]+$/m,
					message: 'no spaces',
				},
			},
			maxLength: MAX_MAIL_LENGTH,
		},
		{
			id: 'password',
			name: 'password',
			label: t('password'),
			autoComplete: 'current-password',
			type: 'password',
			autoFocus: false,
			rules: {
				required: {
					value: true,
					message: t('the_field_cannot_be_empty'),
				},
			},
			maxLength: MAX_PASSWORD_LENGTH,
		},
	];

	return (
		<Box>
			<form onSubmit={onSubmit}>
				<Stack
					direction="column"
					mt={7}
					spacing={3}
				>
					{
						fields.map((data) => (
							<Controller
								key={data.name}
								name={data.name}
								control={control}
								rules={data.rules}
								render={({ field, fieldState: {error} }) => (
									<TextFieldWithCount
										{...field}
										maxLength={data.maxLength}
										sx={{height: '77px'}}
										label={data.label}
										fullWidth
										id={data.id}
										autoComplete={data.autoComplete}
										autoFocus={data.autoFocus}
										disabled={loading}
										error={!!(error && error.type)}
										helperText={error?.message}
										type={data.type}
									/>
								)}
							/>
						))
					}
				</Stack>
				<Button
					sx={{mt: 2}}
					type="submit"
					fullWidth
					variant="contained"
					color="primary"
					disabled={loading}
				>
					{t('enter')}
				</Button>
			</form>

			<Box sx={{position: 'relative', zIndex: 1}}>
				<GradientButton
					label={t('forgot_login_or_password')}
					ariaLabel={t('forgot_login_or_password')}
					borderRadius={0}
					hoveredBorderRadius={4}
					mt={4}
					disabled={loading}
					fullWidth
					fullGradient
					onClick={resetPasswordMode}
				/>
			</Box>

			<Stack
				mt={5}
				spacing={1}
				direction="column"
				alignItems="flex-start"
				justifyContent="center"
				sx={{position: 'relative', zIndex: 1}}
			>
				<Typography>Don&apos;t have an account?</Typography>
				<GradientButton
					ariaLabel="registration in WodaQota"
					borderRadius={4}
					disabled={loading}
					fullWidth
					label="Registration in WodaQota"
					onClick={() => navigate('/registration')}
				/>

			</Stack>

			<SocialNetworkEnter />
		</Box>
	);
};

export default SignInForm;
