import { Box } from '@mui/material';
import React, { PropsWithChildren } from 'react';

import WQLoader from './WQLoader/WQLoader';

interface IProps {
    showLoader: boolean;
}

// TODO USE THIS FOR LOADER
const LoadingWrapper: React.FunctionComponent<PropsWithChildren<IProps>> = ({ showLoader, children = null}) => (showLoader
	? (
		<Box
			sx={{
				width: '100%',
				height: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				background: (theme) => (theme.palette.background as any).main,
			}}
		>
			<WQLoader />
		</Box>
	) : (
		<>
			{children}
		</>
	));

export default LoadingWrapper;
