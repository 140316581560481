import {Components} from '@mui/material/styles/components.d';
import {CustomTheme} from './Theme.d';

const Card = (theme: CustomTheme): Components => ({
	MuiCard: {
		styleOverrides: {
			root: {
				boxShadow: 'none',
				borderRadius: theme.shape.borderRadiusSm,
				position: 'relative',
				zIndex: 0, // Fix Safari overflow: hidden with border radius
			},
		},
	},
	MuiCardHeader: {
		defaultProps: {
			titleTypographyProps: { variant: 'h6' },
			subheaderTypographyProps: { variant: 'body2' },
		},
		styleOverrides: {
			root: {
				padding: theme.spacing(3, 3, 0),
			},
		},
	},
	MuiCardContent: {
		styleOverrides: {
			root: {
				padding: theme.spacing(3),
			},
		},
	},
});

export default Card;
