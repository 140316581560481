import React, { PropsWithChildren, ReactNode } from 'react';
import {
	Box, Button, Stack, Typography,
} from '@mui/material';
import isNonNullable from 'library/utils/isNonNullable';

interface IProps {
	amount?: number;
	title: string;
	description: ReactNode;
	customButton?: ReactNode;
	buttonLabel?: string;
	onButtonClick?: () => void;
	isBlur?: boolean;
	isComingSoon?: boolean;
	disabledButton?: boolean;
}

const Base: React.FunctionComponent<PropsWithChildren<IProps>> = ({
	amount,
	children,
	title,
	description,
	customButton,
	buttonLabel,
	onButtonClick,
	isBlur = false,
	disabledButton = false,
	isComingSoon = false,
}) => (
	<Box
		sx={{
			filter: isBlur ? 'blur(4px)' : undefined,
			userSelect: isBlur ? 'none' : undefined,
			borderRadius: '8px',
			padding: '1px',
			background: 'linear-gradient(267.83deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(90deg, #419AF2 0%, #3945F0 59.9%, #503EE6 100%)',
		}}
	>
		<Box sx={{
			minHeight: '120px', px: 5, py: 4, borderRadius: '7px', background: ({ palette }) => palette.common.white,
		}}
		>
			<Stack direction={{ xs: 'column', md: 'row' }} spacing={5}>
				<Stack spacing={2.5} sx={{ flex: 0.3 }}>
					<Typography
						variant="h5"
						sx={{ color: '#272342', fontSize: '26px' }}
					>
						{title}
					</Typography>

					{description}

					{isComingSoon && (
						<Typography
							variant="h5"
							sx={{ color: '#272342' }}
						>
							* coming soon
						</Typography>
					)}
				</Stack>
				<Box sx={{ flex: 0.4 }}>
					{children}
				</Box>
				<Stack
					direction={{ xs: 'row-reverse', md: 'column' }}
					sx={{ flex: 0.3 }}
					alignItems="flex-end"
					justifyContent="space-between"
				>
					<Box sx={{ textAlign: 'end' }}>
						{isNonNullable(amount) && (
							<>
								<Typography
									variant="h3"
									sx={{ color: '#272342' }}
								>
									{`+${amount}`}
								</Typography>
								<Typography
									variant="body1"
									sx={{ color: ({ palette }) => palette.text.primary }}
								>
									WodaBonus
								</Typography>
							</>
						)}
					</Box>
					{customButton}
					{!customButton && buttonLabel && (
						<Button
							disabled={disabledButton}
							variant="outlined"
							onClick={onButtonClick}
							sx={{
								px: 4,
								minWidth: '152px',
								textTransform: 'none',
								color: ({ palette }) => palette.secondary.light,
								borderColor: ({ palette }) => palette.secondary.light,

								'&:hover': {
									borderColor: ({ palette }) => palette.secondary.main,
								},
							}}
						>
							{buttonLabel}
						</Button>
					)}
				</Stack>
			</Stack>
		</Box>
	</Box>
);

export default Base;
