import {
	Box, Button, Paper, Stack, Typography,
} from '@mui/material';
import React from 'react';

import resources from '../resources';
import { Sections } from './NavContext';
import useNav from '../useNav';

interface IProps {
	isAuth?: boolean;
	threshold?: number | number[];
	onJoinThePlatform: () => void;
}

const paperSx = {
	width: { xs: '100%', md: '504px'},
	display: 'flex',
};

const blocks = [
	{
		title: 'Result-Oriented Platform',
		text: 'Each instruction features the final result, time to achieve it and number of steps it will take – it’s all part of WodaQota’s result-focused design, which ensures you don’t waste a second when considering whether a topic’s worth pursuing',
		img: resources.resultOrientedPlatformImage,
		icon: resources.resultOrientedPlatformIcon,
	},
	{
		title: 'E-commerce List',
		text: 'Before diving into any instruction, you can take a look at the list of required components needed to achieve the desired result. We will enable e-commerce entities to feature exclusive deals on relevant products for WodaQota component lists',
		img: resources.eCommerceListImage,
		icon: resources.eCommerceListIcon,
	},
	{
		title: 'Knowledge Mining',
		text: 'We believe in celebrating our contributors – so we reward WodaQota experts for their active participation in creating and distributing knowledge with our WodaBonuses (future WodaQoins)',
		img: resources.knowledgeMiningImage,
		icon: resources.knowledgeMiningIcon,
		scale: 1.1,
	},
	{
		title: 'Video Interaction',
		text: 'You can request one-to-one video meets with the expert of your favorite instruction to enjoy personal consultations – or team up to hold group calls and ask any questions you may have. Experts can provide video meets free, or receive payment in exchange!',
		img: resources.videoInteractionImage,
		icon: resources.videoInteractionIcon,
		scale: 0.8,
	},
	{
		title: 'Google Search Enhancement',
		text: 'As WodaQota’s knowledge-sharing ecosystem grows, it will help optimize business marketing product strategies by helping consumers master products and platforms in simple ways accessed by millions',
		img: resources.googleSearchEnhancementImage,
		icon: resources.googleSearchEnhancementIcon,
	},
	{
		title: 'Unique AI (The Matrix Tech)',
		text: 'We are also developing an industry-defining AI to facilitate the learning process. Imagine mastering a new software program in minutes, or gaining conversational Spanish in just a week! And thanks to tech progress, Matrix-like possibilities await…',
		img: resources.theMatrixTechImage,
		icon: resources.theMatrixTechIcon,
	},
];

const Advantages: React.FunctionComponent<IProps> = ({ isAuth, threshold, onJoinThePlatform }) => {
	const ref = useNav(Sections.ADVANTAGES, threshold);

	return (
		<Stack ref={ref} sx={{ position: 'relative', zIndex: 1 }}>
			<Typography
				sx={{
					textAlign: 'center',
					fontWeight: 200,
					fontSize: {
						xs: '50px',
						md: '150px',
					},
					lineHeight: '130%',
					textTransform: 'uppercase',
					color: '#151133',
					userSelect: 'none',

					'&:after': {
						content: "'S'",
						fontWeight: 600,
						background: (theme) => (theme.palette as any).gradients.info,
						WebkitBackgroundClip: 'text',
						WebkitTextFillColor: 'transparent',
					},
				}}
			>
				Advantage
			</Typography>

			{blocks.map(({
				title, text, img, icon, scale,
			}, index) => (
				<Stack
					key={title}
					mt={12}
					direction={{ xs: 'column', md: index % 2 === 0 ? 'row' : 'row-reverse' }}
					justifyContent={{ xs: 'inherit', md: 'space-around'}}
					alignItems={{ xs: 'inherit', md: 'center'}}
				>
					<Stack direction="column" spacing={3}>
						<Paper
							elevation={3}
							sx={{
								...paperSx,
								py: 2.5,
								pl: 3,
								alignItems: 'center',
							}}
						>
							<Box
								component="img"
								src={icon}
								alt={`${title}_icon`}
								sx={scale ? { transform: `scale(${scale})` } : undefined}
							/>
							<Typography
								variant="h6"
								sx={{
									ml: 1,
									fontWeight: 700,
									fontSize: '28px',
									lineHeight: '130%',
									background: (theme) => (theme.palette as any).gradients.info,
									WebkitBackgroundClip: 'text',
									WebkitTextFillColor: 'transparent',
								}}
							>
								{title}
							</Typography>
						</Paper>
						<Paper
							elevation={3}
							sx={{
								...paperSx, position: 'relative', pt: 2, pb: 2.5, pl: 5, pr: 3,
							}}
						>
							<Box sx={{
								position: 'absolute',
								top: '16px',
								left: '20px',
								width: '2px',
								height: '56px',
								background: 'linear-gradient(180deg, #419AF2 0.94%, #3945F0 28.62%, #503EE6 37.07%, #642CEC 44.04%, #9A39CB 59.83%, #DC43A3 76.36%, #F8B3A8 100.94%)',
								borderRadius: '10px',
							}}
							/>
							<Typography
								variant="h6"
								sx={{
									fontWeight: 400,
									fontSize: '24px',
									lineHeight: '130%',
									color: '#151133',
								}}
							>
								{text}
							</Typography>
						</Paper>
					</Stack>
					<Box sx={{
						mt: { xs: 3, md: 0 },
						pl: { xs: 0, md: index % 2 === 0 ? '0' : '48px' },
					}}
					>
						<Box
							component="img"
							src={img}
							alt={title}
							sx={{
								borderRadius: '16px',
								width: {
									xs: '100%',
									md: '300px',
									lg: '500px',
								},
							}}
						/>
					</Box>
				</Stack>
			))}

			<Box
				sx={{
					mt: 15,
					width: '100%',
					height: { xs: 'auto', md: '483px' },
					backgroundImage: `url(${resources.maskGroupImage})`,
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
					borderRadius: '16px',
					py: '48px',
					px: '48px',
				}}
			>
				<Typography
					variant="h1"
					sx={{
						color: (theme) => theme.palette.common.white,
						fontWeight: 700,
						fontSize: { xs: '44px', md: '72px' },
						lineHeight: '130%',
					}}
				>
					WodaQota
				</Typography>

				<Typography
					variant="h6"
					sx={{
						mt: 3,
						color: (theme) => theme.palette.common.white,
						fontWeight: 400,
						fontSize: { xs: '16px', md: '28px' },
						lineHeight: '130%',
					}}
				>
					Join now and find
					<br />
					any solutions quickly
				</Typography>

				<Button
					variant="contained"
					sx={(theme) => ({
						mt: 6,
						background: theme.palette.common.white,
						height: '48px',

						'&:hover': {
							background: theme.palette.grey[400],
						},
					})}
					onClick={onJoinThePlatform}
				>
					<Box
						component="span"
						sx={{
							fontWeight: 400,
							fontSize: '16px',
							lineHeight: '20px',
							background: (theme) => (theme.palette as any).gradients.primary,
							WebkitBackgroundClip: 'text',
							WebkitTextFillColor: 'transparent',
						}}
					>
						{`${isAuth ? 'Back to' : 'Join'} the platform`}
					</Box>
				</Button>

			</Box>
		</Stack>
	);
};

export default Advantages;
