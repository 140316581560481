import React, {SyntheticEvent, useState} from 'react';
import {Control, Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {
	Box, Button, IconButton, TextField,
} from '@mui/material';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import passwordValidator from '../../utils/passwordValidator';
import PasswordErrors from './PasswordErrors/PasswordErrors';

interface IKratosInput {
  type: string;
  name: string;
	label: string;
  control: Control;
  defaultValue?: string;
  disabled: boolean;
  required: boolean;
	error: boolean;
	helperText?: string | string[];
}

const KratosInput = ({
	type, name, control, defaultValue, required, disabled, error, label, helperText,
}: IKratosInput) => {
	const {t} = useTranslation();

	if (type === 'text' || type === 'email') {
		return (
			<Controller
				name={name}
				control={control}
				defaultValue={defaultValue}
				rules={{
					required: {
						value: required,
						message: t('the_field_cannot_be_empty'),
					},
					pattern: type === 'email' ? {
						value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
						message: t('invalid_email_format'),
					} : undefined,
				}}
				render={({field}) => (
					<TextField
						{...field}
						disabled={disabled}
						type={type}
						label={label}
						autoComplete="off"
						size="small"
						error={error}
						helperText={helperText}
					/>
				)}
			/>
		);
	}

	if (type === 'submit') {
		return (
			<Box sx={{
				display: 'flex',
				flexGrow: 1,
				alignItems: 'end',
				justifyContent: 'flex-end',
			}}
			>
				<Button
					variant="contained"
					color="primary"
					type={type}
					disabled={disabled}
					sx={{
						mt: {
							xs: 4,
							md: 0,
						},
						width: {
							xs: '100%',
							md: '200px',
						},
					}}
				>
					{label}
				</Button>
			</Box>
		);
	}

	if (type === 'password') {
		// TODO Вынести в отдельный компонент, что бы избежать условного создания хука
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const [inputType, setInputType] = useState<'text' | 'password'>('password');

		const handleMouseDownPassword = (event: SyntheticEvent) => {
			event.preventDefault();
		};

		const handleClickShowPassword = () => setInputType(inputType === 'password' ? 'text' : 'password');

		return (
			<Box>
				<Controller
					name={name}
					control={control}
					defaultValue=""
					rules={{
						validate: (value) => {
							const {haveError, errorText} = passwordValidator(value);

							return !haveError || errorText;
						},
					}}
					render={({field}) => (
						<TextField
							{...field}
							disabled={disabled}
							fullWidth
							type={inputType}
							label={label}
							autoComplete="off"
							size="small"
							error={error}
							InputProps={{
								endAdornment: (
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{inputType === 'text' ? <VisibilityOff /> : <Visibility />}
									</IconButton>),
							}}
						/>
					)}
				/>

				<PasswordErrors errors={`${helperText}`} />
			</Box>
		);
	}
	return <div />;
};

export default KratosInput;
