import React from 'react';

import { IRule } from 'library/common/types/wodaprograms.d';

import Visit from './components/Visite';
import Invite from './components/Invite';
import ComingSoon from './components/ComingSoon';
import CardCreation from './components/CardCreation';

const ProgramCard: React.FunctionComponent<IRule> = ({ type, amount }) => {
	switch (type) {
	case 'VISIT':
		return <Visit amount={amount} />;
	case 'INVITE':
		return <Invite amount={amount} />;
	case 'CREATE_CARD':
		return <CardCreation amount={amount} />;
	default:
		return <ComingSoon type={type} amount={amount} />;
	}
};

export default ProgramCard;
