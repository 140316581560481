import React from 'react';
import { Typography } from '@mui/material';

interface IProps {
	data: string;
}

const TextContent: React.FunctionComponent<IProps> = ({ data }) => (
	<Typography
		variant="body1"
		sx={{
			overflowWrap: 'break-word',
			wordBreak: 'break-word',
			wordWrap: 'break-word',
			hyphens: 'auto',
			textAlign: 'left',
		}}
	>
		{data}
	</Typography>
);

export default TextContent;
