import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';

export default [
	{
		label: 'Profile',
		path: '/app/profile',
		icon: PersonIcon,
	},
	{
		label: 'Settings',
		path: '/app/settings',
		icon: SettingsIcon,
	},
];
