import React from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {
	Box,
	List,
	ListItemIcon,
	ListItemText,
	Stack,
	Typography,
	ListItemButton,
} from '@mui/material';
import {styled} from '@mui/material/styles';

import Title from 'library/common/components/Title/Title';
import StyledLink from 'library/common/components/Dashboard/children/StyledLink';
import {APPBAR_DESKTOP, APPBAR_MOBILE} from 'resources/styles/constants';
import serviceLinks from 'library/utils/serviceLinks';
import SocialLinks from 'library/common/components/SocialLinks';
import useMainPageStyles from 'library/common/hook/useMainPageStyles';

import menuItems from '../../menuItems';

const NavStyle = styled('nav')(() => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
}));

const Sidebar = () => {
	const {t} = useTranslation();

	const navigate = useNavigate();

	const { pathname } = useLocation();

	const isMainPageStyles = useMainPageStyles();

	return (
		<Stack
			direction="column"
			sx={{
				height: '100%',
				background: (theme) => (isMainPageStyles ? theme.palette.common.white : (theme.palette.background as any).main),
			}}
		>
			<Box
				sx={{
					pt: 3.5,
					marginLeft: 4,
					height: (theme) => (theme.breakpoints.up('lg') ? APPBAR_DESKTOP : APPBAR_MOBILE),
				}}
			>
				<Title />
			</Box>
			<Stack
				pt={6}
				direction="column"
				sx={{
					width: '100%',
					flexGrow: 1,
					background: (theme) => (theme.palette.background as any).main,
				}}
			>
				<NavStyle>
					<Box sx={{width: '100%'}}>
						<List>
							{menuItems
								.filter((item) => !item.onlyMobile)
								.map((item) => (
									<ListItemButton
										key={item.path}
										sx={{
											paddingLeft: 4,
											'&.Mui-selected': {
												backgroundColor: 'inherit',
												color: (theme) => theme.palette.secondary.main,
												'& .MuiListItemIcon-root': {
													'& .MuiSvgIcon-root': {
														fill: `url(#linearColors${item.path})`,
													},
												},
											},
											'& .MuiListItemText-root': {
												'& span': {
													fontWeight: 'bold',
												},
											},
										}}
										disabled={item.disabled}
										selected={pathname.indexOf(item.path) >= 0}
										onClick={() => navigate(`/app/${item.path}`)}
									>
										<ListItemIcon sx={{minWidth: '32px'}}>
											<svg width={0} height={0}>
												<linearGradient id={`linearColors${item.path}`}>
													<stop offset={0} stopColor="rgba(65, 154, 242, 1)" />
													<stop offset={1} stopColor="rgba(57, 69, 240, 1)" />
													<stop offset={2} stopColor="rgba(80, 62, 229, 1)" />
												</linearGradient>
											</svg>
											<item.icon />
										</ListItemIcon>
										<ListItemText primary={t(item.title)} />
									</ListItemButton>
								))}
						</List>
					</Box>
				</NavStyle>

				<Box sx={{ flexGrow: 1 }} />

				<Stack direction="column" sx={{margin: '0 auto 16px 32px'}} spacing={1}>
					{
						serviceLinks.map((link) => (
							<StyledLink key={link.value} label={link.name} to={link.value} />
						))
					}
					<Box>
						<SocialLinks />
					</Box>
					<Box sx={{display: 'flex'}}>
						<Typography
							variant="caption"
							sx={{
								color: (theme) => theme.palette.text.secondary,
							}}
						>
							{`© ${new Date().getFullYear()} WodaQota Inc.`}
						</Typography>
					</Box>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default Sidebar;
