import React from 'react';
import _ from 'lodash';
import {useForm} from 'react-hook-form';
import {Identity, UiNode} from '@ory/kratos-client/api';
import {Box, Stack, Typography} from '@mui/material';

import KratosInput from './KratosInput';

interface IKratosForm {
	identity?: Identity,
	fields?: UiNode[];
	submitForm: (data: object) => void;
}

const KratosForm = ({identity, fields, submitForm}: IKratosForm) => {
	if (fields === undefined || identity === undefined || identity.traits === undefined) {
		return (
			<Box
				sx={{
					height: '100%',
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Typography
					variant="h4"
					sx={{ color: 'text.secondary', fontWeight: 700, opacity: 0.6 }}
				>
					No data for edit
				</Typography>
			</Box>
		);
	}

	// TODO Вынести в отдельный компонент, что бы избежать условного создания хука
	const {
		handleSubmit,
		control,
		formState: {
			errors,
		},
	// eslint-disable-next-line react-hooks/rules-of-hooks
	} = useForm();

	const onSubmit = handleSubmit(async (data) => submitForm(data));

	return (
		<form onSubmit={onSubmit} autoComplete="off" style={{height: '100%'}}>
			<Stack direction="column" spacing={2} sx={{ '&:last-child': {height: '100%'} }}>
				{
					fields.reduce((accumulator: any[], node) => {
						if ('type' in node.attributes
							&& 'name' in node.attributes
							&& node.attributes.name !== 'traits.referral_id'
						) {
							const fieldName: string = node.attributes.name.replace('traits.', '');
							const errorText = _.get(errors, `${fieldName}.message`);
							return (
								[
									...accumulator,
									<KratosInput
										key={node.attributes.name}
										type={node.attributes.type}
										name={fieldName}
										required={!!node.attributes.required}
										disabled={node.attributes.disabled}
										defaultValue={node.attributes.value}
										label={node.meta.label?.text || ''}
										error={!!_.get(errors, `${fieldName}.message`)}
										helperText={errorText}
										control={control}
									/>,
								]
							);
						}
						return accumulator;
					}, [])
				}
			</Stack>
		</form>
	);
};

export default KratosForm;
