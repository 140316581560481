import CloseIcon from '@mui/icons-material/Close';
import {
	Button,
	Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography,
} from '@mui/material';
import isNonNullable from 'library/utils/isNonNullable';
import React, { useMemo, useState } from 'react';
import {
	EmailIcon,
	EmailShareButton,
	FacebookIcon,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	RedditIcon,
	RedditShareButton,
	TelegramIcon,
	TelegramShareButton,
	TwitterIcon,
	TwitterShareButton,
	VKIcon,
	VKShareButton,
	WhatsappIcon,
	WhatsappShareButton,
} from 'react-share';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CopyToClipboard from 'library/common/components/CopyToClipboard';
import MHidden from 'library/common/components/MHidden/MHidden';

export interface IOpenShareProps {
	url: string;
	target?: 'card' | 'profile';
}

interface IResult {
    openShareDialog: (props: IOpenShareProps) => void;
    closeShareDialog: () => void;
    ShareComponent: React.FunctionComponent;
}

const socialNetworksForSharing = (shareUrl: string, title: string) => [
	{
		title: 'Facebook',
		ButtonComponent: FacebookShareButton,
		buttonProps: {
			url: shareUrl,
			quote: title,
		},
		IconComponent: FacebookIcon,
		iconComponentProps: {
			round: true,
		},
	},
	{
		title: 'Twitter',
		ButtonComponent: TwitterShareButton,
		buttonProps: {
			url: shareUrl,
		},
		IconComponent: TwitterIcon,
		iconComponentProps: {
			round: true,
		},
	},
	{
		title: 'Send email',
		ButtonComponent: EmailShareButton,
		buttonProps: {
			url: shareUrl,
		},
		IconComponent: EmailIcon,
		iconComponentProps: {
			round: true,
		},
	},
	{
		title: 'WhatsApp',
		ButtonComponent: WhatsappShareButton,
		buttonProps: {
			url: shareUrl,
		},
		IconComponent: WhatsappIcon,
		iconComponentProps: {
			round: true,
		},
	},
	{
		title: 'Reddit',
		ButtonComponent: RedditShareButton,
		buttonProps: {
			url: shareUrl,
		},
		IconComponent: RedditIcon,
		iconComponentProps: {
			round: true,
		},
	},
	{
		title: 'LinkedIn',
		ButtonComponent: LinkedinShareButton,
		buttonProps: {
			url: shareUrl,
		},
		IconComponent: LinkedinIcon,
		iconComponentProps: {
			round: true,
		},
	},
	{
		title: 'VK',
		ButtonComponent: VKShareButton,
		buttonProps: {
			url: shareUrl,
		},
		IconComponent: VKIcon,
		iconComponentProps: {
			round: true,
		},
	},
	{
		title: 'Telegram',
		ButtonComponent: TelegramShareButton,
		buttonProps: {
			url: shareUrl,
		},
		IconComponent: TelegramIcon,
		iconComponentProps: {
			round: true,
		},
	},
];

const useShare = (): IResult => {
	const title = 'WodaQota';

	const [shareUrl, setShareUrl] = useState<string | undefined>();

	const openShareDialog = ({ url, target }: IOpenShareProps): void => {
		const currentHost = `${window.location.protocol}//${window.location.host}`;

		switch (target) {
		case 'card':

			setShareUrl(`${currentHost}/app/card/${url}`);
			break;

		case 'profile':

			setShareUrl(`${currentHost}/app/profile/${url}`);
			break;

		default:
			setShareUrl(url);
			break;
		}
	};
	const closeShareDialog = () => setShareUrl(undefined);

	const buttons = useMemo(() => {
		if (shareUrl) {
			return socialNetworksForSharing(shareUrl, title);
		}

		return [];
	}, [shareUrl, title]);

	const ShareComponent: React.FunctionComponent = () => (
		<Dialog
			onClose={closeShareDialog}
			open={isNonNullable(shareUrl)}
			PaperProps={{
				sx: {
					maxHeight: '666px',
					maxWidth: '900px',
				},
			}}
		>
			<DialogTitle>
				Share
				<IconButton
					aria-label="close"
					onClick={closeShareDialog}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent sx={{ overflow: 'hidden' }}>
				<Stack mt={2} spacing={5}>
					<Stack direction="row" spacing={{ xs: 0, md: 2 }} sx={{ flexFlow: 'row wrap' }}>
						{
							buttons.map((button) => (
								<Stack key={button.title} alignItems="center" spacing={0.6} justifyContent="center" sx={{ flexGrow: 1, pt: { xs: 2, md: 0 } }}>
									<button.ButtonComponent {...button.buttonProps}>
										<button.IconComponent {...button.iconComponentProps} />
									</button.ButtonComponent>
									<Typography variant="caption" sx={{textAlign: 'center'}}>
										{button.title}
									</Typography>
								</Stack>
							))
						}
					</Stack>

					{shareUrl && (
						<>
							<MHidden breakpoint="md" direction="Down">
								<Stack
									direction={{ xs: 'column', md: 'row' }}
									alignItems="center"
									justifyContent="space-between"
									sx={{
										p: 1,
										borderRadius: '12px',
										borderWidth: '1px',
										borderStyle: 'solid',
										borderColor: 'rgba(0, 0, 0, 0.1)',
										backgroundColor: '#f9f9f9',
									}}
								>
									<Typography variant="body1">
										{shareUrl}
									</Typography>
									<CopyToClipboard>
										{({ copy }) => (
											<IconButton onClick={() => copy(shareUrl)}>
												<ContentCopyIcon />
											</IconButton>
										)}
									</CopyToClipboard>
								</Stack>
							</MHidden>
							<MHidden breakpoint="md" direction="Up">
								<CopyToClipboard>
									{({ copy }) => (
										<Button
											fullWidth
											variant="contained"
											color="primary"
											onClick={() => copy(shareUrl)}
										>
											Copy url link
										</Button>
									)}
								</CopyToClipboard>
							</MHidden>
						</>
					)}
				</Stack>
			</DialogContent>
		</Dialog>
	);

	return ({
		openShareDialog,
		closeShareDialog,
		ShareComponent,
	});
};

export default useShare;
