import React from 'react';
import {Typography} from '@mui/material';

interface IFieldErrorText {
    message?: string;
}

const FieldErrorText = ({message}: IFieldErrorText) => (
	<Typography
		component="p"
		sx={{
			color: (theme) => theme.palette.error.main,
			fontSize: '0.75rem',
			fontWeight: 400,
			lineHeight: 1.66,
			letterSpacing: '0.03333em',
		}}
	>
		{message}
	</Typography>
);

export default FieldErrorText;
