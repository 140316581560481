import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';

import Editor from './Editor';
import Input from './Input';
import {IWQAvatarInput} from './types.d';

const WQAvatarInput: React.FunctionComponent<IWQAvatarInput> = ({
	image, setImage, withInfoLabel, isPending,
}) => {
	const [editMode, setEditMode] = useState(false);
	const [imageState, setImageState] = useState<undefined | any>(undefined);

	useEffect(() => {
		setImageState(image);
	}, [image]);

	const onImageChange = (images: File[] | undefined) => {
		// TODO filter max size
		if (images && images[0]) {
			const img = images[0];
			setImageState(URL.createObjectURL(img));
			setEditMode(true);
		} else {
			setImageState(undefined);
		}
	};

	const onEditImage = (editedImage: File[] | undefined) => {
		setImage(editedImage);
		setEditMode(false);
	};

	const dropAvatar = () => setImage(undefined);

	const onCancel = () => setEditMode(false);

	return (
		<Box>
			{
				editMode && !!imageState ? (
					<Editor image={imageState} setImage={onEditImage} onCancel={onCancel} />
				) : (
					<Input
						mobileViewDisabled
						isPending={isPending}
						image={imageState}
						onChange={onImageChange}
						setMode={setEditMode}
						dropAvatar={dropAvatar}
						withInfoLabel={withInfoLabel}
					/>
				)
			}
		</Box>
	);
};

export default WQAvatarInput;
