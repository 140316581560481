import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Box, Button, Stack, Typography,
} from '@mui/material';

import BonusesBanner from 'resources/image/Banner_for_bonuses.svg';
import getSelfProfileUrl from 'library/utils/routes/getSelfProfileUrl';
import getBonusProgramsUrl from 'library/utils/routes/getBonusProgramsUrl';

interface IProps {}

const Frame: React.FunctionComponent<IProps> = () => {
	const navigate = useNavigate();

	return (
		<Stack
			direction={{ xs: 'column', md: 'row' }}
			sx={{
				borderRadius: 1,
				height: { xs: '208px', md: '104px' },
				width: '100%',
				background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(302.12deg, #1A237E 13.6%, #311B92 100%)',
				color: (theme) => theme.palette.common.white,
			}}
		>
			<Stack
				direction="row"
				sx={{
					background: '#503EE5',
					// Fix after adding WB programs
					flex: 1,
					borderRadius: 1,
					position: 'relative',
					zIndex: 1,

					'&:after': {
						content: "''",
						display: 'block',
						position: 'absolute',
						zIndex: -1,
						width: '100%',
						height: '100%',
						backgroundImage: `url(${BonusesBanner})`,
						backgroundSize: 'cover',
					},
				}}
			>
				<Box sx={{ px: 4, pt: 1.5 }}>
					<Typography variant="h5" sx={{ fontWeight: 600, lineHeight: '40px' }}>WB Wallet</Typography>
					<Typography variant="caption">Look at your gained WodaBonuses in</Typography>
					<Typography variant="caption" component="p" sx={{ fontWeight: 400, lineHeight: '20px'}}>your personal wallet</Typography>
				</Box>
				<Box>
					<Button
						variant="contained"
						onClick={() => navigate(getSelfProfileUrl())}
						sx={{
							mt: { xs: 5, md: 7 },
							mr: { xs: 5, md: 0 },
							color: '#0C1A67',
							textTransform: 'none',
							fontWeight: 400,
							fontSize: '13px',
							lineHeight: '20px',
							background: (theme) => theme.palette.common.white,

							'&:hover': {
								background: (theme) => theme.palette.grey[400],
							},
						}}
					>
						Go to Wallet
					</Button>
				</Box>
			</Stack>

			<Stack
				alignItems="start"
				spacing={0.5}
				direction={{ xs: 'row', md: 'column' }}
				justifyContent={{ xs: 'space-between', md: 'flex-start' }}
				sx={{
					px: 4,
					pt: 1.5,
					pb: { xs: 1.5, md: 0 },
					flex: 0.3,
				}}
			>
				<Typography variant="h5" sx={{ fontWeight: 600, lineHeight: '40px' }}>WB Programs</Typography>
				<Button
					variant="contained"
					onClick={() => navigate(getBonusProgramsUrl())}
					sx={{
						color: '#0C1A67',
						textTransform: 'none',
						fontWeight: 400,
						fontSize: '13px',
						lineHeight: '20px',
						background: (theme) => theme.palette.grey[400],

						'&:hover': {
							background: (theme) => theme.palette.grey[600],
						},
					}}
				>
					See Programs
				</Button>
			</Stack>
		</Stack>
	);
};

export default Frame;
