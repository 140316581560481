import { UUID } from 'library/common/types/app.d';
import {createAction, createAsyncAction} from 'typesafe-actions';
import cardActionTypes from '../constants/cards.constant';
import { IPoorCard } from '../types/cards/cards.d';

export const getCardsAsync = createAsyncAction(
	cardActionTypes.GET_CARDS_REQUEST,
	cardActionTypes.GET_CARDS_SUCCESS,
	cardActionTypes.GET_CARDS_FAILURE,
)<
	{ init?: boolean, categoryId?: UUID | '0', searchQuery?: string | 0 },
	{ cards: IPoorCard[], totalPages?: number, pageNumber: number, init?: boolean },
	unknown
>();

export const dropPageData = createAction(cardActionTypes.DROP_PAGE_DATA)();
