import React, {
	useCallback,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useIntersection } from 'react-use';
import { config } from 'react-spring';
import { Box, Stack } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import WqReelsContext from 'library/common/context/WqReelsContext';
import { IReelsStep } from 'library/common/types/cards/reels.d';

import SlideStep from './SlideStep';
import TimeIndicators from '../../../../components/TimeIndicators';
import Carousel from '../../../../../../library/common/components/Carousel';

interface IProps {
    steps: IReelsStep[];
}

const MediaCards: React.FunctionComponent<IProps> = ({ steps }) => {
	const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);

	const timelineRef = useRef<HTMLDivElement>(null);
	const cardRef = useRef<HTMLDivElement>(null);

	const inViewport = useIntersection(cardRef, {
		root: null,
		rootMargin: '0px',
		threshold: 1,
	});

	const setProgressHandler = useCallback((progress: number) => {
		if (timelineRef.current?.style) {
			timelineRef.current.style.width = `${Math.round(progress * 100)}%`;
		}
	}, []);

	const dropIndicatorWidth = () => {
		if (timelineRef.current?.style) {
			timelineRef.current.style.width = '0';
		}
	};

	const changeActiveSlider = useCallback((index: number) => {
		dropIndicatorWidth();
		setActiveSlideIndex(index % steps.length);
	}, [steps.length]);

	const goToNextSlideHandler = useCallback(() => {
		dropIndicatorWidth();
		setActiveSlideIndex((prev) => (prev + 1) % steps.length);
	}, [steps.length]);

	const cards = useMemo(() => steps.map((step, index) => ({
		key: uuidv4(),
		onClick: () => changeActiveSlider(index),
		content: (
			<SlideStep
				source={step.video.source}
				thumbnail={step.video.thumbnail}
				index={index}
				metadata={step.video.metadata}
				setProgress={setProgressHandler}
				goNextSlide={goToNextSlideHandler}
			/>
		),
	})), [changeActiveSlider, goToNextSlideHandler, setProgressHandler, steps]);

	return (
		<Stack
			ref={cardRef}
			spacing={2}
			sx={{ alignItems: 'center', justifyContent: 'center' }}
		>
			<TimeIndicators
				steps={steps}
				activeStepIndex={activeSlideIndex}
				timelineRef={timelineRef}
			/>
			<Box sx={{
				overflow: 'hidden',
				width: 'calc(100% - 10rem)',
				height: 'calc(100vh - 24rem)',
				alignItems: 'center',
				justifyContent: 'center',
			}}
			>
				<WqReelsContext.Provider
					value={{
						activeIndex: activeSlideIndex,
						isIntersecting: !!inViewport?.isIntersecting,
					}}
				>
					<Carousel
						slides={cards}
						activeSlide={activeSlideIndex}
						animationConfig={config.gentle}
						offsetRadius={3}
					/>
				</WqReelsContext.Provider>
			</Box>
		</Stack>
	);
};

export default MediaCards;
