import React, { RefObject } from 'react';
import {
	Box, Stack, SxProps, Theme,
} from '@mui/material';

import { IReelsStep } from 'library/common/types/cards/reels.d';

interface IProps {
    steps: IReelsStep[];
    activeStepIndex: number;
	timelineRef: RefObject<HTMLDivElement>;
	sx?: SxProps<Theme>;
}

const TimeIndicators: React.FunctionComponent<IProps> = ({
	steps, activeStepIndex, timelineRef, sx,
}) => (
	<Stack spacing={1} direction="row" sx={{ width: '100%', ...sx }}>
		{ steps.map((step, index) => (
			<Box
				key={step.id}
				sx={{
					overflow: 'hidden',
					position: 'relative',
					borderRadius: '2px',
					height: '4px',
					flexGrow: 1,
					background: index < activeStepIndex ? '#726E83' : '#D9D9D9',
				}}
			>
				<Box
					ref={activeStepIndex === index ? timelineRef : undefined}
					sx={{
						visibility: activeStepIndex === index ? 'visible' : 'hidden',
						zIndex: 1,
						position: 'absolute',
						background: '#726E83',
						borderRadius: '2px',
						top: 0,
						left: 0,
						width: 0,
						height: '4px',
						WebkitTransition: 'width 1s linear',
						MozTransformStyle: 'width 1s linear',
						OTransition: 'width 1s linear',
						transition: 'width 1s linear',
					}}
				/>
			</Box>
		)) }
	</Stack>
);

export default TimeIndicators;
