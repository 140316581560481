import React from 'react';
import {useTranslation} from 'react-i18next';
import {Card} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';

import {RootState} from 'main/rootReducer';
import KratosForm from 'library/common/components/KratosForm';
import {applySettings} from 'library/common/actions/app.action';

const ChangePassword = () => {
	const dispatch = useDispatch();
	const passwordSettings = useSelector((state: RootState) => state.app.settings?.passwordSettings);
	const identity = useSelector((state: RootState) => state.app.settings?.identity);

	const {t} = useTranslation();

	const submitForm = (data: object) => {
		dispatch(applySettings.request({
			methodGroup: 'password',
			data,
			successMessage: t('password_has_been_successfully_changed'),
		}));
	};

	return (
		<Card sx={{ height: '260px', flexGrow: 1, padding: 3 }}>
			<KratosForm fields={passwordSettings} identity={identity} submitForm={submitForm} />
		</Card>
	);
};

export default ChangePassword;
