import React, {SyntheticEvent} from 'react';
import { useDropzone } from 'react-dropzone';
import {
	Avatar, Box, Fab, Skeleton, Stack, Typography,
} from '@mui/material';
import TouchRipple, {TouchRippleActions} from '@mui/material/ButtonBase/TouchRipple';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';

import { avatarRadius } from 'library/common/components/WQAvatarInput/constants';
import AddPhotoIcon from 'resources/icons/AddPhotoIcon';

import {IInput} from './types.d';

import styles from './avatar.module.css';

const Input = ({
	image, onChange, setMode, dropAvatar, withInfoLabel, isPending, mobileViewDisabled,
}: IInput) => {
	const rippleRef = React.useRef<TouchRippleActions>(null);
	const {getRootProps, getInputProps, isDragActive} = useDropzone({
		onDrop: onChange,
		multiple: false,
		accept: ['image/png', 'image/jpeg'],
	});

	const onRippleStart = (e: SyntheticEvent) => {
		rippleRef.current?.start(e);
	};

	const onRippleStop = (e: SyntheticEvent) => {
		rippleRef.current?.stop(e);
	};

	return (
		<Stack direction="column" spacing={4}>
			{
				isPending ? (
					<Skeleton
						variant="circular"
						sx={{ ...avatarRadius(mobileViewDisabled) }}
					/>
				) : (
					<Box
						sx={{
							...avatarRadius(mobileViewDisabled),
							margin: 'auto',
							...(withInfoLabel && {
								borderColor: isDragActive ? 'rgb(248, 107, 106)' : 'rgba(145, 158, 171, 0.32)',
								borderRadius: '50%',
								padding: '8px',
								borderWidth: '1px',
								borderStyle: 'dashed',
							}),
							position: 'relative',
						}}
						{...getRootProps()}
					>

						<Box
							sx={{
								width: '100%',
								height: '100%',
								zIndex: 0,
								outline: 'none',
								display: 'flex',
								overflow: 'hidden',
								borderRadius: '50%',
								position: 'relative',
								alignItems: 'center',
								justifyContent: 'center',
								cursor: 'pointer',
							}}
						>
							<input
								type="file"
								autoComplete="off"
								style={{
									display: 'none',
									width: '100%',
									height: '100%',
								}}
								{...getInputProps()}
							/>
							<Avatar
								src={image}
								alt="QW Avatar"
								sx={{
									width: '100%',
									height: '100%',
								}}
							>
								<AddPhotoIcon width="80" height="68" />
							</Avatar>
							<Box
								onMouseDown={onRippleStart}
								onMouseUp={onRippleStop}
								sx={{
									width: '100%',
									height: '100%',
									display: 'flex',
									position: 'absolute',
									alignItems: 'center',
									flexDirection: 'column',
									justifyContent: 'center',
									transition: 'opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
									opacity: isDragActive ? 0.6 : 0,
									color: 'rgb(255, 255, 255)',
									backgroundColor: 'rgb(22, 28, 36)',
									'&:hover': {
										opacity: 0.6,
									},
								}}
							>
								<Typography
									sx={{
										textAlign: 'center',
										fontWeight: 700,
										mt: { xs: 0, md: 1 },
									}}
									className={styles.unselectable}
									component="p"
								>
									{image ? 'Change' : 'Add'}
									&nbsp;
									photo
								</Typography>
								<TouchRipple ref={rippleRef} center={false} />
							</Box>
						</Box>

						{
							image && setMode && (
								<Fab
									color="primary"
									aria-label="edit"
									sx={{
										position: 'absolute',
										top: 0,
										right: '16px',
									}}
									onClick={(e) => {
										e.stopPropagation();
										setMode(true);
									}}
								>
									<EditIcon />
								</Fab>
							)
						}
						{
							image && dropAvatar && (
								<Fab
									color="primary"
									aria-label="drop"
									sx={{
										position: 'absolute',
										bottom: 0,
										right: '16px',
									}}
									onClick={(e) => {
										e.stopPropagation();
										dropAvatar();
									}}
								>
									<ClearIcon />
								</Fab>
							)
						}
					</Box>
				)
			}
			{withInfoLabel && (
				<Stack justifyContent="end" height="82px">
					<Typography
						variant="body1"
						sx={{
							fontWeight: 400,
							color: 'text.secondary',
							opacity: 0.7,
							textAlign: 'center',
							lineHeight: '1.5',
							fontSize: '0.75rem',
						}}
					>
						Allowed *.jpeg, *.jpg, *.png, *.gif
						<br />
						max size of 3.1 MB
					</Typography>
				</Stack>
			)}
		</Stack>
	);
};

export default Input;
