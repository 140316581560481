import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const DashboardIcon = (props: SvgIconProps) => (
	<SvgIcon viewBox="0 0 32 32" {...props}>
		<path d="M13.7086 6.00009H7.45805C7.07152 6.00056 6.70097 6.15431 6.42766 6.42763C6.15434
        6.70094 6.00059 7.07149 6.00012 7.45801V11.2089C6.00059 11.5954 6.15434 11.9659 6.42766
        12.2392C6.70097 12.5126 7.07152 12.6663 7.45805 12.6668H13.7086C14.0952 12.6665 14.466
        12.5129 14.7395 12.2396C15.013 11.9662 15.1669 11.5955 15.1674 11.2089V7.45801C15.1669
        7.07134 15.013 6.70065 14.7395 6.42731C14.466 6.15398 14.0952 6.00033 13.7086 6.00009V6.00009Z"
		/>
		<path d="M13.7084 14.3331H7.45792C7.0714 14.3336 6.70085 14.4874 6.42753 14.7607C6.15422
        15.034 6.00047 15.4045 6 15.7911V24.5377C6.00047 24.9242 6.15422 25.2948 6.42753 25.5681C6.70085
        25.8414 7.0714 25.9952 7.45792 25.9956H13.7084C14.0951 25.9954 14.4659 25.8417 14.7394 25.5684C15.0129
        25.2951 15.1668 24.9244 15.1673 24.5377V15.7911C15.1668 15.4044 15.0129 15.0337 14.7394 14.7604C14.4659
        14.487 14.0951 14.3334 13.7084 14.3331Z"
		/>
		<path d="M24.5427 19.3334H18.2922C17.9056 19.3338 17.5351 19.4876 17.2618 19.7609C16.9885 20.0342
        16.8347 20.4048 16.8342 20.7913V24.5421C16.8347 24.9287 16.9885 25.2992 17.2618 25.5725C17.5351
        25.8458 17.9056 25.9996 18.2922 26.0001H24.5427C24.9292 25.9996 25.2998 25.8458 25.5731 25.5725C25.8464
        25.2992 26.0001 24.9287 26.0006 24.5421V20.7913C26.0001 20.4048 25.8464 20.0342 25.5731 19.7609C25.2998
        19.4876 24.9292 19.3338 24.5427 19.3334Z"
		/>
		<path d="M24.5427 6H18.2922C17.9056 6.00047 17.5351 6.15422 17.2618 6.42753C16.9885 6.70085 16.8347
        7.0714 16.8342 7.45792V16.2046C16.8347 16.5911 16.9885 16.9616 17.2618 17.235C17.5351 17.5083 17.9056
        17.662 18.2922 17.6625H24.5427C24.9293 17.6623 25.3001 17.5086 25.5736 17.2353C25.8471 16.9619 26.001
        16.5912 26.0015 16.2046V7.45792C26.001 7.07125 25.8471 6.70056 25.5736 6.42722C25.3001 6.15388 24.9293
        6.00023 24.5427 6V6Z"
		/>
	</SvgIcon>

);

export default DashboardIcon;
