import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const EditIcon = (props: SvgIconProps) => (
	<SvgIcon viewBox="0 0 20 20" {...props}>
		<path
			d="M3.56072 13.5815C3.70403 13.5787 3.82092 13.6956 3.81816 13.8389L3.78275 15.6796L4.22095 16.1178L6.06164 16.0824C6.20495
            16.0797 6.32185 16.1965 6.31909 16.3399L6.30257 17.1984C6.57234 17.1374 6.82866 17.0019 7.03869 16.7919L15.4346 8.39595L11.5046
            4.46593L3.10866 12.8619C2.89864 13.0719 2.76313 13.3282 2.70214 13.598L3.56072 13.5815Z"
		/>

		<path
			d="M16.8638 6.96685L12.9337 3.03683L14.8987 1.07181C15.4907 0.479861 16.4504 0.479858 17.0424 1.07181L18.8288 2.85819C19.4207
            3.45014 19.4207 4.40989 18.8288 5.00184L16.8638 6.96685Z"
		/>

		<path
			d="M0.83424 18.4475C0.733573 18.8232 1.07736 19.167 1.45306 19.0663L3.56636 18.5001C3.94206 18.3994 4.0679 17.9298 3.79287
            17.6548L2.24582 16.1077C1.97079 15.8327 1.50117 15.9585 1.4005 16.3342L0.83424 18.4475Z"
		/>
	</SvgIcon>

);

export default EditIcon;
