import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { withErrorBoundary } from 'react-error-boundary';
import { Box } from '@mui/material';
import {SxProps} from '@mui/system';
import {Theme} from '@mui/material/styles';
import mediaDownloader from 'library/utils/mediaDownloader';

import ErrorComponent from 'library/common/components/Error';
import Input from './Input';
import View from './View';

interface IVideo {
	value?: string;
    setData: (data: string) => void;
	disabled: boolean;
    sx?: SxProps<Theme>;
	openVideoInputRef?: React.MutableRefObject<(() => void) | undefined>;
}

const Video = ({
	value, setData, disabled, sx, openVideoInputRef,
}: IVideo) => {
	const [file, setFile] = useState<File | undefined>(undefined);
	const [progress, setProgress] = useState<number | null>(null);
	const [errors, setErrors] = useState<string>('');

	/**
	 *
	 * Функция которая срабатывает после загрузки картинки на сервер
	 *
	 * @param file выбранная картинка для отображения
	 * @param res id загруженной картинки
	 */
	const onUploadHandle = (data: File, res: number) => {
		setData(`${res}`);
		setFile(data);
	};

	const {
		getRootProps, getInputProps, isDragActive, open,
	} = useDropzone({
		onDrop: (acceptedFiles, fileRejections) => {
			if (fileRejections && fileRejections.length > 0) {
				fileRejections.forEach((rejectedFile) => {
					rejectedFile.errors.forEach((err) => {
						if (err.code === 'file-too-large') {
							setErrors(`Error: ${err.message}`);
						}

						if (err.code === 'file-invalid-type') {
							setErrors(`Error: ${err.message}`);
						}
					});
				});
			} else {
				setErrors('');
				mediaDownloader(
					'video',
					acceptedFiles[0],
					setProgress,
					onUploadHandle,
				);
			}
		},
		multiple: false,
		accept: ['video/quicktime', 'video/mp4', 'video/mov'],
		maxSize: 1073741824,
		disabled: disabled || !!progress,
	});

	if (openVideoInputRef) {
		// eslint-disable-next-line no-param-reassign
		openVideoInputRef.current = open;
	}

	const source = file ? URL.createObjectURL(file) : value;

	// TODO Разрулить этот ужас, когда буду менять блоб на ссыль
	// TODO Разобраться с размерами видео
	return (
		<Box sx={sx}>
			{
				(source) ? (
					<>
						{
							progress ? (
								null
							) : (
								<>
									<View src={source} />
									<input
										type="file"
										autoComplete="off"
										style={{ display: 'none' }}
										{...getInputProps()}
									/>
								</>
							)
						}
					</>

				) : (
					<Input
						isDragActive={isDragActive}
						progress={progress}
						errors={errors}
						getInputProps={getInputProps}
						getRootProps={getRootProps}
						openVideoInputRef={openVideoInputRef}
					/>
				)
			}
		</Box>
	);
};

// Until they fix the import problem in the library https://github.com/bvaughn/react-error-boundary/issues/111
export default withErrorBoundary(Video, {
	FallbackComponent: ErrorComponent,
}) as (props : IVideo) => React.ReactElement;
