import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const Subtract = (props: SvgIconProps) => (
	<SvgIcon viewBox="0 0 28 27" {...props}>
		<path
			d="m23.946 8.854-2.122 2.121a3.982 3.982 0 0 1-2.202 1.123c-.24.037-.47.136-.643.308l-.31.311c-.546.545-.28
			1.448.49 1.427a5.98 5.98 0 0 0 4.08-1.755l2.12-2.121a6 6 0 1 0-8.485-8.485l-2.12 2.12a6.002 6.002 0 0 0-.65
			7.72l-1.53 1.532a6.002 6.002 0 0 0-7.72.648l-2.122 2.122a6 6 0 0 0 8.486 8.485l2.121-2.121a5.98 5.98 0 0 0
			1.755-4.08c.02-.77-.883-1.035-1.427-.49l-.311.31c-.172.172-.27.403-.309.643a3.981 3.981 0 0 1-1.122 2.203l-2.122 2.12a4
			4 0 0 1-5.656-5.656l2.12-2.121a4.002 4.002 0 0 1 4.862-.618l-2.74 2.739a1 1 0 1 0 1.414 1.414l9.9-9.9a1 1 0 0
			0-1.414-1.414l-2.74 2.74a4.002 4.002 0 0 1 .618-4.86l2.122-2.122a4 4 0 1 1 5.657 5.657Z"
			fill="#fff"
			fillOpacity=".8"
		/>
	</SvgIcon>
);

export default Subtract;
