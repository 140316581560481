import { UUID } from 'library/common/types/app.d';
import axios from 'axios';

// const schema = process.env.REACT_APP_BACKEND_SCHEMA;
// export const identityUrl = document.getElementById('identity-url')?.dataset.url;
// const prodSchema = document.getElementById('api-schema')?.dataset.schema;
// теперь на всех окружениях https

const apiHost = document.getElementById('api-url')?.dataset?.url;
const wsHost = apiHost || process.env.REACT_APP_BACKEND_HOST;

const apiSchema = window.location.protocol ? `${window.location.protocol}//` : '';
const wsSchema = window.location.protocol.includes('https') ? 'wss' : 'ws';

const apiUrl = apiHost ? `${apiSchema}${apiHost}` : '';

export const socketUrl = `${wsSchema}://${wsHost}/denotification/`;

export const getReferralLink = (userID: UUID) => `${apiUrl}/registration?referral_link=${userID}`;

const config = {
	withCredentials: true,
	headers: {
		'Content-Type': 'application/json',
		// 'X-Session-Id': 'ADMIN',
	},
};

export const profileApi = axios.create({
	baseURL: `${apiUrl}/profile`,
	...config,
});

export const identityApi = axios.create({
	baseURL: `${apiUrl}/identity`,
	...config,
});

export const cardApiV1 = axios.create({
	baseURL: `${apiUrl}/cardapi/api/v1`,
	...config,
});

export const cardQueryApiV1 = axios.create({
	baseURL: `${apiUrl}/card_query/api/v1`,
	...config,
});

export const cardApiV2 = axios.create({
	baseURL: `${apiUrl}/cardapi/api/v2`,
	...config,
});

export const notificationsApi = axios.create({
	baseURL: `${apiUrl}/denotification`,
	...config,
});

export const wodabonusApi = axios.create({
	baseURL: `${apiUrl}/wodabonus/api/v1`,
	...config,
});
