import React, {useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {
	Menu,
	IconButton,
	MenuItem,
	Typography,
	Stack,
	Box,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Link from '@mui/material/Link';

import {timeSince} from 'library/utils/textGenerators';
import AvatarButton from 'library/common/components/AvatarButton';
import { UUID } from 'library/common/types/app.d';
import { IAuthor } from 'library/common/types/cards/cards.d';
import { getToolbarAvatar } from 'library/utils/getters/getPicture';

interface ICommentHeader {
	commentId: UUID;
	author: IAuthor;
	createdDate?: Date;
	isAuthor: boolean;
	removeCommentFn?: (id: UUID) => Promise<void>;
}

const CommentHeader = ({
	commentId,
	author,
	createdDate,
	isAuthor,
	removeCommentFn,
}: ICommentHeader) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [time] = useState(createdDate && timeSince(createdDate));

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const removeCommentHandler = removeCommentFn
		? () => {
			removeCommentFn(commentId)
				.then();
		} : undefined;

	return (
		<Stack direction="row" spacing={2}>
			<AvatarButton
				authorId={author.id}
				author={author.login}
				userPhoto={getToolbarAvatar(author?.avatar)}
				size={56}
			/>

			<Stack direction="column">
				<Link
					underline="none"
					component={RouterLink}
					variant="subtitle1"
					color="black"
					sx={{
						flex: 1,
						fontWeight: 600,
						background: (theme) => (theme.palette as any).gradients.textDark,
						WebkitBackgroundClip: 'text',
						WebkitTextFillColor: 'transparent',
					}}
					to={`/app/profile/${author.id}`}
				>
					{author.login}
				</Link>
				{
					createdDate && (
						<Typography
							variant="caption"
							sx={{
								background: (theme) => (theme.palette as any).gradients.textDark,
								WebkitBackgroundClip: 'text',
								WebkitTextFillColor: 'transparent',
							}}
						>
							{time}
						</Typography>
					)
				}

			</Stack>

			{
				isAuthor && removeCommentHandler && (
					<Box style={{ marginLeft: 'auto' }}>
						<IconButton size="small" onClick={handleClick}>
							<MoreVertIcon fontSize="small" />
						</IconButton>
						<Menu
							id="comment-menu"
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={handleClose}
						>
							<MenuItem onClick={removeCommentHandler}>
								Remove
							</MenuItem>
						</Menu>
					</Box>
				)
			}

		</Stack>
	);
};

export default CommentHeader;
