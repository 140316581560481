import { useEffect, useRef } from 'react';
import {useDispatch, useSelector} from 'react-redux';

import WebSockets from 'library/common/sockets/WebSockets';
import { setSocketAliveStatus} from 'library/common/actions/user.action';
import { notificationEvent } from 'library/common/actions/app.action';
import { selectIsAuthorized } from 'library/common/selectors/user.selector';

const useWebsocket = () => {
	const dispatch = useDispatch();

	const socket = useRef<WebSockets>(new WebSockets());

	const isAuth = useSelector(selectIsAuthorized);

	useEffect(() => {
		if (isAuth && socket.current) {
			socket.current.initializeWebSockets(
				(status: boolean) => dispatch(setSocketAliveStatus(status)),
				(data: any) => dispatch(notificationEvent(data)),
			);
		}

		return () => {
			socket.current?.destroy();
		};
	}, [dispatch, isAuth]);

	return {
		socket: socket.current,
	};
};

export default useWebsocket;
