import React from 'react';
import { Stack, SxProps, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { UUID } from 'library/common/types/app.d';
import { IStep } from 'library/common/types/cards/cards.d';

import Step from '../Step';

interface IProps {
	cardTitle: string;
	sx?: SxProps<Theme>;
    steps: IStep[];
    // isCardActivate: boolean;
	authorMode: boolean;
    isAuthorized: boolean;
	// toggleStepActivation(stepId: UUID): void;
	deleteStepHandler(stepId: UUID): Promise<void>;
	toggleCreateMode(sourceStepWeight: number, sourceStepIndex: number): void;
	toggleEditMode(stepId: UUID): void;
	moveStepUpHandler(step: IStep, stepIndex: number): Promise<void>;
	moveStepDownHandler(step: IStep, stepIndex: number): Promise<void>;
}

const Steps: React.FunctionComponent<IProps> = ({
	cardTitle,
	sx,
	steps,
	// isCardActivate,
	// toggleStepActivation,
	authorMode,
	isAuthorized,
	deleteStepHandler,
	toggleCreateMode,
	toggleEditMode,
	moveStepUpHandler,
	moveStepDownHandler,
}) => {
	const {t} = useTranslation();

	return (
		<Stack spacing={8} sx={sx}>
			{
				steps.map((step: IStep, index, array) => (
					<Step
						title={`${t('Step')} ${index + 1}`}
						cardTitle={cardTitle}
						key={step.id}
						isLast={index + 1 === array.length}
						isFirst={index === 0}
						isAuthorized={isAuthorized}
						authorMode={authorMode}
						weight={step.weight}
						text={step.text}
						image={step.image}
						draw={step.draw}
						video={step.video}
						resources={step.resources}
						// isCardActivate={isCardActivate}
						// isStepCompleted={step.client_info?.is_completed}
						// toggleActivation={() => toggleStepActivation(step.id)}
						deleteStepHandler={() => deleteStepHandler(step.id)}
						toggleEditMode={() => toggleEditMode(step.id)}
						toggleCreateMode={() => toggleCreateMode(step.weight, index)}
						moveStepUpHandler={() => moveStepUpHandler(step, index)}
						moveStepDownHandler={() => moveStepDownHandler(step, index)}
					/>
				))
			}
		</Stack>
	);
};

export default Steps;
