import isNonNullable from 'library/utils/isNonNullable';
import getLikedPageUrl from 'library/utils/routes/getLikedPageUrl';
import { getMainPageUrl, getMainSearchPageUrl } from 'library/utils/routes/mainPageUrls';
import {
	matchRoutes,
	useLocation,
} from 'react-router-dom';

export default () => {
	const { pathname } = useLocation();

	const matches = matchRoutes([
		{path: getMainPageUrl() },
		{path: getMainSearchPageUrl() },
		{path: getLikedPageUrl() },
	], pathname);

	return isNonNullable(matches);
};
