import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import {
	Box, CircularProgress, IconButton, InputAdornment, InputBase, Stack,
} from '@mui/material';

import { PoorResource } from 'library/common/types/cards/cards.d';
import SearchIcon from 'resources/icons/SearchIcon';
import ResourceImage from 'resources/image/Resource.png';

import ResourceItem from './ResourceItem';

interface IProps {
	isTouched: boolean;
    disabled: boolean;
    isPending: boolean;
    resources: PoorResource[];
	onSearchResource(value: string): void;
	onResourceSelectHandler(value: PoorResource): void;
	addNewResourceModeHandler(): void;
}

const SelectResources: React.FunctionComponent<IProps> = ({
	isTouched,
	disabled,
	isPending,
	resources,
	onSearchResource,
	onResourceSelectHandler,
	addNewResourceModeHandler,
}) => {
	const {t} = useTranslation();

	const [searchFieldValue, setSearchFieldValue] = useState<string>('');

	const onSearchResourceDebounced = useMemo(
		() => debounce((value: string) => onSearchResource(value), 1000),
		[onSearchResource],
	);

	const setSearchFieldValueHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setSearchFieldValue(e.target.value);
		onSearchResourceDebounced(e.target.value);
	};

	return (
		(
			<Box
				sx={{
					position: 'relative',
					padding: '1px',
					borderRadius: '4px',
					backgroundImage: (theme) => (
						`linear-gradient(white, white), ${(theme.palette as any).gradients.primary}`
					),
					backgroundOrigin: 'border-box',
					backgroundClip: 'content-box, border-box',
					overflow: 'hidden',
				}}
			>
				<Box
					sx={{
						width: '100%',
						position: 'absolute',
						top: 0,
						left: 0,
						padding: '1px',
						borderRadius: '4px',
						backgroundOrigin: 'border-box',
						backgroundClip: 'content-box, border-box',
						backgroundImage: (theme) => (
							`linear-gradient(white, white), ${(theme.palette as any).gradients.primary}`
						),
					}}
				>
					<InputBase
						//	placeholder={t('select_resources')}
						fullWidth
						autoFocus
						value={searchFieldValue}
						disabled={disabled}
						placeholder={t('find_resources')}
						sx={{
							p: (theme) => theme.spacing(0.5, 2),
							fontSize: '16px',
						}}
						endAdornment={(
							<InputAdornment position="end">
								{
									isPending ? (
										<CircularProgress color="inherit" size={20} />
									) : (
										<IconButton
											aria-label="toggle password visibility"
											edge="end"
											size="small"
											sx={{
												opacity: 0.5,
											}}
										>
											<SearchIcon />
										</IconButton>
									)
								}
							</InputAdornment>
						)}
						onChange={setSearchFieldValueHandler}
					/>
				</Box>
				<Stack
					spacing={2}
					sx={{
						mt: isTouched ? 6 : 3,
						p: 1,
						maxHeight: isTouched ? '312px' : 0,
						transition: 'max-height 0.30s ease-out',
						overflow: 'auto',
						'&::-webkit-scrollbar': {
							width: '0.4em',
						},
						'&::-webkit-scrollbar-thumb': {
							backgroundColor: 'rgba(0,0,0,.1)',
						},
					}}
				>
					{resources.map((resource) => (
						<ResourceItem
							key={resource.id}
							title={resource.title}
							icon={resource.images}
							onSelect={() => onResourceSelectHandler(resource)}
						/>
					))}
					{
						isTouched && resources.length === 0 && (
							<ResourceItem
								title={t('could_find_the_component')}
								icon={ResourceImage}
								onSelect={addNewResourceModeHandler}
							/>
						)
					}
				</Stack>
			</Box>
		)
	);
};

export default SelectResources;
