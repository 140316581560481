import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
	data: string;
}

const VideoContent: React.FunctionComponent<IProps> = ({ data: video }) => {
	const {t} = useTranslation();

	return (
		<Box
			component="video"
			controls
			playsInline
			sx={{
				maxWidth: '100%',
				height: {
					md: 480,
				},
			}}
		>
			<source src={`${video}#t=0.001`} type="video/mp4" />
			<track kind="captions" />
			{t('browser_doesnt_support')}
			{' '}
			<a href={video}>{t('by_this_link')}</a>
			{t('and_open_it_on_your_device')}
		</Box>
	);
};

export default VideoContent;
