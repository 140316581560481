import {ActionType, createReducer} from 'typesafe-actions';
import { UUID } from 'library/common/types/app.d';
import * as actions from 'library/common/actions/cards.action';
import { IPoorCard } from 'library/common/types/cards/cards.d';
import resultTransformer from 'library/utils/resultTransformer';

export type CardActions = ActionType<typeof actions>;

export type CardState = Readonly<{
	isPending: boolean;

	cards: IPoorCard[];
	totalPages?: number;
	pageNumber?: number;
	selectedCategory?: UUID;
	searchPhrase?: string;
}>

const initialState: CardState = {
	isPending: false,
	cards: [],
};

export default createReducer<CardState, CardActions>(
	initialState,
)
	// Загрузка карточек
	.handleAction(actions.getCardsAsync.request, (state, action) => {
		const { init, categoryId, searchQuery } = action.payload;

		const newState = init ? {
			...state,
			cards: [],
			pageNumber: undefined,
			totalPages: undefined,
			searchPhrase: undefined,
			selectedCategory: undefined,
			isPending: !!action.payload.init,
		} : state;

		return ({
			...newState,
			searchPhrase: searchQuery === 0 ? undefined : searchQuery ?? state.searchPhrase,
			selectedCategory: categoryId === 'all' ? undefined : categoryId ?? state.selectedCategory,
		});
	})
	.handleAction(actions.getCardsAsync.success, (state, action) => ({
		...state,
		isPending: false,
		cards: [
			...state.cards,
			...action.payload.cards.map((card: IPoorCard) => ({
				...card,
				result: {
					...card.result,
					title: resultTransformer(card.result),
				},
			})),
		],
		totalPages: action.payload.totalPages || state.totalPages,
		pageNumber: action.payload.pageNumber || state.pageNumber,
	}))
	.handleAction(actions.getCardsAsync.failure, (state) => ({
		...state,
		isPending: false,
	}))

	// drop cards, pageNumber, totalPages count
	.handleAction(actions.dropPageData, (state) => ({
		...state,
		cards: [],
		pageNumber: undefined,
		totalPages: undefined,
		searchPhrase: undefined,
		selectedCategory: undefined,
	}));
