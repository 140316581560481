import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import timeConverter from 'library/utils/converters/time.converter';
import cardsService from 'library/common/services/cards.service';
import { ICardFormData, ICreateCardForm } from 'library/common/types/cards/cardsCreation.d';
import { showSnackbarMessage } from 'library/utils/showSnackbarMessage';

interface IResult {
	isPending: boolean;
    saveCardHandle: (data: ICardFormData) => void;
}

const WEIGHT_COEFFICIENT: number = 1000;

const useCardCreation = (): IResult => {
	const {t} = useTranslation();

	const navigate = useNavigate();

	const [isPending, setIsPending] = useState(false);

	const saveCardHandle = useCallback(
		(data: ICardFormData) => {
			const {
				title, cover, days, hours, minutes, seconds, result, stages,
			} = data;
			const duration = timeConverter(days, hours, minutes, seconds);
			const steps = stages.map((step, index) => ({
				video: step.video,
				text: step.text,
				draw: step.draw,
				image: step.image,
				weight: index * WEIGHT_COEFFICIENT,
				resource_ids: step.resources.map((resource) => resource.id),
			}));

			const requestData: ICreateCardForm = {
				title,
				cover,
				duration,
				result_id: result.id,
				steps,
			};

			cardsService
				.createCard(requestData)
				.then(() => {
					showSnackbarMessage(t('card_created'), 'success', 3000);
					navigate('/app/main');
				})
				.catch(() => showSnackbarMessage(t('card_creation_error'), 'error', 3000))
				.finally(() => setIsPending(false));
		},
		[navigate, t],
	);

	return {
		isPending,
		saveCardHandle,
	};
};

export default useCardCreation;
