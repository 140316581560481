import React, { useState } from 'react';
import {Collapse, Stack} from '@mui/material';
import {useMount, useUpdateEffect} from 'react-use';
import { keyBy, omit } from 'lodash';

import { PoorResource } from 'library/common/types/cards/cards.d';
import useResources from 'library/common/hook/useResources';
import { UUID } from 'library/common/types/app.d';
import ResourcesList from 'library/common/components/ResourcesList';

import SelectResources from './SelectResources';
import NewResourceDialog from './AddResourceDialog';

interface IResources {
  disabled: boolean;
  stepResources: PoorResource[];
  setFormResources: (resources: PoorResource[]) => void,
}

const Resources = ({
	disabled,
	stepResources,
	setFormResources,
}: IResources) => {
	const [selectedResource, setSelectedResource] = useState<{[key: string]: PoorResource}>({});
	const [AddNewResourceMode, setAddNewResourceMode] = useState(false);

	const onResourceSelectHandler = (value: PoorResource) => setSelectedResource((prev) => ({...prev, [value.id]: value}));
	const onResourceRemoveHandler = (id: UUID) => setSelectedResource((prev) => omit(prev, id));

	useMount(() => {
		if (stepResources && stepResources.length > 0) {
			setSelectedResource(keyBy(stepResources, 'id'));
		}
	});

	useUpdateEffect(() => {
		setFormResources(Object.values(selectedResource));
	}, [selectedResource]);

	const {
		resources,
		resourcesIsPending,
		isTouched,
		onSearchResource,
		addResource,
	} = useResources({addToSelectedHandler: onResourceSelectHandler});

	const openAddNewResourceWindowHandler = () => setAddNewResourceMode(true);
	const closeAddNewResourceWindowHandler = () => setAddNewResourceMode(false);

	return (
		<Stack direction="column" spacing={5}>
			<SelectResources
				isTouched={isTouched}
				resources={resources}
				disabled={disabled}
				isPending={resourcesIsPending}
				onSearchResource={onSearchResource}
				onResourceSelectHandler={onResourceSelectHandler}
				addNewResourceModeHandler={openAddNewResourceWindowHandler}
			/>

			<Collapse in={stepResources.length > 0} unmountOnExit mountOnEnter>
				<ResourcesList
					withBorder
					stepResources={stepResources}
					onResourceRemoveHandler={onResourceRemoveHandler}
				/>
			</Collapse>

			<NewResourceDialog
				isLoading={resourcesIsPending}
				isOpen={AddNewResourceMode}
				onCloseHandle={closeAddNewResourceWindowHandler}
				addResourceHandler={addResource}
			/>
		</Stack>
	);
};

export default Resources;
