import React, {useState} from 'react';
import {
	Snackbar,
	Button,
	Card,
	Stack,
	Typography,
} from '@mui/material';
import {Link as RouterLink} from 'react-router-dom';
import Link from '@mui/material/Link';
import CookieIcon from '@mui/icons-material/Cookie';

const CookieBanner = () => {
	const [showBanner, setShowBanner] = useState<boolean>(!localStorage.getItem('cookie_accepted'));

	const closeBannerShow = () => {
		localStorage.setItem('cookie_accepted', 'true');
		setShowBanner(false);
	};

	return (
		<Snackbar
			open={showBanner}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
		>
			<Card sx={{padding: 3}}>
				<Stack
					spacing={{
						xs: 2,
						sm: 2,
						md: 5,
						lg: 5,
						xl: 5,
					}}
					direction={{
						xs: 'column',
						sm: 'column',
						md: 'column',
						lg: 'row',
						xl: 'row',
					}}
					alignItems="center"
				>
					<CookieIcon fontSize="large" color="primary" />
					<Typography>
						We use cookies to improve your experience on our website. To find out more about
						the cookies we use please read our&nbsp;
						<Link component={RouterLink} to="/cookies-policy">Cookie Policy</Link>
						. We use strictly necessary cookies
						and analytics cookies. If you agree to the use of analytics cookie,
						please click &quot;Accept&quot;
					</Typography>
					<Button variant="outlined" onClick={closeBannerShow} sx={{minWidth: 120}}>Accept</Button>
				</Stack>
			</Card>
		</Snackbar>
	);
};

export default React.memo(CookieBanner, () => false);
