import WalletIcon from 'resources/icons/WalletIcon';
import AddIcon from 'resources/icons/AddIcon';

export default [
	// {
	// 	aria_label: 'cards',
	// 	path: 'cards',
	// 	disabled: true,
	// 	icon: CardsIcon,
	// },
	{
		aria_label: 'wallet',
		path: 'wallet',
		disabled: true,
		icon: WalletIcon,
	},
	{
		aria_label: 'add_card',
		path: 'card-create',
		disabled: false,
		icon: AddIcon,
	},
	// {
	// 	aria_label: 'progress-bonuses',
	// 	path: 'progress-bonuses',
	// 	disabled: true,
	// 	icon: ProgressBonusesIcon,
	// },
];
