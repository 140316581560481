import React from 'react';
import {
	Stack, IconButton, Link, StackProps, Theme, SxProps,
} from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';

const defaultStyles = {
	color: (theme: Theme) => theme.palette.text.secondary,
	borderRadius: '30%',
	'& .MuiTouchRipple-root .MuiTouchRipple-child': {
		borderRadius: '30%',
	},
};

interface IProps {
	containerProps?: StackProps;
	linkSx?: SxProps<Theme>;
}

const SocialLinks: React.FunctionComponent<IProps> = ({ containerProps, linkSx }) => (
	<Stack
		mt={3}
		direction="row"
		spacing={1}
		alignItems="center"
		{...containerProps}
	>
		<Link
			component={IconButton}
			sx={{
				...defaultStyles,
				'&:hover': {color: '#d62976'},
				...linkSx,
			}}
			href="https://instagram.com/wodaqota?igshid=YmMyMTA2M2Y="
			target="_blank"
		>
			<InstagramIcon />
		</Link>
		<Link
			component={IconButton}
			sx={{
				...defaultStyles,
				'&:hover': {color: '#3b5998'},
				...linkSx,
			}}
			href="https://www.facebook.com/wodaqota"
			target="_blank"
		>
			<FacebookIcon />
		</Link>
		<Link
			component={IconButton}
			sx={{
				...defaultStyles,
				'&:hover': {color: '#00acee'},
				...linkSx,
			}}
			href="https://www.twitter.com/WodaQota"
			target="_blank"
		>
			<TwitterIcon />
		</Link>
	</Stack>
);

export default SocialLinks;
