import WodaBonusIcon from 'resources/icons/WodaBonusIcon';
import DashboardIcon from 'resources/icons/DashboardIcon';
import AddIcon from 'resources/icons/AddIcon';
import LikeIcon from './LikeIcon';

export default [
	{
		title: 'Main',
		path: 'main',
		disabled: false,
		icon: DashboardIcon,
		fontSize: '2.3rem',
	},
	{
		title: 'Liked',
		path: 'liked',
		disabled: false,
		icon: LikeIcon,
		fontSize: '1.7rem',
	},
	{
		title: 'WodaBonus',
		path: 'bonuses/global',
		disabled: false,
		icon: WodaBonusIcon,
		fontSize: '2.3rem',
	},
	{
		title: 'Add Card',
		path: 'card-create',
		disabled: false,
		icon: AddIcon,
		onlyMobile: true,
		fontSize: '2.3rem',
	},
];
