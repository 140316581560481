import { AxiosResponse } from 'axios';
/* eslint-disable import/prefer-default-export */
import { sortBy } from 'lodash';

import { IReelsCard } from 'library/common/types/cards/reels.d';
import { CardApiData } from 'library/common/types/cards/request.d';

export const getReelsCardDataMapper = (response: AxiosResponse<CardApiData<IReelsCard[]>>) => response.data.payload
	.filter((card) => card.id !== '027ab351-24d7-49d3-9114-d1aceb908067')
	.filter((card) => card.steps.some((step) => step.video))
	.map((card) => ({
		...card,
		steps: sortBy(card.steps.filter((step) => step.video), (step_1) => step_1.weight),
	}));
