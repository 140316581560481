import React from 'react';

import styles from './passwordErrors.module.css';

interface IPasswordErrors {
	errors: string | undefined;
}

const PasswordErrors = ({errors }: IPasswordErrors) => (
	<div className={styles.passwordErrors}>
		{
			errors && (
				<>
					{errors.split(';')
						.map((error) => <p key={error}>{error}</p>)}
				</>
			)
		}
	</div>
);

export default PasswordErrors;
