import * as React from 'react';
import CircularProgress, {
	CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface ICircularProgressWithLabel extends CircularProgressProps {
	value: number;
	height?: number;
}

const CircularProgressWithLabel = ({
	value, height, sx, ...props
}: ICircularProgressWithLabel) => (
	<Box sx={{
		height, display: 'flex', alignItems: 'center', justifyContent: 'center',
	}}
	>
		<Box sx={{ position: 'relative', display: 'inline-flex' }}>
			<CircularProgress value={value} sx={sx} {...props} />
			<Box
				sx={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: 'absolute',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Typography
					variant="caption"
					component="div"
					sx={{
						opacity: 0.6,
						...sx,
					}}
				>
					{`${value}%`}
				</Typography>
			</Box>
		</Box>
	</Box>
);

export default CircularProgressWithLabel;
