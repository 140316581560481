import { isEmpty, isEqual } from 'lodash';

import { MediaContent, UUID } from 'library/common/types/app.d';
import { PoorResource, Resource } from 'library/common/types/cards/cards.d';
import { getReducedStepPicture } from 'library/utils/getters/getPicture';

export const resourcesChanges = (oldResources: Resource[], newResources: PoorResource[]): null | undefined | Array<UUID> => {
	if (oldResources.length > 0 && newResources.length === 0) {
		return null;
	}

	const currentResourcesId = oldResources.map((resource) => resource.id);
	const newResourcesId = newResources.map((resource) => resource.id);

	if (isEqual(currentResourcesId, newResourcesId)) {
		return undefined;
	}

	return newResourcesId;
};

export const textChanges = (oldText?: string, newText?: string): null | undefined | string => {
	if (oldText !== undefined && (newText === undefined || newText === '')) {
		return null;
	}

	if (isEqual(oldText, newText)) {
		return undefined;
	}

	return newText;
};

export const mediaChanges = (oldMedia?: MediaContent, newMedia?: string): null | undefined | string => {
	if (!isEmpty(oldMedia) && (newMedia === undefined || newMedia === '')) {
		return null;
	}

	if (oldMedia && isEqual(getReducedStepPicture(oldMedia), newMedia)) {
		return undefined;
	}

	return newMedia;
};
