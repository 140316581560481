import React from 'react';

import SadCatImage from 'resources/image/Sad_cat.png';
import palette from 'library/utils/theme/palette';

import styles from './styles.module.css';

const AppError: React.FunctionComponent = () => (
	<div style={{ background: palette.gradients.info }} className={styles.container}>
		<img className={styles.errorImage} src={SadCatImage} alt="sad cat" />
		<h1 className={styles.title}>OOOPS</h1>
		<h4>Something went wrong and we are already solving the problem. Sorry for the inconvenience, please try again later</h4>
	</div>
);

export default AppError;
