import React from 'react';
import { useSelector } from 'react-redux';
import {
	Button, Stack, Theme, Tooltip,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import VideoCallIcon from '@mui/icons-material/VideoCall';

import { RootState } from 'main/rootReducer';
import DoneIcon from 'resources/icons/DoneIcon';
import { UUID } from 'library/common/types/app.d';
import { IAuthor } from 'library/common/types/cards/cards.d';
import AvatarButton from 'library/common/components/AvatarButton';
import toProfilePage from 'library/utils/routes/getProfilePageUrl';
import TypographyLink from 'library/common/components/TypographyLink';
import { getToolbarAvatar } from 'library/utils/getters/getPicture';
import { interactPermissions, selectIsSubscribed } from 'library/common/selectors/user.selector';
import VerifiedIcon from 'resources/icons/VerifiedIcon';

interface IProps {
    author: IAuthor;
	onSubscribeHandle(authorId: UUID, isSubscribed: boolean): void;
	goToCard(): void;
}

const containedColorSx = (theme: Theme) => ({
	textTransform: 'none',
	boxShadow: 'none',
	background: '#D9EBFC',
	color: theme.palette.secondary.main,

	'&:hover': {
		background: theme.palette.primary.light,
		color: theme.palette.common.white,
	},
});

const Footer: React.FunctionComponent<IProps> = ({
	author, onSubscribeHandle, goToCard,
}) => {
	const canISubscribe = useSelector((state: RootState) => interactPermissions(state, author.id));

	const isSubscribed = useSelector((state: RootState) => selectIsSubscribed(state, author.id));

	return (
		<Stack direction="row" justifyContent="space-between">
			<Stack direction="row" spacing={2}>

				<AvatarButton
					author={author.login}
					authorId={author.id}
					userPhoto={getToolbarAvatar(author.avatar)}
					size={60}
				/>

				<Stack spacing={1}>
					<Stack spacing={1} direction="row">
						<TypographyLink label={author.login} to={toProfilePage(author.id)} />
						{author.is_verified && <VerifiedIcon sx={{ width: '16px', height: '16px' }} />}
					</Stack>
					<Stack direction="row" spacing={3} alignItems="center">
						{/* тут должна быть еще одна кнопка "Get advice" */}
						{
							canISubscribe && (
								<Button
									variant="contained"
									sx={containedColorSx}
									startIcon={isSubscribed ? <DoneIcon /> : undefined}
									onClick={() => onSubscribeHandle(author.id, isSubscribed)}
								>
									{isSubscribed ? 'You subscribed' : 'Follow'}
								</Button>
							)
						}
					</Stack>
				</Stack>
			</Stack>

			<Stack direction="row" spacing={1} alignItems="center">
				<Tooltip title="Coming soon">
					<div>
						<Button
							disabled
							variant="outlined"
							startIcon={<VideoCallIcon />}
							sx={{
								textTransform: 'none',
								boxShadow: 'none',
								border: '1px solid #AB47BC4D',
								color: (theme) => theme.palette.secondary.main,

								'&:hover': {
									borderColor: '#AB47BC96',
								},
							}}
						>
							1:1 Consultation
						</Button>
					</div>
				</Tooltip>
				<Button
					variant="contained"
					startIcon={<InfoOutlinedIcon />}
					sx={containedColorSx}
					onClick={goToCard}
				>
					Learn more
				</Button>
			</Stack>
		</Stack>
	);
};

export default Footer;
