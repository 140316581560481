import {Breakpoint} from '@mui/system/createTheme/createBreakpoints';

export const breakpointsValues: { [key in Breakpoint]: number } = {
	xs: 320,
	sm: 612,
	md: 906,
	lg: 1024,
	xl: 1824,
};

/**
 * gridSize - part of 12 (grid)
 *
 * count - count of items in line (wrong now)
 */
export const countOfTile: { [key in Breakpoint]: {gridSize: number, count: number} } = {
	xs: {gridSize: 12, count: 1},
	sm: {gridSize: 6, count: 2},
	md: {gridSize: 4, count: 3},
	lg: {gridSize: 3, count: 4},
	xl: {gridSize: 3, count: 5},
};
