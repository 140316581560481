import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Stack, Typography} from '@mui/material';

import {createUserAsync} from 'library/common/actions/user.action';
import FacebookIcon from 'resources/icons/FacebookIcon';
import GoogleIcon from 'resources/icons/GoogleIcon';
import GradientButton from './GradientButton';

interface ISocialNetworkEnter {
	disabled?: boolean
}

const buttons: {
	label: string;
	ariaLabel: string;
	icon: ({width, height}: {width: number, height: number}) => React.ReactNode;
	authWith: 'facebook' | 'google';
}[] = [
	{
		label: 'Facebook',
		ariaLabel: 'authorization with facebook',
		icon: FacebookIcon,
		authWith: 'facebook',
	},
	{
		label: 'Google',
		ariaLabel: 'authorization with google+',
		icon: GoogleIcon,
		authWith: 'google',
	},
];

const SocialNetworkEnter = ({disabled}: ISocialNetworkEnter) => {
	const {t} = useTranslation();
	const dispatch = useDispatch();

	const onClickHandle = (provider: 'facebook' | 'google') => {
		dispatch(createUserAsync.request({
			method: 'oidc',
			provider,
		}));
	};

	return (
		<Stack
			mt={5}
			spacing={1}
			direction="column"
			alignItems="flex-start"
			justifyContent="center"
			sx={{
				position: 'relative', zIndex: 1,
			}}
		>
			<Typography>{t('login_with')}</Typography>
			<Stack direction="row" alignItems="center" justifyContent="center" sx={{width: '100%'}} spacing={3}>
				{
					buttons.map((button) => (
						<GradientButton
							key={button.label}
							ariaLabel={button.ariaLabel}
							borderRadius={20}
							disabled={disabled}
							icon={button.icon}
							label={button.label}
							onClick={() => onClickHandle(button.authWith)}
						/>
					))
				}
			</Stack>
		</Stack>
	);
};

export default SocialNetworkEnter;
