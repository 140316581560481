import { Box } from '@mui/material';
import React from 'react';

import styles from './styles.module.css';

const DotsLoader = () => (
	<Box
		sx={{
			color: (theme) => theme.palette.grey[600],
			backgroundColor: (theme) => theme.palette.grey[600],
			'&::after': {
				color: (theme) => theme.palette.grey[600],
				backgroundColor: (theme) => theme.palette.grey[600],
			},
			'&::before': {
				color: (theme) => theme.palette.grey[600],
				backgroundColor: (theme) => theme.palette.grey[600],
			},
		}}
		className={styles.dot_collision}
	/>
);

export default DotsLoader;
