import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Paper, Stack, Typography} from '@mui/material';

import {RootState} from 'main/rootReducer';

import Footer from './Footer';
import Header from './Header';

import styles from '../authorization.module.css';

interface IAuthorizationLayout {
	children: any;
}

const AuthorizationLayout = ({children}: IAuthorizationLayout) => {
	const navigate = useNavigate();
	const isAuth = useSelector((state: RootState) => state.user.isAuth);

	useEffect(() => {
		if (isAuth) navigate('/app/main');
	}, [isAuth, navigate]);

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<Header />
			</div>
			<div className={styles.main}>
				<Paper
					sx={({ palette }) => ({
						width: {
							xs: '100%',
							sm: '512px',
							md: '640px',
							lg: '1040px',
						},
						backgroundColor: {
							xs: 'transparent',
							sm: palette.common.white,
						},
						p: {
							xs: 3,
							sm: '48px 70px 56px 70px',
							md: '48px 140px 56px 140px',
							lg: '48px 280px 56px 280px',
							xl: '48px 280px 56px 280px',
						},
					})}
					elevation={0}
				>
					<Stack direction="column">
						<Stack direction="column" spacing={1} alignItems="center">
							<Typography
								variant="h6"
								sx={{
									fontWeight: 600,
									background: (theme) => (theme.palette as any).gradients.primary,
									WebkitBackgroundClip: 'text',
									WebkitTextFillColor: 'transparent',
								}}
							>
								Welcome to the platform
							</Typography>
							<Typography
								variant="h5"
								sx={{
									fontWeight: 600,
									color: (theme) => theme.palette.text.primary,
									fontSize: '1.625rem',
								}}
							>
								WodaQota
							</Typography>
						</Stack>
						{children}
					</Stack>
				</Paper>
				<Footer />
			</div>
		</div>
	);
};

export default AuthorizationLayout;
