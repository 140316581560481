import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const ExcludeIcon = ({...props}: SvgIconProps) => (
	<SvgIcon viewBox="0 0 24 17" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1 0C0.447715 0 0 0.447715 0 1V15.8967C0 16.4489 0.447716 16.8967 1 16.8967H17.4954C18.0477
         16.8967 18.4954 16.4489 18.4954 15.8967V10.6161L22.2239 15.2048C22.8176 15.9355 24 15.5157 24
         14.5742V2.32245C24 1.38098 22.8176 0.961162 22.2239 1.69184L18.4954 6.28055V1C18.4954 0.447715
         18.0477 0 17.4954 0H1ZM9.34781 3.13043C8.79552 3.13043 8.34781 3.57814 8.34781 4.13043V7.30433H5.1739C4.62162
         7.30433 4.1739 7.75205 4.1739 8.30433V8.39129C4.1739 8.94357 4.62162 9.39129 5.17391
         9.39129H8.34781V12.5652C8.34781 13.1175 8.79552 13.5652 9.34781 13.5652H9.43476C9.98705 13.5652
         10.4348 13.1175 10.4348 12.5652V9.39129H13.6087C14.161 9.39129 14.6087 8.94357 14.6087 8.39129V8.30433C14.6087
         7.75205 14.161 7.30433 13.6087 7.30433H10.4348V4.13043C10.4348 3.57814 9.98705 3.13043 9.43476 3.13043H9.34781Z"
		/>
	</SvgIcon>
);

export default ExcludeIcon;
