import React from 'react';
import {
	Box, Divider, Grid, Stack, Typography,
} from '@mui/material';
import CommentIcon from '@mui/icons-material/Comment';
// import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

import { IPoorCard } from 'library/common/types/cards/cards.d';
import { getPoster } from 'library/utils/getters/getPicture';
import { declOfNum, getFullDuration } from 'library/utils/workWithData';
import Label from 'library/common/components/Label/Label';
import LabelWithIcon from 'library/common/components/LabelWIthIcon';

import MobileListItem from './ListItem';

interface IProps {
    cards: IPoorCard[];
}

const MobilePublishedList: React.FunctionComponent<IProps> = ({ cards }) => (
	<>
		{
			cards.map((card) => (
				<MobileListItem key={card.id} id={card.id}>
					<>
						<Grid
							item
							xs={2}
							display="flex"
							alignItems="center"
							justifyContent="center"
						>
							<Box
								component="img"
								sx={{
									width: '48px',
									height: '48px',
								}}
								src={getPoster(card, 'LIST')}
							/>
						</Grid>

						<Grid item xs={8}>
							<Stack
								direction="column"
								sx={{ height: '100%', justifyContent: 'center' }}
							>
								<Label
									component="h6"
									title={card.title}
									sx={{
										fontWeight: 600,
										WebkitLineClamp: 1,
										color: (theme) => (theme.palette as any).gradients.textDark,
									}}
								/>

								<Stack direction="row" mt={0.5}>
									<Typography
										variant="h6"
										sx={{ color: (theme) => theme.palette.secondary.light}}
									>
										{card.duration && getFullDuration(card.duration)}
									</Typography>

									<Divider
										orientation="vertical"
										flexItem
										sx={{
											mx: 1,
											height: '16px',
											alignSelf: 'auto',
										}}
									/>

									<Typography
										variant="h6"
										sx={{
											color: (theme) => theme.palette.secondary.light,
										}}
									>
										{`${card.number_of_steps || 0} ${declOfNum(card.number_of_steps || 0, ['step', 'steps', 'steps'])}`}
									</Typography>
								</Stack>
							</Stack>
						</Grid>

						<Grid item xs={2}>
							<Stack
								sx={{ height: '100%' }}
								spacing={1}
								alignItems="center"
								justifyContent="center"
								direction="column"
							>
								{/* <LabelWithIcon
									sx={{ color: (theme) => theme.palette.text.secondary }}
									tooltip="Number of activations"
									title={`${card.statistics.number_of_activation || 0}`}
									icon={(
										<CheckCircleRoundedIcon sx={{
											width: '20px',
											height: '20px',
											color: (theme) => theme.palette.text.secondary,
										}}
										/>
									)}
								/> */}

								<LabelWithIcon
									sx={{ color: (theme) => theme.palette.text.secondary }}
									tooltip="Number of likes"
									title={`${card.statistics.number_of_favorites || 0}`}
									icon={<ThumbUpIcon sx={{ color: (theme) => theme.palette.text.secondary }} />}
								/>

								<LabelWithIcon
									sx={{ color: (theme) => theme.palette.text.secondary }}
									tooltip="Number of comments"
									title={`${card.statistics.number_of_comments || 0}`}
									icon={(
										<CommentIcon sx={{
											width: '20px',
											height: '20px',
											color: (theme) => theme.palette.text.secondary,
										}}
										/>
									)}
								/>
							</Stack>
						</Grid>
					</>
				</MobileListItem>
			))
		}
	</>
);

export default MobilePublishedList;
