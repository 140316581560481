import React from 'react';
import {useSelector} from 'react-redux';
import {Box} from '@mui/material';

import { RootState } from 'main/rootReducer';
import MHidden from 'library/common/components/MHidden/MHidden';
import DesktopActions from 'library/common/components/Dashboard/children/Navbar/ActionsBar/DesktopActions';
import MobileActions from 'library/common/components/Dashboard/children/Navbar/ActionsBar/MobileActions';
import { selectIsAuthorized, selectUserData } from 'library/common/selectors/user.selector';

const ActionsBar = () => {
	const isAuth = useSelector(selectIsAuthorized);
	const userData = useSelector(selectUserData);
	const notificationCount = useSelector((state: RootState) => state.app.notificationCount);

	return (
		<Box>
			<MHidden breakpoint="md" direction="Down">
				<DesktopActions
					isAuth={isAuth}
					userData={userData}
				/>
			</MHidden>
			<MHidden breakpoint="md" direction="Up">
				<MobileActions
					isAuth={isAuth}
					notificationCount={notificationCount}
					userData={userData}
				/>
			</MHidden>
		</Box>
	);
};

export default ActionsBar;
