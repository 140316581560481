import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { withErrorBoundary } from 'react-error-boundary';
import {
	Box,
	Dialog,
	SxProps,
	Theme,
	useMediaQuery,
} from '@mui/material';

import MyImageEditor from 'library/common/components/MyImageEditor';
import ErrorComponent from 'library/common/components/Error';
import View from './View';
import Input from './Input';

interface IImage {
	disabled: boolean;
	value?: string;
    sx?: SxProps<Theme>;
    setData(data: string): void;
	// Blob url path
	setFileUrl?: (data: string) => void;
	allowDropImageButton?: boolean;
	openImageInputRef?: React.MutableRefObject<(() => void) | undefined>;
}

const Image = ({
	value, setData, disabled, sx, setFileUrl, allowDropImageButton, openImageInputRef,
}: IImage) => {
	const [file, setFile] = useState<File | undefined>(undefined);
	const [errors, setErrors] = useState<string>('');
	const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

	useEffect(() => {
		if (setFileUrl) setFileUrl(file ? URL.createObjectURL(file) : '');
	}, [setFileUrl, file]);

	const mobileMode = useMediaQuery((theme: Theme) => (theme.breakpoints.down('md')));

	/**
	 * Закрытие диалогового окна с редактором
	 */
	const onClose = () => {
		setFile(undefined);
		setData('');
		setIsDialogOpen(false);
	};

	/**
	 *
	 * Функция которая срабатывает после загрузки картинки на сервер
	 *
	 * @param file выбранная картинка для отображения
	 * @param res id загруженной картинки
	 */
	const onUploadHandle = (data: File, res: number) => {
		setData(`${res}`);
		setFile(data);
		setIsDialogOpen(false);
	};

	const onImageSelect = (data: File) => {
		setFile(data);
		setIsDialogOpen(true);
	};

	const {
		getRootProps, getInputProps, isDragActive, open,
	} = useDropzone({
		onDrop: (acceptedFiles, fileRejections) => {
			if (fileRejections && fileRejections.length > 0) {
				fileRejections.forEach((rejectedFile) => {
					rejectedFile.errors.forEach((err) => {
						if (err.code === 'file-too-large') {
							setErrors(`Error: ${err.message}`);
						}

						if (err.code === 'file-invalid-type') {
							setErrors(`Error: ${err.message}`);
						}
					});
				});
			} else {
				setErrors('');
				onImageSelect(acceptedFiles[0]);
			}
		},
		multiple: false,
		accept: ['image/png', 'image/jpeg', 'image/bmp'],
		maxSize: 3000000,
		disabled,
	});

	if (openImageInputRef) {
		// eslint-disable-next-line no-param-reassign
		openImageInputRef.current = open;
	}

	if (value && file === undefined) {
		return (
			<Box sx={sx}>
				<View src={value} alt={value} />
				<input
					type="file"
					autoComplete="off"
					style={{ display: 'none' }}
					{...getInputProps()}
				/>
			</Box>
		);
	}

	const source = file ? URL.createObjectURL(file) : value;

	return (
		<Box sx={sx}>
			{
				(source && !isDialogOpen) ? (
					<View
						src={source}
						alt={`step_${file?.name || 'edit'}_${file?.type || 'image'}`}
						dropImage={allowDropImageButton ? onClose : undefined}
					/>
				) : (
					<Input
						errors={errors}
						getInputProps={getInputProps}
						getRootProps={getRootProps}
						isDragActive={isDragActive}
					/>
				)
			}
			<Dialog
				open={isDialogOpen}
				onClose={onClose}
				fullWidth
				maxWidth="xl"
				disableEnforceFocus
				fullScreen={mobileMode}
			>
				<MyImageEditor
					menu={['crop', 'rotate', 'draw', 'shape', 'icon', 'text']}
					path={file ? URL.createObjectURL(file) : ''}
					fileName={file?.name || 'SampleImage'}
					onCancelHandle={onClose}
					onLoad={onUploadHandle}
					mobileMode={mobileMode}
				/>
			</Dialog>
		</Box>
	);
};

// Until they fix the import problem in the library https://github.com/bvaughn/react-error-boundary/issues/111
export default withErrorBoundary(Image, {
	FallbackComponent: ErrorComponent,
}) as (props : IImage) => React.ReactElement;
