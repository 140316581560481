import React, { useMemo } from 'react';
import {
	Stack, SxProps, Theme, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { UUID } from 'library/common/types/app.d';
import NewCommentComponent from 'pages/Card/components/Comments/children/NewCommentComponent';
import Comment from 'pages/Card/components/Comments/children/Comment';
import { withErrorBoundary } from 'react-error-boundary';
import ErrorComponent from 'library/common/components/Error';
import useComments from 'library/common/data/comments/useComments';
import { getCommentsMapper } from 'library/common/data/comments/mappers';
import useAddCommentMutation from 'library/common/data/comments/useAddCommentMutation';
import { selectUserData } from 'library/common/selectors/user.selector';

interface IProps {
	cardID: UUID;
	commentsCount: number;
	sx?: SxProps<Theme>;
}

const Comments: React.FunctionComponent<IProps> = ({ cardID, commentsCount, sx }) => {
	const {t} = useTranslation();

	const { id: userId } = useSelector(selectUserData);

	const { data: commentsObj } = useComments(cardID);
	const { mutateAsync: addCommentHandler } = useAddCommentMutation();

	const comments = useMemo(() => getCommentsMapper(commentsObj), [commentsObj]);

	return (
		<Stack direction="column" sx={{ ...sx }}>
			<Stack direction="column">
				<Typography variant="h3" sx={{ color: ({palette}) => palette.text.primary, fontWeight: 600}}>{ commentsCount }</Typography>
				<Typography variant="subtitle1" sx={{ color: ({palette}) => palette.text.primary }}>{t('comments')}</Typography>
			</Stack>

			<NewCommentComponent
				sx={{ mt: 2 }}
				addCommentHandler={(text: string) => addCommentHandler({ cardId: cardID, commentMessage: text })}
			/>

			<Stack direction="column" spacing={2} mt={8}>
				{
					comments.map((comment) => (
						<Comment
							root
							parentId={cardID}
							key={comment.comment.id}
							userId={userId}
							comment={comment}
						/>
					))
				}
			</Stack>
		</Stack>
	);
};

export default withErrorBoundary(Comments, {
	FallbackComponent: ErrorComponent,
}) as (props : IProps) => React.ReactElement;
