import React from 'react';
import { TFunction } from 'react-i18next';
import { Typography } from '@mui/material';

import { INotification } from 'library/common/types/notifications/notifications.d';
import NotificationType from 'library/common/types/notifications/notificationTypes';
import { takeHead } from 'library/utils/textGenerators';

interface IProps {
    t: TFunction<'translation'>,
	notification: INotification,
}

const NotificationPrimeText: React.FunctionComponent<IProps> = ({notification, t}) => {
	const username = notification.author ?? '';

	switch (notification.type) {
	case NotificationType.COMMENT: {
		return (
			<Typography variant="subtitle2">
				{username}
				<Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
						&nbsp;
					{`${t('commented')}: ${takeHead(notification?.commentText || '')}`}
				</Typography>
			</Typography>
		);
	}

	case NotificationType.SUBSCRIPTION: {
		return (
			<Typography variant="subtitle2">
				{username}
				<Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
					&nbsp;
					{`${t('started_following_you')}`}
				</Typography>
			</Typography>
		);
	}

	case NotificationType.ACTIVATION: {
		return (
			<Typography variant="body2" sx={{ color: 'text.secondary' }}>
				{`${t('your_card_was_activated_by')}`}
				<Typography component="span" variant="subtitle2" sx={{ color: 'black' }}>
					&nbsp;
					{`${username}`}
				</Typography>
			</Typography>
		);
	}

	case NotificationType.CHILD_COMMENT: {
		return (
			<Typography variant="subtitle2">
				{username}
				<Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
				&nbsp;
					{`left a message under your comment: ${takeHead(notification?.commentText || '')}`}
				</Typography>
			</Typography>
		);
	}

	case NotificationType.LIKE: {
		return (
			<Typography variant="subtitle2">
				{username}
				<Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
				&nbsp;
					{`liked your comment: ${takeHead(notification?.commentText || '')}`}
				</Typography>
			</Typography>
		);
	}

	default: return null;
	}
};

export default NotificationPrimeText;
