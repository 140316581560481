import {all, fork} from 'redux-saga/effects';
import usersSaga from 'library/common/sagas/user.saga';
import cardsSaga from 'library/common/sagas/cards.saga';
import appSaga from 'library/common/sagas/app.saga';

export default function* rootSaga() {
	yield all([
		fork(usersSaga),
		fork(cardsSaga),
		fork(appSaga),
	]);
}
