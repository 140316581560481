import React, { SyntheticEvent, useRef } from 'react';
import TouchRipple, { TouchRippleActions } from '@mui/material/ButtonBase/TouchRipple';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface IProps {
	id: string | number;
    children: React.ReactElement
}

const MobileListItem: React.FunctionComponent<IProps> = ({ id, children }) => {
	const navigate = useNavigate();

	const rippleRef = useRef<TouchRippleActions>(null);

	const onRippleStart = (e: SyntheticEvent) => {
		rippleRef?.current?.start(e);
	};

	const onRippleStop = (e: SyntheticEvent) => {
		rippleRef?.current?.stop(e);
	};

	return (
		<Grid
			container
			columnSpacing={1}
			sx={{
				mt: 1,
				px: 1,
				height: '64px',
				cursor: 'pointer',
				position: 'relative',
				userSelect: 'none',
				MozUserSelect: 'none',
				KhtmlUserSelect: 'none',
				'&::after': {
					position: 'absolute',
					content: '""',
					left: 0,
					top: 0,
					height: '100%',
					width: '100%',
					background: 'linear-gradient(0deg, rgba(65, 154, 242, 0.3), rgba(65, 154, 242, 0.3)), #FFFFFF2B',
					opacity: 0.15,
				},
			}}
			onTouchStart={onRippleStart}
			onTouchEnd={onRippleStop}
			onTouchCancel={onRippleStop}
			onClick={() => navigate(`/app/card/${id}`)}
		>
			{children}
			<TouchRipple ref={rippleRef} center={false} />
		</Grid>
	);
};

export default MobileListItem;
