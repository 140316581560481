import React from 'react';
import {
	IconButton, Stack, SxProps, Theme,
} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';

interface IFooter {
    addStep: () => void;
    sx?: SxProps<Theme>;
}

const Footer = ({addStep, sx}: IFooter) => (
	<Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end" sx={sx}>
		<IconButton
			onClick={addStep}
			aria-label="delete"
			size="small"
			sx={{
				color: (theme) => theme.palette.grey[400],
				'&:hover': {
					color: (theme) => theme.palette.grey[600],
				},
			}}
		>
			<AddBoxIcon />
		</IconButton>
	</Stack>
);

export default Footer;
