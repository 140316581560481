import React from 'react';
import Typography from '@mui/material/Typography';
import {SxProps, Theme} from '@mui/material';

interface ILabel {
	title: string;
	variant?: 'button' | 'caption' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' |
		'inherit' | 'body1' | 'subtitle1' | 'subtitle2' | 'body2' | 'overline';
	component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
	sx?: SxProps<Theme>;
}

const Label = ({
	title, variant, component, sx,
}: ILabel) => (
	<Typography
		gutterBottom
		variant={variant || 'h6'}
		component={component || 'h6'}
		sx={{
			display: '-webkit-box',
			overflow: 'hidden',
			WebkitBoxOrient: 'vertical',
			WebkitLineClamp: 2,
			// maxHeight: '4.4rem',
			textOverflow: 'ellipsis',
			whiteSpace: 'normal',
			...sx,
		}}
	>
		{title}
	</Typography>
);

export default Label;
