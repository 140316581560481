import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const DoneIcon = (props: SvgIconProps) => (
	<SvgIcon viewBox="0 0 14 10" {...props}>
		<path
			d="M13.424 0.000241401C13.2787 0.00453964 13.1407 0.0648279 13.0394 0.168334L4.48371 8.65971L0.963106 5.16556C0.911555
		5.11227 0.849809 5.06972 0.781484 5.04041C0.713159 5.0111 0.639629 4.99562 0.565199 4.99487C0.490769 4.99412 0.416937 5.00811
		0.348025 5.03604C0.279113 5.06396 0.216508 5.10525 0.163876 5.15748C0.111243 5.20972 0.0696418 5.27186 0.0415075 5.34025C0.0133732
		5.40864 -0.000728297 5.48192 2.8958e-05 5.55579C0.000786213 5.62966 0.016387 5.70264 0.0459175 5.77045C0.0754481 5.83826 0.118315
		5.89955 0.172007 5.95071L4.08816 9.83744C4.19308 9.94153 4.33536 10 4.48371 10C4.63206 10 4.77434 9.94153 4.87926 9.83744L13.8305
		0.953487C13.9113 0.875496 13.9665 0.775085 13.9889 0.665412C14.0112 0.55574 13.9997 0.441924 13.9558 0.338878C13.9118 0.235832 13.8375
		0.148364 13.7426 0.087937C13.6478 0.0275101 13.5367 -0.00305697 13.424 0.000241401Z"
			// fill="#DADADA"
		/>
	</SvgIcon>
);

export default DoneIcon;
