import React from 'react';
import { Box, Stack } from '@mui/material';

import SearchInput from 'library/common/components/Search/SearchInput';

import Title from 'library/common/components/Title/Title';
import ActionsBar from 'library/common/components/Dashboard/children/Navbar/ActionsBar/ActionsBar';
import CategoryTabs, { IProps as CategoryTabsProps} from 'library/common/components/Dashboard/children/Navbar/ActionsBar/CategoryTabs';

interface IProps extends CategoryTabsProps {
    isMainPage: boolean;
}

const Mobile: React.FunctionComponent<IProps> = ({ isMainPage }) => (
	<Stack sx={{ width: '100%', height: '100%' }} direction="column">

		<Stack
			sx={{
				px: 2,
				height: '50px',
				alignItems: 'center',
				flex: 1,
			}}
			spacing={2}
			direction="row"
		>
			<Title mobileMode />

			<Box sx={{ flex: 1 }}>
				{
					isMainPage && (
						<SearchInput />
					)
				}
			</Box>

			<ActionsBar />
		</Stack>

		{
			isMainPage && (
				<Stack sx={{ alignItems: 'center', height: '50px' }} direction="row">
					<CategoryTabs />
				</Stack>
			)
		}
	</Stack>
);

export default Mobile;
