import React, { useMemo } from 'react';
// material
import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
import palette from 'library/utils/theme/palette';
import shadows, {customShadows} from 'library/utils/theme/shadows';
import componentsOverride from 'library/utils/theme/overrides';
import breakpoints from 'library/utils/theme/breakpoints';
import {CustomTheme} from 'library/utils/theme/overrides/Theme.d';
import shape from 'library/utils/theme/shape';
//

// ----------------------------------------------------------------------

interface IThemeConfig {
  children: React.ReactElement;
}

const ThemeConfig = ({ children }: IThemeConfig) => {
	const themeOptions = useMemo(
		() => ({
			palette,
			shape,
			breakpoints,
			// spacing: 4,
			shadows,
			customShadows,
			typography: {
				fontFamily: [
					'Inter',
					'-apple-system',
					'BlinkMacSystemFont',
					'"Segoe UI"',
					'Roboto',
				].join(','),

				caption: {
					fontStyle: 'normal',
					fontWeight: 400,
					fontSize: '0.8rem',
					lineHeight: '0.9rem',
				},
				body1: {
					fontStyle: 'normal',
					fontWeight: 400,
					fontSize: '14px',
					lineHeight: '16px',
				},

				body2: {
					fontStyle: 'normal',
					fontWeight: 400,
					fontSize: '14px',
					lineHeight: '16px',
				},

				h6: {
					fontStyle: 'normal',
					fontWeight: 400,
					fontSize: '16px',
					lineHeight: '20px',
				},
				h5: {
					fontStyle: 'normal',
					fontWeight: 400,
					fontSize: '24px',
					lineHeight: '30px',
				},
				h4: {
					fontStyle: 'normal',
					fontWeight: 600,
					fontSize: '26px',
					lineHeight: '32px',
				},
				h3: {
					fontStyle: 'normal',
					fontWeight: 600,
					fontSize: '32px',
					lineHeight: '38px',
				},
			},
		}),
		[],
	);

	const theme = createTheme(themeOptions);
	theme.components = componentsOverride(theme as CustomTheme);

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				{children}
			</ThemeProvider>
		</StyledEngineProvider>
	);
};

export default ThemeConfig;
