import React from 'react';
import { useSelector } from 'react-redux';
import {Link as RouterLink} from 'react-router-dom';
import {
	Button, Link, Stack, Typography,
} from '@mui/material';

import CopyToClipboard from 'library/common/components/CopyToClipboard';
import { getReferralLink } from 'library/common/services/urls';
import { selectUserId } from 'library/common/selectors/user.selector';

import { showSnackbarMessage } from 'library/utils/showSnackbarMessage';
import Base from './Base';
import DialogCloud from './DialogCloud';
import CountNumber from './CountNumber';

interface IProps {
    amount: number;
}

const Invite: React.FunctionComponent<IProps> = ({ amount }) => {
	const id = useSelector(selectUserId);

	return (
		<Base
			title="Referral program"
			amount={amount}
			customButton={(
				<CopyToClipboard>
					{({ copy }) => (
						<Button
							onClick={() => {
								if (id) {
									copy(getReferralLink(id));
								} else {
									showSnackbarMessage('You must be logged in to receive a referral link.', 'info');
								}
							}}
							variant="outlined"
							sx={{
								px: 4,
								minWidth: '152px',
								textTransform: 'none',
								color: ({ palette }) => palette.secondary.light,
								borderColor: ({ palette }) => palette.secondary.light,

								'&:hover': {
									borderColor: ({ palette }) => palette.secondary.main,
								},
							}}
						>
							Get link
						</Button>
					)}
				</CopyToClipboard>
			)}
			description={(
				<>
					<Typography
						variant="body1"
						sx={{ color: ({ palette }) => palette.text.primary }}
					>
						{`Share your generated link and get +${amount} bonus (WB) for each new registered user`}
					</Typography>

					<Typography
						variant="caption"
					>
						<Link
							color="secondary"
							component={RouterLink}
							sx={{ color: ({ palette }) => palette.info.main }}
							to={{ pathname: '/terms', hash: '#referral_program' }}
						>
							Special offer for blog advertising
						</Link>
					</Typography>
				</>
			)}
		>
			<Stack spacing={3}>
				<DialogCloud
					text="Invite friends with a link"
					background="rgba(217, 235, 253, 0.3)"
				/>

				<Typography
					variant="caption"
					sx={{ pl: 0.5, color: ({ palette }) => palette.common.black, fontWeight: 500 }}
				>
					Registered with your link
				</Typography>

				<Stack direction="row" spacing={1}>
					<CountNumber count="01" />

					<Typography
						variant="body1"
						sx={{ pl: 1, pt: 2, color: ({ palette }) => palette.common.black }}
					>
						Person
					</Typography>
				</Stack>
			</Stack>
		</Base>
	);
};

export default Invite;
