import React, { SyntheticEvent, useRef } from 'react';
import TouchRipple, { TouchRippleActions } from '@mui/material/ButtonBase/TouchRipple';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface IProps {
	id: string | number;
    children: React.ReactElement
}

const ListItem: React.FunctionComponent<IProps> = ({id, children}) => {
	const navigate = useNavigate();

	const rippleRef = useRef<TouchRippleActions>(null);

	const onRippleStart = (e: SyntheticEvent) => {
		rippleRef?.current?.start(e);
	};

	const onRippleStop = (e: SyntheticEvent) => {
		rippleRef?.current?.stop(e);
	};

	return (
		<Grid
			container
			columnSpacing={1}
			sx={{
				height: '64px',
				cursor: 'pointer',
				position: 'relative',
				px: 1,
				'&:hover': {
					backgroundColor: '#3E395C',
					'.borderLine': {
						background: (theme) => (theme.palette as any).gradients.info,
					},

					'.info': {
						color: '#FFF3E0',
					},

					hr: {
						borderColor: '#FFF3E0',
					},

					svg: {
						fill: '#FFF3E0',
					},
				},
				'&:not(:first-of-type)': {
					mt: 2,
				},
			}}
			onMouseDown={onRippleStart}
			onMouseUp={onRippleStop}
			onMouseOut={onRippleStop}
			onClick={() => navigate(`/app/card/${id}`)}
		>
			{children}
			<TouchRipple ref={rippleRef} center={false} />
		</Grid>
	);
};

export default ListItem;
