import {ActionType, createReducer} from 'typesafe-actions';
import {Identity, UiNode} from '@ory/kratos-client/api';
import {Method} from 'axios';
import * as actions from '../actions/app.action';
import kratosUtils from '../../utils/kratos';
import { INotification } from '../types/notifications/notifications.d';

export type AppActions = ActionType<typeof actions>;

export type AppState = Readonly<{
  error: boolean;

	// Loader in settings page
	settingsLoader: boolean;

	settings?: {
		flowId: string;
		actionUrl: string;
		csrfToken: string;
		httpMethod: Method;
		expiresAt: Date;
		profileSettings: UiNode[],
		linksSettings: UiNode[],
		passwordSettings: UiNode[],
		identity: Identity,
	}

	isReactQueryDevtoolsOpen?: boolean;
	notificationsList?: INotification[];
	notificationCount: number;
	isWodaStoryPlaying: boolean;
	isWodaStoryMuted: boolean;
	mainPageParams: {
		isResourcesVisible: boolean;
		isCategoryVisible: boolean;
	}
}>

const initialState: AppState = {
	error: false,
	settingsLoader: true,
	notificationCount: 0,
	isWodaStoryPlaying: false,
	isWodaStoryMuted: true,
	mainPageParams: {
		isResourcesVisible: false,
		isCategoryVisible: false,
	},
};

export default createReducer<AppState, AppActions>(initialState)
	.handleAction(actions.setError, (state, action) => ({
		...state,
		error: action.haveError,
	}))

	.handleAction(actions.addNotification, (state, { payload }) => ({
		...state,
		notificationCount: state.notificationCount + 1,
		notificationsList: [
			payload, ...(state.notificationsList ? state.notificationsList : []),
		],
	}))

	.handleAction(actions.dropNotificationsList, (state) => ({
		...state,
		notificationCount: 0,
		notificationsList: [],
	}))

	.handleAction(actions.increaseNotificationCount, (state) => ({
		...state,
		notificationCount: state.notificationCount + 1,
	}))

	.handleAction(actions.setNotificationCount, (state, action) => ({
		...state,
		notificationCount: action.count,
	}))

	.handleAction(actions.setNotificationViewed, (state, action) => ({
		...state,
		notificationsList: state.notificationsList?.map((notification) => {
			if (notification.id === action.payload) {
				return { ...notification, viewed: true };
			}

			return notification;
		}),
	}))

	.handleAction(actions.setReactQueryDevtoolsOpen, (state) => ({
		...state,
		isReactQueryDevtoolsOpen: !state.isReactQueryDevtoolsOpen,
	}))

	.handleAction([actions.initSettingsPage.request, actions.applySettings.request], (state) => ({
		...state,
		settingsLoader: true,
	}))
	.handleAction([actions.initSettingsPage.success, actions.applySettings.success], (state, action) => ({
		...state,
		settingsLoader: false,
		settings: {
			flowId: action.payload.id,
			actionUrl: action.payload.ui.action.substring(action.payload.ui.action.indexOf('identity/') + 8),
			httpMethod: action.payload.ui.method as Method,
			expiresAt: new Date(action.payload.expires_at),
			profileSettings: action.payload?.ui?.nodes.filter((node) => node.group === 'profile'),
			linksSettings: action.payload?.ui?.nodes.filter((node) => node.group === 'oidc'),
			passwordSettings: action.payload?.ui?.nodes.filter((node) => node.group === 'password'),
			identity: action.payload.identity,
			csrfToken: kratosUtils.extractCSRFToken(action.payload.ui.nodes),
		},
	}))
	.handleAction([actions.initSettingsPage.failure, actions.applySettings.failure], (state) => ({
		...state,
		settingsLoader: false,
	}))
	.handleAction(actions.setWodaStoryPlayingState, (state, action) => ({
		...state,
		isWodaStoryPlaying: action.state === undefined ? !state.isWodaStoryPlaying : action.state,
	}))
	.handleAction(actions.setWodaStoryMuteState, (state, action) => ({
		...state,
		isWodaStoryMuted: action.state === undefined ? !state.isWodaStoryMuted : action.state,
	}));
