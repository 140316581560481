import { IMetadata } from 'library/common/types/cards/reels.d';

export default (metadata?: IMetadata) => {
	if (metadata?.orientation === 8) {
		return {
			width: 'calc(100vh - 24rem)',
			height: 'calc((100vh - 24rem) * 0.6)',
		};
	}

	if (metadata === undefined || metadata === null) {
		return {
			width: 265,
			height: '100%',
		};
	}

	if (metadata.height === metadata.width) {
		return {
			width: 500,
			height: 500,
		};
	}

	if (metadata.height > metadata.width) {
		return {
			width: 'calc((100vh - 24rem) * 0.6)',
			height: '100%',
		};
	}

	return {
		width: 'calc(100vh - 24rem)',
		height: 'calc((100vh - 24rem) * 0.6)',
	};
};
