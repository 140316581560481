import { useEffect, RefObject, useState } from 'react';

const useIsVisible = (ref: RefObject<any>, threshold?: number | number[]) => {
	const [isIntersecting, setIntersecting] = useState(false);

	useEffect(() => {
		const observer = new IntersectionObserver(([entry]) => setIntersecting(
			entry.isIntersecting,
		), {
			threshold,
		});

		observer.observe(ref.current);

		return () => {
			observer.disconnect();
		};
	}, [ref]);

	return isIntersecting;
};

export default useIsVisible;
