import React from 'react';
import { useSpring, animated } from 'react-spring';
import { IconButton } from '@mui/material';

interface IProps {
    isOpen: boolean;
    onClick(): void;
}

const BurgerButton: React.FunctionComponent<IProps> = ({ isOpen, onClick }) => {
	const first = useSpring({
		transform: isOpen
			? 'translate(5px, 32px) rotate(-45deg)'
			: 'translate(2px, 7px) rotate(0deg)',
	});
	const second = useSpring({
		transform: isOpen
			? 'translate(10px, 4px) rotate(45deg)'
			: 'translate(2px, 19px) rotate(0deg)',
	});
	const third = useSpring({
		transform: isOpen
			? 'translate(5px, 32px) rotate(-45deg)'
			: 'translate(2px, 31px) rotate(0deg)',
	});

	return (
		<IconButton
			size="small"
			aria-label="header-menu"
			onClick={onClick}
			sx={{
				'& svg': {
					fill: 'url(#linearColors)',
				},
			}}
		>
			<svg
				width="40"
				height="32"
				viewBox="0 0 44 44"
				fill="url(#linearColors)"
				xmlns="http://www.w3.org/2000/svg"
			>
				<linearGradient id="linearColors">
					<stop offset={0} stopColor="rgba(65, 154, 242, 1)" />
					<stop offset={1} stopColor="rgba(57, 69, 240, 1)" />
					<stop offset={2} stopColor="rgba(80, 62, 229, 1)" />
				</linearGradient>
				<animated.rect width="40" height="4" rx="2" style={first} />
				<animated.rect width="40" height="4" rx="2" style={second} />
				<animated.rect width="40" height="4" rx="2" style={third} />
			</svg>
		</IconButton>

	);
};

export default BurgerButton;
