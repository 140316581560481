import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const VerifiedIcon = (props: SvgIconProps) => (
	<SvgIcon width="89" height="93" viewBox="0 0 89 93" {...props}>
		<g clipPath="url(#a)">
			<path d="M0 21.429C0 9.594 9.181 0 20.507 0h47.986C79.819 0 89 9.594 89 21.429V71.57C89 83.406 79.819 93 68.493 93H20.507C9.18 93 0 83.406 0 71.571V21.43Z" fill="url(#b)" />
			<g filter="url(#c)" clipPath="url(#d)"><path d="M37.66 51.748a2 2 0 0 1-3.021.019l-6.829-7.775a2 2 0 0 0-2.81-.193l-4.493 3.89a2 2 0 0 0-.21 2.813L34.643 67.23a2 2 0 0 0 3.036 0l33.837-39.452a2 2 0 0 0-.425-2.977l-5.125-3.344a2 2 0 0 0-2.608.37L49 38.5 37.66 51.748Z" fill="#fff" /></g>
		</g>
		<defs>
			<clipPath id="a"><path fill="#fff" d="M0 0h89v93H0z" /></clipPath>
			<clipPath id="d"><path fill="#fff" d="M-3-1h96v96H-3z" /></clipPath>
			<linearGradient id="b" x1="0" y1="47" x2="89" y2="47" gradientUnits="userSpaceOnUse">
				<stop stopColor="#419AF2" />
				<stop offset="1" stopColor="#503EE6" />
			</linearGradient>
			<filter id="c" x="15.815" y="21.132" width="60.184" height="54.796" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
				<feOffset dy="4" />
				<feGaussianBlur stdDeviation="2" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
				<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1804_35085" />
				<feBlend in="SourceGraphic" in2="effect1_dropShadow_1804_35085" result="shape" />
			</filter>
		</defs>
	</SvgIcon>
);

export default VerifiedIcon;
