import React, { RefObject, useMemo, useState } from 'react';
import {
	Box, IconButton, Stack, Theme,
} from '@mui/material';
import { useSelector } from 'react-redux';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { useNavigate } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ShareIcon from 'resources/icons/ShareIcon';

import AvatarButton from 'library/common/components/AvatarButton';
import { CountedResource, IAuthor } from 'library/common/types/cards/cards.d';
import { getResourcePictureFrom, getToolbarAvatar } from 'library/utils/getters/getPicture';
import TimeIndicators from 'pages/Main/components/TimeIndicators';
import { IReelsStep } from 'library/common/types/cards/reels.d';
import { UUID } from 'library/common/types/app.d';
import toCardPage from 'library/utils/routes/getCardPageUrl';
import countDuplicateResources from 'library/utils/countDuplicateResources';
import BasketIcon from 'resources/icons/BasketIcon';
import { selectMainPageResourcesVisibility } from 'library/common/selectors/app.selector';

interface IProps {
    cardId: UUID;
    author: IAuthor;
    muted: boolean;
    toggleMuted(): void;
    steps: IReelsStep[];
    activeStepIndex: number;
	timelineRef: RefObject<HTMLDivElement>;
	isLiked?: boolean;
	shareCard(): void;
	likeCardHandler(): void;
}

const buttonStyle = {
	fill: (theme: Theme) => theme.palette.common.white,
};

const Interface: React.FunctionComponent<IProps> = ({
	cardId,
	author,
	muted,
	toggleMuted,
	activeStepIndex,
	steps,
	timelineRef,
	likeCardHandler,
	shareCard,
	isLiked,
}) => {
	const isResourcesVisible = useSelector(selectMainPageResourcesVisibility);

	const [isResourcesOpen, setisResourcesOpen] = useState(false);

	const countedResources: CountedResource[] = useMemo(() => countDuplicateResources(steps), [steps]);

	const navigate = useNavigate();

	const { width } = useSpring({
		from: { width: '0px' },
		width: isResourcesOpen ? `${countedResources.length > 3 ? 180 : 48 + countedResources.length * 38}px` : '0px',
		config: {
			duration: 300,
		},
	});

	return (
		<Box
			sx={{
				position: 'absolute',
				top: 0,
				left: 0,
				zIndex: 1,
				width: '100%',
				height: '100%',
			}}
			onClick={() => navigate(toCardPage(cardId))}
		>
			<TimeIndicators
				steps={steps}
				activeStepIndex={activeStepIndex}
				timelineRef={timelineRef}
				sx={{
					position: 'absolute',
					zIndex: 1,
					top: 8,
					left: 10,
					right: 10,
					width: 'auto',
				}}
			/>

			<Box
				sx={{
					height: '54px',
					width: '54px',
					borderRadius: '50%',
					position: 'absolute',
					zIndex: 1,
					top: 20,
					left: 10,
					background: ({ palette }) => (palette as any).gradients.info,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<AvatarButton
					author={author.login}
					authorId={author.id}
					isVerified={author.is_verified}
					userPhoto={getToolbarAvatar(author.avatar)}
					size={50}
				/>
			</Box>

			<IconButton
				sx={{
					position: 'absolute',
					zIndex: 1,
					top: 20,
					right: 10,
					background: ({ palette }) => palette.grey[600],

					'&:hover': {
						background: ({ palette }) => palette.grey[600],
					},
				}}
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					toggleMuted();
				}}
			>
				{
					muted ? <VolumeOffIcon sx={buttonStyle} /> : <VolumeUpIcon sx={buttonStyle} />
				}
			</IconButton>
			{
				isResourcesVisible && countedResources.length > 0 && (
					<IconButton
						sx={{
							position: 'absolute',
							zIndex: 1,
							bottom: 20,
							left: 10,
							background: ({ palette }) => palette.grey[600],

							'&:hover': {
								background: ({ palette }) => palette.grey[600],
							},
						}}
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							setisResourcesOpen((prev) => !prev);
						}}
					>
						<BasketIcon sx={buttonStyle} />
					</IconButton>
				)
			}
			<animated.div
				style={{
					position: 'absolute',
					bottom: 20,
					left: 10,
					height: '2.5rem',
					overflow: 'hidden',
					width,
				}}
			>
				<Stack
					direction="row"
					spacing={1}
					sx={{
						pl: '3rem',
						height: '100%',
						alignItems: 'center',
						borderWidth: '4px',
						fontWeight: 700,
						borderColor: ({ palette }) => palette.grey[600],
						background: ({ palette }) => `${palette.grey[600]}80`,
						borderStyle: 'solid',
						borderRadius: '20px',
					}}
				>
					{countedResources.slice(0, 3).map((resource) => (
						<Box
							key={resource.id}
							component="img"
							sx={{
								width: '24px',
								height: '24px',
							}}
							src={getResourcePictureFrom(resource)}
						/>
					))}
					{
						countedResources.length > 3 && (
							<Box
								sx={{
									width: '24px',
									height: '24px',
									color: ({ palette }) => palette.common.white,
								}}
							>
								{`+${countedResources.length - 3}`}
							</Box>
						)
					}
				</Stack>
			</animated.div>

			<IconButton
				sx={{
					position: 'absolute',
					bottom: 20,
					right: 60,
					background: ({ palette }) => palette.grey[600],

					'&:hover': {
						background: ({ palette }) => palette.grey[600],
					},
				}}
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					shareCard();
				}}
			>
				<ShareIcon sx={buttonStyle} />
			</IconButton>

			<IconButton
				sx={{
					position: 'absolute',
					bottom: 20,
					right: 10,
					background: ({ palette }) => palette.grey[600],

					'&:hover': {
						background: ({ palette }) => palette.grey[600],
					},
				}}
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					likeCardHandler();
				}}
			>
				<ThumbUpIcon sx={{ fill: isLiked ? '#E35EA4' : (theme: Theme) => theme.palette.common.white }} />
			</IconButton>
		</Box>
	);
};

export default Interface;
