import React from 'react';
import { Box, Button, Stack } from '@mui/material';

import ListWrapper from 'pages/Profile/components/List/ListWrapper';
import DoneIcon from 'resources/icons/DoneIcon';
import ProfileCounters from 'pages/Profile/components/ProfileCounters';
import { getUserDataSchema } from 'pages/Profile/data';
import { IUserProfileProps } from 'pages/Profile/types.d';

import ProfileHeader from './components/ProfileHeader';
import MobileSimpleList from './components/MobileSimpleList';
import SearchInput from '../components/SearchInput';

const MobileUserProfile: React.FunctionComponent<IUserProfileProps> = ({
	isCardsPending,
	isPending,
	isSubscribing,
	isSubscribed,
	canISubscribe,
	profileData: {
		id,
		username,
		logoURL,
		followers,
		followings,
		isVerified,
	},
	publishedCards,
	subscribeToUser,
	getPublishedCards,
}) => (
	<Stack sx={{ mt: 7.5, pb: 7.5, width: '100%' }}>
		<ProfileHeader
			isPending={isPending}
			username={username}
			logoURL={logoURL}
			isVerified={isVerified}
		/>

		<ProfileCounters
			isPending={isPending}
			data={{
				followers,
				followings,
				cardsCount: publishedCards.length ?? 0,
			}}
			schema={getUserDataSchema()}
		/>

		{
			canISubscribe && subscribeToUser && (
				<Box sx={{ mt: 4, px: 2 }}>
					<Button
						sx={{
							background: (theme) => (isSubscribed ? `${theme.palette.common.white}4D` : theme.palette.secondary.main),
						}}
						fullWidth
						variant="contained"
						disabled={isPending || isSubscribing}
						onClick={subscribeToUser}
						startIcon={isSubscribed ? <DoneIcon /> : undefined}
					>
						{isSubscribed ? 'You subscribed' : 'Follow author'}
					</Button>
				</Box>
			)
		}

		<Box sx={{ mt: 4, width: '100%', height: '100%' }}>
			<Stack direction="column" sx={{ height: '100%' }}>
				{id && <SearchInput isMobile userId={id} getPublishedCards={getPublishedCards} />}
				<ListWrapper
					label="No cards"
					cards={publishedCards}
					isCardsPending={isCardsPending}
					List={MobileSimpleList}
				/>
			</Stack>
		</Box>
	</Stack>
);

export default MobileUserProfile;
