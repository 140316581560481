import { CountedResource, IStep } from 'library/common/types/cards/cards.d';
import { IReelsStep } from 'library/common/types/cards/reels.d';

export default (stages: Array<IStep | IReelsStep>): CountedResource[] => Object.values(stages
	.map((stage) => stage.resources)
	.reduce((accumulator, currentValue) => ([...accumulator, ...currentValue]), [])
	.reduce((accumulator, currentValue) => ({
		...accumulator,
		[currentValue.id]: {
			...currentValue,
			count: ((accumulator)[currentValue.id]
				? (accumulator)[currentValue.id].count
				: 0
			) + 1,
		},
	}), {} as any));
