import React from 'react';
import { Modal, Paper } from '@mui/material';

import { COVER_SIZE, EditMode } from 'pages/Card/environments';
import { IDetailedCard } from 'library/common/types/cards/cards.d';
import { ICardDetailedFormData, IStepData } from 'library/common/types/cards/cardsCreation.d';
import { UUID } from 'library/common/types/app.d';
import { EditStateType } from 'pages/Card/types.d';

import DescriptionEdit from './DescriptionEdit';
import StepEdit from './StepEdit';
import StepCreate from './StepCreate';

interface IProps {
	editState: EditStateType;
    detailedCard: IDetailedCard;
    handleClose(): void;

    changeDescriptionHandler(newCardState: Partial<ICardDetailedFormData>): Promise<boolean>;
	changeStepHandler(stepId: UUID, newStepData: IStepData): Promise<boolean>;
	createStepHandler(sourceStepWeight: number, newStepData: IStepData): Promise<boolean>;
}

const Edit: React.FunctionComponent<IProps> = ({
	editState,
	detailedCard,
	handleClose,
	changeDescriptionHandler,
	changeStepHandler,
	createStepHandler,
}) => (
	<Modal
		open={editState.editMode !== EditMode.NONE}
		onClose={handleClose}
		aria-labelledby="modal-modal-title"
		aria-describedby="modal-modal-description"
	>
		<Paper sx={{
			p: { xs: 2, md: 4},
			overflowY: 'auto',
			maxHeight: '90%',
			borderRadius: '12px',
			position: 'absolute',
			display: 'block',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			minWidth: '50%',
			maxWidth: '99%',
		}}
		>
			{ editState.editMode === EditMode.DESCRIPTION && (
				<DescriptionEdit
					detailedCard={detailedCard}
					coverSize={COVER_SIZE}
					handleClose={handleClose}
					changeDescriptionHandler={changeDescriptionHandler}
				/>
			)}
			{
				editState.editMode === EditMode.EDIT_STEP && (
					<StepEdit
						steps={detailedCard.steps}
						selectedStepId={editState.editingStepId}
						changeStepHandler={(newStepData: IStepData) => changeStepHandler(editState.editingStepId, newStepData)}
						handleClose={handleClose}
					/>
				)
			}
			{
				editState.editMode === EditMode.CREATE_STEP && (
					<StepCreate
						sourceStepIndex={editState.sourceStepIndex}
						sourceStepWeight={editState.sourceStepWeight}
						createStepHandler={createStepHandler}
						handleClose={handleClose}
					/>
				)
			}
		</Paper>
	</Modal>
);

export default Edit;
