import React, {useEffect} from 'react';
import {Backdrop} from '@mui/material';
import {connect} from 'react-redux';
import {RootState} from 'main/rootReducer';
import * as userActionTypes from 'library/common/actions/user.action';
import WQLoader from '../WQLoader/WQLoader';

interface IWithInitializationProvider {
	globalLoader: boolean;
	getUserData: () => void;
}

const mapStateToProps = (state: RootState) => ({
	globalLoader: state.user.isGlobalPending,
});

const mapDispatchToProps = {
	getUserData: userActionTypes.authorizationAsync.request,
};

const withInitialization = (WrappedComponent: React.FunctionComponent) => {
	const WithInitializationProvider = ({globalLoader, getUserData}: IWithInitializationProvider) => {
		useEffect(() => {
			getUserData();
		}, []);

		return (
			<>
				{
					globalLoader ? (
						<Backdrop
							open
							style={{zIndex: 999, backgroundColor: '#FFF'}}
							transitionDuration={{appear: 0, enter: 0, exit: 50}}
						>
							<WQLoader />
						</Backdrop>
					) : (
						<WrappedComponent />
					)
				}
			</>
		);
	};

	return connect(mapStateToProps, mapDispatchToProps)(WithInitializationProvider);
};

export default withInitialization;
