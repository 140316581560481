import React from 'react';
import { Stack, IconButton } from '@mui/material';
import CommentIcon from '@mui/icons-material/Comment';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ShareIcon from 'resources/icons/ShareIcon';

interface IProps {
    likesCount: number;
	isLiked?: boolean;
    commentsCount: number;
    shareCard(): void;
    goToCard(): void;
    likeCardHandler(): void;
}

const Buttons: React.FunctionComponent<IProps> = ({
	commentsCount = 0,
	likesCount = 0,
	isLiked = false,
	shareCard,
	goToCard,
	likeCardHandler,
}) => (
	<Stack>
		<IconButton
			sx={{ backgroundColor: '#f9f9f9' }}
			onClick={shareCard}
		>
			<ShareIcon />
		</IconButton>

		<IconButton
			sx={{
				mt: 2,
				backgroundColor: '#f9f9f9',

				'&:after': {
					position: 'absolute',
					content: `"${commentsCount}"`,
					bottom: '-17px',
					fontSize: '14px',
				},
			}}
			onClick={goToCard}
		>
			<CommentIcon />
		</IconButton>

		<IconButton
			sx={{
				mt: 3,
				backgroundColor: '#f9f9f9',

				'&:after': {
					position: 'absolute',
					content: `"${likesCount}"`,
					bottom: '-17px',
					fontSize: '14px',
				},
			}}
			onClick={likeCardHandler}
		>
			<ThumbUpIcon sx={{ fill: isLiked ? 'url(#linearColors)' : undefined }} />
			<svg width={0} height={0}>
				<linearGradient id="linearColors">
					<stop offset={0} stopColor="rgba(65, 154, 242, 1)" />
					<stop offset={1} stopColor="rgba(57, 69, 240, 1)" />
					<stop offset={2} stopColor="rgba(80, 62, 229, 1)" />
				</linearGradient>
			</svg>
		</IconButton>
	</Stack>
);

export default Buttons;
