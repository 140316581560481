import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { RootState } from 'main/rootReducer';
import userServices from 'library/common/services/user.service';
import { showSnackbarMessage } from 'library/utils/showSnackbarMessage';
import { getProfileAvatar } from 'library/utils/getters/getPicture';
import { interactPermissions, selectIsSubscribed } from 'library/common/selectors/user.selector';
import { changeFollowingsList, setUserAvatar } from 'library/common/actions/user.action';
import { IExternalProfileData } from 'library/common/types/user.d';
import { UUID } from 'library/common/types/app.d';

// TODO Перенести тип
export interface IProfile {
	id: UUID;
	username: string;
	logoURL?: string;
	followings: number;
	followers: number;
	referredUsersCount: number;
	isVerified?: boolean;
}

interface IResult {
	progress: number | null;
    profileData: Partial<IProfile>;
    isPending: boolean;
	isSubscribing: boolean;
    noSuchUser: boolean;
    canISubscribe?: boolean;
    isSubscribed?: boolean;
    onSubscribe(subscriberId: string): void;
	setAvatarRequest(newAvatar: File[] | undefined): void;
}

const useProfile = (id?: UUID): IResult => {
	const {t} = useTranslation();

	const dispatch = useDispatch();

	// TODO REACT QUERY !!!!!!

	const [isPending, setIsPending] = useState<boolean>(false);
	const [noSuchUser, setNoSuchUser] = useState<boolean>(false);
	const [profileData, setProfileData] = useState<Partial<IProfile>>({});
	const [isSubscribing, setIsSubscribing] = useState<boolean>(false);

	const [progress, setProgress] = useState<number | null>(null);

	const canISubscribe = useSelector((state: RootState) => {
		if (id) {
			return interactPermissions(state, id);
		}

		return false;
	});

	const isSubscribed = useSelector((state: RootState) => selectIsSubscribed(state, id));

	const getProfile = useCallback(async (profileId: UUID | undefined) => {
		try {
			setIsPending(true);

			const profileResult = await userServices.getProfile(profileId);
			const fullStatisticsResult = await userServices.getFullStatistics(profileId);

			const { followers, followings } = fullStatisticsResult;

			const referredUsersCount = profileResult.referrals ?? 0;

			setProfileData({
				id: profileResult.id,
				logoURL: profileResult.logoURL,
				username: profileResult.username,
				followers: followers?.length || 0,
				followings: followings?.length || 0,
				referredUsersCount,
				isVerified: profileResult.isVerified,
			});
		} catch (error: any) {
			if (error?.response?.status && error?.response?.status === 404) {
				setNoSuchUser(true);
			}
			showSnackbarMessage(t('error_loading_data'), 'error', 4000);
		} finally {
			setIsPending(false);
		}
	}, [t]);

	const onSubscribe = useCallback(async (subscriberId: string) => {
		setIsSubscribing(true);

		try {
			await userServices[isSubscribed ? 'unfollowUser' : 'followUser'](subscriberId);
			const { followings, followers } = await userServices.getFullStatistics(subscriberId);

			setProfileData((prevState) => ({
				...prevState,
				followers: followers?.length || 0,
				followings: followings?.length || 0,
			}));

			dispatch(changeFollowingsList(subscriberId));
		} catch (e) {
			showSnackbarMessage('Something goes bad', 'error', 3000);
		} finally {
			setIsSubscribing(false);
		}
	},
	[dispatch, isSubscribed]);

	useEffect(() => {
		getProfile(id);
	}, [getProfile, id]);

	const setAvatarRequest = useCallback(async (newAvatar: File[] | undefined) => {
		setProgress(1);
		try {
			if (newAvatar && newAvatar.length > 0) {
				const file = newAvatar[0];
				const formData = new FormData();
				const cancelTokenSource = axios.CancelToken.source();

				formData.append('file', file);

				const result = await userServices
					.setAvatar<IExternalProfileData>(
						formData,
						{
							headers: {
								'Content-Type': 'multipart/form-data',
							},
							cancelToken: cancelTokenSource.token,
							onUploadProgress: (progressEvent: ProgressEvent) => (
								setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
							),
						},
					);

				const {data: {payload: {avatar}}} = result;

				const avatarUrl = getProfileAvatar(avatar);

				dispatch(setUserAvatar(avatarUrl));
				setProfileData((prev) => ({ ...prev, logoURL: avatarUrl }));
			} else {
				await userServices.dropAvatar({
					onUploadProgress: (progressEvent: ProgressEvent) => (
						setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
					),
				});
				dispatch(setUserAvatar(undefined));
				setProfileData((prev) => ({ ...prev, logoURL: undefined }));
			}
		} catch (e) {
			showSnackbarMessage('Avatar changing error', 'error');
		} finally {
			setProgress(null);
		}
	},
	[dispatch, setProfileData]);

	return {
		isPending,
		isSubscribing,
		canISubscribe,
		isSubscribed,
		noSuchUser,
		profileData,
		onSubscribe,
		setAvatarRequest,
		progress,
	};
};

export default useProfile;
