import React, { useContext } from 'react';
import {
	Box, Button, Stack, Typography,
} from '@mui/material';

import resources from '../resources';
import useNav from '../useNav';
import { NavContext, Sections } from './NavContext';

interface IProps {
	threshold?: number | number[]
	isAuth?: boolean;
	onJoinThePlatform: () => void;
}

const AboutUs: React.FunctionComponent<IProps> = ({ threshold, isAuth, onJoinThePlatform }) => {
	const ref = useNav(Sections.ABOUT_US, threshold);

	const { setActiveNavLinkId } = useContext(NavContext);

	const toFaqHandler = () => {
		setActiveNavLinkId?.(Sections.FAQ);
		document.getElementById(Sections.FAQ)?.scrollIntoView({ behavior: 'smooth' });
	};

	return (
		<Stack ref={ref} sx={{ position: 'relative', zIndex: 1 }}>
			<Box sx={{ display: 'flex' }}>
				<Typography
					variant="h6"
					sx={{
						fontWeight: 600,
						background: (theme) => (theme.palette as any).gradients.info,
						WebkitBackgroundClip: 'text',
						WebkitTextFillColor: 'transparent',
					}}
				>
					Welcome to WodaQota
				</Typography>
			</Box>
			<Typography
				variant="h2"
				sx={{
					mt: 2,
					fontSize: '44px',
					lineHeight: '130%',
					fontWeight: 800,
				}}
			>
				Platform for Discovering
				<br />
				Bite-Sized “How-To” Instructions
			</Typography>

			<Stack
				sx={{ mt: 5 }}
				direction={{ md: 'column', lg: 'row'}}
				alignItems="flex-start"
				justifyContent="space-between"
			>
				<Box sx={{ flex: { md: 1, lg: 0.5, xl: 0.4 } }}>
					<Box>
						<Box sx={{ position: 'relative', px: 2, maxWidth: '600px' }}>
							<Box sx={{
								position: 'absolute',
								top: 0,
								left: 0,
								width: '2px',
								height: '100%',
								background: 'linear-gradient(180deg, #419AF2 0.94%, #3945F0 28.62%, #503EE6 37.07%, #642CEC 44.04%, #9A39CB 59.83%, #DC43A3 76.36%, #F8B3A8 100.94%)',
								borderRadius: '10px',
							}}
							/>
							<Typography
								variant="h5"
								sx={{
									color: (theme) => theme.palette.text.secondary,
									fontWeight: 300,
									fontSize: '24px',
									lineHeight: '130%',
								}}
							>
								From recipes to NFTs, education to excursions and thousands of superb subjects in-between – WodaQota is the place to develop life-changing skills when time’s not on your side!
								<br />
								<br />
								Starting today, what will you learn?
							</Typography>
						</Box>

						<Stack direction="row" spacing={4} mt={9}>
							<Button
								sx={{
									height: '48px',
									fontSize: { sx: '12px', lg: '16px' },
									fontWeight: 400,
								}}
								variant="contained"
								onClick={onJoinThePlatform}
							>
								{`${isAuth ? 'Back to' : 'Join'} the platform`}
							</Button>
							<Button
								sx={{
									color: (theme) => theme.palette.secondary.light,
									height: '48px',
									fontSize: { sx: '12px', lg: '16px' },
									fontWeight: 400,
								}}
								onClick={toFaqHandler}
							>
								Answers to questions
							</Button>
						</Stack>
					</Box>
				</Box>
				<Box sx={{ flex: { md: 1, lg: 0.5, xl: 0.6 } }}>
					<Box
						component="img"
						src={resources.macBookImage}
						alt="Mac Book Pro"
						sx={{
							width: '100%',
							mt: {
								xs: 10, sm: 8, md: 5, lg: '-20px',
							},
							transform: {
								xs: 'translateX(20%)', sm: 'translateX(35%)', md: 'translateX(50%)', lg: 'none',
							},
						}}
					/>
				</Box>
			</Stack>

			<Stack mt={15}>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						justifyContent: 'start',
						alignItems: 'start',
					}}
				>
					<Box
						sx={{
							py: 0.3,
							pr: 0.3,
							ml: '-96px',
							width: '811px',
							color: '#151133',
							background: (theme) => (theme.palette as any).gradients.primary,
							borderTopRightRadius: '16px',
							borderBottomRightRadius: '16px',
						}}
					>
						<Box
							sx={{
								py: 5,
								px: 12,
								borderTopRightRadius: '13px',
								borderBottomRightRadius: '13px',
								background: (theme) => theme.palette.common.white,
							}}
						>
							<Typography
								variant="h4"
								sx={{
									fontWeight: 800,
									fontSize: '44px',
									lineHeight: '130%',
									background: (theme) => (theme.palette as any).gradients.primary,
									WebkitBackgroundClip: 'text',
									WebkitTextFillColor: 'transparent',
								}}
							>
								What is WodaQota?
							</Typography>
							<br />
							<Typography
								variant="body1"
								sx={{
									fontWeight: 300,
									fontSize: '24px',
									lineHeight: '130%',
								}}
							>
								WodaQota is a knowledge hub powered by the real experiences of real people,
								making it easier than ever for you to learn about anything you want while on the
								sofa or out and about
								<br />
								<br />
								We are currently focused on supporting the Indian market but have global aspirations!
							</Typography>
						</Box>

					</Box>

				</Box>

				<Box
					sx={{
						mt: 8,
						width: '100%',
						display: 'flex',
						justifyContent: { xs: 'flex-start', md: 'flex-end' },
						alignItems: 'flex-start',
					}}
				>
					<Box
						sx={{
							py: 0.3,
							pl: { xs: 0, md: 0.3 },
							pr: { xs: 0.3, md: 0 },
							ml: { xs: '-96px', md: 0 },
							mr: { xs: 0, md: '-96px' },
							width: '811px',
							background: (theme) => (theme.palette as any).gradients.success,
							color: '#151133',
							borderTopLeftRadius: { xs: 0, md: '16px' },
							borderBottomLeftRadius: { xs: 0, md: '16px' },
							borderTopRightRadius: { xs: '16px', md: 0 },
							borderBottomRightRadius: { xs: '16px', md: 0 },
						}}
					>
						<Box
							sx={{
								py: 5,
								px: 12,
								borderTopLeftRadius: { xs: 0, md: '13px' },
								borderBottomLeftRadius: { xs: 0, md: '13px' },
								borderTopRightRadius: { xs: '13px', md: 0 },
								borderBottomRightRadius: { xs: '13px', md: 0 },
								background: (theme) => theme.palette.common.white,
							}}
						>
							<Typography
								variant="h4"
								sx={{
									fontWeight: 800,
									fontSize: '44px',
									lineHeight: '130%',
									background: (theme) => (theme.palette as any).gradients.success,
									WebkitBackgroundClip: 'text',
									WebkitTextFillColor: 'transparent',
								}}
							>
								What is Our Mission?
							</Typography>
							<br />
							<Typography
								variant="body1"
								sx={{
									fontWeight: 300,
									fontSize: '24px',
									lineHeight: '130%',
								}}
							>
								WodaQota was created with a simple vision: help people help each other!
								<br />
								<br />
								It provides an endlessly rewarding, infinitely accessible learning ecosystem for
								enthusiastic experts to create crystal-clear guidance and keen students to absorb them
								<br />
								<br />
								Share your expertise and benefit from the experience of fellow knowledge-lovers around
								the world – with WodaQota, it’s easy!
							</Typography>
						</Box>
					</Box>
				</Box>
			</Stack>

		</Stack>
	);
};

export default AboutUs;
