import React from 'react';
import { Stack, Box, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import MHidden from 'library/common/components/MHidden/MHidden';
import SearchInput from 'library/common/components/Search/SearchInput';

import ActionsBar from 'library/common/components/Dashboard/children/Navbar/ActionsBar/ActionsBar';
import CategoryTabs, { IProps as CategoryTabsProps} from 'library/common/components/Dashboard/children/Navbar/ActionsBar/CategoryTabs';

interface IProps extends CategoryTabsProps {
    isMainPage: boolean;
    onOpenSidebar(): void;
}

const Desktop: React.FunctionComponent<IProps> = ({ isMainPage, onOpenSidebar }) => (
	<Stack
		direction="row"
		sx={{
			mt: { xs: 0, md: 3 },
			width: '100%',
			height: { xs: '100%', md: 'auto' },
			alignItems: { xs: 'center', md: 'flex-start' },
		}}
	>
		<MHidden breakpoint="lg" direction="Up">
			<MHidden breakpoint="md" direction="Down">
				<Box sx={{ mr: 6 }}>
					<IconButton onClick={onOpenSidebar}>
						<MenuIcon color="primary" />
					</IconButton>
				</Box>
			</MHidden>
		</MHidden>

		{
			isMainPage && (
				<Stack
					direction="column"
					width={{
						xs: '100%', md: '60%', lg: '70%', xl: '80%',
					}}
				>
					<SearchInput />
					<CategoryTabs />
				</Stack>
			)
		}

		<Box sx={{ ml: 'auto' }}>
			<ActionsBar />
		</Box>
	</Stack>
);

export default Desktop;
