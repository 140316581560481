import React, {useRef, useState} from 'react';
import {
	Box, Button, ButtonGroup, Stack,
} from '@mui/material';
import AvatarEditor from 'react-avatar-editor';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

import {IEditor} from './types.d';

const Editor = ({image, setImage, onCancel}: IEditor) => {
	const [scaleValue, setScaleValue] = useState<number>(1);
	const [rotate, setRotate] = useState<number>(0);

	const ref = useRef<any>(null);

	return (
		<Stack
			direction="column"
			spacing={2}
			justifyContent="center"
			alignItems="center"
		>
			<Box>
				<AvatarEditor
					ref={ref}
					image={image}
					borderRadius={300}
					border={8}
					width={250}
					height={250}
					scale={scaleValue}
					rotate={rotate}
				/>
			</Box>

			<ButtonGroup variant="outlined" aria-label="outlined button group">
				<Button onClick={() => setRotate((prevState) => (prevState - 90) % 360)}>
					<RotateLeftIcon />
				</Button>
				<Button disabled={scaleValue === 1} onClick={() => setScaleValue((prevState) => prevState - 1)}>
					<ZoomOutIcon />
				</Button>
				<Button disabled={scaleValue === 10} onClick={() => setScaleValue((prevState) => prevState + 1)}>
					<ZoomInIcon />
				</Button>
				<Button onClick={() => setRotate((prevState) => (prevState + 90) % 360)}>
					<RotateRightIcon />
				</Button>
			</ButtonGroup>

			<Stack spacing={1} direction="row">
				<Button variant="outlined" onClick={onCancel}>Cancel</Button>
				<Button
					variant="contained"
					onClick={() => {
						if (ref.current && ref.current?.getImageScaledToCanvas) {
							const blobBin = atob(ref.current.getImageScaledToCanvas().toDataURL('image/png').split(',')[1]);
							const array = blobBin.split('').map((_, i) => blobBin.charCodeAt(i));
							const file = new File([new Uint8Array(array)], 'avatar', {type: 'image/png'});

							setImage([file]);
						}
					}}
				>
					Apply
				</Button>
			</Stack>

		</Stack>
	);
};

export default Editor;
