import React from 'react';
import {
	Accordion, AccordionDetails, AccordionSummary, Box, Button, Paper, Stack, Typography,
} from '@mui/material';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

import useNav from '../useNav';
import { Sections } from './NavContext';

interface IProps {
	threshold?: number | number[]
}

const data = [
	{
		question: 'What Problem Does the WodaQota Platform Solve?',
		answer: 'We are spending hours searching everyday but we are wasting too much time searching for knowledge! WodaQota helps people to save own time and get clear practical HowTo knowledge, as well as provide content creators with option to monetize own HowTo experience',
	},
	{
		question: 'How Do You Contribute to Experts (Content Creators)?',
		answer: 'We provide contributors with the thrilling opportunity to monetize their experience and goods, services and lessons celebrating their expertise. In line with our future platform gamification strategy, they will also be rewarded digital coins designed for boosting user interaction!',
	},
	{
		question: 'Can Businesses / Universities Use WodaQota and How?',
		answer: 'Of course! WodaQota is a knowledge hub, where companies and public organisations can make and save clear instructions for their employees and for customers',
	},
	{
		question: 'What is WodaQard?',
		answer: 'WodaQota’s lightning-fast, super-effective learning platform is powered by instructions which we call WodaQards: bite-sized'
			+ 'knowledge cards that feature the result they provide and time it will take to achieve. No coding, no complex moderator review – just minimalist learning for maximum effect!',
	},
];

const FAQ: React.FunctionComponent<IProps> = ({ threshold }) => {
	const ref = useNav(Sections.FAQ, threshold);

	return (
		<Stack
			ref={ref}
			direction={{ xs: 'column', md: 'row' }}
			sx={{ position: 'relative', zIndex: 1 }}
			alignItems={{ xs: 'center', md: 'inherit' }}
			spacing={{ xs: 5, md: 0 }}
		>
			<Box
				sx={(theme) => ({
					width: { xs: '100%', md: '50%' },
					py: 5,
					px: { xs: 2, md: 12 },
					ml: { xs: 0, md: '-96px' },
					background: (theme.palette as any).gradients.success,
					color: theme.palette.common.white,
					borderTopRightRadius: { xs: 0, md: '16px' },
					borderBottomRightRadius: { xs: 0, md: '16px' },
					transform: { xs: 'scale(1.1)', sm: 'scale(1.05)', md: 'none' },
				})}
			>
				<Typography
					variant="h3"
					sx={{
						fontWeight: 800,
						fontSize: '44px',
						lineHeight: '130%',
					}}
				>
					FAQ
				</Typography>

				<Typography
					variant="h6"
					sx={{
						mt: 2,
						mb: 5,
						fontWeight: 600,
						fontSize: '24px',
						lineHeight: '130%',
					}}
				>
					Answered the most popular questions
				</Typography>

				{data.map((datum, index) => (
					<Accordion
					// eslint-disable-next-line react/no-array-index-key
						key={index}
						disableGutters
						sx={{
							backgroundColor: 'inherit',
							boxShadow: 'none',

							'&:before': {
								background: 'linear-gradient(90deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0) 100%)',
								borderRadius: '10px',
								transform: 'matrix(1, 0, 0, -1, 0, 0)',
							},
						}}
					>
						<AccordionSummary
							expandIcon={<ExpandCircleDownIcon sx={{ fill: (theme) => theme.palette.common.white }} />}
							aria-controls={`panel${index}a-content`}
							id={`panel${index}a-header`}
						>
							<Typography
								variant="h6"
								sx={{
									fontWeight: 400,
									fontSize: '20px',
									lineHeight: '24px',
									color: (theme) => theme.palette.common.white,
								}}
							>
								{datum.question}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Paper
								elevation={0}
								sx={{
									py: 2,
									pl: 2,
									pr: 2.8,
									color: (theme) => theme.palette.common.white,
									backgroundColor: (theme) => `${theme.palette.common.white}59`,
									borderRadius: '16px',
								}}
							>
								<Typography
									variant="body1"
									sx={{
										fontWeight: 400,
										fontSize: '16px',
										lineHeight: '130%',
									}}
								>
									{datum.answer}
								</Typography>
							</Paper>
						</AccordionDetails>
					</Accordion>
				))}
			</Box>

			<Box
				sx={{
					width: { xs: '80%', sm: '70%', md: '50%' },
					display: 'flex',
				}}
			>
				<Paper
					elevation={3}
					sx={{
						p: 4,
						mx: 'auto',
						mb: 'auto',
						borderRadius: '16px',
					}}
				>
					<Typography
						variant="body1"
						sx={{
							fontWeight: 700,
							fontSize: '28px',
							lineHeight: '34px',
							color: '#151133',
						}}
					>
						Any other questions?
					</Typography>

					<Typography
						variant="body1"
						sx={{
							mt: 2,
							fontWeight: 400,
							fontSize: '20px',
							lineHeight: '24px',
							color: '#151133',
						}}
					>
						We will promptly answer
						<br />
						any of your questions
					</Typography>

					<Button
						variant="contained"
						href="mailto:support@wodaqota.com"
						sx={{
							mt: 6,
							height: '48px',
							width: '136px',
							fontWeight: 400,
							fontSize: '16px',
							lineHeight: '20px',
						}}
					>
						To apply
					</Button>
				</Paper>
			</Box>
		</Stack>
	);
};

export default FAQ;
