import React, { useContext } from 'react';
import { Typography } from '@mui/material';
import { Sections, NavContext } from './NavContext';

interface IProps {
    id: Sections
    name: string;
	underlineWidth?: string;
}

const NavbarLink: React.FunctionComponent<IProps> = ({ id, name, underlineWidth = '50%' }) => {
	const { activeNavLinkId, setActiveNavLinkId } = useContext(NavContext);

	const onClickHandler = () => {
		setActiveNavLinkId?.(id);
		document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
	};

	return (
		<Typography
			variant="caption"
			component="span"
			onClick={onClickHandler}
			sx={(theme) => ({
				px: 2,
				py: 1,
				position: 'relative',
				cursor: 'pointer',
				color: theme.palette.secondary.main,
				fontWeight: 400,
				fontSize: '16px',
				lineHeight: '20px',

				'&:hover': {
					background: theme.palette.grey[100],
				},

				'&:after': {
					position: 'absolute',
					bottom: 6,
					left: 16,
					content: "''",
					width: activeNavLinkId === id ? underlineWidth : 0,
					height: '1px',
					background: (theme.palette.primary as any).darker,
					borderRadius: '10px',
					transition: 'width 1s ease-in-out',
					transitionDelay: '100ms',
				},
			})}
		>
			{name}
		</Typography>
	);
};

export default NavbarLink;
