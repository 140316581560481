import React, { useContext, useMemo } from 'react';
import { Box, Typography } from '@mui/material';

import Card from 'library/common/components/Card/Card';
import useFavoritesCards from 'library/common/data/card/useFavoritesCards';
import WQGridWithScroll from 'library/common/components/WQGrid/WQGridWithScroll';
import WQLoader from 'library/common/components/WQLoader/WQLoader';
import isNonNullable from 'library/utils/isNonNullable';
import { ICardActionListItem, IPoorCard } from 'library/common/types/cards/cards.d';
import { useTranslation } from 'react-i18next';
import { getPoster, getToolbarAvatar } from 'library/utils/getters/getPicture';
import ShareContext from 'library/common/context/ShareContext';
import ShareIcon from 'resources/icons/ShareIcon';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import { UUID } from 'library/common/types/app.d';
import { logger } from 'library/utils/lintersSugar';

interface IProps {}

const Liked: React.FunctionComponent<IProps> = () => {
	const {t} = useTranslation();

	const {
		data,
		isLoading,
		fetchNextPage,
		hasNextPage,
	} = useFavoritesCards();

	const cards = useMemo(() => (isNonNullable(data)
		? data.pages.reduce((accumulator, currentValue) => [...accumulator, ...currentValue.cards], [] as IPoorCard[])
		: []
	), [data]);

	const isSharingAvailable = document.getElementById('is-sharing-available')?.dataset?.sharing;

	const shareContext = useContext(ShareContext);

	const handleShareCard = (url: string) => shareContext?.openShareDialog({ url, target: 'card' });

	const cardButtonActionsList: ICardActionListItem[] = [
		// TODO make alive
		// {icon: BookmarkAddIcon, title: 'Add to favorites', callback: () => console.log(id)},
		...(isNonNullable(handleShareCard) && isSharingAvailable === 'true' ? [{
			icon: ShareIcon,
			title: 'Share',
			callback: (id: UUID) => handleShareCard?.(id),
		}] : []),
		// eslint-disable-next-line no-constant-condition
		...(false ? [{
			icon: ReportGmailerrorredIcon,
			title: 'Complain',
			callback: (id: UUID) => logger(id),
		}] : []),
	];

	if (isLoading) {
		return (
			<Box
				sx={{
					width: '100%',
					height: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					background: (theme) => (theme.palette.background as any).main,
				}}
			>
				<WQLoader />
			</Box>
		);
	}

	return (
		<Box
			pt={2}
			pb={4}
			sx={{
				width: '100%',
				background: (theme) => (theme.palette.background as any).main,
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			{cards.length === 0 && (
				<Box
					sx={{
						height: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Typography
						variant="h3"
						sx={{ opacity: 0.4 }}
					>
						{t('no_results_found_search_for_other_keywords')}
					</Typography>
				</Box>
			)}

			<WQGridWithScroll
				dataLength={cards?.length}
				hasNextPage={!!hasNextPage}
				getData={fetchNextPage}
			>
				{
					cards.map((card) => (
						<Card
							key={card.id}
							id={card.id}
							title={card.title}
							category={card.category.title}
							poster={getPoster(card, 'MIDDLE')}
							duration={card.duration}
							result={card.result.title}
							authorID={card.author.id}
							author={card.author.login}
							isVerified={card.author.is_verified}
							userPhoto={getToolbarAvatar(card.author.avatar)}
							date={card.created}
							comments={card.statistics.number_of_comments}
							// activations={card.statistics.number_of_activation}
							favorites={card.statistics.number_of_favorites}
							stageCount={card.number_of_steps}
							cardButtonActionsList={cardButtonActionsList}
						/>
					))
				}
			</WQGridWithScroll>
		</Box>
	);
};

export default Liked;
