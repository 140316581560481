import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Button, IconButton, Stack} from '@mui/material';

import {IUserData} from 'library/common/types/user.d';
import NotificationsPopover from 'library/common/components/Popovers/NotificationsPopover/NotificationsPopover';
import AccountPopover from 'library/common/components/Popovers/AccountPopover/AccountPopover';
import actionBarItems from '../actionBarItems';

interface IDesktopActions {
	isAuth: boolean;
	userData: IUserData;
}

const DesktopActions = ({isAuth, userData}: IDesktopActions) => {
	const {t} = useTranslation();

	const navigate = useNavigate();

	const {pathname} = useLocation();

	return (
		<Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
			{
				isAuth ? (
					<>
						{
							actionBarItems.map((items) => (
								<IconButton
									color={pathname === '/app/profile' ? 'secondary' : 'default'}
									key={items.path}
									aria-label={items.aria_label}
									disabled={items.disabled}
									onClick={() => navigate(`/app/${items.path}`)}
								>
									<items.icon fontSize="inherit" />
								</IconButton>
							))
						}

						<NotificationsPopover />

						<AccountPopover
							email={userData.email}
							username={userData.username}
							backgroundColor={userData.backgroundColor}
							logoURL={userData.logoURL}
						/>
					</>
				) : (
					<Button
						color="primary"
						variant="contained"
						sx={{p: '6px 64px'}}
						onClick={() => navigate('/login')}
					>
						{t('sign_in')}
					</Button>
				)
			}
		</Stack>
	);
};

export default DesktopActions;
