import { useSnackbar, WithSnackbarProps } from 'notistack';

let useSnackbarRef: WithSnackbarProps;

export const SnackbarUtilsConfigurator = () => {
	useSnackbarRef = useSnackbar();
	return null;
};

export const showSnackbarMessage = (
	message: string,
	type: 'success' | 'error' | 'info' | 'warning' = 'success',
	displayTime: number = 3000,
) => { useSnackbarRef.enqueueSnackbar(message, { variant: type, autoHideDuration: displayTime}); };
