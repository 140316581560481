export const MOBILE_WIDTH = 80;
export const MOBILE_HEIGHT = 80;
export const DESKTOP_WIDTH = 240;
export const DESKTOP_HEIGHT = 240;

export const avatarRadius = (mobileViewDisabled?: boolean) => {
	if (mobileViewDisabled) {
		return {
			width: DESKTOP_WIDTH,
			height: DESKTOP_HEIGHT,
		};
	}

	return {
		width: { xs: MOBILE_WIDTH, md: DESKTOP_WIDTH },
		height: { xs: MOBILE_HEIGHT, md: DESKTOP_HEIGHT },
	};
};
