import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

import { Sections } from './NavContext';
import useNav from '../useNav';

interface IProps {
	threshold?: number | number[]
}

const values = [
	{
		title: 'Social justice',
		description: 'Access to information for all, and the chance for every person on Earth to share their knowledge as well as be rewarded for doing so',
	},
	{
		title: 'Sustainable development',
		description: 'Sharing information and practices that support our preservation of Planet Earth and make it a safer, cleaner place for future generations to enjoy',
	},
	{
		title: 'Quality education',
		description: 'The availability of a solid education to citizens of developing countries, so that we all have a fair chance to provide for ourselves and our families',
	},
];

const CoreValues: React.FunctionComponent<IProps> = ({ threshold }) => {
	const ref = useNav(Sections.CORE_VALUES, threshold);

	return (
		<Stack ref={ref} direction="column" sx={{ position: 'relative', zIndex: 1 }}>
			<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<Typography
					sx={{
						textAlign: 'center',
						fontWeight: 800,
						fontSize: { xs: '50px', sm: '75px', md: '100px' },
						lineHeight: '130%',
						textTransform: 'uppercase',
						background: (theme) => (theme.palette as any).gradients.info,
						WebkitBackgroundClip: 'text',
						WebkitTextFillColor: 'transparent',
					}}
				>
					3 core values
				</Typography>
			</Box>

			<Stack pt={{ xs: 2, md: 9}}>
				{
					values.map((value, index) => (
						<Box
							key={value.title}
							sx={{
								display: 'flex',
								justifyContent: index % 2 === 0 ? 'flex-start' : 'flex-end',
							}}
						>
							<Box sx={{
								my: { xs: 4, md: 0 },
								width: { xs: '100%', sm: '60%', md: '50%' },
								px: { xs: 4, md: 10 },
							}}
							>
								<Box sx={{ display: 'inline-block' }}>
									<Typography
										sx={{
											fontWeight: 700,
											fontSize: { xs: '32px', md: '48px' },
											lineHeight: '130%',
											color: '#151133',
										}}
									>
										{value.title}
									</Typography>
									<Box sx={{
										mt: 1,
										mb: 4,
										height: '3px',
										width: '100%',
										borderRadius: '10px',
										background: (theme) => (theme.palette as any).gradients.info,
									}}
									/>
								</Box>
								<Typography
									sx={{
										fontWeight: 400,
										fontSize: '20px',
										lineHeight: '130%',
										color: '#151133',
									}}
								>
									{value.description}
								</Typography>
							</Box>
						</Box>
					))
				}
			</Stack>
		</Stack>
	);
};

export default CoreValues;
